function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { worldViewerWindow } from './const';
import { getMixerTab, getUseLegacyAudioToggle } from './logics/dataOps';
export class CHELAudio {
    load(fileName, toplay, loop) {
        this.toplay = toplay;
        if (getUseLegacyAudioToggle()) {
            this.audio.src = fileName;
            this.audio.load();
            const that = this;
            if (toplay) {
                that.play(loop);
            }
            this.audio.addEventListener('ended', function() {
                that.playing = false;
            }, false);
        } else {
            const request = new XMLHttpRequest();
            request.open('GET', fileName, true);
            request.responseType = 'arraybuffer';
            const that = this;
            request.onload = function() {
                that.context.decodeAudioData(request.response, function(buf) {
                    that.source.buffer = buf;
                    that.source.connect(that.gainNode);
                    that.gainNode.connect(that.context.destination);
                    if (toplay) {
                        that.play(loop);
                    }
                });
            };
            request.send();
        }
    }
    play(loop) {
        if (!this.toplay && this.isPlay()) return;
        if (this.audio !== undefined) {
            this.audio.loop = loop;
            this.audio.play();
            this.playing = true;
        } else {
            this.source.loop = loop;
            this.source.start(0);
            this.playBeginTime = this.context.currentTime;
            this.playing = true;
        }
        this.toplay = false;
    }
    stop() {
        if (!this.playing) return;
        if (this.audio !== undefined) {
            this.audio.pause();
        } else {
            this.source.stop();
        }
        this.playing = false;
    }
    isPlay() {
        if (this.toplay) return 1;
        return this.playing ? 1 : 0;
    }
    getPlayTimeAudio() {
        if (!this.playing) return 0;
        if (this.audio !== undefined) {
            return 0;
        } else {
            const parseTime = this.context.currentTime - this.playBeginTime;
            return parseInt(parseTime * 1000, 10);
        }
    }
    setVolume(volume) {
        if (this.audio === undefined) {
            this.gainNode.gain.value = getMixerTab() * volume;
        }
    }
    release() {
        this.stop();
    }
    constructor(type, volume){
        _defineProperty(this, "type", void 0);
        _defineProperty(this, "toplay", void 0);
        _defineProperty(this, "audio", void 0);
        _defineProperty(this, "playing", void 0);
        _defineProperty(this, "playBeginTime", void 0);
        _defineProperty(this, "context", void 0);
        _defineProperty(this, "source", void 0);
        _defineProperty(this, "gainNode", void 0);
        _defineProperty(this, "getType", ()=>this.type);
        _defineProperty(this, "isPlaying", ()=>this.playing);
        _defineProperty(this, "getContext", ()=>this.context);
        _defineProperty(this, "getGainNode", ()=>this.gainNode);
        this.type = type;
        this.toplay = false;
        if (getUseLegacyAudioToggle()) {
            this.audio = new Audio();
            this.playing = false;
            this.playBeginTime = 0.0;
        } else {
            this.context = new (worldViewerWindow.AudioContext || worldViewerWindow.webkitAudioContext)();
            this.playing = false;
            this.playBeginTime = 0.0;
            if (this.context === undefined) {
                return;
            }
            this.source = this.context.createBufferSource();
            this.gainNode = this.context.createGain();
            this.gainNode.gain.value = volume;
            const that = this;
            this.source.onended = function() {
                const buf = that.source.buffer;
                const ended = that.source.onended;
                that.source.stop();
                that.source = that.context.createBufferSource();
                that.source.onended = ended;
                that.source.buffer = buf;
                that.source.connect(that.gainNode);
                that.playing = false;
            };
        }
    }
}
