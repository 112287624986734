const formatDate = (date)=>{
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()} ${date.getSeconds()}`;
};
export const getNowDate = ()=>{
    const date = new Date();
    return formatDate(date);
};
export const debugLog = (text, ...optionalParams)=>{
    // TODO 今後dotenvを導入し、環境変数別にdebugLogを表示しないように設定も可能
    // tslint:disable-next-line:no-console
    console.log(`[ ${getNowDate()} ] ${text} `, ...optionalParams);
};
