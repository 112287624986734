function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { CAchievementApiNotification } from "./CAchievementApiNotification";
export class CAchievement {
    addReceivedToastEventListener(fn) {
        var _this_m_AchievementApiNotification;
        (_this_m_AchievementApiNotification = this.m_AchievementApiNotification) === null || _this_m_AchievementApiNotification === void 0 ? void 0 : _this_m_AchievementApiNotification.addPersonalEvent(fn);
    }
    send(params) {
    /* Heliportにあったコード、タイトル名の取得を行っている
    const resourceRepository = factory.get('resource')
    const achievement = await resourceRepository.get.getAchievementInformation(
      data.ReferenceID
    )
    if (!achievement) {
      const message = `failed to fetch achivement. ReferenceID: ${data.ReferenceID}`
      console.error(message)
      return returnFailure(400, 'heliport', message)
    }
    */ /* APIの対応が完了するまではコメントアウト
    // paramsからタイトルが取れる？
    const isLangJa = worldViewerWindow.hel_isLangJa()
    //let achievementName = isLangJa ? achievement.title : achievement.title_en
    let achievementName = "Test achievementName"
    if (achievementName.length > 15) {
      achievementName = achievementName.substring(0, 15) + '...'
    }
    // TODO: 英語の通知
    const message = isLangJa
      ? `アチーブメント「${achievementName}」を獲得しました！`
      : `アチーブメント「${achievementName}」を獲得しました！`

    worldViewerWindow.hel_toast_notify_message(message, 250, 3500, 250);
    */ }
    // コンストラクタ
    constructor(){
        _defineProperty(this, "m_AchievementApiNotification", null);
        this.m_AchievementApiNotification = new CAchievementApiNotification();
    }
}
