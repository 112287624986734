function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { GetIHeliNetwork } from "../heliodor_networklib";
export class CItemPropertyApiNotification {
    addPosChannelEvent(fn) {
        var _this_m_ApiNotification, _this_m_ApiNotification1;
        (_this_m_ApiNotification = this.m_ApiNotification) === null || _this_m_ApiNotification === void 0 ? void 0 : _this_m_ApiNotification.addEventListener((_this_m_ApiNotification1 = this.m_ApiNotification) === null || _this_m_ApiNotification1 === void 0 ? void 0 : _this_m_ApiNotification1.m_notifyCollectionPositionChannel, 'ItemProperty', fn);
    }
    // コンストラクタ
    constructor(){
        var _GetIHeliNetwork;
        _defineProperty(this, "m_ApiNotification", null);
        this.m_ApiNotification = (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getApiNotificationHandler();
    }
}
