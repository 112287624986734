import { worldViewerWindow } from '../const';
import { debugLog } from '../debugLog';
import { getGyroActiveToggle, getXrwDataFolderName } from './dataOps';
import { getMainCanvasDOMElement, getMainCanvas4XrDOMElement } from './domOps';
import { hel_getiOSVersion, hel_isLangJa, hel_isMobile } from './platform';
/**
 * Common 初期�?
 * @param type
 * @param characterType
 * @param args1
 * @param strArgs0
 * @param canTouch
 * @param isMultiPlay
 * @param isXr
 */ export const requestBootStrapGameEngine = (type, characterType, args1, strArgs0, canTouch, isMultiPlay, isXr)=>{
    if (Module.ccall === undefined) {
        return;
    }
    const mainCanvas = getMainCanvasDOMElement();
    const mainCanvas4Xr = getMainCanvas4XrDOMElement();
    const width = isXr && mainCanvas4Xr !== undefined ? mainCanvas4Xr.width : mainCanvas !== undefined ? mainCanvas.width : -1;
    const height = isXr && mainCanvas4Xr !== undefined ? mainCanvas4Xr.height : mainCanvas !== undefined ? mainCanvas.height : -1;
    if (width < 0 || height < 0) {
        debugLog(`[ requestBootStrapGameEngine ] canvas is not found for target env`);
        return;
    }
    Module.ccall('Start', '', [
        'int',
        'int',
        'int',
        'int',
        'int',
        'int',
        'int',
        'int',
        'int',
        'int',
        'int',
        'string',
        'string',
        'boolean'
    ], [
        type,
        width,
        height,
        hel_isMobile() ? 1 : 0,
        hel_getiOSVersion(),
        worldViewerWindow.hel_isMac() ? 1 : 0,
        canTouch ? 1 : 0,
        hel_isLangJa() ? 1 : 0,
        characterType,
        args1,
        isMultiPlay ? 1 : 0,
        strArgs0,
        getXrwDataFolderName(),
        isXr
    ], {
        async: true
    });
};
/**
 * COMMON
 */ export const requestToDrawFrame = ()=>{
    if (Module.ccall !== undefined) {
        worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_SetDateNow, Date.now().toString());
        Module.ccall('DrawFrame', '', [], []);
    }
};
/**
 * COMMON
 * @param procedureId
 * @param order
 */ export const requestProcedureCallFor = (procedureId, order)=>{
    if (worldViewerWindow.cef_SetText !== undefined) {
        worldViewerWindow.cef_SetText(procedureId, order);
        // CEFが別プロセスで動いており、現時点で同期的に戻り値を取得出来ないのでひとまず0を返す
        return 0;
    } else if (Module.ccall !== undefined) {
        return Module.ccall('SetText', 'int', [
            'int',
            'string'
        ], [
            procedureId,
            order
        ]);
    } else {
        return 0;
    }
};
//
export const requestProcedurePostFor = (procedureId, order)=>{
    if (worldViewerWindow.cef_PostMessage !== undefined) {
        worldViewerWindow.cef_PostMessage(procedureId, order);
    } else if (Module.ccall !== undefined) {
        Module.ccall('PostMessage', 'void', [
            'int',
            'string'
        ], [
            procedureId,
            order
        ]);
    }
};
/**
 * COMMON
 * @param procedureId
 * @param ary_u8
 */ export const requestProcedureCallBinaryFor = (procedureId, ary_u8)=>{
    if (Module.ccall !== undefined) {
        return Module.ccall('SetBinary', 'int', [
            'int',
            'array',
            'int'
        ], [
            procedureId,
            ary_u8,
            ary_u8.length
        ]);
    }
    return 0;
};
/**
 * Skyway
 * @param data
 */ export const requestSendingReceivedPacketsFromSkyway = (data)=>{
    if (worldViewerWindow.cef_ReceiveSkywayData !== undefined) {
        worldViewerWindow.cef_ReceiveSkywayData(data);
    } else if (Module.ccall !== undefined) {
        Module.ccall('ReceiveSkywayData', '', [
            'string'
        ], [
            data
        ]);
    }
};
/**
 * Net
 * @param ID
 * @param Data
 */ export const requestOnNetReceivePosJoin = (ID, Data)=>{
    if (worldViewerWindow.cef_OnNetReceivePosJoin !== undefined) {
        worldViewerWindow.cef_OnNetReceivePosJoin(ID, Data);
    } else if (Module.ccall !== undefined) {
        Module.ccall('OnNetReceivePosJoin', '', [
            'string',
            'string'
        ], [
            ID,
            Data
        ]);
    }
};
/**
 * Net
 * @param ID
 * @param Data
 */ export const requestOnNetReceivePosLeave = (ID, Data)=>{
    if (worldViewerWindow.cef_OnNetReceivePosLeave !== undefined) {
        worldViewerWindow.cef_OnNetReceivePosLeave(ID, Data);
    } else if (Module.ccall !== undefined) {
        Module.ccall('OnNetReceivePosLeave', '', [
            'string',
            'string'
        ], [
            ID,
            Data
        ]);
    }
};
/**
 * Net
 * @param ID
 * @param Data
 */ export const requestOnNetReceiveVoiceJoin = (ID, Data)=>{
    if (worldViewerWindow.cef_OnNetReceiveVoiceJoin !== undefined) {
        worldViewerWindow.cef_OnNetReceiveVoiceJoin(ID, Data);
    } else if (Module.ccall !== undefined) {
        Module.ccall('OnNetReceiveVoiceJoin', '', [
            'string',
            'string'
        ], [
            ID,
            Data
        ]);
    }
};
/**
 * Net
 * @param ID
 * @param Data
 */ export const requestOnNetReceiveVoiceLeave = (ID, Data)=>{
    if (worldViewerWindow.cef_OnNetReceiveVoiceLeave !== undefined) {
        worldViewerWindow.cef_OnNetReceiveVoiceLeave(ID, Data);
    } else if (Module.ccall !== undefined) {
        Module.ccall('OnNetReceiveVoiceLeave', '', [
            'string',
            'string'
        ], [
            ID,
            Data
        ]);
    }
};
/**
 * XR
 * @param vrcameradata
 */ export const requestToSetVrCameraPointer = (vrcameradata)=>{
    if (Module.ccall !== undefined) {
        Module.ccall('SetVRCameraValue', '', [
            'float'
        ], [
            vrcameradata
        ]);
    }
};
/**
 * XR
 * @param data
 */ export const requestSetVrPointer = (data)=>{
    if (Module.ccall !== undefined) {
        Module.ccall('SetVRValue', '', [
            'float'
        ], [
            data
        ]);
    }
};
/**
 * XR
 * @version 2.9.0
 * @param pose
 */ export const requestSetVrPosePointer = (pose)=>{
    if (Module.ccall !== undefined) {
        Module.ccall('SetVRPoseValue', '', [
            'float'
        ], [
            pose
        ]);
    }
};
/**
 * XR。ctx経由
 */ export const requestToBindFrameBufferOf = (glLayer)=>{
    if (Module.ctx !== undefined && Module.ctx !== null && glLayer !== undefined) {
        Module.ctx.bindFramebuffer(Module.ctx.FRAMEBUFFER, glLayer.framebuffer);
    }
};
/**
 * XR。ctx経由
 */ export const requestToReleaseBoundFrameBuffer = ()=>{
    if (Module.ctx !== undefined && Module.ctx !== null) {
        Module.ctx.bindFramebuffer(Module.ctx.FRAMEBUFFER, null);
    }
};
/**
 * XR
 * @param cameradata
 */ export const requestIfCameraFpsValueAvailable = (cameradata)=>{
    if (Module.ccall !== undefined) {
        return Module.ccall('GetFPSCameraValue', 'boolean', [
            'float'
        ], [
            cameradata
        ]);
    }
    return false;
};
/**
 * XR
 * @param target
 */ export const requestToGetValueOf = (target)=>{
    if (Module.ccall !== undefined) {
        return Module.getValue(target, 'float');
    }
    return 0;
};
/**
 * XR
 * @param controllerNum
 * @param activeData
 * @param controllerData {float}
 * @param gamePadData {float}
 */ export const requestVRControllerValue = (controllerNum, activeData, controllerData, gamePadData)=>{
    if (Module.ccall !== undefined) {
        return Module.ccall('SetVRControllerValues', '', [
            'number',
            'number',
            'float',
            'float'
        ], [
            controllerNum,
            activeData,
            controllerData,
            gamePadData
        ]);
    }
    return false;
};
/**
 * Switch to WebXR
 * @param width
 * @param height
 * @returns
 */ export const switchToVR = (width, height)=>{
    if (Module.ccall !== undefined) {
        return Module.ccall('SwitchToVR', '', [
            'int',
            'int'
        ], [
            width,
            height
        ]);
    }
    return false;
};
/**
 * Switch to None WebXR
 * @param width
 * @param height
 * @returns
 */ export const switchToNoneVR = ()=>{
    if (Module.ccall !== undefined) {
        return Module.ccall('SwitchToNoneVR', '', [], []);
    }
    return false;
};
/**
 * DEBUG
 * @param text
 */ export const requestDebugOutput = (text)=>{
    if (Module.ccall !== undefined) {
        Module.ccall('DebugOutput', '', [
            'string'
        ], [
            text
        ]);
    }
};
/**
 * DEBUG
 * @param pointer
 * @param length
 */ export const requestMemoryBytesOutput = (pointer, length)=>{
    if (Module.ccall !== undefined) {
        Module.ccall('DebugMemoryByteOutput', '', [
            'array',
            'int'
        ], [
            pointer,
            length
        ]);
    }
};
/**
 * [ GUI ]
 */ export const requestResizeWindow = (mainCanvas)=>{
    if (Module.ccall !== undefined && mainCanvas !== undefined) {
        const width = Math.floor(mainCanvas.clientWidth * window.devicePixelRatio);
        const height = Math.floor(mainCanvas.clientHeight * window.devicePixelRatio);
        Module.ccall('ResizeWindow', '', [
            'int',
            'int',
            'int',
            'int'
        ], [
            mainCanvas.width,
            mainCanvas.height,
            width,
            height
        ]);
    }
};
/**
 * [ GYRO ]
 */ export const requestToToggleActivateGyro = ()=>{
    if (Module.ccall !== undefined) {
        Module.ccall('ActiveGyro', '', [
            'int'
        ], [
            getGyroActiveToggle() ? 1 : 0
        ]);
    }
};
/**
 * [ FILE ]
 * @param file
 */ export const requestOnDropFilesBegin = (file)=>{
    if (Module.ccall !== undefined) {
        Module.ccall('OnDropFilesBeginFromJS', '', [
            'string'
        ], [
            file.name
        ]);
    }
};
/**
 * [ FILE ]
 * @param evt
 */ export const requestOnDropFilesProgress = (evt)=>{
    if (Module.ccall !== undefined) {
        Module.ccall('OnDropFilesProgressFromJS', '', [
            'float'
        ], [
            evt.loaded / evt.total
        ]);
    }
};
/**
 * [ FILE ]
 * @param file
 * @param ary_u8
 * @param data
 */ export const requestOnDropFiles = (file, ary_u8, data)=>{
    if (Module.ccall !== undefined) {
        Module.ccall('OnDropFilesFromJS', '', [
            'string',
            'number',
            'int'
        ], [
            file.name,
            data,
            ary_u8.length
        ]);
    }
};
/**
 * ウィンドウアクティブ化・非アクティブ化
 */ export const requestOnWindowActivate = (isActivate)=>{
    if (Module.ccall !== undefined) {
        Module.ccall('OnWindowActivate', ``, [
            'int'
        ], [
            isActivate
        ]);
    }
};
/**
 * アンロード直前
 */ export const requestOnBeforeUnload = ()=>{
    if (Module.ccall !== undefined) {
        Module.ccall('OnBeforeUnload');
    }
};
/**
 * アンロード
 */ export const requestOnUnload = ()=>{
    if (Module.ccall !== undefined) {
        Module.ccall('OnUnload');
    }
};
/**
 * HeliScriptのコンポーネント呼び出し
 */ export const callComponentMethod = (isScene, itemName, componentName, methodName, params)=>{
    if (Module.ccall !== undefined) {
        // 戻り値を入れてもらう配列 (要素を1個埋めておく)
        const resultArray = [
            undefined
        ];
        const success = Module.ccall('CallComponentMethod', 'number', [
            `number`,
            'number',
            'string',
            'string',
            'string',
            'number'
        ], [
            Emval.toHandle(resultArray),
            isScene,
            itemName,
            componentName,
            methodName,
            Emval.toHandle(params)
        ]);
        if (success) {
            return resultArray[0];
        }
    }
    return null;
};
/**
 * [ HARDWARE ]
 * @param targetLength
 */ export const requestMemoryAllocate = (targetLength)=>{
    return Module._malloc(targetLength);
};
/**
 * [ HARDWARE ]
 * @param ary_u8
 * @param data
 */ export const requestSetDataToHeap = (ary_u8, data)=>{
    Module.HEAPU8.set(ary_u8, data);
};
/**
 * [ HARDWARE ]
 * @param data
 */ export const requestReleaseMemoryFor = (data)=>{
    Module._free(data);
};
/**
 * [ HARDWARE ]
 * @param data
 */ export const sendGamepadData = (button1, button2, button3, button4, button5, button6, button7, button8, button9, button10, button11, button12, button13, button14, button15, button16, axis1, axis2, axis3, axis4)=>{
    if (Module.ccall !== undefined) {
        Module.ccall('ReceiveGamepadData', '', [
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float',
            'float'
        ], [
            button1,
            button2,
            button3,
            button4,
            button5,
            button6,
            button7,
            button8,
            button9,
            button10,
            button11,
            button12,
            button13,
            button14,
            button15,
            button16,
            axis1,
            axis2,
            axis3,
            axis4
        ]);
    }
};
