import { INITIAL_MIXER_DISTANCE_SETTING, INITIAL_MIXER_FADER_SETTING, INITIAL_MIXER_MUTE_SETTING, INITIAL_MIXER_SETTING, INITIAL_MIXER_REAL_SETTING, worldViewerWindow } from '../const';
/**
 * WorldIdAccessor(GET)
 * @returns String
 */ export const getWorldId = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_WorldID === undefined || worldViewerWindow.g_WorldID === null) {
        return undefined;
    }
    return worldViewerWindow.g_WorldID;
};
/**
 * WorldIdAccessor(SET)
 * @remarks
 * 未使用
 * worldIdがstringでなく入ってくる可能性も考えられるためunknown型で定義
 * front側で設定することが多い。var設定
 * @param worldId グローバルワールドID
 */ export const setWorldId = (worldId)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof worldId !== 'string') {
        return;
    }
    worldViewerWindow.g_WorldID = worldId;
};
/**
 * SkyRoomIdAccessor(GET)
 * TODO 内海 default値がわからないため0にしてる
 * @returns String
 */ export const getSkyRoomId = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Skyway_RoomID === undefined || worldViewerWindow.g_Skyway_RoomID === null) {
        return '0';
    }
    return worldViewerWindow.g_Skyway_RoomID;
};
/**
 * SkyRoomIdAccessor(SET)
 * @remarks
 * front側で設定することが多い。var設定
 * @param skyRoomId グローバルスカイルームID
 */ export const setSkyRoomId = (skyRoomId)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_Skyway_RoomID = skyRoomId;
};
/**
 * SpotIdAccessor(GET)
 * @returns String
 */ export const getSpotId = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Spot === undefined || worldViewerWindow.g_Spot === null) {
        return undefined;
    }
    return worldViewerWindow.g_Spot;
};
/**
 * SpotIdAccessor(SET)
 * @remarks
 * 未使用
 * spotIdがstringでなく入ってくる可能性も考えられるためunknown型で定義
 * front側で設定することが多い。var設定
 * @param spotId グローバルスポットID
 */ export const setSpotId = (spotId)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof spotId !== 'string') {
        return;
    }
    worldViewerWindow.g_Spot = spotId;
};
/**
 * WorldSelectedToggle (GET)
 * @returns Boolean
 */ export const getWorldSelectedToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_ShowWorldSelect === undefined || worldViewerWindow.g_ShowWorldSelect === null) {
        return false;
    }
    return worldViewerWindow.g_ShowWorldSelect;
};
/**
 * WorldSelectedToggle(SET)
 * @remarks
 * 未使用
 * toggleがboolean以外の可能性があるためunknown型に
 * front側で設定することが多い。var設定
 * @param toggle ワールド選択されてるか
 */ export const setWorldSelectedToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_ShowWorldSelect = toggle;
};
/**
 * LoggedInStatusToggle (GET)
 * @returns Boolean
 */ export const getLoggedInStatusToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Logined === undefined || worldViewerWindow.g_Logined === null) {
        return false;
    }
    return worldViewerWindow.g_Logined;
};
/**
 * LoggedInStatusToggle(SET)
 * @remarks
 * toggleがbooleanでなく入ってくる可能性も考えられるためunknown型で定義
 *
 * @param toggle ログイン状態
 */ export const setLoggedInStatusToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Logined = toggle;
};
/**
 * CanvasName (GET)
 * @returns String
 */ export const getCanvasFileName = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_CanvasFileName === undefined || worldViewerWindow.g_CanvasFileName === null) {
        return '';
    }
    return worldViewerWindow.g_CanvasFileName;
};
/**
 * CanvasName(SET)
 * @remarks
 * updatedCanvasNameがstringでなく入ってくる可能性も考えられるためunknown型で定義
 *
 * @param updatedCanvasName 更新予定のキャンバスファイル名
 */ export const setCanvasFileName = (updatedCanvasName)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof updatedCanvasName !== 'string') {
        return;
    }
    worldViewerWindow.g_CanvasFileName = updatedCanvasName;
};
/**
 * CompImageListFileName (GET)
 * @returns String
 */ export const getCompImageListFileName = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_CompImageListFileName === undefined || worldViewerWindow.g_CompImageListFileName === null) {
        return '';
    }
    return worldViewerWindow.g_CompImageListFileName;
};
/**
 * CompImageListFileName(SET)
 * @remarks
 * updatedCompImageListFileNameがstringでなく入ってくる可能性も考えられるためunknown型で定義
 *
 * @param updatedCanvasName 更新予定のキャンバスファイル名
 */ export const setCompImageListFileName = (updatedCompImageListFileName)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof updatedCompImageListFileName !== 'string') {
        return;
    }
    worldViewerWindow.g_CompImageListFileName = updatedCompImageListFileName;
};
/**
 * CurrentUserName (GET)
 * @returns String
 */ export const getCurrentUserName = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_CurrentUserName === undefined || worldViewerWindow.g_CurrentUserName === null) {
        return '';
    }
    return worldViewerWindow.g_CurrentUserName;
};
/**
 * CurrentUserName(SET)
 * @remarks
 * updatedCanvasNameがstringでなく入ってくる可能性も考えられるためunknown型で定義
 *
 * @param updatedUserName 更新予定のユーザ名
 */ export const setCurrentUserName = (updatedUserName)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_CurrentUserName = updatedUserName;
};
/**
 * WebGL10Toggle (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns Boolean
 */ export const getWebGL10Toggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_UseWebGL10 === undefined || worldViewerWindow.g_UseWebGL10 === null) {
        return false;
    }
    return worldViewerWindow.g_UseWebGL10;
};
/**
 * WebGL10Toggle(SET)
 * @remarks
 * updatedMixerTabValueがnumberでなく入ってくる可能性も考えられるためunknown型で定義
 *
 * @param toggle フラグ
 */ export const setWebGL10Toggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_UseWebGL10 = toggle;
};
/**
 * WebGLDebugRendererInfo(GET)
 * @returns WEBGL_debug_renderer_info|undefined
 */ export const getWebGLDebugRendererInfo = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_WEBGL_debug_renderer_info === undefined || worldViewerWindow.g_WEBGL_debug_renderer_info === null) {
        return undefined;
    }
    return worldViewerWindow.g_WEBGL_debug_renderer_info;
};
/**
 * WebGLDebugRendererInfo(SET)
 * @param webglDebugRendererInfo
 */ export const setWebGLDebugRendererInfo = (webglDebugRendererInfo)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || webglDebugRendererInfo === undefined || webglDebugRendererInfo === null) {
        return;
    }
    worldViewerWindow.g_WEBGL_debug_renderer_info = webglDebugRendererInfo;
};
/**
 * WebGLDebugRendererInfoToggle(SET)
 * @param toggle
 */ export const setWebGLDebugRendererInfoToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_WEBGL_debug_renderer_info = toggle;
};
/**
 * UnmaskedRendererWebGL(SET)
 * @param text
 * @returns
 */ export const setUnmaskedRendererWebGL = (text)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof text !== 'string') {
        return;
    }
    worldViewerWindow.g_UnmaskedRendererWebGL = text;
};
/**
 * UnmaskedVendorWebGL(SET)
 * @param text
 * @returns
 */ export const setUnmaskedVendorWebGL = (text)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof text !== 'string') {
        return;
    }
    worldViewerWindow.g_UnmaskedVendorWebGL = text;
};
/**
 * IntelWebGLPlatform(GET)
 * @returns Intel製GPUかどうか
 */ export const isIntelWebGLPlatform = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_UnmaskedRendererWebGL === undefined || worldViewerWindow.g_UnmaskedRendererWebGL == null) {
        return false;
    }
    return worldViewerWindow.g_UnmaskedRendererWebGL.toLowerCase().indexOf('intel') >= 0;
};
/**
 * CompressedTexturePvrtc (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns WEBGL_compressed_texture_pvrtc|undefined
 */ export const getTextureExtOnPvrtc = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_extPVRTC === undefined || worldViewerWindow.g_extPVRTC === null) {
        return undefined;
    }
    return worldViewerWindow.g_extPVRTC;
};
/**
 * CompressedTexturePvrtc(SET)
 * @remarks
 * compressedTexturePvrtc以外が入ってくる可能性も考えられるためunknown型で定義
 *
 * @param compressedTexturePvrtc
 */ export const setTextureExtOnPvrtc = (compressedTexturePvrtc)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || compressedTexturePvrtc === undefined || compressedTexturePvrtc === null) {
        return;
    }
    worldViewerWindow.g_extPVRTC = compressedTexturePvrtc;
};
/**
 * TextureEtc2Toggle (GET)
 * @remarks
 * 未使用
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns Boolean
 */ export const getTexturePvrtcToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_TextureCompression_PVRTC === undefined || worldViewerWindow.g_TextureCompression_PVRTC === null) {
        return false;
    }
    return worldViewerWindow.g_TextureCompression_PVRTC;
};
/**
 * TextureEtc2Toggle(SET)
 * @remarks
 * toggleが違う形式で入ってくる可能性も考えられるためunknown型で定義
 *
 * @param toggle
 */ export const setTexturePvrtcToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_TextureCompression_PVRTC = toggle;
};
/**
 * CompressedTextureEtc2 (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns WEBGL_compressed_texture_etc|undefined
 */ export const getTextureExtOnETC2 = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_extETC2 === undefined || worldViewerWindow.g_extETC2 === null) {
        return undefined;
    }
    return worldViewerWindow.g_extETC2;
};
/**
 * CompressedTextureEtc2(SET)
 * @remarks
 * compressedTextureEtc2c以外が入ってくる可能性も考えられるためunknown型で定義
 *
 * @param compressedTextureEtc2
 */ export const setTextureExtOnETC2 = (compressedTextureEtc2)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || compressedTextureEtc2 === undefined || compressedTextureEtc2 === null) {
        return;
    }
    worldViewerWindow.g_extETC2 = compressedTextureEtc2;
};
/**
 * TextureEtc2Toggle (GET)
 * @remarks
 * 未使用
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns Boolean
 */ export const getTextureETC2Toggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_TextureCompression_ETC2 === undefined || worldViewerWindow.g_TextureCompression_ETC2 === null) {
        return false;
    }
    return worldViewerWindow.g_TextureCompression_ETC2;
};
/**
 * TextureEtc2Toggle(SET)
 * @remarks
 * toggleが違う形式で入ってくる可能性も考えられるためunknown型で定義
 *
 * @param toggle
 */ export const setTextureETC2Toggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_TextureCompression_ETC2 = toggle;
};
/**
 * CompressedTextureASTC (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns WEBGL_compressed_texture_astc|undefined
 */ export const getTextureExtOnAstc = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_extASTC === undefined || worldViewerWindow.g_extASTC === null) {
        return undefined;
    }
    return worldViewerWindow.g_extASTC;
};
/**
 * CompressedTextureASTC(SET)
 * @remarks
 * compressedTextureAstc以外が入ってくる可能性も考えられるためunknown型で定義
 *
 * @param compressedTextureAstc
 */ export const setTextureExtOnAstc = (compressedTextureAstc)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || compressedTextureAstc === undefined || compressedTextureAstc === null) {
        return;
    }
    worldViewerWindow.g_extASTC = compressedTextureAstc;
};
/**
 * TextureASTCToggle (GET)
 * @remarks
 * 未使用
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns Boolean
 */ export const getTextureAstcToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_TextureCompression_ASTC === undefined || worldViewerWindow.g_TextureCompression_ASTC === null) {
        return false;
    }
    return worldViewerWindow.g_TextureCompression_ASTC;
};
/**
 * TextureASTCToggle(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setTextureAstcToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_TextureCompression_ASTC = toggle;
};
/**
 * CompressedTextureDXT (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns any|undefined
 */ export const getTextureExtOnDxt = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_extDXT === undefined || worldViewerWindow.g_extDXT === null) {
        return undefined;
    }
    return worldViewerWindow.g_extDXT;
};
/**
 * CompressedTextureDXT(SET)
 * @remarks
 * compressedTextureDxt以外が入ってくる可能性も考えられるためunknown型で定義
 *
 * @param compressedTextureDxt
 */ export const setTextureExtOnDxt = (compressedTextureDxt)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || compressedTextureDxt === undefined || compressedTextureDxt === null) {
        return;
    }
    worldViewerWindow.g_extDXT = compressedTextureDxt;
};
/**
 * TextureDXTToggle (GET)
 * @remarks
 * 未使用
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns Boolean
 */ export const getTextureDxtToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_TextureCompression_DXT === undefined || worldViewerWindow.g_TextureCompression_DXT === null) {
        return false;
    }
    return worldViewerWindow.g_TextureCompression_DXT;
};
/**
 * TextureDXTToggle(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setTextureDxtToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_TextureCompression_DXT = toggle;
};
/**
 * ExtensionShaderTextureLod (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns EXT_shader_texture_lod|undefined
 */ export const getExtShaderTextureLod = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_EXT_shader_texture_lod === undefined || worldViewerWindow.g_EXT_shader_texture_lod === null) {
        return undefined;
    }
    return worldViewerWindow.g_EXT_shader_texture_lod;
};
/**
 * ExtensionShaderTextureLod(SET)
 * @remarks
 * extShaderTextureLod以外が入ってくる可能性も考えられるためunknown型で定義
 *
 * @param extShaderTextureLod
 */ export const setExtShaderTextureLod = (extShaderTextureLod)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || extShaderTextureLod === undefined || extShaderTextureLod === null) {
        return;
    }
    worldViewerWindow.g_EXT_shader_texture_lod = extShaderTextureLod;
};
/**
 * OesStandardDerivatives(GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns OES_standard_derivatives|undefined
 */ export const getOesStandardDerivatives = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_OES_standard_derivatives === undefined || worldViewerWindow.g_OES_standard_derivatives === null) {
        return undefined;
    }
    return worldViewerWindow.g_OES_standard_derivatives;
};
/**
 * OesStandardDerivatives(SET)
 * @remarks
 * oesStandardDerivatives以外が入ってくる可能性も考えられるためunknown型で定義
 *
 * @param oesStandardDerivatives
 */ export const setOesStandardDerivatives = (oesStandardDerivatives)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || oesStandardDerivatives === undefined || oesStandardDerivatives === null) {
        return;
    }
    worldViewerWindow.g_OES_standard_derivatives = oesStandardDerivatives;
};
/**
 * ExtShaderTextureLodToggle (GET)
 * @remarks
 * 未使用
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns Boolean
 */ export const getExtShaderTextureLodToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Use_EXT_shader_texture_lod === undefined || worldViewerWindow.g_Use_EXT_shader_texture_lod === null) {
        return false;
    }
    return worldViewerWindow.g_Use_EXT_shader_texture_lod;
};
/**
 * ExtShaderTextureLodToggle(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setExtShaderTextureLodToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_EXT_shader_texture_lod = toggle;
};
/**
 * OesStandardDerivatives (GET)
 * @remarks
 * 未使用
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns Boolean
 */ export const getOesStandardDerivativesToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Use_OES_standard_derivatives === undefined || worldViewerWindow.g_Use_OES_standard_derivatives === null) {
        return false;
    }
    return worldViewerWindow.g_Use_OES_standard_derivatives;
};
/**
 * OesStandardDerivatives(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setOesStandardDerivativesToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_OES_standard_derivatives = toggle;
};
/**
 * ExtColorBufferFloat (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns undefined
 */ export const getExtColorBufferFloat = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_EXT_color_buffer_float === undefined || worldViewerWindow.g_EXT_color_buffer_float === null) {
        return undefined;
    }
    return worldViewerWindow.g_EXT_color_buffer_float;
};
/**
 * ExtColorBufferFloat(SET)
 * @remarks
 *
 * @param ExtColorBufferFloat
 */ export const setExtColorBufferFloat = (ExtColorBufferFloat)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || ExtColorBufferFloat === undefined || ExtColorBufferFloat === null) {
        return;
    }
    worldViewerWindow.g_EXT_color_buffer_float = ExtColorBufferFloat;
};
/**
 * ExtColorBufferHalfFloat (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns EXT_color_buffer_half_float|undefined
 */ export const getExtColorBufferHalfFloat = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_EXT_color_buffer_half_float === undefined || worldViewerWindow.g_EXT_color_buffer_half_float === null) {
        return undefined;
    }
    return worldViewerWindow.g_EXT_color_buffer_half_float;
};
/**
 * ExtColorBufferHalfFloat(SET)
 * @remarks
 * extColorBufferHalfFloat以外が入ってくる可能性も考えられるためunknown型で定義
 *
 * @param extColorBufferHalfFloat
 */ export const setExtColorBufferHalfFloat = (extColorBufferHalfFloat)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || extColorBufferHalfFloat === undefined || extColorBufferHalfFloat === null) {
        return;
    }
    worldViewerWindow.g_EXT_color_buffer_half_float = extColorBufferHalfFloat;
};
/**
 * ExtColorBufferToggle (GET)
 * @remarks
 * 未使用
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns Boolean
 */ export const getExtColorBufferToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Use_EXT_color_buffer === undefined || worldViewerWindow.g_Use_EXT_color_buffer === null) {
        return false;
    }
    return worldViewerWindow.g_Use_EXT_color_buffer;
};
/**
 * ExtColorBufferToggle(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setExtColorBufferToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_EXT_color_buffer = toggle;
};
/**
 * WebglTextureFloat (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns WEBGL_depth_texture|undefined
 */ export const getWebglDepthTexture = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_WEBGL_depth_texture === undefined || worldViewerWindow.g_WEBGL_depth_texture === null) {
        return undefined;
    }
    return worldViewerWindow.g_WEBGL_depth_texture;
};
/**
 * OesTextureFloat (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns OES_texture_float|undefined
 */ export const getOesTextureFloat = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_OES_texture_float === undefined || worldViewerWindow.g_OES_texture_float === null) {
        return undefined;
    }
    return worldViewerWindow.g_OES_texture_float;
};
/**
 * OesTextureHalfFloat (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns OES_texture_half_float|undefined
 */ export const getOesTextureHalfFloat = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_OES_texture_half_float === undefined || worldViewerWindow.g_OES_texture_half_float === null) {
        return undefined;
    }
    return worldViewerWindow.g_OES_texture_half_float;
};
/**
 * OesTextureFloatLinear (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns OES_texture_float_linear|undefined
 */ export const getOesTextureFloatLinear = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_OES_texture_float_linear === undefined || worldViewerWindow.g_OES_texture_float_linear === null) {
        return undefined;
    }
    return worldViewerWindow.g_OES_texture_float_linear;
};
/**
 * OesTextureHalfFloatLinear (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns OES_texture_half_float_linear|undefined
 */ export const getOesTextureHalfFloatLinear = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_OES_texture_half_float_linear === undefined || worldViewerWindow.g_OES_texture_half_float_linear === null) {
        return undefined;
    }
    return worldViewerWindow.g_OES_texture_half_float_linear;
};
/**
 * WebglDepthTexture(SET)
 * @remarks
 * webglDepthTexture以外が入ってくる可能性も考えられるためunknown型で定義
 *
 * @param oesTextureFloat
 */ export const setWebglDepthTexture = (webglDepthTexture)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || webglDepthTexture === undefined || webglDepthTexture === null) {
        return;
    }
    worldViewerWindow.g_WEBGL_depth_texture = webglDepthTexture;
};
/**
 * OesTextureFloat(SET)
 * @remarks
 * oesTextureloat以外が入ってくる可能性も考えられるためunknown型で定義
 *
 * @param oesTextureFloat
 */ export const setOesTextureFloat = (oesTextureFloat)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || oesTextureFloat === undefined || oesTextureFloat === null) {
        return;
    }
    worldViewerWindow.g_OES_texture_float = oesTextureFloat;
};
/**
 * OesTextureHalfFloat(SET)
 * @remarks
 * oesTextureHalfFloat以外が入ってくる可能性も考えられるためunknown型で定義
 *
 * @param oesTextureHalfFloat
 */ export const setOesTextureHalfFloat = (oesTextureHalfFloat)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || oesTextureHalfFloat === undefined || oesTextureHalfFloat === null) {
        return;
    }
    worldViewerWindow.g_OES_texture_half_float = oesTextureHalfFloat;
};
/**
 * OesTextureFloatLinear(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param oesTextureFloatLinear
 */ export const setOesTextureFloatLinear = (oesTextureFloatLinear)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || oesTextureFloatLinear === undefined || oesTextureFloatLinear === null) {
        return;
    }
    worldViewerWindow.g_OES_texture_float_linear = oesTextureFloatLinear;
};
/**
 * OesTextureHalfFloatLinear(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param oesTextureHalfFloatLinear
 */ export const setOesTextureHalfFloatLinear = (oesTextureHalfFloatLinear)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || oesTextureHalfFloatLinear === undefined || oesTextureHalfFloatLinear === null) {
        return;
    }
    worldViewerWindow.g_OES_texture_half_float_linear = oesTextureHalfFloatLinear;
};
/**
 * ExtTextureFilterAnisotropic (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns EXT_texture_filter_anisotropic |undefined
 */ export const getExtTextureFilterAnisotropic = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_EXT_texture_filter_anisotropic === undefined || worldViewerWindow.g_EXT_texture_filter_anisotropic === null) {
        return undefined;
    }
    return worldViewerWindow.g_EXT_texture_filter_anisotropic;
};
/**
 * ExtTextureFilterAnisotropic(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param extTextureFilterAnisotropic
 */ export const setExtTextureFilterAnisotropic = (extTextureFilterAnisotropic)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || extTextureFilterAnisotropic === undefined || extTextureFilterAnisotropic === null) {
        return;
    }
    worldViewerWindow.g_EXT_texture_filter_anisotropic = extTextureFilterAnisotropic;
};
/**
 * AngleInstancedArrays (GET)
 * @remarks
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns ANGLE_instanced_arrays |undefined
 */ export const getAngleInstancedArrays = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_ANGLE_instanced_arrays === undefined || worldViewerWindow.g_ANGLE_instanced_arrays === null) {
        return undefined;
    }
    return worldViewerWindow.g_ANGLE_instanced_arrays;
};
/**
 * AngleInstancedArrays (SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param angleInstancedArray
 */ export const setAngleInstancedArrays = (angleInstancedArray)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || angleInstancedArray === undefined || angleInstancedArray === null) {
        return;
    }
    worldViewerWindow.g_ANGLE_instanced_arrays = angleInstancedArray;
};
/**
 * OesTextureHalfFloatToggle (GET)
 * @remarks
 * 未使用
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns Boolean
 */ export const getOesTextureHalfFloatToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Use_OES_texture_half_float === undefined || worldViewerWindow.g_Use_OES_texture_half_float === null) {
        return false;
    }
    return worldViewerWindow.g_Use_OES_texture_half_float;
};
/**
 * OesTextureFloatToggle(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setOesTextureFloatToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_OES_texture_float = toggle;
};
/**
 * WebglDepthTextureToggle(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setWebglDepthTextureToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_WEBGL_depth_texture = toggle;
};
/**
 * ExtTextureFilterAnisotropic (SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setExtTextureFilterAnisotropicToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_Ext_texture_filter_anisotropic = toggle;
};
/**
 * AngleInstancedArraysToggle (SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setAngleInstancedArraysToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_ANGLE_instanced_arrays = toggle;
};
/**
 * AngleInstancedArraysToggle (GET)
 * @remarks
 * 未使用
 * render_calleeのみで利用してる...他と一緒に状態管理してもよいかも
 * @returns Boolean
 */ export const getAngleInstancedArraysToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Use_ANGLE_instanced_arrays === undefined || worldViewerWindow.g_Use_ANGLE_instanced_arrays === null) {
        return false;
    }
    return worldViewerWindow.g_Use_ANGLE_instanced_arrays;
};
/**
 * OesTextureHalfFloatToggle(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setOesTextureHalfFloatToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_OES_texture_half_float = toggle;
};
/**
 * OesTextureFloatLinearToggle(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setOesTextureFloatLinearToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_OES_texture_float_linear = toggle;
};
/**
 * OesTextureHalfFloatLinearToggle(SET)
 * @remarks
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setOesTextureHalfFloatLinearToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Use_OES_texture_half_float_linear = toggle;
};
/**
 * MixerTab (GET)
 * @remarks
 * 状態管理であるためシングルトンのように持ちたいかも
 * @returns Number(decimal)
 */ export const getMixerTab = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_MixerTabActive === undefined || worldViewerWindow.g_hel_MixerTabActive === null) {
        return 1.0;
    }
    return worldViewerWindow.g_hel_MixerTabActive;
};
/**
 * MixerTab(SET)
 * @remarks
 * updatedMixerTabValueがnumberでなく入ってくる可能性も考えられるためunknown型で定義
 *
 * @param updatedMixerTabValue 更新予定のMixerタブ値
 */ export const setMixerTab = (updatedMixerTabValue)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof updatedMixerTabValue !== 'number') {
        return;
    }
    worldViewerWindow.g_hel_MixerTabActive = updatedMixerTabValue;
};
/**
 * Mixer (GET)
 * @param index
 * @returns Number
 */ export const getMixerSettingAt = (index)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_Mixer === undefined || worldViewerWindow.g_hel_Mixer === null) {
        return INITIAL_MIXER_SETTING[index];
    }
    return worldViewerWindow.g_hel_Mixer[index];
};
/**
 * Mixer(SET)
 * @param volume ボリューム。decimal
 * @param typeIndex 指定インデックス。0より
 */ export const setMixerSettingAt = (volume, typeIndex)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_hel_Mixer[typeIndex] = volume;
};
/**
 * MixerReal (GET)
 * @param index
 * @returns Number
 */ export const getMixerRealSettingAt = (index)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_MixerReal === undefined || worldViewerWindow.g_hel_MixerReal === null) {
        return INITIAL_MIXER_REAL_SETTING[index];
    }
    return worldViewerWindow.g_hel_RealMixer[index];
};
/**
 * MixerReal (SET)
 * @param volume ボリューム。decimal
 * @param typeIndex 指定インデックス。0より
 */ export const setMixerRealSettingAt = (volume, typeIndex)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_hel_RealMixer[typeIndex] = volume;
};
/**
 * MixerFader (GET)
 * @param index
 * @returns Number
 */ export const getMixerFaderSettingAt = (index)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_MixerFade === undefined || worldViewerWindow.g_hel_MixerFade === null) {
        return INITIAL_MIXER_FADER_SETTING[index];
    }
    return worldViewerWindow.g_hel_MixerFade[index];
};
/**
 * MixerFader(SET)
 * @param volume ボリューム。decimal
 * @param typeIndex 指定インデックス。0より
 */ export const setMixerFaderSettingAt = (volume, typeIndex)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_hel_MixerFade[typeIndex] = volume;
};
/**
 * MixerDistance (GET)
 * @param index
 * @returns Number
 */ export const getMixerDistanceAt = (index)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_MixerDistance === undefined || worldViewerWindow.g_hel_MixerDistance === null) {
        return INITIAL_MIXER_DISTANCE_SETTING[index];
    }
    return worldViewerWindow.g_hel_MixerDistance[index];
};
/**
 * MixerDistance(SET)
 * @param volume ボリューム。decimal
 * @param typeIndex 指定インデックス。0より
 */ export const setMixerDistanceAt = (volume, typeIndex)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_hel_MixerDistance[typeIndex] = volume;
};
/**
 * MixerMute (GET)
 * @param index
 * @returns Number
 */ export const getMixerMuteAt = (index)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_MixerMute === undefined || worldViewerWindow.g_hel_MixerMute === null) {
        return INITIAL_MIXER_MUTE_SETTING[index];
    }
    return worldViewerWindow.g_hel_MixerMute[index];
};
/**
 * MixerMute(SET)
 * @param volume ボリューム。decimal
 * @param typeIndex 指定インデックス。0より
 */ export const setMixerMuteAt = (volume, typeIndex)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_hel_MixerMute[typeIndex] = volume;
};
/**
 * MixerMasterVolume (GET)
 */ export const getMixerMasterVolume = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_MixerMasterVolume === undefined || worldViewerWindow.g_hel_MixerMasterVolume === null) {
        return 1.0;
    }
    return worldViewerWindow.g_hel_MixerMasterVolume;
};
/**
 * MixerMasterVolume(SET)
 * @param volume ボリューム
 */ export const setMixerMasterVolume = (volume)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_hel_MixerMasterVolume = volume;
};
/**
 * MixerMasterMute (GET)
 */ export const getMixerMasterMute = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_MixerMasterMute === undefined || worldViewerWindow.g_hel_MixerMasterMute === null) {
        return 0;
    }
    return worldViewerWindow.g_hel_MixerMasterMute;
};
/**
 * MixerMasterMute(SET)
 * @param volume ボリューム
 */ export const setMixerMasterMute = (mute)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_hel_MixerMasterMute = mute;
};
/**
 * UseLegacyAudioToggle (GET)
 * @returns Boolean
 */ export const getUseLegacyAudioToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_UseLegacyAudio === undefined || worldViewerWindow.g_hel_UseLegacyAudio === null) {
        return false;
    }
    return worldViewerWindow.g_hel_UseLegacyAudio;
};
/**
 * UseLegacyAudioToggle(SET)
 * @remarks
 * 未使用
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setUseLegacyAudioToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_hel_UseLegacyAudio = toggle;
};
/**
 * AudioList (GET)
 * @returns (CHELAudio|null)[]
 */ export const getAudioList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_audioList === undefined || worldViewerWindow.g_audioList === null) {
        return [];
    }
    return worldViewerWindow.g_audioList;
};
/**
 * AudioList(SET)
 * @remarks
 * 必要あればnullを設定
 * @param audio CHELAudioインスタンス
 * @param index 設定可能なインデックス
 */ export const setAudioList = (audio, index)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_audioList === undefined || worldViewerWindow.g_audioList === null) {
        return;
    }
    worldViewerWindow.g_audioList[index] = audio;
};
/**
 * AudioListに処理の流れ上Nullを設定
 */ export const pushNullToAudioList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_audioList === undefined || worldViewerWindow.g_audioList === null) {
        worldViewerWindow.g_audioList = [];
    }
    worldViewerWindow.g_audioList.push(null);
};
/**
 * VideoCanPlayToggle (GET)
 * @remarks
 * 未使用
 * @returns Boolean
 */ export const getVideoCanPlayToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_videocanplay === undefined || worldViewerWindow.g_videocanplay === null) {
        return false;
    }
    return worldViewerWindow.g_videocanplay;
};
/**
 * VideoCanPlayToggle(SET)
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setVideoCanPlayToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_videocanplay = toggle;
};
/**
 * VideoEndedToggle (GET)
 * @returns Boolean
 */ export const getVideoEndedToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_videoended === undefined || worldViewerWindow.g_videoended === null) {
        return false;
    }
    return worldViewerWindow.g_videoended;
};
/**
 * VideoEndedToggle(SET)
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setVideoEndedToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_videoended = toggle;
};
/**
 * VideoAudioContext (GET)
 * @returns AudioContext|undefined
 */ export const getVideoAudioContext = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_VideoAudioContext === undefined || worldViewerWindow.g_VideoAudioContext === null) {
        return undefined;
    }
    return worldViewerWindow.g_VideoAudioContext;
};
/**
 * VideoAudioContext (SET)
 * @param videoAudioContext AudioContext型
 */ export const setVideoAudioContext = (videoAudioContext)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || videoAudioContext === undefined || videoAudioContext === null) {
        return;
    }
    worldViewerWindow.g_VideoAudioContext = videoAudioContext;
};
/**
 * VideoSource (GET)
 * @returns MediaElementAudioSourceNode|undefined
 */ export const getVideoSource = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_VideoSource === undefined || worldViewerWindow.g_VideoSource === null) {
        return undefined;
    }
    return worldViewerWindow.g_VideoSource;
};
/**
 * VideoSource (SET)
 * @param videoSource MediaElementAudioSourceNode
 */ export const setVideoSource = (videoSource)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || videoSource === undefined || videoSource === null) {
        return;
    }
    worldViewerWindow.g_VideoSource = videoSource;
};
/**
 * VideoGainNode (GET)
 * @returns GainNode|undefined
 */ export const getVideoGainNode = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_VideoGainNode === undefined || worldViewerWindow.g_VideoGainNode === null) {
        return undefined;
    }
    return worldViewerWindow.g_VideoGainNode;
};
/**
 * VideoGainNode (SET)
 * @param gainNode GainNode
 */ export const setVideoGainNode = (gainNode)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || gainNode === undefined || gainNode === null) {
        return;
    }
    worldViewerWindow.g_VideoGainNode = gainNode;
};
/**
 * VideoHlsConfig (GET)
 * @returns HlsConfig|undefined
 */ export const getVideoHlsConfig = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_VideoHlsConfig === undefined || worldViewerWindow.g_VideoHlsConfig === null) {
        return undefined;
    }
    return worldViewerWindow.g_VideoHlsConfig;
};
/**
 * VideoHlsConfig (SET)
 * @param updatedConfig HlsConfig
 */ export const setVideoHlsConfig = (updatedConfig)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || updatedConfig === undefined || updatedConfig === null) {
        return;
    }
    worldViewerWindow.g_VideoHlsConfig = updatedConfig;
};
/**
 * TextureCanvasList (GET)
 * @returns (CHELAudio|null)[]
 */ export const getTextureCanvasList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_TextCanvasList === undefined || worldViewerWindow.g_TextCanvasList === null) {
        return [];
    }
    return worldViewerWindow.g_TextCanvasList;
};
/**
 * TextureCanvasList(SET)
 * @remarks
 * 必要あればnullを設定
 * @param textureCanvas HTMLCanvasElementインスタンス
 * @param index 設定可能なインデックス
 */ export const setTextureCanvasList = (textureCanvas, index)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_TextCanvasList === undefined || worldViewerWindow.g_TextCanvasList === null) {
        return;
    }
    worldViewerWindow.g_TextCanvasList[index] = textureCanvas;
};
/**
 * ATextureCanvasListに処理の流れ上Nullを設定
 */ export const pushNullToTextureCanvasList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_TextCanvasList === undefined || worldViewerWindow.g_TextCanvasList === null) {
        return;
    }
    worldViewerWindow.g_TextCanvasList.push(null);
};
/**
 * GyroActiveToggle (GET)
 * @returns Boolean
 */ export const getGyroActiveToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_GyroActive === undefined || worldViewerWindow.g_GyroActive === null) {
        return false;
    }
    return worldViewerWindow.g_GyroActive;
};
/**
 * GyroActiveToggle(SET)
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @param toggle
 */ export const setGyroActiveToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_GyroActive = toggle;
};
/**
 * UploadedFileBuffer (GET)
 * @remarks
 * 未使用
 * @returns string|ArrayBuffer|undefined
 */ export const getUploadedFileBuffer = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_UploadFileImage === undefined || worldViewerWindow.g_hel_UploadFileImage === null) {
        return undefined;
    }
    return worldViewerWindow.g_hel_UploadFileImage;
};
/**
 * UploadedFileBuffer (SET)
 * @param buffer String型 | ArrayBuffer型 | Null
 */ export const setUploadedFileBuffer = (buffer)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_hel_UploadFileImage = buffer;
};
/**
 * VCUID (GET)
 * @returns String|undefined
 */ export const getVCUID = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_VCUID === undefined || worldViewerWindow.g_VCUID === null || worldViewerWindow.g_VCUID.length === 0) {
        return undefined;
    }
    return worldViewerWindow.g_VCUID;
};
/**
 * VCUID (SET)
 * @param vcuid unknown
 */ export const setVCUID = (vcuid)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof vcuid !== 'string') {
        return;
    }
    worldViewerWindow.g_VCUID = vcuid;
};
/**
 * SkywayName (GET)
 * @returns String
 */ export const getSkywayName = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Skyway_MyName === undefined || worldViewerWindow.g_Skyway_MyName === null || worldViewerWindow.g_Skyway_MyName.length === 0) {
        return 'Guest';
    }
    return worldViewerWindow.g_Skyway_MyName;
};
/**
 * SkywayName (SET)
 * @param name unknown
 */ export const setSkywayName = (name)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof name !== 'string') {
        return;
    }
    worldViewerWindow.g_Skyway_MyName = name;
};
/**
 * LocalStream (GET)
 * @returns MediaStream|undefined
 */ export const getLocalStream = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_localStream === undefined || worldViewerWindow.g_localStream === null) {
        return undefined;
    }
    return worldViewerWindow.g_localStream;
};
/**
 * LocalStream (SET)
 * @param mediaStream MediaStream
 */ export const setLocalStream = (mediaStream)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || mediaStream === undefined || mediaStream === null) {
        return;
    }
    worldViewerWindow.g_localStream = mediaStream;
};
/**
 * LocalStreamを閉じる
 */ export const closeLocalStream = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_localStream === undefined || worldViewerWindow.g_localStream === null) {
        return;
    }
    worldViewerWindow.g_localStream.getTracks().forEach((track)=>track.stop());
    worldViewerWindow.g_localStream = null;
};
/**
 * SkywayKey (GET)
 * @returns String
 */ export const getSkywayKey = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_SKYWAY_KEY === undefined || worldViewerWindow.g_SKYWAY_KEY === null || worldViewerWindow.g_SKYWAY_KEY.length === 0) {
        return '37825849-7771-49bd-890e-61971fb4f431';
    }
    return worldViewerWindow.g_SKYWAY_KEY;
};
/**
 * SkywayKey (SET)
 * @remarks
 * 未使用
 * @param key unknown
 */ export const setSkywayKey = (key)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof key !== 'string') {
        return;
    }
    worldViewerWindow.g_SKYWAY_KEY = key;
};
/**
 * SkywayAvatarNum(GET)
 * @remarks
 * 未使用
 * @returns Number
 */ export const getSkywayAvatarNumber = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Skyway_MyAvatar === undefined || worldViewerWindow.g_Skyway_MyAvatar === null) {
        return 0;
    }
    return worldViewerWindow.g_Skyway_MyAvatar;
};
/**
 * SkywayAvatarNum(SET)
 * @remarks
 * avatarNumがnumberでなく入ってくる可能性も考えられるためunknown型で定義
 *
 * @param avatarNum アバター番号
 */ export const setSkywayAvatarNumber = (avatarNum)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_Skyway_MyAvatar = avatarNum;
};
/**
 * SkywayAvatarNum(GET)
 * @returns Number
 */ export const getSkywayAvatarNumberForSend = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Skyway_MyAvatar_ForSend === undefined || worldViewerWindow.g_Skyway_MyAvatar_ForSend === null) {
        return 0;
    }
    return worldViewerWindow.g_Skyway_MyAvatar_ForSend;
};
/**
 * SkywayAvatarNum(SET)
 * @remarks
 * avatarNumがnumberでなく入ってくる可能性も考えられるためunknown型で定義
 *
 * @param avatarNum アバター番号
 */ export const setSkywayAvatarNumberForSend = (avatarNum)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_Skyway_MyAvatar_ForSend = avatarNum;
};
/**
 * SkywayAvatarIcon(GET)
 * @param
 * @param
 * @returns number
 */ export const getSkywayUseCustomAvatarIcon = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Skyway_Use_CustomAvatarIcon === undefined || worldViewerWindow.g_Skyway_Use_CustomAvatarIcon === null) {
        return false;
    }
    return worldViewerWindow.g_Skyway_Use_CustomAvatarIcon;
};
/**
 * SkywayAvatarIcon(SET)
 * @param number
 * @param
 * @returns
 */ export const setSkywayUseCustomAvatarIcon = (UseAvatarIcon)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_Skyway_Use_CustomAvatarIcon = UseAvatarIcon;
};
/**
 * SkywayCustomAvatarIconURL(GET)
 */ export const getSkywayCustomAvatarIconURL = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Skyway_CustomAvatarIcon_URL === undefined || worldViewerWindow.g_Skyway_CustomAvatarIcon_URL === null) {
        return '';
    }
    return worldViewerWindow.g_Skyway_CustomAvatarIcon_URL;
};
/**
 * SkywayCustomAvatarIconURL(SET)
 */ export const setSkywayCustomAvatarIconURL = (AvatarIconURL)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_Skyway_CustomAvatarIcon_URL = AvatarIconURL;
};
/**
 * SkywayStartToggle (GET)
 * @returns Boolean
 */ export const getSkywayStartToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Skyway_Start === undefined || worldViewerWindow.g_Skyway_Start === null) {
        return false;
    }
    return worldViewerWindow.g_Skyway_Start;
};
/**
 * SkywayStartToggle(SET)
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @remarks
 * 未使用
 * @param toggle
 */ export const setSkywayStartToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_Skyway_Start = toggle;
};
/**
 * SkywayMicStateTimer (GET)
 * @returns NodeJS.Timer|undefined
 */ // eslint-disable-next-line no-undef
export const getSkywayMicStateTimer = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_skyway_set_mic_state_timer === undefined || worldViewerWindow.g_skyway_set_mic_state_timer === null) {
        return undefined;
    }
    return worldViewerWindow.g_skyway_set_mic_state_timer;
};
/**
 * SkywayMicStateTimer (SET)
 * @param timer NodeJS.Timer
 */ // eslint-disable-next-line no-undef
export const setSkywayMicStateTimer = (timer)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || timer === undefined || timer === null) {
        return;
    }
    worldViewerWindow.g_skyway_set_mic_state_timer = timer;
};
/**
 * XrwFriendCode (GET)
 * @remarks
 * XRWorldIngameにて定義あり（helidor_front.js)
 * heliodor.jsやCPP側でも参照あるため定義は必要
 *
 * @returns String
 */ export const getXrwFriendCode = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_xrwFriendCode === undefined || worldViewerWindow.g_xrwFriendCode === null || worldViewerWindow.g_xrwFriendCode.length === 0) {
        return '';
    }
    return worldViewerWindow.g_xrwFriendCode;
};
/**
 * XrwFriendCode (SET)
 * @remarks
 * 未使用
 * XRWorldIngameにて定義あり（helidor_front.js)
 * heliodor.jsやCPP側でも参照あるため定義は必要
 *
 * @param key unknown
 */ export const setXrwFriendCode = (key)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof key !== 'string') {
        return;
    }
    worldViewerWindow.g_xrwFriendCode = key;
};
/**
 * XrwDataFolderName (GET)
 * @remarks
 * XRWorldIngameにて定義あり（helidor_front.js)
 *
 * @returns String
 */ export const getXrwDataFolderName = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_DataFolderName === undefined || worldViewerWindow.g_DataFolderName === null || worldViewerWindow.g_DataFolderName.length === 0) {
        return '';
    }
    return worldViewerWindow.g_DataFolderName;
};
/**
 * XrwDataFolderName (SET)
 * @remarks
 * 未使用
 * XRWorldIngameにて定義あり（helidor_front.js)
 *
 * @param key unknown
 */ export const setXrwDataFolderName = (key)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof key !== 'string') {
        return;
    }
    setDataFolderName(key);
};
/**
 * setDataFolderName (SET)
 * @remarks
 *
 * @param DataFolderName string
 */ export const setDataFolderName = (DataFolderName)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_DataFolderName = DataFolderName;
};
/**
 * UseSkywayAudioGainToggle (GET)
 * @remarks
 * 未使用
 * XRWorldIngameにて定義あり（helidor_skyway_xrw.js)
 * @returns Boolean
 */ export const getUseSkywayAudioGainToggle = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_UseSkywayAudioGain === undefined || worldViewerWindow.g_hel_UseSkywayAudioGain === null) {
        return false;
    }
    return worldViewerWindow.g_hel_UseSkywayAudioGain;
};
/**
 * UseSkywayAudioGainToggle(SET)
 * boolean以外が違う形式で入ってくる可能性も考えられるためunknown型で定義
 * @remarks
 * 未使用
 * XRWorldIngameにて定義あり（helidor_skyway_xrw.js)
 * @param toggle
 */ export const setUseSkywayAudioGainToggle = (toggle)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof toggle !== 'boolean') {
        return;
    }
    worldViewerWindow.g_hel_UseSkywayAudioGain = toggle;
};
/**
 * SceneJson (GET)
 * @remarks
 * @returns any
 */ export const getSceneJson = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_SceneJson === undefined || worldViewerWindow.g_SceneJson === null) {
        return;
    }
    return worldViewerWindow.g_SceneJson;
};
/**
 * SceneJson (SET)
 * @remarks
 * @param json
 */ export const setSceneJson = (json)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof json !== 'string') {
        return;
    }
    worldViewerWindow.g_SceneJson = json;
};
/**
 * SceneJsonPath (GET)
 * @remarks
 * @returns string
 */ export const getSceneJsonPath = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_SceneJsonPath === undefined || worldViewerWindow.g_SceneJsonPath === null) {
        return;
    }
    return worldViewerWindow.g_SceneJsonPath;
};
/**
 * SceneJsonPath (SET)
 * @remarks
 * @param path
 */ export const setSceneJsonPath = (path)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof path !== 'string') {
        return;
    }
    worldViewerWindow.g_SceneJsonPath = path;
};
/**
 * VideoInfo (GET)
 * @remarks
 * @returns any
 */ export const getVideoInfo = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_VideoInfo === undefined || worldViewerWindow.g_VideoInfo === null) {
        return;
    }
    return worldViewerWindow.g_VideoInfo;
};
/**
 * VideoInfo (SET)
 * @remarks
 * @param json
 */ export const setVideoInfo = (json)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_VideoInfo = json;
};
/**
 * VideoSourceUrl (GET)
 * @remarks
 * @returns Streaming Video URL
 */ export const getVideoSourceUrl = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_VideoSourceUrl === undefined || worldViewerWindow.g_VideoSourceUrl === null) {
        return;
    }
    return worldViewerWindow.g_VideoSourceUrl;
};
/**
 * VideoSourceUrl (SET)
 * @remarks
 * @param url Streaming Video Url
 */ export const setVideoSourceUrl = (url)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof url !== 'string') {
        return;
    }
    worldViewerWindow.g_VideoSourceUrl = url;
};
/**
 * SkywayOnTellScreenShareStart (GET)
 * @remarks
 * @returns 画面共有の開始事前通知を受け取ったときに呼ばれる関数
 */ export const getSkywayOnTellScreenShareStart = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_OnTellScreenStartFunction === undefined || worldViewerWindow.g_hel_OnTellScreenStartFunction === null) {
        return;
    }
    return worldViewerWindow.g_hel_OnTellScreenStartFunction;
};
/**
 * SkywayOnTellScreenShareStart (SET)
 * @remarks
 * @param fn 画面共有の開始事前通知を受け取ったときに呼ばれる関数
 */ export const setSkywayOnTellScreenShareStart = (fn)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof fn !== 'function') {
        return;
    }
    worldViewerWindow.g_hel_OnTellScreenStartFunction = fn;
};
/**
 * SkywayOnTellWaitScreenShare (GET)
 * @remarks
 * @returns 画面共有の待受開始通知を受け取ったときに呼ばれる関数
 */ export const getSkywayOnTellWaitScreenShare = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_hel_OnTellWaitScreenShareFunction === undefined || worldViewerWindow.g_hel_OnTellWaitScreenShareFunction === null) {
        return;
    }
    return worldViewerWindow.g_hel_OnTellWaitScreenShareFunction;
};
/**
 * SkywayOnTellWaitScreenShare (SET)
 * @remarks
 * @param fn 画面共有の待受開始通知を受け取ったときに呼ばれる関数
 */ export const setSkywayOnTellWaitScreenShare = (fn)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof fn !== 'function') {
        return;
    }
    worldViewerWindow.g_hel_OnTellWaitScreenShareFunction = fn;
};
/**
 * SkywayOnScreenShareStarted (SET)
 * @remarks
 * @param version SDKバージョン
 */ export const setHeliodorSdkVersion = (version)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof version !== 'string') {
        return;
    }
    worldViewerWindow.g_HeliodorSDKVersion = version;
};
/**
 * ActionProcedureList (GET)
 * @remarks
 * ブリッジ関数の設定
 */ export const getActionProcedureList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_ActionProcedureList === undefined || worldViewerWindow.g_ActionProcedureList === null) {
        return [];
    }
    return worldViewerWindow.g_ActionProcedureList;
};
/**
 * ActionProcedureList (SET)
 * @param callFunc
 */ export const setActionProcedureList = (callFunc)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof callFunc !== 'function') {
        return;
    }
    worldViewerWindow.g_ActionProcedureList.push(callFunc);
};
/**
 * ActionProcedureList (-)
 */ export const cleanActionProcedureList = ()=>{
    for(let i = 0; i < worldViewerWindow.g_ActionProcedureList.length; i += 1){
        delete worldViewerWindow.g_ActionProcedureList[i];
        worldViewerWindow.g_ActionProcedureList[i] = undefined;
    }
    worldViewerWindow.g_ActionProcedureList = [];
};
/**
 * OnclickedAvatarProcedureList (GET)
 */ export const getOnclickedAvatarProcedureList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_OnclickedAvatarProcedureList === undefined || worldViewerWindow.g_OnclickedAvatarProcedureList === null) {
        return [];
    }
    return worldViewerWindow.g_OnclickedAvatarProcedureList;
};
/**
 * OnclickedAvatarProcedureList (SET)
 * @param callFunc
 */ export const setOnclickedAvatarProcedureList = (callFunc)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof callFunc !== 'function') {
        return;
    }
    worldViewerWindow.g_OnclickedAvatarProcedureList.push(callFunc);
};
/**
 * OnloadedProcedureList (GET)
 * @remarks
 * Onloaded関数の設定
 */ export const getOnloadedProcedureList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_OnloadedProcedureList === undefined || worldViewerWindow.g_OnloadedProcedureList === null) {
        return [];
    }
    return worldViewerWindow.g_OnloadedProcedureList;
};
/**
 * OnloadedProcedureList (SET)
 * @param callFunc
 */ export const setOnloadedProcedureList = (callFunc)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof callFunc !== 'function') {
        return;
    }
    worldViewerWindow.g_OnloadedProcedureList.push(callFunc);
};
/**
 * OnfirsttouchProcedureList (GET)
 * @remarks
 * Onfirsttouch関数の設定
 */ export const getOnfirsttouchProcedureList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_OnfirsttouchProcedureList === undefined || worldViewerWindow.g_OnfirsttouchProcedureList === null) {
        return [];
    }
    return worldViewerWindow.g_OnfirsttouchProcedureList;
};
/**
 * OnfirsttouchProcedureList (SET)
 * @param callFunc
 */ export const setOnfirsttouchProcedureList = (callFunc)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof callFunc !== 'function') {
        return;
    }
    worldViewerWindow.g_OnfirsttouchProcedureList.push(callFunc);
};
/**
 * OnresizeProcedureList (GET)
 * @remarks
 * Onloaded関数の設定
 */ export const getOnresizeProcedureList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_OnresizeProcedureList === undefined || worldViewerWindow.g_OnresizeProcedureList === null) {
        return [];
    }
    return worldViewerWindow.g_OnresizeProcedureList;
};
/**
 * OnresizeProcedureList (SET)
 * @param callFunc
 */ export const setOnresizeProcedureList = (callFunc)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof callFunc !== 'function') {
        return;
    }
    worldViewerWindow.g_OnresizeProcedureList.push(callFunc);
};
/**
 * SkywayCommandProcedureList (GET)
 * @remarks
 * Onloaded関数の設定
 */ export const getSkywayCommandProcedureList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_SkywayCommandProcedureList === undefined || worldViewerWindow.g_SkywayCommandProcedureList === null) {
        return [];
    }
    return worldViewerWindow.g_SkywayCommandProcedureList;
};
/**
 * SkywayCommandProcedureList (SET)
 * @param callFunc
 */ export const setSkywayCommandProcedureList = (callFunc)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof callFunc !== 'function') {
        return;
    }
    worldViewerWindow.g_SkywayCommandProcedureList.push(callFunc);
};
/**
 * SkywayMyinfoProcedureList (GET)
 * @remarks
 * Onloaded関数の設定
 */ export const getSkywayMyinfoProcedureList = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_SkywayMyinfoProcedureList === undefined || worldViewerWindow.g_SkywayMyinfoProcedureList === null) {
        return [];
    }
    return worldViewerWindow.g_SkywayMyinfoProcedureList;
};
/**
 * SkywayMyinfoProcedureList (SET)
 * @param callFunc
 */ export const setSkywayMyinfoProcedureList = (callFunc)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || typeof callFunc !== 'function') {
        return;
    }
    worldViewerWindow.g_SkywayMyinfoProcedureList.push(callFunc);
};
/**
 * AnimationFrameRequest(SET)
 * @param callFunc
 */ export const setAnimationFrameRequest = (id)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_AnimationFrameRequest = id;
};
/**
 * AnimationFrameRequest(GET)
 * @remarks
 *
 */ export const getAnimationFrameRequest = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_AnimationFrameRequest === undefined || worldViewerWindow.g_AnimationFrameRequest === null) {
        return -1;
    }
    return worldViewerWindow.g_AnimationFrameRequest;
};
/**
 * XRSession(SET)
 * @param callFunc
 */ export const setXRSession = (xrsession)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_XRSession = xrsession;
};
/**
 * XRSession(GET)
 * @remarks
 *
 */ export const getXRSession = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_XRSession === undefined || worldViewerWindow.g_XRSession === null) {
        return -1;
    }
    return worldViewerWindow.g_XRSession;
};
worldViewerWindow.g_CanvasLayerShow = {};
/**
 * CanvasLayerShow(SET)
 * @param callFunc
 */ export const setCanvasLayerShow = (LayerName, show)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_CanvasLayerShow[LayerName] = show;
};
/**
 * CanvasLayerShow(GET)
 * @remarks
 *
 */ export const getCanvasLayerShow = (LayerName)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return false;
    }
    return worldViewerWindow.g_CanvasLayerShow[LayerName];
};
// Video Recorder
/**
 * g_VideoRecorder(GET)
 */ export const getVideoRecorder = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return null;
    }
    return worldViewerWindow.g_VideoRecorder;
};
/**
 * g_VideoRecorder(SET)
 */ export const setVideoRecorder = (Recorder)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_VideoRecorder = Recorder;
};
/**
 * g_RecordedVideoBlobObj(GET)
 */ export const getRecordedVideoBlobObj = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return null;
    }
    return worldViewerWindow.g_RecordedVideoBlobObj;
};
/**
 * g_RecordedVideoBlobObj(SET)
 */ export const setRecordedVideoBlobObj = (BlobObj)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_RecordedVideoBlobObj = BlobObj;
};
/**
 * g_RecordedVideoBlobURL(GET)
 */ export const getRecordedVideoBlobURL = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return null;
    }
    return worldViewerWindow.g_RecordedVideoBlobURL;
};
/**
 * g_RecordedVideoBlobURL(SET)
 */ export const setRecordedVideoBlobURL = (BlobURL)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_RecordedVideoBlobURL = BlobURL;
};
/**
 * g_VideoRecorderExtension(GET)
 */ export const getVideoRecorderExtension = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return null;
    }
    return worldViewerWindow.g_VideoRecorderExtension;
};
/**
 * g_VideoRecorderExtension(SET)
 */ export const setVideoRecorderExtension = (Extension)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_VideoRecorderExtension = Extension;
};
/**
 * g_VideoRecordCanvas(GET)
 */ export const getVideoRecordCanvas = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return null;
    }
    return worldViewerWindow.g_VideoRecordCanvas;
};
/**
 * g_VideoRecordCanvas(SET)
 */ export const setVideoRecordCanvas = (Canvas)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_VideoRecordCanvas = Canvas;
};
/**
 * g_VideoRecordContext(GET)
 */ export const getVideoRecordContext = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return null;
    }
    return worldViewerWindow.g_VideoRecordContext;
};
/**
 * g_VideoRecordContext(SET)
 */ export const setVideoRecordContext = (Context)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_VideoRecordContext = Context;
};
/**
 * CanvasIsPortrait(SET)
 * @param callFunc
 */ export const setCanvasIsPortrait = (IsPortrait)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_CanvasIsPortrait = IsPortrait;
};
/**
 * CanvasIsPortrai(GET)
 * @remarks
 *
 */ export const getCanvasIsPortrait = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return false;
    }
    return worldViewerWindow.g_CanvasIsPortrait;
};
