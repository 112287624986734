function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { getUseSkywayAudioGainToggle } from '../../logics/dataOps';
export class CHELCarnelianAgoraPlayer {
    release() {}
    getPeerId() {
        return this.playerId;
    }
    getPlayerId() {
        return this.playerId;
    }
    setStream(stream) {}
    setChannelSessionCode(channelSessionCode) {
        this.channelSessionCode = channelSessionCode;
    }
    getValue(key) {
        return this[key];
    }
    getVideoMediaStream() {
        const mediaStream = new MediaStream();
        if (this.agoraUser.hasAudio) {
            mediaStream.addTrack(this.agoraUser.videoTrack.getMediaStreamTrack());
        }
        return mediaStream;
    }
    setAgoraUser(agoraUser) {
        this.agoraUser = agoraUser;
    }
    setVolume(volume) {
        this.volume = volume;
        this.updateVolume();
    }
    SetVoiceAttenuate(volume) {
        this.attenuateVolume = volume;
        this.updateVolume();
    }
    SetMute(mute) {
        this.mute = mute;
        this.updateVolume();
    }
    updateVolume() {
        // 距離減衰有効無効
        const useSkywayAudioGainToggle = getUseSkywayAudioGainToggle();
        if (!useSkywayAudioGainToggle) {
            return;
        }
        if (this.agoraUser && this.agoraUser.hasAudio) {
            const fixedVolume = this.volume * this.attenuateVolume * (this.mute ? 0.0 : 1.0);
            console.log('Player updateVolume', this.getPlayerId(), fixedVolume, this.volume, this.attenuateVolume);
            this.agoraUser.audioTrack.setVolume(100.0 * fixedVolume);
        }
    /*

    if (worldViewerWindow.hel_isiPhone()) {
      if (this.gainNode !== undefined) {
        this.gainNode.gain.value = this.volume * this.attenuateVolume * (this.mute ? 0.0 : 1.0);
      }
    } else {
      this.audio.volume = this.volume * this.attenuateVolume * (this.mute ? 0 : 1);
    }
    */ }
    constructor(playerId, userCode, volume, role){
        _defineProperty(this, "playerId", void 0);
        _defineProperty(this, "context", void 0);
        _defineProperty(this, "gainNode", void 0);
        _defineProperty(this, "volume", void 0);
        _defineProperty(this, "attenuateVolume", void 0);
        _defineProperty(this, "mute", void 0);
        _defineProperty(this, "source", void 0);
        _defineProperty(this, "audioDestination", void 0);
        _defineProperty(this, "audio", void 0);
        _defineProperty(this, "role", void 0);
        _defineProperty(this, "agoraUser", void 0);
        _defineProperty(this, "channelSessionCode", void 0);
        console.log(playerId, userCode, volume, role);
        this.playerId = playerId;
        this.role = role;
        this.audio = document.createElement('audio');
        this.volume = volume;
        this.attenuateVolume = 0.0;
        this.mute = false;
        this.updateVolume();
    }
}
