import { HEL_AUDIO_TYPE_VIDEO, HEL_AUDIO_TYPE_VOICE, worldViewerWindow } from '../const';
import { getAudioList, getMixerDistanceAt, getMixerFaderSettingAt, getMixerMuteAt, getMixerSettingAt, getMixerMasterVolume } from './dataOps';
const hel_mixer_UpdateByType = (type)=>{
    worldViewerWindow.hel_mixer_SetVolume(type, getMixerSettingAt(type), getMixerMasterVolume(), getMixerFaderSettingAt(type), getMixerDistanceAt(type), getMixerMuteAt(type));
};
export const hel_mixer_UpdateAll = ()=>{
    hel_mixer_UpdateByType(HEL_AUDIO_TYPE_VOICE);
    hel_mixer_UpdateByType(HEL_AUDIO_TYPE_VIDEO);
    const audioList = getAudioList();
    for(let index = 0; index < audioList.length; index++){
        var _audioList_index, _audioList_index1;
        if (audioList[index] === null) continue;
        const type = (_audioList_index = audioList[index]) === null || _audioList_index === void 0 ? void 0 : _audioList_index.getType();
        const realVolume = getMixerSettingAt(type) * getMixerFaderSettingAt(type) * getMixerDistanceAt(type) * (getMixerMuteAt(type) !== 0 ? 0.0 : 1.0);
        (_audioList_index1 = audioList[index]) === null || _audioList_index1 === void 0 ? void 0 : _audioList_index1.setVolume(realVolume);
    }
};
