// Scene
import { ETextParamType_OperateCanvas, ETextParamType_ReplaceMyAvatarIcon, ETextParamType_SceneItem, EBinaryParamType_SetGUIImage, worldViewerWindow } from './const';
import { getCanvasLayerShow, setCanvasLayerShow, getCanvasIsPortrait, setCanvasIsPortrait } from './logics/dataOps';
// OperateCanvas
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * JRWorld_web->heliodor_skyway.jsにて参照
 * XRWorldIngame->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * XRWorldIngame->heliodor_skyway.jsにて参照
 * heliodor_front, skywayから呼出があるため、global関数としておいておく
 * @param layoutName
 * @param show
 */ worldViewerWindow.hel_canvas_SetLayoutShow = (layoutName, show)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetLayoutShow,' + layoutName + ',' + (show ? 'true' : 'false'));
};
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * JRWorld_web->heliodor_skyway.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * XRWorldIngame->heliodor_skyway.jsにて参照
 * heliodor_front, xrw_interface, skywayから呼出があるため、global関数としておいておく
 * @param layoutName
 * @param guiName
 * @param show
 */ worldViewerWindow.hel_canvas_SetGUIShow = (layoutName, guiName, show)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetGUIShow,' + layoutName + ',' + guiName + ',' + (show ? 'true' : 'false'));
};
/**
 * @remarks
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param layoutName
 * @param guiName
 */ worldViewerWindow.hel_canvas_ResetGUIPos = (layoutName, guiName)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'ResetGUIPos,' + layoutName + ',' + guiName);
};
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * heliodor_front, xrw_interface, skyway, skyway_rcpから呼出があるため、global関数としておいておく
 * @param layoutName
 * @param guiName
 * @param text
 */ worldViewerWindow.hel_canvas_SetGUIText = (layoutName, guiName, text)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetGUIText,' + layoutName + ',' + guiName + ',' + text);
};
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * heliodor_front.jsから参照あるためglobal関数としておいておく
 * @param ItemName
 * @param NodeName
 * @param Show
 */ worldViewerWindow.hel_scene_SetShowNode = (ItemName, NodeName, Show)=>{
    worldViewerWindow.hel_setText(ETextParamType_SceneItem, 'SetShowNode,' + ItemName + ',' + NodeName + ',' + (Show ? '1' : '0'));
};
// TogggleDefaultで設定された値に戻す
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * heliodor_front.jsから参照あるためglobal関数としておいておく
 * @param ToggleName
 */ worldViewerWindow.hel_canvas_ResetToggleDefault = (ToggleName)=>{
    const layoutName = 'DummyLayout'; // 構文ルール上必要
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'ToggleDefault,' + layoutName + ',' + ToggleName);
};
// Toggleを切り替える
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * heliodor_front.jsから参照あるためglobal関数としておいておく
 * @param ToggleName
 */ worldViewerWindow.hel_canvas_ToggleChange = (ToggleName)=>{
    const layoutName = 'DummyLayout'; // 構文ルール上必要
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'ToggleChange,' + layoutName + ',' + ToggleName);
};
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 */ worldViewerWindow.hel_canvas_MakeAvatarIconMap = ()=>{
    // アバターアイコンのパス情報を作る。
    //
    // hel_set_avatar_iconpath() は heliodor_front.js に記述する。
    // プロジェクトにより存在しない場合もあるが、その場合は従来どおり
    // config_avatar.json の "AvatarList" を参照して、
    // エンジンがアイコンパス情報を作る。
    if (typeof worldViewerWindow.hel_set_avatar_iconpath !== 'undefined') {
        worldViewerWindow.hel_set_avatar_iconpath();
    }
};
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 */ worldViewerWindow.hel_canvas_ClearAvatarIconMap = ()=>{
    // アバターアイコンのパス情報をすべて消す
    const layoutName = 'DummyLayout'; // 構文ルール上必要
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'ClearAvatarIconMap,' + layoutName + ',' + 'true');
};
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param AvatarId
 * @param AvatarIconPath
 */ worldViewerWindow.hel_canvas_AddAvatarIconMap = (AvatarId, AvatarIconPath)=>{
    // アバターアイコンのパス情報を追加する
    const layoutName = 'DummyLayout'; // 構文ルール上必要
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'AddAvatarIconMap,' + layoutName + ',' + AvatarId + ',' + AvatarIconPath);
};
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param AvatarId
 * @param AvatarIconPath
 */ worldViewerWindow.hel_canvas_ReplaceAvatarIconMap = (AvatarId, AvatarIconPath)=>{
    // アバターアイコンのパス情報を追加する
    const layoutName = 'DummyLayout'; // 構文ルール上必要
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'ReplaceAvatarIconMap,' + layoutName + ',' + AvatarId + ',' + AvatarIconPath);
};
/**
 * @remarks
 * XRWorldIngame->heliodor_xrw_main.jsにて参照
 * @param newAvatarId
 */ worldViewerWindow.hel_canvas_ReplaceMyAvatarIcon = (newAvatarId)=>{
    worldViewerWindow.hel_setText(ETextParamType_ReplaceMyAvatarIcon, newAvatarId);
};
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param layoutName
 * @param guiName
 * @param fileName
 */ worldViewerWindow.hel_canvas_SetGUIImage = (layoutName, guiName, fileName)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetGUIImage,' + layoutName + ',' + guiName + ',' + fileName);
};
/**
 * @remarks
 * Housing の 「画像アップロード」で使用されます。
 * guiNameのImageを textureWidth, textureHeight の
 * サイズの大きい方にテクスチャを縦横等倍でフィッティングさせ、
 * そのテクスチャの中心を起点に縦・横をカットして表示させます。
 * @param layoutName
 * @param guiName
 * @param textureWidth '256', '512', '1024' のいずれか。
 * @param textureHeight '256', '512', '1024' のいずれか。
 */ worldViewerWindow.hel_canvas_SetGUIImageUploadTextureClipping = (layoutName, guiName, textureWidth, textureHeight)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetGUIImageUploadTextureClipping,' + layoutName + ',' + guiName + ',' + textureWidth + ',' + textureHeight);
};
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xrw_interface.jsにて参照
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param layoutName
 * @param guiName
 * @param fileName
 */ worldViewerWindow.hel_canvas_SetGUIImageResizeRequest = (layoutName, guiName, resizeType)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetGUIImageResizeRequest,' + layoutName + ',' + guiName + ',' + resizeType);
};
/**
 * @remarks
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param layoutName
 * @param guiName
 * @param canvasID
 */ worldViewerWindow.hel_canvas_SetGUIImageByHTMLCanvas = (layoutName, guiName, canvasID)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetGUIImageByHTMLCanvas,' + layoutName + ',' + guiName + ',' + canvasID);
};
/**
 * @remarks
 *
 * @param layoutName
 * @param guiName
 * @param ary_u8
 */ worldViewerWindow.hel_canvas_SetGUIImageByUint8Array = (layoutName, guiName, ary_u8)=>{
    // 先にレイヤー名とGUI名を設定しておく
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetGUIImageByUint8Array,' + layoutName + ',' + guiName);
    // バイナリデータを送る
    worldViewerWindow.hel_setBinary(EBinaryParamType_SetGUIImage, ary_u8);
};
/**
 * @remarks
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param layoutName
 */ worldViewerWindow.hel_canvas_GetLayerShow = (layoutName)=>{
    // エンジン側に問い合わせする。
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'GetLayerShow,' + layoutName);
    // エンジンでこの関数が呼ばれ、
    // 結果が g_CanvasLayerShow[] に入る。その値を返す。
    return getCanvasLayerShow(layoutName);
};
/**
 * @remarks
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param layoutName
 */ worldViewerWindow.hel_canvas_IsPortrait = ()=>{
    // エンジン側に問い合わせする。
    const layoutName = 'Dummy';
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'IsPortrait,' + layoutName);
    // エンジンでこの関数が呼ばれ、
    // 結果が g_CanvasIsPortrait に入る。その値を返す。
    return getCanvasIsPortrait();
};
/**
 * @remarks
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param layoutName
 * @param isShow
 */ worldViewerWindow.hel_setCanvasLayerShow = (layoutName, isShow)=>{
    // LayerのON/OFFを設定する
    // ※エンジンがこの関数を呼び出すため、JS側でこの関数を直接呼び出す事は無い。
    setCanvasLayerShow(layoutName, isShow);
};
/**
 * @remarks
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param layoutName
 */ worldViewerWindow.hel_getCanvasLayerShow = (layoutName)=>{
    // LayerのON/OFFを取得する
    return getCanvasLayerShow(layoutName);
};
/**
 * @remarks
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param layoutName
 * @param isShow
 */ worldViewerWindow.hel_setCanvasIsPortrait = (isPortrait)=>{
    // 縦画面・横画面のどちらの状態かを設定する
    // ※エンジンがこの関数を呼び出すため、JS側でこの関数を直接呼び出す事は無い。
    setCanvasIsPortrait(isPortrait);
};
/**
 * @remarks
 * heliodor_front, xrw_interface.jsから呼出があるため、global関数としておいておく
 * @param layoutName
 */ worldViewerWindow.hel_getCanvasIsPortrait = ()=>{
    // 縦画面・横画面のどちらの状態かを設定する
    return getCanvasIsPortrait();
};
