import { ETextParamType_LocationHref, ETextParamType_RoomID, ETextParamType_ShowWorldSelect, ETextParamType_SpotName, ETextParamType_WorldID, worldViewerWindow } from '../const';
import { getSkyRoomId, getSpotId, getWorldId, getWorldSelectedToggle } from './dataOps';
/**
 * @remarks
 * boot前に下記の諸情報をHeliodorに設定
 * - WorldId
 * - RoomId
 * - SpotName
 * - Location
 * - SelectedWorld
 */ export const presetBeforeBoot = ()=>{
    const worldId = getWorldId();
    const skyRoomId = getSkyRoomId();
    const spotId = getSpotId();
    if (worldId !== undefined) {
        worldViewerWindow.hel_setText(ETextParamType_WorldID, worldId);
    }
    if (skyRoomId !== undefined) {
        worldViewerWindow.hel_setText(ETextParamType_RoomID, skyRoomId);
    }
    if (spotId !== undefined) {
        worldViewerWindow.hel_setText(ETextParamType_SpotName, spotId);
    }
    worldViewerWindow.hel_setText(ETextParamType_LocationHref, location.href);
    worldViewerWindow.hel_setText(ETextParamType_ShowWorldSelect, getWorldSelectedToggle() ? '1' : '0');
};
