import { HEL_AUDIO_TYPE_SE, HEL_AUDIO_TYPE_SYSTEMSE, HEL_AUDIO_TYPE_VIDEO, HEL_AUDIO_TYPE_VOICE, HEL_AUDIO_TYPE_WORLD, worldViewerWindow } from '../const';
import { setMixerMasterMute, setMixerMasterVolume, setMixerMuteAt, setMixerSettingAt } from './dataOps';
export const hel_mixer_LoadCookie_Volume = ()=>{
    const VolumeWorld = worldViewerWindow.hel_get_cookie('mixervolume_world');
    if (VolumeWorld !== undefined) {
        setMixerSettingAt(VolumeWorld, HEL_AUDIO_TYPE_WORLD);
    }
    const VolumeVoice = worldViewerWindow.hel_get_cookie('mixervolume_voice');
    if (VolumeVoice !== undefined) {
        setMixerSettingAt(VolumeVoice, HEL_AUDIO_TYPE_VOICE);
    }
    const VolumeSE = worldViewerWindow.hel_get_cookie('mixervolume_se');
    if (VolumeSE !== undefined) {
        setMixerSettingAt(VolumeSE, HEL_AUDIO_TYPE_SE);
    }
    const VolumeSystemSE = worldViewerWindow.hel_get_cookie('mixervolume_systemse');
    if (VolumeSystemSE !== undefined) {
        setMixerSettingAt(VolumeSystemSE, HEL_AUDIO_TYPE_SYSTEMSE);
    }
    const VolumeVideo = worldViewerWindow.hel_get_cookie('mixervolume_video');
    if (VolumeVideo !== undefined) {
        setMixerSettingAt(VolumeVideo, HEL_AUDIO_TYPE_VIDEO);
    }
    const VolumeMaster = worldViewerWindow.hel_get_cookie('mixervolume_master');
    if (VolumeMaster !== undefined) {
        setMixerMasterVolume(VolumeMaster);
    }
};
export const hel_mixer_LoadCookie_Mute = ()=>{
    const MuteWorld = worldViewerWindow.hel_get_cookie('mixermute_world');
    if (MuteWorld !== undefined) {
        setMixerMuteAt(MuteWorld, HEL_AUDIO_TYPE_WORLD);
    }
    const MuteVoice = worldViewerWindow.hel_get_cookie('mixermute_voice');
    if (MuteVoice !== undefined) {
        setMixerMuteAt(MuteVoice, HEL_AUDIO_TYPE_VOICE);
    }
    const MuteSE = worldViewerWindow.hel_get_cookie('mixermute_se');
    if (MuteSE !== undefined) {
        setMixerMuteAt(MuteSE, HEL_AUDIO_TYPE_SE);
    }
    const MuteSystemSE = worldViewerWindow.hel_get_cookie('mixermute_systemse');
    if (MuteSystemSE !== undefined) {
        setMixerMuteAt(MuteSystemSE, HEL_AUDIO_TYPE_SYSTEMSE);
    }
    const MuteVideo = worldViewerWindow.hel_get_cookie('mixermute_video');
    if (MuteVideo !== undefined) {
        setMixerMuteAt(MuteVideo, HEL_AUDIO_TYPE_VIDEO);
    }
    const MuteMaster = worldViewerWindow.hel_get_cookie('mixermute_master');
    if (MuteMaster !== undefined) {
        setMixerMasterMute(MuteMaster);
    }
};
