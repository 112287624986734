function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { HEL_AUDIO_TYPE_VOICE, worldViewerWindow } from '../../const';
// import {hel_isiPhone} from '../../logics/platform';
import { debugLog } from '../../debugLog';
import { helcore_video_play } from '../../heliodor_core_video_callee';
import { closeLocalStream, getLocalStream, getMixerRealSettingAt, getSkywayName, getSkywayOnTellScreenShareStart, getSkywayOnTellWaitScreenShare, setLocalStream, getSkywayAvatarNumberForSend, getSkywayStartToggle, getSkywayUseCustomAvatarIcon, getSkywayCustomAvatarIconURL } from '../../logics/dataOps';
import { getVideo } from '../../logics/domOps';
import { requestOnNetReceiveVoiceJoin, requestOnNetReceiveVoiceLeave } from '../../logics/moduleCaller';
import { EHeliMicPermissionState } from '../IHeliNetwork';
import { CHELCarnelianAgoraPlayer } from './CHELCarnelianAgoraPlayer';
import { CApiNotification } from "../apiNotificationLib/CApiNotification";
async function getDecodedChannelSession() {
    const channelSessionJwt = (await worldViewerWindow.heliport.api.channelSessionApi.getChannelSessionJwt()).data;
    return parseJwt(channelSessionJwt);
}
function parseJwt(token) {
    if (typeof token !== 'string') {
        return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}
function setCrpFront(crpFront) {
    worldViewerWindow.crpFront = crpFront;
}
function getCrpFront() {
    return worldViewerWindow.crpFront;
}
function setLocalStreamFromAgoraLocalTrack() {
    if (!worldViewerWindow.agoraClient) {
        return false;
    }
    const localMediaStream = worldViewerWindow.agoraClient.getLocalMediaStream();
    setLocalStream(localMediaStream);
    return true;
}
async function getNormalAgoraToken(userID, channel, role) {
    const tokenApiHost = 'https://agora-test-app.vcb.hikky.dev';
    const TOKEN_TYPE = 'voice';
    const url = `${tokenApiHost}/api/rtc_token`;
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid: userID,
            tokenType: TOKEN_TYPE,
            channel: channel,
            role: role
        })
    });
    return res;
}
function converChannelSessionToPlayerInfo(channelSession) {
    return _objectSpread({}, channelSession.user, channelSession);
}
export class CHeliChsPlayerInfo {
    getValue(key) {
        if (this.m_playerInfo) {
            return this.m_playerInfo[key];
        }
        return undefined;
    }
    getUserId() {
        var _this_m_playerInfo;
        return (_this_m_playerInfo = this.m_playerInfo) === null || _this_m_playerInfo === void 0 ? void 0 : _this_m_playerInfo.user_code;
    }
    getChannelSessionCode() {
        var _this_m_playerInfo;
        return (_this_m_playerInfo = this.m_playerInfo) === null || _this_m_playerInfo === void 0 ? void 0 : _this_m_playerInfo.channel_session_code;
    }
    getRole() {
        var _this_m_playerInfo, _this_m_playerInfo1;
        if (!((_this_m_playerInfo = this.m_playerInfo) === null || _this_m_playerInfo === void 0 ? void 0 : _this_m_playerInfo.user_role)) {
            return 'normal';
        }
        return (_this_m_playerInfo1 = this.m_playerInfo) === null || _this_m_playerInfo1 === void 0 ? void 0 : _this_m_playerInfo1.user_role;
    }
    constructor(playerInfo){
        _defineProperty(this, "m_playerInfo", void 0);
        this.m_playerInfo = playerInfo;
    }
}
export class IHeliCarnelianAgoraPosChannelList {
    getList() {
        const iHeliPosChList = [];
        let ArrayNum = 0;
        for(let i = this.m_offset; i < this.m_position_channels.length; i++){
            if (this.m_limit !== undefined && this.m_limit !== null && ArrayNum >= this.m_limit) {
                break;
            }
            iHeliPosChList.push(this.m_position_channels[i]);
            ArrayNum++;
        }
        return iHeliPosChList;
    }
    setError(error) {
        this.m_errorMessage = error;
    }
    getError() {
        return this.m_errorMessage;
    }
    isValid() {
        return this.m_errorMessage === null;
    }
    constructor(postion_channels = [], offset = 0, limit = null){
        _defineProperty(this, "m_position_channels", void 0);
        _defineProperty(this, "m_offset", void 0);
        _defineProperty(this, "m_limit", void 0);
        _defineProperty(this, "m_errorMessage", void 0);
        this.m_position_channels = postion_channels;
        this.m_offset = offset;
        this.m_limit = limit;
    }
}
export class IHeliCarnelianAgoraVoiceChannelList {
    getList() {
        const iHeliVoiceChList = [];
        let ArrayNum = 0;
        for(let i = this.m_offset; i < this.m_vocie_channels.length; i++){
            if (this.m_limit !== undefined && this.m_limit !== null && ArrayNum >= this.m_limit) {
                break;
            }
            iHeliVoiceChList.push(this.m_vocie_channels[i]);
            ArrayNum++;
        }
        return iHeliVoiceChList;
    }
    setError(error) {
        this.m_errorMessage = error;
    }
    getError() {
        return this.m_errorMessage;
    }
    isValid() {
        return this.m_errorMessage === null;
    }
    constructor(vocie_channels = [], offset = 0, limit = null){
        _defineProperty(this, "m_vocie_channels", void 0);
        _defineProperty(this, "m_offset", void 0);
        _defineProperty(this, "m_limit", void 0);
        _defineProperty(this, "m_errorMessage", void 0);
        this.m_vocie_channels = vocie_channels;
        this.m_offset = offset;
        this.m_limit = limit;
    }
}
export class IHeliCarnelianAgoraLiveChannelList {
    getList() {
        const iHeliLiveChList = [];
        let ArrayNum = 0;
        for(let i = this.m_offset; i < this.m_live_channels.length; i++){
            if (this.m_limit !== undefined && this.m_limit !== null && ArrayNum >= this.m_limit) {
                break;
            }
            iHeliLiveChList.push(this.m_live_channels[i]);
            ArrayNum++;
        }
        return iHeliLiveChList;
    }
    setError(error) {
        this.m_errorMessage = error;
    }
    getError() {
        return this.m_errorMessage;
    }
    isValid() {
        return this.m_errorMessage === null;
    }
    constructor(live_channels = [], offset = 0, limit = null){
        _defineProperty(this, "m_live_channels", void 0);
        _defineProperty(this, "m_offset", void 0);
        _defineProperty(this, "m_limit", void 0);
        _defineProperty(this, "m_errorMessage", void 0);
        this.m_live_channels = live_channels;
        this.m_offset = offset;
        this.m_limit = limit;
    }
}
export class CHeliCarnelianAgoraPosChannel {
    isConnected() {
        const crpFront = getCrpFront();
        if (crpFront && crpFront.ownUser && crpFront.ownUser.positionChannelCode && crpFront.ownUser.positionChannelCode === this.m_channelId && crpFront.ownObjectId) {
            return true;
        }
        return false;
    }
    getChannelId() {
        return this.m_channelId;
    }
    getError() {
        return this.m_error;
    }
    setError(errorMessage) {
        this.m_error = errorMessage;
    }
    getValue(key) {
        if (this.positionChannel) {
            return this.positionChannel[key];
        }
        return undefined;
    }
    async join(authorityJwt, ticketJwt) {
        console.log('CarnelianAgora joinPosChannel start channelid:', this.m_channelId);
        const authorizationJwt = authorityJwt;
        // API対応したらHeliportも対応して入室リクエストに使用する
        try {
            const resEntryPch = (await worldViewerWindow.heliport.api.channelSessionApi.enterPositionChannel(this.m_channelId, authorizationJwt, ticketJwt)).data;
            if (resEntryPch.code >= 400) {
                // 入室エラー
                this.setError(JSON.stringify(resEntryPch));
                return false;
            }
            console.log('CarnelianAgora joinPosChannel ChS-API enterPos resEntryPch:', resEntryPch);
            const channelSessionJwt = (await worldViewerWindow.heliport.api.channelSessionApi.getChannelSessionJwt()).data;
            this.m_parent.m_selfPlayerInfo = parseJwt(channelSessionJwt);
            this.m_parent.setCurrentPositionChannel(this);
            console.log('CRP open start');
            worldViewerWindow.crpFront.sendMovement([
                0,
                0,
                0
            ], [
                0,
                0,
                0,
                0
            ], [
                0,
                0,
                0
            ], 1, 0);
            await worldViewerWindow.crpFront.open(channelSessionJwt);
            console.log('CRP open done');
            return true;
        } catch (error) {
            console.error('iHeliNet joinPosChannel error:', error);
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async leave() {
        this.m_parent.setCurrentPositionChannel(null);
        this.m_parent.releaseAllPlayer();
        await worldViewerWindow.crpFront.close();
        const voCh = this.m_parent.getCurrentVoiceChannel();
        if (voCh) {
            await voCh.leave();
        }
        try {
            const resLeavePosCh = (await worldViewerWindow.heliport.api.channelSessionApi.leavePositionChannel()).data;
            if (resLeavePosCh.code >= 400) {
                this.setError(JSON.stringify(resLeavePosCh));
                return false;
            }
        } catch (error) {
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async update(params) {
        try {
            const result = (await worldViewerWindow.heliport.api.channelSessionApi.updatePositionChannel(this.m_channelId, params.channelType, params.name, params.description)).data;
            if (result.code >= 400) {
                this.setError(JSON.stringify(result));
                return false;
            }
            this.positionChannel = result.data.position_channel;
            return true;
        } catch (error) {
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async refresh() {
        try {
            const resPosChDetail = (await worldViewerWindow.heliport.api.channelSessionApi.fetcthPositionChannelDetail(this.m_channelId)).data;
            if (resPosChDetail.code >= 400) {
                this.setError(JSON.stringify(resPosChDetail));
                return false;
            }
            const postionChannelDetail = resPosChDetail.data.position_channel;
            this.positionChannel = postionChannelDetail;
            return true;
        } catch (error) {
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async getPlayerList() {
        const updateResult = await this.refresh();
        if (updateResult && this.positionChannel && this.positionChannel.channel_sessions) {
            return this.positionChannel.channel_sessions.map((channelSession)=>{
                const playerInfoDetail = converChannelSessionToPlayerInfo(channelSession);
                return new CHeliChsPlayerInfo(playerInfoDetail);
            });
        } else {
            return [];
        }
    }
    constructor(parent, positionChannel){
        _defineProperty(this, "m_parent", void 0);
        _defineProperty(this, "m_channelId", void 0);
        _defineProperty(this, "m_channelType", void 0);
        _defineProperty(this, "positionChannel", void 0);
        _defineProperty(this, "m_error", void 0);
        this.m_parent = parent;
        this.positionChannel = positionChannel;
        if (positionChannel) {
            this.m_channelId = positionChannel.channel_id;
            this.m_channelType = positionChannel.channel_type;
        }
        this.m_error = null;
    }
}
export class CHeliCarnelianAgoraVoiceChannel {
    isConnected() {
        if (worldViewerWindow.agoraClient && worldViewerWindow.agoraClient.ChannelName() === this.m_channelId) {
            return true;
        }
        return false;
    }
    getChannelId() {
        return this.m_channelId;
    }
    getError() {
        return this.m_error;
    }
    setError(errorMessage) {
        this.m_error = errorMessage;
    }
    getValue(key) {
        if (this.voiceChannel) {
            return this.voiceChannel[key];
        }
        return undefined;
    }
    async join(pIHeliMic) {
        var _this_m_channelId;
        console.log('CarnelianAgora joinVoiceChannel start:', pIHeliMic);
        const currentPosCh = this.m_parent.getCurrentPositionChannel();
        // 座標チャンネルに接続済みか判定
        console.log(currentPosCh.isConnected());
        if (!currentPosCh || !currentPosCh.isConnected()) {
            console.warn('Could not connect VoCh. connect PosCh first.');
            return false;
        }
        // 音声チャンネルが接続中の座標チャンネルの親か判定
        if (!((_this_m_channelId = this.m_channelId) === null || _this_m_channelId === void 0 ? void 0 : _this_m_channelId.startsWith(currentPosCh.getChannelId()))) {
            console.warn('Could not connect VoCh. connect diffrent parent PosCh.');
            return false;
        }
        if (this.m_parent.m_MicPermissionState !== EHeliMicPermissionState.Granted) {}
        try {
            var // TODO: ここでコールバックを呼び出すよりも、agoraClientのイベントから呼び出したい
            _this_m_parent_m_IHeliNetworkCallback;
            const resEntryVoCh = (await worldViewerWindow.heliport.api.channelSessionApi.enterVoiceChannel(this.m_channelId)).data;
            if (resEntryVoCh.code >= 400) {
                // 入室エラー
                this.setError(JSON.stringify(resEntryVoCh));
                return false;
            }
            if (this.m_parent.m_Mic_ready === false) {
                const deviceId = (pIHeliMic === null || pIHeliMic === void 0 ? void 0 : pIHeliMic.deviceId) || null;
                if (deviceId !== 'default') {
                    await this.m_parent.initLocalMicInput(deviceId);
                } else {
                    await this.m_parent.initLocalMicInput(null);
                }
                setLocalStreamFromAgoraLocalTrack();
            }
            this.m_parent.setCurrentVoiceChannel(this);
            // crp1.2になったらjoinでuserが取れたらuserCodeで
            // const decodeJwt = await this.getDecodedChannelSession()
            // const userCode = decodeJwt.user_code
            const channelSessionJwt = (await worldViewerWindow.heliport.api.channelSessionApi.getChannelSessionJwt()).data;
            console.log('VoCh join genericRtcToken channelSessionJwt', parseJwt(channelSessionJwt));
            const type = 'agora';
            const mode = 'talk';
            const token = (await worldViewerWindow.heliport.api.genericRtcToken.getToken(channelSessionJwt, type, mode)).data;
            console.log('joinVoiceChannel genericRtcToken jsonToken', token);
            const agoraToken = token.token;
            const agoraAppId = token.app_id;
            const agoraChannelId = token.room_id;
            // TODO agoraUserID と CRP objectIDの変換テーブル
            const agoraUserId = token.user_id;
            const micEnabled = false;
            await worldViewerWindow.agoraClient.joinTalk(agoraAppId, agoraToken, agoraChannelId, agoraUserId, micEnabled);
            await this.m_parent.checkMicPermissionState();
            (_this_m_parent_m_IHeliNetworkCallback = this.m_parent.m_IHeliNetworkCallback) === null || _this_m_parent_m_IHeliNetworkCallback === void 0 ? void 0 : _this_m_parent_m_IHeliNetworkCallback.OnNetVoiceConnected(agoraUserId);
            console.log('CarnelianAgora joinVoiceChannel done');
            return true;
        } catch (error) {
            const currentVoCh = this.m_parent.getCurrentVoiceChannel();
            if (currentVoCh && currentVoCh.getChannelId() === this.m_channelId) {
                this.leave();
            }
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async changeMic(pIHeliMic) {
        await worldViewerWindow.agoraClient.initLocalTrack({
            microphoneId: pIHeliMic.deviceId,
            cameraId: false
        });
    }
    async leave() {
        var // 音声チャンネルからの退出を通知
        _this_m_parent_m_IHeliNetworkCallback;
        try {
            const resLeaveVosCh = (await worldViewerWindow.heliport.api.channelSessionApi.leaveVoiceChannel()).data;
            if (resLeaveVosCh.code >= 400) {
                this.setError(JSON.stringify(resLeaveVosCh));
                return false;
            }
        } catch (error) {
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
        this.m_parent.setCurrentVoiceChannel(null);
        if (worldViewerWindow.agoraClient) {
            worldViewerWindow.agoraClient.close();
        }
        (_this_m_parent_m_IHeliNetworkCallback = this.m_parent.m_IHeliNetworkCallback) === null || _this_m_parent_m_IHeliNetworkCallback === void 0 ? void 0 : _this_m_parent_m_IHeliNetworkCallback.OnNetVoiceDisconnected();
        return true;
    }
    async update(params) {
        try {
            const result = (await worldViewerWindow.heliport.api.channelSessionApi.updateVoiceChannel(this.m_channelId, params.channelType, params.name, params.description)).data;
            if (result.code >= 400) {
                this.setError(JSON.stringify(result));
                return false;
            }
            this.voiceChannel = result.data.voice_channel;
            return true;
        } catch (error) {
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async refresh() {
        try {
            const resVoiceChDetail = (await worldViewerWindow.heliport.api.channelSessionApi.fetcthVoiceChannelDetail(this.m_channelId)).data;
            if (resVoiceChDetail.code >= 400) {
                this.setError(JSON.stringify(resVoiceChDetail));
                return false;
            }
            const voiceChannelDetail = resVoiceChDetail.data.voice_channel;
            this.voiceChannel = voiceChannelDetail;
            return true;
        } catch (error) {
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async getPlayerList() {
        const updateResult = await this.refresh();
        if (updateResult && this.voiceChannel && this.voiceChannel.channel_sessions) {
            return this.voiceChannel.channel_sessions.map((channelSession)=>{
                const playerInfoDetail = converChannelSessionToPlayerInfo(channelSession);
                return new CHeliChsPlayerInfo(playerInfoDetail);
            });
        } else {
            return [];
        }
    }
    constructor(parent, voiceChannelDetail){
        _defineProperty(this, "m_parent", void 0);
        _defineProperty(this, "m_channelId", void 0);
        _defineProperty(this, "m_channelType", void 0);
        _defineProperty(this, "voiceChannel", void 0);
        _defineProperty(this, "m_error", void 0);
        this.m_parent = parent;
        this.voiceChannel = voiceChannelDetail;
        if (voiceChannelDetail) {
            this.m_channelId = voiceChannelDetail.channel_id;
            this.m_channelType = voiceChannelDetail.channel_type;
        }
        this.m_error = null;
    }
}
export class CHeliCarnelianAgoraLiveChannel {
    isConnected() {
        if (worldViewerWindow.agoraLiveClient && worldViewerWindow.agoraLiveClient.ChannelName() === this.m_channelId) {
            return true;
        }
        return false;
    }
    getChannelId() {
        return this.m_channelId;
    }
    getError() {
        return this.m_error;
    }
    setError(errorMessage) {
        this.m_error = errorMessage;
    }
    getValue(key) {
        if (this.liveChannel) {
            return this.liveChannel[key];
        }
        return undefined;
    }
    async joinHost(pIHeliMic) {
        console.log('CarnelianAgora joinliveChannel start:', pIHeliMic);
        if (this.m_parent.m_MicPermissionState !== EHeliMicPermissionState.Granted) {}
        try {
            const resEntryVoCh = (await worldViewerWindow.heliport.api.channelSessionApi.enterLiveChannel(this.m_channelId)).data;
            if (resEntryVoCh.code >= 400) {
                // 入室エラー
                this.setError(JSON.stringify(resEntryVoCh));
                return false;
            }
            if (this.m_parent.m_Mic_ready === false) {
                const deviceId = (pIHeliMic === null || pIHeliMic === void 0 ? void 0 : pIHeliMic.deviceId) || null;
                if (deviceId !== 'default') {
                    await this.m_parent.initLocalMicInput(deviceId);
                } else {
                    await this.m_parent.initLocalMicInput(null);
                }
                setLocalStreamFromAgoraLocalTrack();
            }
            this.m_parent.setCurrentLiveChannel(this);
            // crp1.2になったらjoinでuserが取れたらuserCodeで
            // const decodeJwt = await this.getDecodedChannelSession()
            // const userCode = decodeJwt.user_code
            const channelSessionJwt = (await worldViewerWindow.heliport.api.channelSessionApi.getChannelSessionJwt()).data;
            console.log('VoCh join genericRtcToken channelSessionJwt', parseJwt(channelSessionJwt));
            const type = 'agora';
            const mode = 'live';
            const res = await worldViewerWindow.heliport.api.genericRtcToken.getToken(channelSessionJwt, type, mode);
            const token = res.data;
            console.log('joinliveChannel genericRtcToken jsonToken', token);
            const agoraToken = token.token;
            const agoraAppId = token.app_id;
            const agoraChannelId = token.room_id;
            // TODO agoraUserID と CRP objectIDの変換テーブル
            const agoraUserId = token.user_id;
            const micEnabled = false;
            if (this.m_parent.m_Mic_ready === false) {
                await this.m_parent.initLocalMicInput(null);
            }
            worldViewerWindow.agoraLiveClient.setLocalAgoraTrack(this.m_parent.m_localAgoraTrack);
            await worldViewerWindow.agoraLiveClient.joinLiveHostByToken(agoraAppId, agoraToken, agoraChannelId, agoraUserId, micEnabled);
            await this.m_parent.checkMicPermissionState();
            // TODO: ここでコールバックを呼び出すよりも、agoraClientのイベントから呼び出したい
            //this.m_parent.m_IHeliNetworkCallback?.OnNetLiveConnected(agoraUserId);
            console.log('CarnelianAgora joinliveChannel done');
            return true;
        } catch (error) {
            console.error(error);
            const currentLiveCh = this.m_parent.getCurrentLiveChannel();
            console.log(currentLiveCh);
            if (currentLiveCh && currentLiveCh.getChannelId() === this.m_channelId) {
                this.leave();
            }
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async joinAudience() {
        try {
            const liveChannelCode = this.m_channelId;
            const res = await worldViewerWindow.heliport.api.channelSessionApi.fetchLiveChannelAudienceJwt(liveChannelCode);
            const json = typeof res == 'string' ? JSON.parse(res) : res;
            const type = 'agora';
            const resGetToken = await worldViewerWindow.heliport.api.genericRtcToken.getToken(json.data.jwt, type, 'live', false);
            console.log(resGetToken);
            const token = resGetToken.data;
            const agoraToken = token.token;
            const agoraAppId = token.app_id;
            const agoraChannelId = token.room_id;
            const agoraUserId = token.user_id;
            const latencyLevel = 1;
            console.log('CarnelianAgora joinLiveChannel audience start');
            await worldViewerWindow.agoraLiveClient.joinLiveAudienceByToken(agoraAppId, agoraToken, agoraChannelId, agoraUserId, latencyLevel);
            // TODO: ここでコールバックを呼び出すよりも、agoraClientのイベントから呼び出したい
            //this.m_parent.m_IHeliNetworkCallback?.OnNetLiveConnected(agoraUserId);
            console.log('CarnelianAgora joinLiveChannel audience done');
            return true;
        } catch (error) {
            const currentLiveCh = this.m_parent.getCurrentLiveChannel();
            if (currentLiveCh && currentLiveCh.getChannelId() === this.m_channelId) {
                this.leave();
            }
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async changeMic(pIHeliMic) {
        await worldViewerWindow.agoraLiveClient.initLocalTrack({
            microphoneId: pIHeliMic.deviceId,
            cameraId: false
        });
    }
    async leave() {
        try {
            const resLeaveVosCh = (await worldViewerWindow.heliport.api.channelSessionApi.leaveLiveChannel()).data;
            if (resLeaveVosCh.code >= 400) {
                this.setError(JSON.stringify(resLeaveVosCh));
                return false;
            }
        } catch (error) {
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
        this.m_parent.setCurrentLiveChannel(null);
        if (worldViewerWindow.agoraClient) {
            worldViewerWindow.agoraClient.close();
        }
        return true;
    }
    async refresh() {
        try {
            const result = (await worldViewerWindow.heliport.api.channelSessionApi.fetcthLiveChannelDetail(this.m_channelId)).data;
            if (result.status === 'ng') {
                this.setError(JSON.stringify(result));
                return false;
            }
            const liveChannelDetail = result.data.data.live_channel;
            this.liveChannel = liveChannelDetail;
            return true;
        } catch (error) {
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async getPlayerList() {
        const updateResult = await this.refresh();
        if (updateResult && this.liveChannel && this.liveChannel.channel_sessions) {
            return this.liveChannel.channel_sessions.map((channelSession)=>{
                const playerInfoDetail = converChannelSessionToPlayerInfo(channelSession);
                return new CHeliChsPlayerInfo(playerInfoDetail);
            });
        } else {
            return [];
        }
    }
    constructor(parent, liveChannelDetail){
        _defineProperty(this, "m_parent", void 0);
        _defineProperty(this, "m_channelId", void 0);
        _defineProperty(this, "m_channelType", void 0);
        _defineProperty(this, "liveChannel", void 0);
        _defineProperty(this, "m_error", void 0);
        this.m_parent = parent;
        this.liveChannel = liveChannelDetail;
        if (liveChannelDetail) {
            this.m_channelId = liveChannelDetail.channel_id;
        }
        this.m_error = null;
    }
}
export class CHeliCarnelianAgora {
    // 接続
    async connect(configKey) {
        worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_SetCRPMode, '1');
        console.log('CarnelianAgora connect start');
        const CONFIG = worldViewerWindow.getIngameConfig();
        const crpEndPoint = `wss://${CONFIG.CRP_HOST}/ws/${CONFIG.CRP_VERSION}/`;
        const p1HeliIF = new worldViewerWindow.P1HeliodorInterface();
        worldViewerWindow.p1HeliIF = p1HeliIF;
        const crpFront = worldViewerWindow.createCrpFront(crpEndPoint);
        setCrpFront(crpFront);
        worldViewerWindow.crpRegist(crpFront, p1HeliIF);
        // 座標チャンネル接続時
        /*
    crpFront.client.on({
      joinChannelReply: async (reply: { requestId: string, channelId: string }) => {
        console.log('CRPFRONT: joinChannelReply reply:', reply)
        //同期開始のための空移動
        crpFront.sendMovement([0, 0, 0], [0, 0, 0, 0], [0, 0, 0], 1, 0)
        this.m_IHeliNetworkCallback?.OnNetPosConnected();
        console.log('CRPFRONT: 座標チャンネル接続完了')
      },
    })
    */ crpFront.onOpened(()=>{
            console.log('onOpened');
            crpFront.sendMovement([
                0,
                0,
                0
            ], [
                0,
                0,
                0,
                0
            ], [
                0,
                0,
                0
            ], 1, 0);
        });
        crpFront.onEntered(({ objectId  })=>{
            var // PlayerIDが使用可能になったことを通知する
            _this_m_IHeliNetworkCallback, _this_m_IHeliNetworkCallback1;
            console.log('crpFront.onEntered objectId', objectId);
            p1HeliIF.receiveData('open', objectId, '');
            this.sendData('start');
            (_this_m_IHeliNetworkCallback = this.m_IHeliNetworkCallback) === null || _this_m_IHeliNetworkCallback === void 0 ? void 0 : _this_m_IHeliNetworkCallback.OnNetCreatedPlayerID(objectId);
            (_this_m_IHeliNetworkCallback1 = this.m_IHeliNetworkCallback) === null || _this_m_IHeliNetworkCallback1 === void 0 ? void 0 : _this_m_IHeliNetworkCallback1.OnNetPosConnected();
            console.log('CRPFRONT: 座標チャンネル接続完了');
        });
        // 他プレーヤー入室時
        crpFront.onRemoteJoin((info)=>{
            console.log('onRemoteJoin', info);
            const objectId = info.objectId;
            const role = info.role;
            const name = info.name;
            const avatarId = info.avatarId;
            const isStart = info.isStart;
            const user = info.user;
            this.createPlayer(objectId, user.userCode, user.channelSessionCode, role);
            const startFlag = isStart ? '1' : '0';
            const sanitizedAvatarId = this.sanitizeAvatarId(role, avatarId);
            p1HeliIF.receiveData('data', objectId, `join:${name}:${sanitizedAvatarId}:${startFlag}`);
            if (role === 'presenter') {
                window.setTimeout(()=>{
                    p1HeliIF.setPresenter(objectId);
                }, 1000);
            }
        });
        crpFront.onReceiveData(({ objectId , data , role  })=>{
            // console.log('crpFront.onReceiveData', objectId, data, role)
            if (data.startsWith('screenshare:')) {
                this.handleMessageScreenShare(objectId, data, role);
            }
            p1HeliIF.receiveData('data', objectId, data);
        });
        const agoraClient = new worldViewerWindow.AgoraClient({
            mode: 'rtc',
            ctrlRemoteVideoLiveStreamVolume: worldViewerWindow.hel_isiPhone()
        });
        agoraClient.on('subscribePlayerAudio', ({ user  })=>{
            console.log('subscribePlayerAudio user:', user.hasAudio);
            user.audioTrack.play();
        });
        // ライブ配信終了時
        agoraClient.on('stopRemoteVideoLiveStream', ({ user , remoteVideoLiveStream  })=>{
            console.log('live streaming stopped', user, remoteVideoLiveStream);
        });
        // ライブ配信受信時
        agoraClient.on('updateRemoteVideoLiveStream', ({ user , remoteVideoLiveStream  })=>{
            console.log('live streaming start', user, remoteVideoLiveStream);
        });
        window.addEventListener('beforeunload', ()=>{
            worldViewerWindow.crpFront.close();
            agoraClient.close();
        });
        // 他プレーヤーjoin自
        agoraClient.onUserJoined((agoraUser)=>{
            console.log('agoraClient onUserJoined', agoraUser);
            const player = this.getPlayerBy('channelSessionCode', agoraUser.uid);
            if (!player) {
                console.warn('no player agoraUser.uid', agoraUser.uid);
                return;
            }
            player.setAgoraUser(agoraUser);
            // 音声チャンネルへのJoinを通知
            requestOnNetReceiveVoiceJoin(agoraUser.uid, '');
        });
        agoraClient.onUserLeft((agoraUser)=>{
            console.log('agoraClient onUserLeft', agoraUser);
            // 音声チャンネルからのLeaveを通知
            requestOnNetReceiveVoiceLeave(agoraUser, '');
        });
        worldViewerWindow.agoraClient = agoraClient;
        // 開発用
        // 何かしらの資材
        const isLive = true;
        if (isLive) {
            this.initLiveClient();
        }
        console.log('ChS-JWT', await worldViewerWindow.heliport.api.channelSessionApi.getChannelSessionJwt());
        console.log('CarnelianAgora connect done');
        return true;
    }
    disconnect() {
        var // 座標チャンネルからの退出を通知
        _this_m_IHeliNetworkCallback;
        console.log('CarnelianAgora disconnect done');
        const currentPosCh = this.getCurrentPositionChannel();
        if (currentPosCh) {
            currentPosCh.leave();
        }
        closeLocalStream();
        console.log('CarnelianAgora disconnect done');
        (_this_m_IHeliNetworkCallback = this.m_IHeliNetworkCallback) === null || _this_m_IHeliNetworkCallback === void 0 ? void 0 : _this_m_IHeliNetworkCallback.OnNetPosDisconnected();
    }
    isConnected() {
        return false;
    }
    isConnectedPositionChannel() {
        const crpFront = getCrpFront();
        if (crpFront && crpFront.positionChannelId && crpFront.ownObjectId) {
            return true;
        }
        return false;
    }
    getPeerId() {
        return '';
    }
    // 仮コード
    leavePosChannel() {
        return;
    }
    async initLocalMicInput(deviceId) {
        console.log('initLocalMicInput deviceId', deviceId);
        try {
            this.m_localAgoraTrack = await worldViewerWindow.agoraClient.initLocalTrack({
                microphoneId: deviceId
            });
            this.m_Mic_ready = true;
            return true;
        } catch (error) {
            // TODO
            console.error(error);
            return false;
        }
    }
    getLocalMicAudioTrack() {
        return this.m_localAgoraTrack.audioTrack;
    }
    setMicEnabled(enabled) {
        worldViewerWindow.agoraClient.setMicMuted(!enabled);
    }
    // マイク許可状態
    getMicPermissionState() {
        return this.m_MicPermissionState;
    }
    async checkMicPermissionState() {
        const prevPermissionState = this.m_MicPermissionState;
        try {
            const permissionStatus = await navigator.permissions.query({
                name: 'microphone'
            });
            if (permissionStatus.state === 'denied') {
                this.m_MicPermissionState = EHeliMicPermissionState.Denied;
            } else if (permissionStatus.state === 'granted') {
                this.m_MicPermissionState = EHeliMicPermissionState.Granted;
            } else if (permissionStatus.state === 'prompt') {
                this.m_MicPermissionState = EHeliMicPermissionState.Prompt;
            } else {
                this.m_MicPermissionState = EHeliMicPermissionState.Prompt;
            }
        } catch (e) {
            // Firefoxでは'microphone'に対応していないので、LocalStreamの設定状態で判別する
            if (getLocalStream() !== undefined) {
                this.m_MicPermissionState = EHeliMicPermissionState.Granted;
            } else {
                this.m_MicPermissionState = EHeliMicPermissionState.Denied;
            }
        }
        // iOSではマイク不許可時にDeniedではなくPromptしか返ってこず、マイク許可を促すCanvasが出なくなってしまうのでDeniedにする
        if (worldViewerWindow.hel_isSafari() && this.m_MicPermissionState == EHeliMicPermissionState.Prompt) {
            this.m_MicPermissionState = EHeliMicPermissionState.Denied;
        }
        // 状態変更によるコールバック呼び出し
        if (this.m_IHeliNetworkCallback != null && prevPermissionState != this.m_MicPermissionState) {
            this.m_IHeliNetworkCallback.OnChangedMicPermissionState(this.m_MicPermissionState);
        }
    }
    // 座標チャネル
    async createPosChannel(spatiumCode, worldCode, params) {
        try {
            const resCreatePosChannel = (await worldViewerWindow.heliport.api.channelSessionApi.createPositionChannel(spatiumCode, worldCode, params.channelType, params.name, params.description)).data;
            if (resCreatePosChannel.code >= 400) {
                const iHeliPositionChannel = new CHeliCarnelianAgoraPosChannel(this, null);
                iHeliPositionChannel.setError(JSON.stringify(resCreatePosChannel));
                return iHeliPositionChannel;
            }
            const postionChannelDetail = resCreatePosChannel.data.position_channel;
            const iHeliPositionChannel = new CHeliCarnelianAgoraPosChannel(this, postionChannelDetail);
            return iHeliPositionChannel;
        } catch (error) {
            const iHeliPositionChannel = new CHeliCarnelianAgoraPosChannel(this, null);
            iHeliPositionChannel.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return iHeliPositionChannel;
        }
    }
    async getPosChannel(channelid) {
        try {
            const resPosChDetail = (await worldViewerWindow.heliport.api.channelSessionApi.fetcthPositionChannelDetail(channelid)).data;
            if (resPosChDetail.code >= 400) {
                const iHeliPositionChannel = new CHeliCarnelianAgoraPosChannel(this, null);
                iHeliPositionChannel.setError(JSON.stringify(resPosChDetail));
                return iHeliPositionChannel;
            }
            const postionChannelDetail = resPosChDetail.data.position_channel;
            const iHeliPositionChannel = new CHeliCarnelianAgoraPosChannel(this, postionChannelDetail);
            return iHeliPositionChannel;
        } catch (error) {
            const iHeliPositionChannel = new CHeliCarnelianAgoraPosChannel(this, null);
            iHeliPositionChannel.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return iHeliPositionChannel;
        }
    }
    async getPosChannelList(spatiumcode, worldCode, params) {
        try {
            const excludeEmptyChannelFlg = params.excludeEmptyChannelFlg !== undefined && params.excludeEmptyChannelFlg !== null ? params.excludeEmptyChannelFlg : true;
            const resPosChList = (await worldViewerWindow.heliport.api.channelSessionApi.fetchWolrdPositionChannelList(spatiumcode, worldCode, excludeEmptyChannelFlg)).data;
            if (resPosChList.code >= 400) {
                const iHeliPosChList = new IHeliCarnelianAgoraPosChannelList();
                iHeliPosChList.setError(`${resPosChList.code}:${resPosChList.message}`);
                return iHeliPosChList;
            }
            const posChList = resPosChList.data.position_channels.map((postionChannel)=>{
                const postionChannelDetail = postionChannel;
                const iHeliPositionChannel = new CHeliCarnelianAgoraPosChannel(this, postionChannelDetail);
                return iHeliPositionChannel;
            });
            const offset = params.offset ? params.offset : 0;
            const limit = params.limit ? params.limit : null;
            const iHeliPosChList = new IHeliCarnelianAgoraPosChannelList(posChList, offset, limit);
            return iHeliPosChList;
        } catch (error) {
            const iHeliPosChList = new IHeliCarnelianAgoraPosChannelList();
            iHeliPosChList.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return iHeliPosChList;
        }
    }
    setCurrentPositionChannel(posionChannel) {
        this.m_current_position_channel = posionChannel;
    }
    getCurrentPositionChannel() {
        return this.m_current_position_channel;
    }
    // ボイスチャネル
    async createVoiceChannel(posionChannelCode, params) {
        try {
            const resCreateVoChannel = (await worldViewerWindow.heliport.api.channelSessionApi.createVoiceChannel(posionChannelCode, params.channelType, params.name, params.description)).data;
            if (resCreateVoChannel.code >= 400) {
                const iHeliVoiceChannel = new CHeliCarnelianAgoraVoiceChannel(this, null);
                iHeliVoiceChannel.setError(JSON.stringify(resCreateVoChannel));
                return iHeliVoiceChannel;
            }
            const voiceChannelDetail = resCreateVoChannel.data.voice_channel;
            const iHeliVoiceChannel = new CHeliCarnelianAgoraVoiceChannel(this, voiceChannelDetail);
            return iHeliVoiceChannel;
        } catch (error) {
            const iHeliVoiceChannel = new CHeliCarnelianAgoraVoiceChannel(this, null);
            iHeliVoiceChannel.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return iHeliVoiceChannel;
        }
    }
    async getVoiceChannel(channelid) {
        try {
            const resVoChDetail = (await worldViewerWindow.heliport.api.channelSessionApi.fetcthVoiceChannelDetail(channelid)).data;
            if (resVoChDetail.code >= 400) {
                const iHeliVoitionChannel = new CHeliCarnelianAgoraVoiceChannel(this, null);
                iHeliVoitionChannel.setError(JSON.stringify(resVoChDetail));
                return iHeliVoitionChannel;
            }
            const voiceChannelDetail = resVoChDetail.data.voice_channel;
            const iHeliVoitionChannel = new CHeliCarnelianAgoraVoiceChannel(this, voiceChannelDetail);
            return iHeliVoitionChannel;
        } catch (error) {
            const iHeliVoiceChannel = new CHeliCarnelianAgoraVoiceChannel(this, null);
            iHeliVoiceChannel.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return iHeliVoiceChannel;
        }
    }
    async getVoiceChannelList(poschannelid, params) {
        try {
            const resVoChList = (await worldViewerWindow.heliport.api.channelSessionApi.fetchPositionChannelVoiceChannelList(poschannelid)).data;
            if (resVoChList.code >= 400) {
                const iHeliVoiceChList = new IHeliCarnelianAgoraVoiceChannelList();
                iHeliVoiceChList.setError(JSON.stringify(resVoChList));
                return iHeliVoiceChList;
            }
            const voChList = resVoChList.data.voice_channels.map((voiceChannel)=>{
                const voiceChannelDetail = voiceChannel;
                const iHeliVoiceChannel = new CHeliCarnelianAgoraVoiceChannel(this, voiceChannelDetail);
                return iHeliVoiceChannel;
            });
            const offset = params.offset ? params.offset : 0;
            const limit = params.limit ? params.limit : null;
            const iHeliVoiceChList = new IHeliCarnelianAgoraVoiceChannelList(voChList, offset, limit);
            return iHeliVoiceChList;
        } catch (error) {
            const iHeliVoiceChList = new IHeliCarnelianAgoraVoiceChannelList();
            iHeliVoiceChList.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return iHeliVoiceChList;
        }
    }
    getCurrentVoiceChannel() {
        return this.m_current_voice_channel;
    }
    setCurrentVoiceChannel(voiceChannel) {
        this.m_current_voice_channel = voiceChannel;
    }
    async getLiveChannel(channelid) {
        try {
            const resLiveChDetail = (await worldViewerWindow.heliport.api.channelSessionApi.fetcthLiveChannelDetail(channelid)).data;
            if (resLiveChDetail.code >= 400) {
                const iHeliVoitionChannel = new CHeliCarnelianAgoraVoiceChannel(this, null);
                iHeliVoitionChannel.setError(JSON.stringify(resLiveChDetail));
                return iHeliVoitionChannel;
            }
            const liveChannelDetail = resLiveChDetail.data.live_channel;
            const iHeliLiveChannel = new CHeliCarnelianAgoraLiveChannel(this, liveChannelDetail);
            return iHeliLiveChannel;
        } catch (error) {
            const iHeliLiveChannel = new CHeliCarnelianAgoraLiveChannel(this, null);
            iHeliLiveChannel.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return iHeliLiveChannel;
        }
    }
    /**
   * @remarks
   * 座標チャンネル単位でのライブチャンネル一覧取得
   */ async getPositionChannelLiveChannelList(posionChannelCode) {
        const result = await worldViewerWindow.heliport.api.channelSessionApi.fetchPositionChannelLiveChannelList(posionChannelCode);
        if (result.status === 'ng') {
            const iHeliLiveChList = new IHeliCarnelianAgoraLiveChannelList();
            iHeliLiveChList.setError(`${result.code}:${result.message}`);
            return iHeliLiveChList;
        }
        const liveChList = result.data.data.live_channels.map((liveChannel)=>{
            const LiveChannelDetail = liveChannel;
            const iHeliLiveChannel = new CHeliCarnelianAgoraLiveChannel(this, LiveChannelDetail);
            return iHeliLiveChannel;
        });
        const iHeliLiveChList = new IHeliCarnelianAgoraLiveChannelList(liveChList);
        return iHeliLiveChList;
    }
    /**
   * @remarks
   * ワールド単位でのライブチャンネル一覧取得
   */ async getWorldLiveChannelList(spatiumCode, worldCode) {
        const result = await worldViewerWindow.heliport.api.channelSessionApi.fetchWolrdLiveChannelList(spatiumCode, worldCode);
        if (result.status === 'ng') {
            const iHeliLiveChList = new IHeliCarnelianAgoraLiveChannelList();
            iHeliLiveChList.setError(`${result.code}:${result.message}`);
            return iHeliLiveChList;
        }
        const liveChList = result.data.data.live_channels.map((liveChannel)=>{
            const LiveChannelDetail = liveChannel;
            const iHeliLiveChannel = new CHeliCarnelianAgoraLiveChannel(this, LiveChannelDetail);
            return iHeliLiveChannel;
        });
        const iHeliLiveChList = new IHeliCarnelianAgoraLiveChannelList(liveChList);
        return iHeliLiveChList;
    }
    getCurrentLiveChannel() {
        return this.m_current_live_channel;
    }
    setCurrentLiveChannel(liveChannel) {
        this.m_current_live_channel = liveChannel;
    }
    async connectRoomAfterPeerOpen(roomid) {}
    // ハートビート
    // heliportが担うので
    sendHeartBeat() {}
    // プレイヤー
    createPlayer(playerId, userCode, channelSessionCode, role) {
        console.log('createPlayer', playerId);
        const player = new CHELCarnelianAgoraPlayer(playerId, userCode, getMixerRealSettingAt(HEL_AUDIO_TYPE_VOICE), role);
        player.setChannelSessionCode(channelSessionCode);
        this.playerListAdd(playerId, player);
    }
    releasePlayer(playerId) {
        const player = this.getPlayer(playerId);
        if (!player) {
            return false;
        }
        player.release();
        this.playerListRemove(playerId);
        return true;
    }
    // CRP agoraでは使わない
    setPlayerStream(stream) {
        if (!stream.peerId) {
            debugLog('stream.peerId not');
            return;
        }
        const player = this.getPlayer(stream.peerId);
        if (player === null) {
            debugLog('CreatePlayer error : ' + stream.peerId);
            return;
        }
        player.setStream(stream);
    }
    getPlayer(playerId) {
        return this.m_PlayerMap.get(playerId);
    }
    getPlayerBy(keyName, findValue) {
        for (const [key, value] of this.m_PlayerMap.entries()){
            if (value.getValue(keyName) == findValue) {
                return value;
            }
        }
        return undefined;
    }
    playerListAdd(playerId, player) {
        this.m_PlayerMap.set(playerId, player);
    }
    playerListRemove(playerId) {
        this.m_PlayerMap.delete(playerId);
    }
    getPlayerList() {
        return this.m_PlayerMap;
    }
    releaseAllPlayer() {
        const playerList = this.getPlayerList();
        console.log('releaseAllPlayer playerList', playerList, playerList.size);
        playerList.forEach((player)=>{
            console.log('releaseAllPlayer player', player.getPlayerId(), player);
            if (player) {
                // TODO: このへん整理したい
                worldViewerWindow.hel_skyway_receive_data('leave', player.getPlayerId(), '');
                player.release();
                this.playerListRemove(player.getPlayerId());
                return;
            }
        });
    }
    sendMyPlayerInfoToAllPlayer() {
        if (!this.isConnected()) return;
        this.sendData('join:' + getSkywayName() + ':' + getSkywayAvatarNumberForSend() + ':' + (getSkywayStartToggle() ? 1 : 0) + ':' + (getSkywayUseCustomAvatarIcon() ? 1 : 0));
        // アバター情報を送信する
        if (getSkywayAvatarNumberForSend() !== -1) {
            worldViewerWindow.hel_skyway_send_data('avatar:' + getSkywayAvatarNumberForSend());
        }
        // カスタムアバターアイコンのURLを送信する
        if (getSkywayUseCustomAvatarIcon() && getSkywayCustomAvatarIconURL() !== '') {
            worldViewerWindow.hel_skyway_send_data('customavataricon:' + getSkywayCustomAvatarIconURL());
        }
        // カスタムユーザーデータを送信する
        worldViewerWindow.hel_skyway_myinfo_bridge_call();
    }
    // プレイヤー：音量
    setPlayerVoiceMute(playreId, mute) {
        const Player = this.getPlayer(playreId);
        if (Player === null) return;
        // eslint-disable-next-line new-cap
        Player.SetMute(mute);
    }
    setPlayerVoiceAttenuate(playerId, volume) {
        if (typeof volume !== 'number') {
            return;
        }
        const player = this.getPlayer(playerId);
        if (!player) {
            return;
        }
        player.SetVoiceAttenuate(volume);
    }
    setAllPlayerVoiceVolume(volume) {
        const playerList = this.getPlayerList();
        playerList.forEach((player)=>{
            if (player) {
                player.setVolume(volume);
            }
        });
    }
    handleMessageScreenShare(playerId, data, role) {
        if (data === 'screenshare:tell') {
            const fn = getSkywayOnTellScreenShareStart();
            if (fn !== undefined) {
                fn();
                return;
            }
        }
        if (data === 'screenshare:waitstart') {
            const fn = getSkywayOnTellWaitScreenShare();
            if (fn !== undefined) {
                fn();
                return;
            }
        }
        const paramsScreenShare = data.split(':');
        if (paramsScreenShare[1] === 'start') {
            const player = this.getPlayer(playerId);
            if (player === null) {
                return;
            }
            const remoteVideoStream = player.getVideoMediaStream();
            if (worldViewerWindow.hel_video_is_playing_same_stream(remoteVideoStream)) {
                return;
            }
            // グローバルにhel_onReceiveNoticeScreenShareStartが定義されていた場合に実行する
            if (typeof worldViewerWindow.hel_onReceiveNoticeScreenShareStart === 'function') {
                worldViewerWindow.hel_onReceiveNoticeScreenShareStart(playerId, remoteVideoStream, paramsScreenShare[2], paramsScreenShare[3]);
            }
            // 予約
            this.reserveRemotoVideoStream(remoteVideoStream, paramsScreenShare[2], paramsScreenShare[3]);
            // HeliScriptのコールバックを呼び出す
            worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_OnReceivedStartScreenShare, '');
        }
        if (paramsScreenShare[1] === 'stop') {
            // グローバルにhel_onReceiveNoticeScreenShareStopが定義されていた場合に実行する
            if (typeof worldViewerWindow.hel_onReceiveNoticeScreenShareStop === 'function') {
                worldViewerWindow.hel_onReceiveNoticeScreenShareStop(playerId);
            } else {
                worldViewerWindow.hel_video_stop();
                // HeliScriptのコールバックを呼び出す
                worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_OnReceivedStopScreenShare, '');
            }
        }
    }
    // 低レベルデータ送信
    sendData(param) {
        // console.log('SEND DATA: ', param)
        if (!worldViewerWindow.crpFront) {
            return;
        }
        const crpFront = getCrpFront();
        const transformParams = param.split(':');
        switch(transformParams[0]){
            case 'move':
                // ベクトル形式の移動メッセージ
                const positionArray = transformParams.slice(1, 4).map((s)=>parseFloat(s));
                const rotationArray = transformParams.slice(4, 8).map((s)=>parseFloat(s));
                const velocityArray = transformParams.slice(8, 11).map((s)=>parseFloat(s));
                const grand = parseInt(transformParams[11]) == 1;
                const userTime = parseInt(transformParams[12]);
                crpFront.sendMovement(positionArray, rotationArray, velocityArray, grand, userTime);
                break;
            case 'pos':
                console.log('pos');
                break;
            case 'name':
                crpFront.setName(transformParams[1]);
                crpFront.updateStatus();
                break;
            case 'avatar':
                crpFront.setAvatar(transformParams[1]);
                crpFront.updateStatus();
                break;
            case 'myavatar':
                crpFront.setState('myAvatarId', transformParams[1]);
                crpFront.updateStatus();
                break;
            case 'avataricon':
                crpFront.setIcon(transformParams[1]);
                crpFront.updateStatus();
                break;
            case 'customavataricon':
                crpFront.setState('customIconUrl', transformParams[1]);
                crpFront.updateStatus();
                break;
            case 'join':
                crpFront.setName(transformParams[1]);
                crpFront.setAvatar(transformParams[2]);
                if (parseInt(transformParams[3]) == 1) {
                    crpFront.setState('preparation', 'start');
                }
                crpFront.updateStatus();
                crpFront.sendMessage(param);
                const profData = {
                    name: transformParams[1],
                    avatar: transformParams[2]
                };
                console.log('crp send join profData', profData);
                break;
            case 'start':
                crpFront.setState('preparation', 'start');
                crpFront.updateStatus();
                break;
            case 'msg':
                if (crpFront.isEntered && crpFront.ownObjectId) {
                    // 必要？
                    worldViewerWindow.p1HeliIF.receiveData('data', crpFront.ownObjectId, param);
                }
                crpFront.sendMessage(param);
                break;
            default:
                crpFront.sendMessage(param);
        }
    }
    // 画面共有が可能なconnect　※agoraでは通常接続でいい
    async connectWithDisplayMedia(configKey) {
        await this.connect(configKey);
    }
    async startScreenShare(videoItemName, videoMaterialName, useCamera, useAudio) {
        if (!navigator.mediaDevices) {
            debugLog('navigator.mediaDevices not found');
            return;
        }
        const localVideoStream = await this.getLocalVideoStream(useCamera, useAudio);
        await worldViewerWindow.agoraClient.publishVideoStream(localVideoStream);
        // ローカル画面共有の再生
        worldViewerWindow.hel_video_stop();
        helcore_video_play(videoItemName, videoMaterialName, './Video/blankVideo.mp4', true, false, false);
        this.setScreenShareCurrentInfo(videoItemName, videoMaterialName);
        const video = getVideo();
        setTimeout(()=>{
            if (video != null) {
                if (localVideoStream) {
                    video.srcObject = localVideoStream;
                }
                video.play();
                this.sendScreenShareStart();
            }
        }, 500);
    }
    setScreenShareCurrentInfo(videoItemName, videoMaterialName) {
        this.m_ScreenShare_CurrentVideoItemName = videoItemName;
        this.m_ScreenShare_CurrentVideoMaterialName = videoMaterialName;
    }
    sendScreenShareStart() {
        if (this.m_ScreenShare_CurrentVideoItemName === '') return;
        if (this.m_ScreenShare_CurrentVideoMaterialName === '') return;
        worldViewerWindow.hel_skyway_send_data(`screenshare:start:${this.m_ScreenShare_CurrentVideoItemName}:${this.m_ScreenShare_CurrentVideoMaterialName}`);
    }
    async getLocalVideoStream(useCamera, useAudio) {
        if (useCamera) {
            const localVideoStream = await navigator.mediaDevices.getUserMedia({
                audio: useAudio,
                video: true
            }).catch(console.error);
            if (!localVideoStream) {
                console.error('');
                return;
            }
            return localVideoStream;
        } else {
            const displayMediaOptions = {
                video: {
                    cursor: 'always'
                },
                audio: false
            };
            const localVideoStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
            localVideoStream.getTracks()[0].addEventListener('ended', ()=>{
                console.log('screen share ended');
            // onScreenShareCancel();
            });
            return localVideoStream;
        }
    }
    async stopScreenShare() {
        await worldViewerWindow.agoraClient.unpublishVideoStream();
    }
    reserveRemotoVideoStream(remoteVideoStream, videoItemName, videoMaterialName) {
        this.m_skyway_reseved_remoteVideoStream = remoteVideoStream;
        this.m_skyway_reseved_videoItemName = videoItemName;
        this.m_skyway_reseved_videoMaterialName = videoMaterialName;
    }
    playScreenShare() {
        worldViewerWindow.hel_skyway_send_data('screenshare:waitstart');
    }
    playScreenShareReserved() {
        worldViewerWindow.hel_skyway_play_remote_video_stream(this.m_skyway_reseved_remoteVideoStream, this.m_skyway_reseved_videoItemName, this.m_skyway_reseved_videoMaterialName);
    }
    holdLiveVideoStream() {}
    releaseLiveVideoStream() {}
    playLiveVideoStream(itemName, materialName) {}
    // コールバック
    setCallback(callback) {
        this.m_IHeliNetworkCallback = callback;
    }
    parseJwt(token) {
        if (typeof token !== 'string') {
            return null;
        }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
    async getDecodedChannelSession() {
        const channelSessionJwt = (await worldViewerWindow.heliport.api.channelSessionApi.getChannelSessionJwt()).data;
        return this.parseJwt(channelSessionJwt);
    }
    async getMicDevices() {
        const deviceList = await navigator.mediaDevices.enumerateDevices();
        const audioInputDevices = deviceList.filter((device)=>{
            if (device.kind === 'audioinput') {
                return true;
            }
            return false;
        });
        return audioInputDevices;
    }
    getApiNotificationHandler() {
        return this.m_ApiNotification;
    }
    apiNotificationReciever(scope, type, params) {
        if (this.m_ApiNotification == null) {
            return;
        }
        this.m_ApiNotification.dispatchEvent(scope, type, params);
    }
    apiChangeItemPropertySender(refId, key, value) {
        var _this_m_current_position_channel_positionChannel, _this_m_current_position_channel_positionChannel1, _this_m_current_position_channel_positionChannel2;
        if (this.m_current_position_channel == null) {
            return;
        }
        // APIにメッセージを送信
        worldViewerWindow.heliport.api.ingameStateManagerApi.changeItemProperty((_this_m_current_position_channel_positionChannel = this.m_current_position_channel.positionChannel) === null || _this_m_current_position_channel_positionChannel === void 0 ? void 0 : _this_m_current_position_channel_positionChannel.spatium_code, (_this_m_current_position_channel_positionChannel1 = this.m_current_position_channel.positionChannel) === null || _this_m_current_position_channel_positionChannel1 === void 0 ? void 0 : _this_m_current_position_channel_positionChannel1.world_code, (_this_m_current_position_channel_positionChannel2 = this.m_current_position_channel.positionChannel) === null || _this_m_current_position_channel_positionChannel2 === void 0 ? void 0 : _this_m_current_position_channel_positionChannel2.channel_id, refId, "{\"" + key + "\": \"" + value + "\"}");
    }
    initApiNotificationHandler() {
        var _this_m_ApiNotification;
        (_this_m_ApiNotification = this.m_ApiNotification) === null || _this_m_ApiNotification === void 0 ? void 0 : _this_m_ApiNotification.init();
    }
    /**
   * TODO
   * エンジン対応するまでの暫定実装
   */ getSelfPlayerRole() {
        var _this_m_selfPlayerInfo, _this_m_selfPlayerInfo1;
        // user_roleがnullの場合がある
        if (((_this_m_selfPlayerInfo = this.m_selfPlayerInfo) === null || _this_m_selfPlayerInfo === void 0 ? void 0 : _this_m_selfPlayerInfo.user_role) == null) {
            return "normal";
        }
        return (_this_m_selfPlayerInfo1 = this.m_selfPlayerInfo) === null || _this_m_selfPlayerInfo1 === void 0 ? void 0 : _this_m_selfPlayerInfo1.user_role;
    }
    initLiveClient() {
        console.log('initLiveClient start');
        const agoraLiveClient = new worldViewerWindow.AgoraClient({
            mode: 'live',
            ctrlRemoteVideoLiveStreamVolume: worldViewerWindow.hel_isiPhone()
        });
        console.log('initLiveClient agoraLiveClient', agoraLiveClient);
        agoraLiveClient.on('subscribePlayerAudio', ({ user  })=>{
            console.log('subscribePlayerAudio user:', user.hasAudio);
            user.audioTrack.play();
        });
        agoraLiveClient.on('stopRemoteVideoLiveStream', ({ user , remoteVideoLiveStream  })=>{
            console.log('live streaming stopped', user, remoteVideoLiveStream);
        });
        agoraLiveClient.on('updateRemoteVideoLiveStream', ({ user , remoteVideoLiveStream  })=>{
            console.log('live streaming start', user, remoteVideoLiveStream);
        });
        window.addEventListener('beforeunload', ()=>{
            agoraLiveClient.close();
        });
        // 他プレーヤーjoin自
        agoraLiveClient.onUserJoined((agoraUser)=>{
            console.log('agoraLiveClient onUserJoined', agoraUser);
            const player = this.getPlayerBy('rtcUserCode', agoraUser.uid);
            if (!player) {
                console.warn('no player agoraUser.uid', agoraUser.uid);
                return;
            }
            player.setAgoraUser(agoraUser);
        // ライブチャンネルへのJoinを通知
        //requestOnNetReceiveLiveJoin(agoraUser.uid, '');
        });
        agoraLiveClient.onUserLeft((agoraUser)=>{
            console.log('agoraLiveClient onUserLeft', agoraUser);
        // ライブチャンネルからのLeaveを通知
        //requestOnNetReceiveLiveLeave(agoraUser, '');
        });
        worldViewerWindow.agoraLiveClient = agoraLiveClient;
        console.log('initLiveClient done');
    }
    getLiveClient() {
        return worldViewerWindow.agoraLiveClient;
    }
    // positionChannelCode: string | null = null
    // コンストラクタ
    constructor(){
        // マイク許可状態
        _defineProperty(this, "m_MicPermissionState", EHeliMicPermissionState.Prompt);
        _defineProperty(this, "m_Mic_ready", false);
        _defineProperty(this, "m_localAgoraTrack", {
            audioTrack: null,
            videoTrack: null
        });
        // プレイヤーリスト
        // m_PlayerList: Array<CHELCarnelianAgoraPlayer | null> = [];
        _defineProperty(this, "m_PlayerMap", new Map());
        // 画面共有
        _defineProperty(this, "m_ScreenShare_CurrentVideoItemName", '');
        _defineProperty(this, "m_ScreenShare_CurrentVideoMaterialName", '');
        _defineProperty(this, "m_skyway_reseved_remoteVideoStream", null);
        _defineProperty(this, "m_skyway_reseved_videoItemName", '');
        _defineProperty(this, "m_skyway_reseved_videoMaterialName", '');
        _defineProperty(this, "m_current_position_channel", null);
        _defineProperty(this, "m_current_voice_channel", null);
        _defineProperty(this, "m_current_live_channel", null);
        // コールバック
        _defineProperty(this, "m_IHeliNetworkCallback", null);
        _defineProperty(this, "m_ApiNotification", null);
        _defineProperty(this, "m_selfPlayerInfo", null);
        _defineProperty(this, "sanitizeAvatarId", (role, avatarId)=>{
            const isProtectedAvatar = true; // protectedAvatarIdList.includes(parseInt(avatarId))
            const invalidAvatarId = isProtectedAvatar && role != 'presenter' && role != 'staff';
            return invalidAvatarId ? '0' : avatarId;
        });
        this.m_ApiNotification = new CApiNotification();
        this.m_selfPlayerInfo = null;
    }
}
