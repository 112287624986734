function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { worldViewerWindow } from '../../const';
import { getUseSkywayAudioGainToggle } from '../../logics/dataOps';
export class CHELSkywayPlayer {
    release() {}
    getPeerId() {
        return this.peerId;
    }
    setStream(stream) {
        const useSkywayAudioGainToggle = getUseSkywayAudioGainToggle();
        if (!useSkywayAudioGainToggle) {
            return;
        }
        const audioTrack = stream.getAudioTracks()[0];
        if (audioTrack === null || audioTrack === undefined) {
            return;
        }
        this.audio.srcObject = new MediaStream([
            audioTrack
        ]);
        this.audio.muted = worldViewerWindow.hel_isiPhone();
        this.audio.play();
        if (worldViewerWindow.hel_isiPhone()) {
            if (this.context !== undefined) {
                this.source = this.context.createMediaStreamSource(new MediaStream([
                    audioTrack
                ]));
                this.audioDestination = this.context.createMediaStreamDestination();
            }
            if (this.gainNode !== undefined && this.audioDestination !== undefined && this.source !== undefined) {
                this.source.connect(this.gainNode);
                this.gainNode.connect(this.audioDestination);
            }
        }
        stream.removeTrack(audioTrack);
        if (this.audioDestination !== undefined) {
            stream.addTrack(this.audioDestination.stream.getAudioTracks()[0]);
        }
    }
    setVolume(volume) {
        this.volume = volume;
        this.updateVolume();
    }
    SetVoiceAttenuate(volume) {
        this.attenuateVolume = volume;
        this.updateVolume();
    }
    SetMute(mute) {
        this.mute = mute;
        this.updateVolume();
    }
    updateVolume() {
        const useSkywayAudioGainToggle = getUseSkywayAudioGainToggle();
        if (!useSkywayAudioGainToggle) {
            return;
        }
        if (worldViewerWindow.hel_isiPhone()) {
            if (this.gainNode !== undefined) {
                this.gainNode.gain.value = this.volume * this.attenuateVolume * (this.mute ? 0.0 : 1.0);
            }
        } else {
            this.audio.volume = this.volume * this.attenuateVolume * (this.mute ? 0 : 1);
        }
    }
    // 画面共有
    SetScreenShare(use) {
        this.m_ScreenShare = use;
    }
    IsScreenShare() {
        return this.m_ScreenShare;
    }
    constructor(peerId, volume){
        _defineProperty(this, "peerId", void 0);
        _defineProperty(this, "context", void 0);
        _defineProperty(this, "gainNode", void 0);
        _defineProperty(this, "volume", void 0);
        _defineProperty(this, "attenuateVolume", void 0);
        _defineProperty(this, "mute", void 0);
        _defineProperty(this, "source", void 0);
        _defineProperty(this, "audioDestination", void 0);
        _defineProperty(this, "audio", void 0);
        _defineProperty(this, "m_ScreenShare", void 0);
        this.peerId = peerId;
        const useSkywayAudioGainToggle = getUseSkywayAudioGainToggle();
        if (useSkywayAudioGainToggle) {
            if (worldViewerWindow.hel_isiPhone()) {
                this.context = new (worldViewerWindow.AudioContext || worldViewerWindow.webkitAudioContext)();
                if (this.context !== undefined) {
                    this.gainNode = this.context.createGain();
                }
            }
        }
        this.audio = document.createElement('audio');
        this.volume = volume;
        this.attenuateVolume = 0.0;
        this.mute = false;
        this.m_ScreenShare = false;
        this.updateVolume();
    }
}
