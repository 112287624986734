/**
 * アクティビティで呼び出す可能性のあるheliport APIのラッパーを提供
 * アクティビティから直接APIを呼び出すことは後方互換性の観点から禁止されている
 */ import { worldViewerWindow } from './const';
/**
 * intermediateEcApi
 * EC連携アクティビティから参照
 */ worldViewerWindow.hel_api_ec_initialize = ()=>{
    heliport.api.intermediateEcApi.initialize();
};
worldViewerWindow.hel_api_ec_getProduct = async (id)=>{
    const res = await heliport.api.intermediateEcApi.getProduct(id);
    return res.data;
};
worldViewerWindow.hel_api_ec_getProducts = async (storeId)=>{
    const res = await heliport.api.intermediateEcApi.getProducts(storeId);
    return res.data;
};
worldViewerWindow.hel_api_ec_getProductByLabel = async (storeId, label)=>{
    const res = await heliport.api.intermediateEcApi.getProductByLabel(storeId, label);
    return res.data;
};
worldViewerWindow.hel_api_ec_getProductVariantByLabel = async (storeId, label)=>{
    const res = await heliport.api.intermediateEcApi.getProductVariantByLabel(storeId, label);
    return res.data;
};
/**
 * license
 * EC連携アクティビティから参照
 */ worldViewerWindow.hel_api_license_getEcs = async (worldId)=>{
    const res = await heliport.api.license.getEcs(worldId);
    return JSON.stringify(res.data);
};
/**
 * parads
 * ParadNPCアクティビティから参照
 */ worldViewerWindow.hel_api_parads_fetchParadListForce = async (params)=>{
    const res = await heliport.api.parads.fetchParadListForce(params);
    return JSON.stringify(res.data);
};
/**
 * analytics
 * ParadNPCアクティビティから参照
 */ worldViewerWindow.hel_api_analytics_setAnalyticsParad = (event, params)=>{
    heliport.api.analytics.setAnalyticsParad(event, params);
};
/**
 * spaceChat
 * 空間チャットアクティビティから参照
 */ worldViewerWindow.hel_api_spaceChat_watchSpaceChatNotification = (spatiumCode, worldId)=>{
    heliport.api.spaceChat.watchSpaceChatNotification(spatiumCode, worldId);
};
worldViewerWindow.hel_api_spaceChat_unWatchSpaceChatNotification = ()=>{
    heliport.api.spaceChat.unWatchSpaceChatNotification();
};
worldViewerWindow.hel_api_spaceChat_checkIsOutOfDate = (param)=>{
    const res = heliport.api.spaceChat.checkIsOutOfDate(param);
    return res.data;
};
worldViewerWindow.hel_api_spaceChat_getNextDateDiff = (year, month, day, hour, minute, second)=>{
    const res = heliport.api.spaceChat.getNextDateDiff(year, month, day, hour, minute, second);
    return res.data;
};
worldViewerWindow.hel_api_spaceChat_getAddedChat = ()=>{
    const res = heliport.api.spaceChat.getAddedChat();
    return res.data;
};
