import { ETextParamType_CancelToCopyVideoFrameToSystem, ETextParamType_CopyVideoFrameToSystem, ETextParamType_VideoEnded, ETextParamType_SetGenericWindowState, HEL_VIDEO_PLAYER_DEFAULT, HEL_VIDEO_PLAYER_IVS, HEL_VIDEO_PLAYER_HLS, worldViewerWindow } from './const';
import { debugLog } from './debugLog';
import { getSceneJsonPath, getVideoAudioContext, getVideoEndedToggle, getVideoGainNode, getVideoHlsConfig, getVideoSource, setSceneJson, setVideoAudioContext, setVideoCanPlayToggle, setVideoEndedToggle, setVideoGainNode, setVideoHlsConfig, setVideoInfo, setVideoSource, setVideoSourceUrl } from './logics/dataOps';
import { getVideo, setVideo } from './logics/domOps';
/**
 * @remarks
 * このファイル内でのみ呼出し
 */ const helcore_video_node_gain = ()=>{
    if (!worldViewerWindow.hel_isiPhone() && !worldViewerWindow.hel_isMac()) {
        // iOS/Mac以外ではVideo.volumeが使えるのでgainNodeは生成しない
        return;
    }
    if (getVideoAudioContext() === undefined) {
        setVideoAudioContext(new (worldViewerWindow.AudioContext || worldViewerWindow.webkitAudioContext)());
    }
    let videoSource = getVideoSource();
    const audioContext = getVideoAudioContext();
    const video = getVideo();
    if (videoSource === undefined && audioContext !== undefined && video !== undefined) {
        setVideoSource(audioContext.createMediaElementSource(video));
        videoSource = getVideoSource();
    }
    const videoGainNode = getVideoGainNode();
    if (videoGainNode === undefined && videoSource !== undefined && audioContext !== undefined) {
        const created = audioContext.createGain();
        setVideoGainNode(created);
        videoSource.connect(created);
        created.connect(audioContext.destination);
    }
};
/**
 * @remarks
 * C++から呼出し
 * @param uint8Array
 * @param x
 * @param y
 * @param width
 * @param height
 */ worldViewerWindow.g_on_video_frame_capture = (uint8Array, x, y, width, height)=>{
    // default implement
    debugLog('worldViewerWindow.g_on_video_frame_capture:', uint8Array, x, y, width, height);
};
// Video
/**
 * @remarks
 * C++から呼出し
 * @param loop
 */ worldViewerWindow.helcpp_video_Prepare = (loop)=>{
    const video = getVideo();
    if (video !== undefined) {
        video.loop = loop;
        return;
    }
    // document.pictureInPictureEnabled = false;
    // worldViewerWindow.g_Video = document.createElement('video');
    const updatedVideo = document.getElementById('video0');
    setVideo(updatedVideo);
    // worldViewerWindow.g_Video.autoplay = false;
    updatedVideo.loop = loop;
    // worldViewerWindow.g_Video.type = 'video/mp4';
    // worldViewerWindow.g_Video.autoPictureInPicture = false;
    // worldViewerWindow.g_Video.disablePictureInPicture = true;
    // worldViewerWindow.g_Video.controlslist = "nodownload";
    // worldViewerWindow.g_Video.playsinline = true;
    setVideoEndedToggle(false);
    updatedVideo.onseeking = ()=>setVideoCanPlayToggle(false);
    updatedVideo.onseeked = ()=>setVideoCanPlayToggle(true);
    updatedVideo.oncanplay = ()=>setVideoCanPlayToggle(false);
    updatedVideo.oncanplaythrough = ()=>{
        setVideoCanPlayToggle(true);
    };
    updatedVideo.onerror = function() {
        this.onerror = null;
    };
    updatedVideo.onended = (event)=>{
        //    debugLog(`g_Video onEnded ${JSON.stringify(event)}`);
        setVideoEndedToggle(true);
        worldViewerWindow.hel_setText(ETextParamType_VideoEnded, '');
    };
    updatedVideo.addEventListener('loadedmetadata', ()=>{
        if (typeof worldViewerWindow.hel_video_on_loadedmetadata === 'function') {
            worldViewerWindow.hel_video_on_loadedmetadata();
        }
    });
};
/**
 * @remarks
 */ export const helcore_video_play = (itemName, materialName, fileName, loop, streaming, seekbar)=>{
    worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_PlayVideo, itemName + ',' + materialName + ',' + fileName + ',' + (loop ? 'true' : 'false') + ',' + (streaming ? 'true' : 'false') + ',' + (seekbar ? 'true' : 'false'));
};
/**
 * @remarks
 * 公開関数
 * @param itemName
 * @param materialName
 * @param fileName
 * @param loop
 * @param streaming
 */ worldViewerWindow.hel_video_play = (itemName, materialName, fileName, loop, streaming)=>{
    helcore_video_play(itemName, materialName, fileName, loop, streaming, true);
};
/**
 * @remarks
 * C++から呼出し
 * @param videoSrc
 * @param videoStreaming
 */ worldViewerWindow.helcpp_video_play = (videoSrc, videoStreaming)=>{
    setVideoSourceUrl(videoSrc);
    const video = getVideo();
    if (video === undefined) {
        return;
    }
    if (videoStreaming !== 0) {
        if (typeof IVSPlayer !== 'undefined' && IVSPlayer.isPlayerSupported) {
            worldViewerWindow.g_VideoPlayerType = HEL_VIDEO_PLAYER_IVS;
            helcore_video_node_gain();
            const player = IVSPlayer.create();
            player.attachHTMLVideoElement(video);
            player.load(videoSrc);
            player.play();
        } else if (!worldViewerWindow.hel_isiPhone() && typeof Hls !== 'undefined' && Hls.isSupported()) {
            worldViewerWindow.g_VideoPlayerType = HEL_VIDEO_PLAYER_HLS;
            helcore_video_node_gain();
            // Create Hls
            worldViewerWindow.g_VideoHls = null;
            const hlsConfig = getVideoHlsConfig();
            if (hlsConfig !== undefined) {
                worldViewerWindow.g_VideoHls = new Hls(hlsConfig);
            } else {
                worldViewerWindow.g_VideoHls = new Hls();
            }
            //
            worldViewerWindow.g_VideoHls.loadSource(videoSrc);
            worldViewerWindow.g_VideoHls.attachMedia(video);
            video.play();
            worldViewerWindow.g_VideoHls.startLoad();
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            worldViewerWindow.g_VideoPlayerType = HEL_VIDEO_PLAYER_DEFAULT;
            helcore_video_node_gain();
            video.srcObject = null;
            video.src = videoSrc;
            video.play();
        } else {
            debugLog('video streaming not supported');
        }
    } else {
        worldViewerWindow.g_VideoPlayerType = HEL_VIDEO_PLAYER_DEFAULT;
        helcore_video_node_gain();
        video.srcObject = null;
        video.src = videoSrc;
        video.play();
    }
};
/**
 * @remarks
 * 公開関数
 */ worldViewerWindow.hel_video_stop = ()=>{
    worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_StopVideo, '');
    worldViewerWindow.g_VideoPlayerType = null;
};
/**
 * @remarks
 * C++から呼出し
 */ worldViewerWindow.helcpp_video_stop = ()=>{
    const video = getVideo();
    if (video === undefined) {
        return;
    }
    video.pause();
    if (worldViewerWindow.g_VideoPlayerType === HEL_VIDEO_PLAYER_HLS) {
        worldViewerWindow.g_VideoHls.stopLoad();
    }
    // setVideo(null);
    worldViewerWindow.g_VideoPlayerType = null;
};
/**
 * @remarks
 * C++から呼出し
 * @param pause_enable
 */ worldViewerWindow.helcpp_video_playpause_toggle = async (pause_enable)=>{
    const video = getVideo();
    if (video === undefined) {
        return;
    }
    if (pause_enable) {
        video.pause();
        if (worldViewerWindow.g_VideoPlayerType === HEL_VIDEO_PLAYER_HLS) {
            worldViewerWindow.g_VideoHls.stopLoad();
        }
    } else {
        await video.play();
        if (worldViewerWindow.g_VideoPlayerType === HEL_VIDEO_PLAYER_HLS) {
            worldViewerWindow.g_VideoHls.startLoad();
        }
    }
};
/**
 * @remarks
 * C++とhel_video_set_movieviewer_uiから呼出し
 */ worldViewerWindow.hel_video_is_paused = ()=>{
    const video = getVideo();
    if (video === undefined) {
        return true;
    }
    return video.paused;
};
/**
 * @remarks
 * C++から呼出し
 * @param seconds
 */ worldViewerWindow.hel_video_add_forward = (seconds)=>{
    const video = getVideo();
    if (video === undefined) {
        return;
    }
    video.currentTime += seconds;
};
/**
 * @remarks
 * 公開関数
 */ worldViewerWindow.hel_video_isplay = ()=>{
    return worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_IsPlayVideo, '');
};
/**
 * @remarks
 * C++から呼出し
 */ worldViewerWindow.helcpp_video_isplay = ()=>{
    const video = getVideo();
    if (video === undefined) {
        return false;
    }
    return !(video.loop && getVideoEndedToggle());
};
/**
 * @remarks
 * C++から呼出し
 * @param seconds
 */ worldViewerWindow.hel_video_add_rewind = (seconds)=>{
    const video = getVideo();
    if (video === undefined) {
        return;
    }
    video.currentTime -= seconds;
};
/**
 * @remarks
 * C++から呼出し
 */ worldViewerWindow.hel_video_get_duration = ()=>{
    const video = getVideo();
    if (video === undefined) {
        return 0;
    }
    return video.duration;
};
/**
 * @remarks
 * C++から呼出し
 * @param sliderRate
 */ worldViewerWindow.hel_video_set_currenttime = (sliderRate)=>{
    const video = getVideo();
    if (video === undefined) {
        return;
    }
    if (video.duration > 0) {
        video.currentTime = sliderRate * video.duration;
    }
};
/**
 * @remarks
 * C++から呼出し
 */ worldViewerWindow.hel_video_get_currenttime = ()=>{
    const video = getVideo();
    if (video === undefined) {
        return 0;
    }
    return video.currentTime;
};
/**
 * @remarks
 * heliodor_front.js等から呼ばれるケースが多い
 */ worldViewerWindow.hel_video_SetHlsConfig = (config)=>setVideoHlsConfig(config);
/**
 * 公開関数
 * @param seconds
 */ worldViewerWindow.hel_video_seek = (seconds)=>{
    const video = getVideo();
    if (video === undefined) {
        return;
    }
    video.currentTime = seconds;
};
/**
 * @remarks
 * heliodor_xrw_video等から呼び出しあり
 * @param mute
 */ worldViewerWindow.hel_video_SetMute = (mute)=>{
    const video = getVideo();
    if (video === undefined) {
        return;
    }
    video.muted = mute;
};
/**
 * 未使用
 */ worldViewerWindow.hel_video_get_slider_rate = ()=>{
    const video = getVideo();
    if (video === undefined) {
        return 0.0;
    }
    let sliderRate = 0.0;
    if (video.duration > 0.0) {
        sliderRate = video.currentTime / video.duration;
    }
    return sliderRate;
};
/**
 * 未使用
 * @param x
 * @param y
 * @param width
 * @param height
 */ worldViewerWindow.hel_video_copy_video_frame_to_system = (x, y, width, height)=>{
    const w = width != null ? width : 0;
    const h = height != null ? height : 0;
    const findVideoJson = (json)=>{
        for (const item of json.items){
            if (item.clickablenodes != null) {
                for (const node of item.clickablenodes){
                    if (node.eventmap != null) {
                        return node;
                    }
                }
            }
        }
        return undefined;
    };
    const sceneJsonPath = getSceneJsonPath();
    if (sceneJsonPath === undefined) {
        console.error('sceneJsonPath not found');
        return;
    }
    fetch(sceneJsonPath).then((response)=>{
        response.json().then((json)=>{
            setSceneJson(json);
            const node = findVideoJson(json);
            if (node != null) {
                setVideoInfo(node);
                worldViewerWindow.hel_setText(ETextParamType_CopyVideoFrameToSystem, `${x},${y},${w},${h}`);
            } else {
                console.error('The video info not found in the scene json file.');
            }
        });
    });
};
/**
 * 未使用
 */ worldViewerWindow.hel_video_cancel_to_copy_video_frame_to_system = ()=>{
    const video = getVideo();
    if (video === undefined) {
        return;
    }
    worldViewerWindow.hel_setText(ETextParamType_CancelToCopyVideoFrameToSystem, '');
};
/**
 * JSから参照有り
 */ worldViewerWindow.hel_video_open_genericwindow = ()=>{
    worldViewerWindow.hel_setText(ETextParamType_SetGenericWindowState, 'movieviewer' + ',' + 'open' + ',' + '');
};
/**
 * JSから参照有り
 */ worldViewerWindow.hel_video_close_genericwindow = ()=>{
    worldViewerWindow.hel_setText(ETextParamType_SetGenericWindowState, 'movieviewer' + ',' + 'close' + ',' + '');
};
