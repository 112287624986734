import { SKYWAY_API_HOST, worldViewerWindow } from './const';
import { debugLog } from './debugLog';
import { helcore_video_play } from './heliodor_core_video_callee';
import { getSkyRoomId, getSkywayName, getWorldId, setSkyRoomId, setSkywayAvatarNumber, setSkywayAvatarNumberForSend, setUseSkywayAudioGainToggle, setSkywayName } from './logics/dataOps';
import { getVideo, getSkywayLocalTextDOMElement, getMainCanvasDOMElement } from './logics/domOps';
import { requestSendingReceivedPacketsFromSkyway, requestOnNetReceivePosJoin, requestOnNetReceivePosLeave } from './logics/moduleCaller';
import { GetHeliNetworkMediator } from './networklib/CHeliNetworkMediator';
// 画面共有
export let g_useVideoStreamFlag = true;
// ビデオストリーム保持用Map
export const videoStreams = new Map();
worldViewerWindow.hel_set_video_stream_use = (flag)=>{
    g_useVideoStreamFlag = flag;
    return g_useVideoStreamFlag;
};
worldViewerWindow.hel_get_video_stream_use = ()=>{
    return g_useVideoStreamFlag;
};
/**
 * heliodor_skyway_xrw, heliodor_skyway_rcpから参照があるためglobal関数
 * @param peerId
 */ worldViewerWindow.hel_skyway_release_player = (peerId)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.releasePlayer(peerId);
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrw, heliodor_xrw_mainから参照があるためglobal関数
 * @param peerId
 */ worldViewerWindow.hel_skyway_get_player = (peerId)=>{
    var _GetHeliNetworkMediator;
    return (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getPlayer(peerId);
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrwから参照があるためglobal関数
 * @param stream
 */ worldViewerWindow.hel_skyway_stream = (stream)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.setPlayerStream(stream);
};
/**
 * JS, HeliScriptから参照あり
 */ worldViewerWindow.hel_skyway_get_peerID = ()=>{
    var _GetHeliNetworkMediator;
    return (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getPeerId();
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrwから参照があるためglobal関数
 * @param volume
 */ worldViewerWindow.hel_skyway_SetMixerVolume = (volume)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.setAllPlayerVoiceVolume(volume);
};
worldViewerWindow.hel_skyway_GetPlayerList = ()=>{
    var _GetHeliNetworkMediator;
    return (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getPlayerList();
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrwから参照があるためglobal関数
 * @param roomid
 */ worldViewerWindow.hel_skyway_set_roomid = (roomid)=>{
    setSkyRoomId(roomid);
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrwから参照があるためglobal関数
 */ worldViewerWindow.hel_skyway_get_roomid = ()=>{
    return getSkyRoomId();
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrwから参照があるためglobal関数
 */ worldViewerWindow.hel_skyway_set_avatar_from_cookie = ()=>{
    const avatar = worldViewerWindow.hel_get_cookie('Avatar');
    if (avatar !== undefined && avatar !== -1) {
        setSkywayAvatarNumber(avatar);
        setSkywayAvatarNumberForSend(avatar);
    }
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrwから参照があるためglobal関数
 * @param cmd
 * @param id
 * @param param
 */ worldViewerWindow.hel_skyway_receive_data = (cmd, id, param)=>{
    const data = cmd + ':' + id + ':' + param;
    // RTCコマンド処理
    handleRtcCommandMessage(cmd, id, param);
    // debugLog("receive_data / " + data);
    requestSendingReceivedPacketsFromSkyway(data);
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrwから参照があるためglobal関数
 * @param id
 * @param text
 */ worldViewerWindow.hel_skyway_add_text_chat = (id, text)=>{
    worldViewerWindow.hel_skyway_receive_data('data', id, 'msg:' + text);
    // チャットログスクロール対応
    if (typeof worldViewerWindow.hel_if_displayChatMessage === 'function') {
        worldViewerWindow.hel_if_displayChatMessage(id, text);
    }
    worldViewerWindow.hel_chatbadge_ui_update(); // チャットログバッジのUI更新
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrwから参照があるためglobal関数
 * @param id
 * @param text
 */ worldViewerWindow.hel_chatbadge_ui_update = ()=>{
    // チャットログのバッジ判定＋表示
    if (typeof worldViewerWindow.hel_if_displayChatBadge === 'function') {
        worldViewerWindow.hel_if_displayChatBadge();
    } else {
        // gudのチャットログアイコンのバッジの表示設定
        worldViewerWindow.hel_canvas_ResetToggleDefault('IsVisible_hud_textchatbadge'); // 初期値はfalse（＝非表示）
        if (worldViewerWindow.hel_canvas_GetLayerShow('ChatLogView') || worldViewerWindow.hel_canvas_GetLayerShow('config_base') || worldViewerWindow.hel_canvas_GetLayerShow('photo_layer')) {
        // チャットログ表示中は何もしない
        // フォトモード表示中は何もしない
        } else {
            // チャットログ横のバッジを表示させる
            worldViewerWindow.hel_canvas_ToggleChange('IsVisible_hud_textchatbadge'); // trueに変更（＝表示）
            worldViewerWindow.hel_canvas_SetGUIShow('HUD', 'hud_textchatbadge', true);
        }
    }
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrw,C++から参照があるためglobal関数
 * @remarks
 * TODO
 * agora/skyway/photon/chalcedonyで今後realtimeEngineSDKに分岐
 * @param peerId
 * @param mute
 * @virtual
 */ worldViewerWindow.hel_skyway_SetVoiceMute = (peerId, mute)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.setPlayerVoiceMute(peerId, mute);
};
/**
 * heliodor.js, heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * @remarks
 * TODO
 * agora/skyway/photon/chalcedonyで今後realtimeEngineSDKに分岐
 * @param peerId
 * @param volume
 * @virtual
 */ worldViewerWindow.hel_skyway_SetVoiceAttenuate = (playerId, volume)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.setPlayerVoiceAttenuate(playerId, volume);
};
/**
 * heliodor.js, heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * @remarks
 * TODO
 * agora/skyway/photon/chalcedonyで今後realtimeEngineSDKに分岐
 * @virtual
 */ worldViewerWindow.hel_skyway_send_heartbeat = ()=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.sendHeartBeat();
};
/**
 * heliodor.js, heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * @remarks
 *
 * TODO
 * agora/skyway/photon/chalcedonyで今後realtimeEngineSDKに分岐
 *
 * @param param
 * @virtual
 */ worldViewerWindow.hel_skyway_send_data = (param)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.sendData(param);
};
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrw, heliodor_xrw_mainから参照があるためglobal関数
 * TODO
 * agora/skyway/photon/chalcedonyで今後realtimeEngineSDKに分岐
 * @virtual
 */ worldViewerWindow.hel_skyway_join = ()=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.joinVoiceChannel(getSkyRoomId());
};
/**
 * heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * @remarks
 * TODO
 * agora/skyway/photon/chalcedonyで今後realtimeEngineSDKに分岐
 * @virtual
 */ worldViewerWindow.hel_skyway_send_myinfo = ()=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.sendMyPlayerInfoToAllPlayer();
};
/**
 * @remarks
 * RTC層からの連携ハンドラ
 */ const handleRtcCommandMessage = (cmd, id, param)=>{
    if (cmd === 'data' && param.startsWith('join:')) {
        noticeHeliScriptPlayerJoin(id, param);
    }
    if (cmd === 'leave') {
        noticeHeliScriptPlayerLeave(id, param);
    }
};
// 暫定実装 HeliScriptにjoin通知
const noticeHeliScriptPlayerJoin = (playerId, data)=>{
    // 座標チャンネルへのJoinを通知
    requestOnNetReceivePosJoin(playerId, data);
};
// 暫定実装 HeliScriptにleave通知
const noticeHeliScriptPlayerLeave = (playerId, data)=>{
    // 座標チャンネルからのLeaveを通知
    requestOnNetReceivePosLeave(playerId, data);
};
//
const helcore_skyway_connect = async (configKey)=>{
    var _GetHeliNetworkMediator;
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.connect(configKey));
};
/*
ネットワークライブラリを明示的に作成する
type : "skyway", "carnelian_agora"
*/ worldViewerWindow.hel_net_create = (type)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.createHeliNetwork(type);
};
/**
 * @remarks
 * Peerの初期化
 * @remarks
 * Peerの初期化を分離
 * @param configKey
 * @returns
 */ worldViewerWindow.initializePeer = async (configKey)=>{
    await helcore_skyway_connect(configKey);
};
/**
 * Cpp, HeliScriptから参照あり
 * チャンネルセッションAPIの入室ダイアログ押下時のコネクト関数を登録する
 * 登録する関数処理フローの流れでhel_net_connectを必ず呼ぶ必要がある
 * 登録していない場合は従来のCppの入室ダイアログ関数が呼ばれる
 */ worldViewerWindow.hel_net_add_EnterDialog_func = (callFunc)=>{
    if (worldViewerWindow.g_EnterDialogNetConnectCallFunc !== undefined) {
        worldViewerWindow.g_EnterDialogNetConnectCallFunc = callFunc;
    }
};
worldViewerWindow.hel_net_debug = ()=>{
    var _GetHeliNetworkMediator;
    return (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.doDebug();
};
/**
 * @remarks
 * 公開関数
 * hel_skyway_mainをラップしているのみ。front.js側への公開関数はhel_netの名称で統一するため
 * @param configKey
 * @virtual
 */ worldViewerWindow.hel_net_connect = async (configKey)=>{
    await worldViewerWindow.hel_skyway_main(configKey);
};
/**
 * @remarks
 * 公開関数
 * hel_skyway_mainと同様に接続を実行する機能だが、先にRoomIDを動的に取得する
 * @param configKey
 * @virtual
 */ worldViewerWindow.hel_net_connect_random_room = async (configKey)=>{
    // 空いているルームIDを取得
    worldViewerWindow.getRandomRoom(getWorldId(), {
        done: (response)=>{
            const room_id = response.data.room_id;
            debugLog(`[ getRandomRoom ] room_id = ${room_id}`);
            setSkyRoomId(room_id);
            worldViewerWindow.hel_skyway_main(configKey);
        },
        error: (error)=>debugLog(`[ getRandomRoom ] error = ${error}`)
    });
};
/**
 * 公開関数
 * ネットワークから切断する
 */ worldViewerWindow.hel_net_disconnect = ()=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.disconnect();
};
/**
 * 公開関数
 * ネットワークの同期開始
 */ worldViewerWindow.hel_net_start_sync = async ()=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.startSync();
};
/**
 * 公開関数
 * ネットワークの初期化
 */ worldViewerWindow.hel_net_initialize = async ()=>{
    await helcore_skyway_connect();
};
/**
 * 公開関数
 * ルームへの接続
 */ worldViewerWindow.hel_net_connect_room = (roomid, authorityJwt = null, ticketJwt = null)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.connectRoomAfterPeerOpen(roomid, authorityJwt, ticketJwt);
};
/**
 * 公開関数
 * ルームからの切断
 */ worldViewerWindow.hel_net_disconnect_room = ()=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.disconnectRoom();
};
/**
 * 公開関数
 * トークルームへの接続
 */ worldViewerWindow.hel_net_connect_talk = (talkid)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.connectTalk(talkid);
};
/**
 * 公開関数
 * トークルームからの切断
 */ worldViewerWindow.hel_net_disconnect_talk = ()=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.disconnectTalk();
};
/**
 * 公開関数
 * RoomURLの設定
 * コンフィグ画面に表示されるものです
 */ worldViewerWindow.hel_net_set_room_url = (url)=>{
    worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_SetRoomURL, url);
};
/**
 * 公開関数
 * 検索バーのURLとRoomURLの置き換え
 * HeliScript(ChS-APIで使用)
 */ worldViewerWindow.hel_net_replace_room_url_with_roomid = (roomid)=>{
    // 現在のURLのroomidパラメータを更新する
    const url = new URL(location.href);
    const search_params = url.searchParams;
    search_params.set('roomid', roomid);
    url.search = search_params.toString();
    // 新しいURLを発行して検索バーを置き換える
    const new_url = url.toString();
    window.history.pushState('', '', new_url);
    // エンジンのRoomURLとRoomIDを置き換え
    worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_RoomID, roomid);
    worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_LocationHref, new_url);
};
/*
C++から参照している
*/ worldViewerWindow.hel_net_get_mic_permission_state = ()=>{
    var _GetHeliNetworkMediator;
    return (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getMicPermissionState();
};
worldViewerWindow.hel_net_check_mic_permission_state = async ()=>{
    var _GetHeliNetworkMediator;
    await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.checkMicPermissionState());
};
worldViewerWindow.hel_net_init_mic_input = (deviceId)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.initLocalMicInput(deviceId);
};
worldViewerWindow.hel_net_set_mic_enabled = (enabled)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.setMicEnabled(enabled);
};
worldViewerWindow.hel_net_get_mic_device_list = async ()=>{
    var _GetHeliNetworkMediator;
    await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getMicDevices());
};
worldViewerWindow.hel_net_get_current_pos_channel = ()=>{
    var _GetHeliNetworkMediator;
    return (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getCurrentPositionChannel();
};
worldViewerWindow.hel_net_get_position_channel = async (channelid)=>{
    var _GetHeliNetworkMediator;
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getPosChannel(channelid));
};
worldViewerWindow.hel_net_get_position_channel_list = async (spatiumcode, worldCode, // params: {key: string; value: string}[]
offset, limit, excludeEmptyChannelFlg)=>{
    var _GetHeliNetworkMediator;
    // JsValを引数にHeliScriptからJS関数を呼ぶとMax Stack Size Errorになってしまうので一時的な対処として通常の型を取り、JSでparamsの連想配列を作り直している
    var params = [
        {
            "key": "offset",
            "value": offset
        },
        {
            "key": "limit",
            "value": limit != -1 ? limit : null
        },
        {
            "key": "excludeEmptyChannelFlg",
            "value": excludeEmptyChannelFlg
        }
    ];
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getPosChannelList(spatiumcode, worldCode, params));
};
worldViewerWindow.hel_net_join_pos_channel = async (channelid, authorityJwt, ticketJwt)=>{
    var _GetHeliNetworkMediator;
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.joinPosChannel(channelid, authorityJwt, ticketJwt));
};
worldViewerWindow.hel_net_leave_pos_channel = async ()=>{
    var _GetHeliNetworkMediator;
    await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.leavePosChannel());
};
worldViewerWindow.hel_net_create_pos_channel = async (spatiumCode, worldCode, // params: Array<{key: string; value: string}>
channelType, name, description)=>{
    var _GetHeliNetworkMediator;
    // JsValを引数にHeliScriptからJS関数を呼ぶとMax Stack Size Errorになってしまうので一時的な対処として通常の型を取り、JSでparamsの連想配列を作り直している
    var params = [
        {
            "key": "channelType",
            "value": channelType
        },
        {
            "key": "name",
            "value": name
        },
        {
            "key": "description",
            "value": description
        }
    ];
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.createPosChannel(spatiumCode, worldCode, params));
};
worldViewerWindow.hel_net_update_pos_channel = async (// params: Array<{key: string; value: string}>
channelType, name, description)=>{
    var _GetHeliNetworkMediator;
    // JsValを引数にHeliScriptからJS関数を呼ぶとMax Stack Size Errorになってしまうので一時的な対処として通常の型を取り、JSでparamsの連想配列を作り直している
    var params = [
        {
            "key": "channelType",
            "value": channelType
        },
        {
            "key": "name",
            "value": name
        },
        {
            "key": "description",
            "value": description
        }
    ];
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.updatePosChannel(params));
};
worldViewerWindow.hel_net_get_current_voice_channel = ()=>{
    var _GetHeliNetworkMediator;
    return (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getCurrentVoiceChannel();
};
worldViewerWindow.hel_net_get_voice_channel = async (channelid)=>{
    var _GetHeliNetworkMediator;
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getVoiceChannel(channelid));
};
worldViewerWindow.hel_net_get_voice_channel_list = async (poschannelid, // params: {key: string; value: string}[]
offset, limit, excludeEmptyChannelFlg)=>{
    var _GetHeliNetworkMediator;
    // JsValを引数にHeliScriptからJS関数を呼ぶとMax Stack Size Errorになってしまうので一時的な対処として通常の型を取り、JSでparamsの連想配列を作り直している
    var params = [
        {
            "key": "offset",
            "value": offset
        },
        {
            "key": "limit",
            "value": limit != -1 ? limit : null
        },
        {
            "key": "excludeEmptyChannelFlg",
            "value": excludeEmptyChannelFlg
        }
    ];
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getVoiceChannelList(poschannelid, params));
};
worldViewerWindow.hel_net_join_voice_channel = async (voiceChannelId)=>{
    var _GetHeliNetworkMediator;
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.joinVoiceChannel(voiceChannelId));
};
worldViewerWindow.hel_net_leave_voice_channel = async ()=>{
    var _GetHeliNetworkMediator;
    await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.leaveVoiceChannel());
};
worldViewerWindow.hel_net_create_voice_channel = async (posionChannelCode, // params: Array<{key: string; value: string}>
channelType, name, description)=>{
    var _GetHeliNetworkMediator;
    // JsValを引数にHeliScriptからJS関数を呼ぶとMax Stack Size Errorになってしまうので一時的な対処として通常の型を取り、JSでparamsの連想配列を作り直している
    var params = [
        {
            "key": "channelType",
            "value": channelType
        },
        {
            "key": "name",
            "value": name
        },
        {
            "key": "description",
            "value": description
        }
    ];
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.createVoiceChannel(posionChannelCode, params));
};
worldViewerWindow.hel_net_update_voice_channel = async (// params: Array<{key: string; value: string}>
channelType, name, description)=>{
    var _GetHeliNetworkMediator;
    // JsValを引数にHeliScriptからJS関数を呼ぶとMax Stack Size Errorになってしまうので一時的な対処として通常の型を取り、JSでparamsの連想配列を作り直している
    var params = [
        {
            "key": "channelType",
            "value": channelType
        },
        {
            "key": "name",
            "value": name
        },
        {
            "key": "description",
            "value": description
        }
    ];
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.updateVoiceChannel(params));
};
worldViewerWindow.hel_net_get_pos_channel_live_channel_list = async (poschannelid)=>{
    var _GetHeliNetworkMediator;
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getPosChannelLiveChannelList(poschannelid));
};
worldViewerWindow.hel_net_get_world_live_channel_list = async (spatiumCode, worldCode)=>{
    var _GetHeliNetworkMediator;
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getWorldLiveChannelList(spatiumCode, worldCode));
};
worldViewerWindow.hel_net_get_current_live_channel = ()=>{
    var _GetHeliNetworkMediator;
    return (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getCurrentLiveChannel();
};
worldViewerWindow.hel_net_join_live_channel_host = async (liveChannelId)=>{
    var _GetHeliNetworkMediator;
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.joinLiveChannelHost(liveChannelId));
};
worldViewerWindow.hel_net_join_live_channel_audience = async (liveChannelId)=>{
    var _GetHeliNetworkMediator;
    return await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.joinLiveChannelAudience(liveChannelId));
};
worldViewerWindow.hel_net_leave_live_channel = async ()=>{
    var _GetHeliNetworkMediator;
    await ((_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.leaveLiveChannel());
};
/**
 * @remarks
 * g_MultiPlay(XRWorldIngame, JRWorld_web）経由で
 * multiPlayの場合に参照
 * TODO
 * agora/skyway/photon/chalcedonyで今後realtimeEngineSDKに分岐
 *
 * heliodor_front.jsから参照されるためglobal関数
 * @param configKey
 * @virtual
 */ worldViewerWindow.hel_skyway_main = async (configKey)=>{
    var // Peer接続完了後にルームにジョインする
    _GetHeliNetworkMediator;
    await helcore_skyway_connect(configKey);
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.connectRoomAfterPeerOpen(getSkyRoomId());
};
/**
 * heliodor.js, heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * @remarks
 * TODO
 * agora/skyway/photon/chalcedonyで今後realtimeEngineSDKに分岐
 * @param focus
 * @virtual
 */ worldViewerWindow.hel_skyway_send_chat_text = (focus = true)=>{
    var _GetHeliNetworkMediator;
    const skywayLocalText = getSkywayLocalTextDOMElement();
    if (skywayLocalText === undefined || skywayLocalText.value === '') {
        return;
    }
    const value = skywayLocalText.value;
    worldViewerWindow.hel_remove_emoji(skywayLocalText);
    // Send message to all of the peers in the room via websocket
    const replace_text = worldViewerWindow.hel_skyway_to_code_colon(value);
    worldViewerWindow.hel_skyway_send_data('msg:' + replace_text);
    // peerIdの取得
    const id = (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getPeerId();
    //
    worldViewerWindow.hel_skyway_add_text_chat(id, replace_text);
    skywayLocalText.value = '';
    const mainCanvas = getMainCanvasDOMElement();
    if (focus && mainCanvas !== undefined) {
        mainCanvas.focus();
    }
    worldViewerWindow.hel_gtag('SendTextChat', {
        param0: getWorldId()
    });
};
/**
 * @remarks
 * JRWorld_webから呼出あり。
 * heliodor_front.jsから呼び出されるためglobal関数化
 * @param method
 * @param url
 * @param option
 */ worldViewerWindow.apiAjax = (method, url, option)=>{
    const api_url = SKYWAY_API_HOST + url;
    // 明示的にnullを送るケースあり
    const payload = option.data === undefined ? null : option.data;
    const ajax = new XMLHttpRequest();
    ajax.open(method, api_url);
    ajax.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    ajax.send(JSON.stringify(payload));
    ajax.addEventListener('load', function(e) {
        try {
            if (e.target === null || e.target === undefined || !('response' in e.target)) {
                return;
            }
            const response = JSON.parse(e.target['response']);
            if (e.target['status'] == 200) {
                if (option.done !== undefined && option.done !== null) {
                    option.done(response);
                }
            } else {
                if (option.error !== undefined && option.error !== null) {
                    option.error(response);
                }
            }
        } catch (error) {
            debugLog(error);
        }
    }, false);
};
/**
 * @remarks
 * heliodor_room.jsから移植
 * TODO アプリケーション層より
 * @param world_id
 * @param option
 */ worldViewerWindow.getRandomRoom = (world_id, option)=>{
    if (!world_id) {
        return;
    }
    worldViewerWindow.apiAjax('GET', '/rooms/random/' + world_id, {
        done: option === undefined ? undefined : option.done,
        error: option === undefined ? undefined : option.error
    });
};
/**
 * @remarks
 * heliodor_room.jsから移植
 * TODO アプリケーション層より
 * @param world_id
 * @param option
 */ worldViewerWindow.createNewRoom = (world_id, option)=>{
    if (!world_id) {
        return;
    }
    worldViewerWindow.apiAjax('POST', '/rooms', {
        data: {
            world_id: world_id
        },
        done: option === undefined ? undefined : option.done,
        error: option === undefined ? undefined : option.error
    });
};
/**
 * @remarks
 * heliodor_room.jsから移植
 * TODO アプリケーション層より
 * @param room_id
 * @param player_count
 * @param option
 */ worldViewerWindow.sendHeartbeat = (room_id, player_count, option)=>{
    if (!room_id || !player_count) {
        return;
    }
    worldViewerWindow.apiAjax('POST', '/rooms/' + room_id + '/heartbeat', {
        data: {
            player_count: player_count
        },
        done: option === undefined ? undefined : option.done,
        error: option === undefined ? undefined : option.error
    });
};
// Replace ":" to "&colon\;"
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrwから参照があるためglobal関数
 * @param text
 */ worldViewerWindow.hel_skyway_to_code_colon = (text)=>{
    return text.replace(':', '&colon;');
};
// Recovery "&colon;" to ":"
/**
 * heliodor_skyway_rcp, heliodor_skyway_xrwから参照があるためglobal関数
 * @param text
 */ worldViewerWindow.hel_skyway_to_char_colon = (text)=>{
    return text.replace('&colon;', ':');
};
//
worldViewerWindow.hel_skyway_main_with_display_media = async (configKey)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.connectWithDisplayMedia(configKey);
};
/**
 * @remarks
 * 画面共有の開始2
 */ worldViewerWindow.hel_skyway_start_screen_sharing_via_room = async (videoItemName, videoMaterialName, useCamera, useAudio)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.startScreenShare(videoItemName, videoMaterialName, useCamera, useAudio);
};
worldViewerWindow.hel_skyway_stop_screen_sharing_via_room = async ()=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.stopScreenShare();
};
// 上記で予約した画面共有情報で再生開始する
worldViewerWindow.hel_skyway_play_remote_video_stream_reserved = ()=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.playScreenShareReserved();
};
// 画面共有の再生開始
worldViewerWindow.hel_skyway_play_remote_video_stream = (remoteVideoStream, videoItemName, videoMaterialName)=>{
    // 動画の開始 ※本来ならMediaStreamを直接再生したい
    worldViewerWindow.hel_video_stop();
    helcore_video_play(videoItemName, videoMaterialName, './Video/blankVideo.mp4', true, false, false);
    setTimeout(()=>{
        if (worldViewerWindow.g_Video !== null) {
            worldViewerWindow.g_Video.srcObject = remoteVideoStream;
            worldViewerWindow.g_Video.play();
        }
    }, 500);
    // HeliScriptのコールバックを呼び出す
    worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_OnPlayScreenShare, '');
};
// 画面共有の再生停止
worldViewerWindow.hel_skyway_stop_remote_video_stream = ()=>{
    worldViewerWindow.hel_video_stop();
};
//
worldViewerWindow.hel_video_is_playing_same_stream = (stream)=>{
    const video = getVideo();
    const videoId = (video === null || video === void 0 ? void 0 : video.paused) === false && video.srcObject && video.srcObject.id;
    return videoId === stream.id;
};
// 映像ストリームを返す
// allがtrueの場合、全てを返す
// all falseの場合、ダミーではない再生可能なストリームだけ返す
worldViewerWindow.hel_skyway_get_remote_video_streams = (all = false)=>{
    if (all) {
        return videoStreams;
    }
    const playableStream = new Map();
    videoStreams.forEach((stream, peerId)=>{
        const videoTrack = stream.getVideoTracks()[0];
        if (videoTrack && videoTrack.getSettings().width && videoTrack.getSettings().frameRate > 0) {
            playableStream.set(peerId, stream);
        }
    });
    return playableStream;
};
worldViewerWindow.hel_skyway_display_room_video = ()=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.playScreenShare();
};
/**
 * @remarks
 * heliodor_xrw_mainから参照あり
 * @param configKey?
 * @returns {any}
 *
 */ worldViewerWindow.hel_skyway_generate_peer = async (configKey)=>{
    return await helcore_skyway_connect(configKey);
};
/**
 * @remarks
 * heliodor_skyway_wrwから参照あり
 * @param toggle
 *
 */ worldViewerWindow.hel_skyway_set_user_skyway_audio_gain = (toggle)=>setUseSkywayAudioGainToggle(toggle);
/*
 */ worldViewerWindow.hel_skyway_set_user_name = (name)=>{
    const currentname = getSkywayName();
    if (currentname === name) return;
    worldViewerWindow.hel_set_cookie('playername', name);
    worldViewerWindow.hel_post_TextField_UserName(true);
};
/**
 * Heliportから参照があるためglobal関数
 * @remarks
 * TODO
 * Heliportから来た通知による発火を行う
 * @param scope
 * @param type
 * @param params
 * @virtual
 */ worldViewerWindow.hel_api_notification_reciever = (scope, type, params)=>{
    var _GetHeliNetworkMediator;
    (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.apiNotificationReciever(scope, type, params);
};
/**
 * HeliScriptから参照があるためglobal関数
 * @remarks
 * TODO
 * 自身のユーザ情報をJwtから取得する
 * @virtual
 */ worldViewerWindow.hel_net_get_self_player_role = ()=>{
    var _GetHeliNetworkMediator;
    return (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.getSelfPlayerRole();
};
/**
 * HeliScriptから参照があるためglobal関数
 * @remarks
 * TODO
 * Heliportへプロパティの変更を伝える
 * @param refId Firebase経由で使用する値（主にメインシーンアクティビティ名/アクティビティのアイテム名）となる想定
 * @param key プロパティのキー名
 * @param value プロパティの値
 * @virtual
 */ worldViewerWindow.hel_net_send_item_sync_property = (refId, key, value)=>{
    var _GetHeliNetworkMediator;
    return (_GetHeliNetworkMediator = GetHeliNetworkMediator()) === null || _GetHeliNetworkMediator === void 0 ? void 0 : _GetHeliNetworkMediator.apiChangeItemPropertySender(refId, key, value);
};
// 即時実行
const cookie_Skyway_MyName = worldViewerWindow.hel_get_cookie('playername');
if (cookie_Skyway_MyName !== undefined) {
    setSkywayName(cookie_Skyway_MyName);
}
worldViewerWindow.hel_skyway_set_show_chatbox(false);
worldViewerWindow.hel_skyway_set_avatar_from_cookie();
