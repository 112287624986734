import { ETextParamType_OnNetCreatedPlayerID, ETextParamType_OnNetPosConnected, ETextParamType_OnNetPosDisconnected, ETextParamType_OnNetVoiceConnected, ETextParamType_OnNetVoiceDisconnected, worldViewerWindow } from '../const';
export class CHeliNetworkCallback {
    OnChangedMicPermissionState(MicPermissionState) {
        worldViewerWindow.hel_postMessage(worldViewerWindow.ETextParamType_OnChangedMicPermissionState, MicPermissionState.toString());
    }
    OnNetCreatedPlayerID(ID) {
        worldViewerWindow.hel_setText(ETextParamType_OnNetCreatedPlayerID, ID);
    }
    OnNetPosConnected() {
        worldViewerWindow.hel_setText(ETextParamType_OnNetPosConnected, '');
    }
    OnNetVoiceConnected(VoiceID) {
        worldViewerWindow.hel_setText(ETextParamType_OnNetVoiceConnected, VoiceID);
    }
    OnNetPosDisconnected() {
        worldViewerWindow.hel_setText(ETextParamType_OnNetPosDisconnected, '');
    }
    OnNetVoiceDisconnected() {
        worldViewerWindow.hel_setText(ETextParamType_OnNetVoiceDisconnected, '');
    }
}
