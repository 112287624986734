import { worldViewerWindow } from '../const';
import { getTextureCanvasList, pushNullToTextureCanvasList } from './dataOps';
/**
 * MainCanvasDOMElement (GET)
 * @returns String型またはundefined
 */ export const getMainCanvasDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_MainCanvas === undefined || worldViewerWindow.g_MainCanvas === null) {
        return undefined;
    }
    return worldViewerWindow.g_MainCanvas;
};
/**
 * MainCanvasDOMElement(SET)
 * @remarks
 * targetMainCanvasがHTMLCanvasElementでなく入ってくる可能性も考えられるためunknown型で定義
 *
 * @param targetMainCanvas 更新予定のメインキャンバス
 */ export const setMainCanvasDOMElement = (targetMainCanvas)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || targetMainCanvas === undefined || targetMainCanvas === null) {
        return;
    }
    worldViewerWindow.g_MainCanvas = targetMainCanvas;
};
/**
 * WebGLContext (GET)
 * @returns WebGLRenderingContext|undefined
 */ export const getWebGLRenderingContext = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_WebglContext === undefined || worldViewerWindow.g_WebglContext === null) {
        return undefined;
    }
    return worldViewerWindow.g_WebglContext;
};
/**
 * WebGLContext (SET)
 * @param webglContext WebGLRenderingContext型
 */ export const setWebGLRenderingContext = (webglContext)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || webglContext === undefined || webglContext === null) {
        return;
    }
    worldViewerWindow.g_WebglContext = webglContext;
};
/**
 * MainCanvas4XrDOMElement (GET)
 * @returns HTMLCanvasElement|undefined
 */ export const getMainCanvas4XrDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_MainCanvas === undefined || worldViewerWindow.g_MainCanvas === null) {
        return undefined;
    }
    return worldViewerWindow.g_MainCanvas;
};
/**
 * MainCanvas4XrDOMElement (SET)
 * @param mainCanvas4Xr HTMLCanvasElement型
 */ // export const setMainCanvas4XrDOMElement = (mainCanvas4Xr: HTMLCanvasElement) => {
//   if (
//     worldViewerWindow === undefined ||
//     worldViewerWindow === null ||
//     mainCanvas4Xr === undefined ||
//     mainCanvas4Xr === null
//   ) {
//     return;
//   }
//   worldViewerWindow.g_MainCanvas4XR = mainCanvas4Xr;
// };
/**
 * Video (GET)
 * @returns HTMLVideoElement|undefined
 */ export const getVideo = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Video === undefined || worldViewerWindow.g_Video === null) {
        return undefined;
    }
    return worldViewerWindow.g_Video;
};
/**
 * Video(SET)
 * @param video HTMLVideoElement | Null
 */ export const setVideo = (video)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null) {
        return;
    }
    worldViewerWindow.g_Video = video;
};
/**
 * InputFileDOMElement (GET)
 * @returns HTMLElement|undefined
 */ export const getInputFileDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_InputFile === undefined || worldViewerWindow.g_InputFile === null) {
        return undefined;
    }
    return worldViewerWindow.g_InputFile;
};
/**
 * InputFileDOMElement (SET)
 * @remarks
 * 未使用
 * @param inputFile HTMLElement型
 */ export const setInputFileDOMElement = (inputFile)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || inputFile === undefined || inputFile === null) {
        return;
    }
    worldViewerWindow.g_InputFile = inputFile;
};
/**
 * ConfigUrlDOMElement (GET)
 * @returns HTMLElement|undefined
 */ export const getConfigUrlDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_configurl === undefined || worldViewerWindow.g_configurl === null) {
        return undefined;
    }
    return worldViewerWindow.g_configurl;
};
/**
 * ConfigUrlDOMElement (SET)
 * @remarks
 * 未使用
 * @param configUrl HTMLElement型
 */ export const setConfigUrlDOMElement = (configUrl)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || configUrl === undefined || configUrl === null) {
        return;
    }
    worldViewerWindow.g_configurl = configUrl;
};
/**
 * LocalTextConfigUrlDOMElement (GET)
 * @returns HTMLElement|undefined
 */ export const getLocalTextConfigUrlDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_localText_configurl === undefined || worldViewerWindow.g_localText_configurl === null) {
        return undefined;
    }
    return worldViewerWindow.g_localText_configurl;
};
/**
 * LocalTextConfigUrlDOMElement (SET)
 * @remarks
 * 未使用
 * @param localTextConfigUrl HTMLElement型
 */ export const setLocalTextConfigUrlDOMElement = (localTextConfigUrl)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || localTextConfigUrl === undefined || localTextConfigUrl === null) {
        return;
    }
    worldViewerWindow.g_localText_configurl = localTextConfigUrl;
};
/**
 * UserNameDOMElement (GET)
 * @returns HTMLElement|undefined
 */ export const getUserNameDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_username === undefined || worldViewerWindow.g_username === null) {
        return undefined;
    }
    return worldViewerWindow.g_username;
};
/**
 * UserNameDOMElement (SET)
 * @remarks
 * 未使用
 * @param userName HTMLElement型
 */ export const setUserNameDOMElement = (userName)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || userName === undefined || userName === null) {
        return;
    }
    worldViewerWindow.g_username = userName;
};
/**
 * LocalTextUserNameDOMElement (GET)
 * @returns HTMLElement|undefined
 */ export const getLocalTextUserNameDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_localText_username === undefined || worldViewerWindow.g_localText_username === null) {
        return undefined;
    }
    return worldViewerWindow.g_localText_username;
};
/**
 * LocalTextUserNameDOMElement (SET)
 * @remarks
 * 未使用
 * @param localTextUserName HTMLElement型
 */ export const setLocalTextUserNameDOMElement = (localTextUserName)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || localTextUserName === undefined || localTextUserName === null) {
        return;
    }
    worldViewerWindow.g_localText_username = localTextUserName;
};
/**
 * SkywayMsgDOMElement (GET)
 * @returns HTMLElement|undefined
 */ export const getSkywayMsgDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_skyway_msg === undefined || worldViewerWindow.g_skyway_msg === null) {
        return undefined;
    }
    return worldViewerWindow.g_skyway_msg;
};
/**
 * SkywayMsgDOMElement (SET)
 * @remarks
 * 未使用
 * @param skywayMsg HTMLElement型
 */ export const setSkywayMsgDOMElement = (skywayMsg)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || skywayMsg === undefined || skywayMsg === null) {
        return;
    }
    worldViewerWindow.g_skyway_msg = skywayMsg;
};
/**
 * SkywayLocalTextDOMElement (GET)
 * @remarks
 * 未使用
 * @returns HTMLElement|undefined
 */ export const getSkywayLocalTextDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_skyway_localText === undefined || worldViewerWindow.g_skyway_localText === null) {
        return undefined;
    }
    return worldViewerWindow.g_skyway_localText;
};
/**
 * SkywayLocalTextDOMElement (SET)
 * @remarks
 * 未使用
 * @param skywayLocalText HTMLElement型
 */ export const setSkywayLocalTextDOMElement = (skywayLocalText)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || skywayLocalText === undefined || skywayLocalText === null) {
        return;
    }
    worldViewerWindow.g_skyway_localText = skywayLocalText;
};
/**
 * StatusDOMElement (GET)
 * @returns HTMLElement|undefined
 */ export const getStatusDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Status === undefined || worldViewerWindow.g_Status === null) {
        return undefined;
    }
    return worldViewerWindow.g_Status;
};
/**
 * StatusDOMElement (SET)
 * @remarks
 * 未使用
 * @param status HTMLElement型
 */ export const setStatusDOMElement = (status)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || status === undefined || status === null) {
        return;
    }
    worldViewerWindow.g_Status = status;
};
/**
 * LogDOMElement (GET)
 * @returns HTMLElement|undefined
 */ export const getLogDOMElement = ()=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || worldViewerWindow.g_Log === undefined || worldViewerWindow.g_Log === null) {
        return undefined;
    }
    return worldViewerWindow.g_Log;
};
/**
 * LogDOMElement (SET)
 * @remarks
 * 未使用
 * @param status HTMLElement型
 */ export const setLogDOMElement = (status)=>{
    if (worldViewerWindow === undefined || worldViewerWindow === null || status === undefined || status === null) {
        return;
    }
    worldViewerWindow.g_Log = status;
};
/**
 * @remarks
 * 次のキャンバスインデックス番号を取得
 */ export const defineNextTextCanvasIndex = ()=>{
    const canvasList = getTextureCanvasList();
    for(let index = 0; index < canvasList.length; index++){
        if (canvasList[index] === null) {
            return index;
        }
    }
    pushNullToTextureCanvasList();
    return canvasList.length - 1;
};
/**
 * 未使用
 */ export const hel_InputFileClick = ()=>{
    const inputFile = getInputFileDOMElement();
    if (inputFile !== undefined) {
        inputFile.click();
    }
};
/**
 * 未使用
 */ export const hel_toggleFullScreen = async ()=>{
    const mainCanvas = getMainCanvasDOMElement();
    if (!document.fullscreenEnabled || mainCanvas === undefined) {
        return;
    }
    if (!document.fullscreenElement) {
        await mainCanvas.requestFullscreen();
    } else {
        await document.exitFullscreen();
    }
};
/**
 * 未使用
 */ export const hel_backToTitle = ()=>{
    location.reload();
};
/**
 * 未使用
 */ export const hel_openLicensePage = ()=>{
    worldViewerWindow.hel_openPage(worldViewerWindow.g_DataFolderName + '/licenselist.html');
};
