import { IOS_VER_15, IPHONE_USER_AGENT_REGS, MOBILE_USER_AGENT_REGS, worldViewerWindow } from '../const';
import { setLoggedInStatusToggle } from './dataOps';
/**
 */ export const hel_isChrome = ()=>{
    return worldViewerWindow.navigator.userAgent.toLowerCase().indexOf('chrome') !== -1;
};
export const hel_getChromeVersion = ()=>{
    if (!hel_isChrome()) return 0;
    const v = window.navigator.userAgent.match(/Chrome\/([0-9.]+)/);
    if (v === null || v === undefined) return 0;
    if (v.length > 1) {
        return parseInt(v[1], 10);
    }
    return 0;
};
/**
 */ export const hel_isAndroid = ()=>{
    return worldViewerWindow.navigator.userAgent.toLowerCase().indexOf('android') !== -1;
};
export const hel_isMobile = ()=>{
    if (hel_isiPad()) {
        return true;
    }
    return worldViewerWindow.navigator.userAgent.search(MOBILE_USER_AGENT_REGS) !== -1;
};
worldViewerWindow.hel_isiPhone = ()=>{
    if (hel_isiPad()) {
        return true;
    }
    return worldViewerWindow.navigator.userAgent.search(IPHONE_USER_AGENT_REGS) !== -1;
};
export const hel_isiPad = ()=>{
    const agent = worldViewerWindow.navigator.userAgent.toLowerCase();
    return agent.indexOf('ipad') > -1 || agent.indexOf('macintosh') > -1 && 'ontouchend' in document;
};
worldViewerWindow.hel_isMac = ()=>{
    const agent = worldViewerWindow.navigator.userAgent.toLowerCase();
    return agent.indexOf('macintosh') > -1;
};
export const hel_isSafari = ()=>{
    const v = window.navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    if (v === null || v === undefined) return false;
    return true;
};
worldViewerWindow.hel_isSafari = hel_isSafari;
export const hel_isFirefox = ()=>{
    return window.navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;
};
worldViewerWindow.hel_isFirefox = hel_isFirefox;
export const hel_getiOSVersion = ()=>{
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
        return parseInt(v[1], 10) * 100 + parseInt(v[2], 10);
    }
    return 0;
};
export const hel_getMacOSVersion = ()=>{
    if (!worldViewerWindow.hel_isMac() && !hel_isiPad()) return 0;
    const v = window.navigator.userAgent.match(/Version\/(\d+).(\d+)/);
    if (v === null || v === undefined) return 0;
    if (v.length >= 3) {
        return parseInt(v[1], 10) * 100 + parseInt(v[2], 10);
    }
    return 0;
};
/**
 * 未使用
 */ export const hel_isiOS14Under = ()=>{
    if (!worldViewerWindow.hel_isiPhone()) {
        return false;
    }
    return hel_getiOSVersion() < IOS_VER_15;
};
//
export const hel_isLangJa = ()=>{
    const browserLang = worldViewerWindow.navigator.languages && worldViewerWindow.navigator.languages[0] || worldViewerWindow.navigator.language || worldViewerWindow.navigator.userLanguage || worldViewerWindow.navigator.browserLanguage;
    return browserLang === 'ja' || browserLang === 'ja-JP';
};
/**
 * 未使用
 */ export const hel_setLogined = (logined)=>setLoggedInStatusToggle(logined);
/**
 * @remarks
 * macM1環境用RenderTypes返却関数
 */ export const defineYourRenderTypes = ()=>{
    // TODO 現在手動運用。macM1@Local環境テスト時仕様
    //      dotEnvパッケージを.envにて運用を検討
    // return ['webgl', 'experimental-webgl', 'webgl2'];
    return [
        'webgl',
        'experimental-webgl'
    ];
};
