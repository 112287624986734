import { INITIAL_MIXER_DISTANCE_SETTING, INITIAL_MIXER_FADER_SETTING, INITIAL_MIXER_MUTE_SETTING, INITIAL_MIXER_SETTING, INITIAL_MIXER_REAL_SETTING, worldViewerWindow } from './const';
import { hel_resizeWindow, hel_reflectCanvasSize } from './logics/callCGui';
import { isIntelWebGLPlatform } from './logics/dataOps';
import { setMainCanvasDOMElement } from './logics/domOps';
// config
worldViewerWindow.g_WorldID = undefined;
worldViewerWindow.g_Skyway_RoomID = '';
worldViewerWindow.g_Spot = undefined;
worldViewerWindow.g_ShowWorldSelect = undefined;
worldViewerWindow.g_Logined = false;
worldViewerWindow.g_CanvasFileName = 'Canvas/CanvasList.json';
worldViewerWindow.g_CompImageListFileName = '';
worldViewerWindow.g_CurrentUserName = 'Guest'; // For send UserName from javascript to c++.
worldViewerWindow.g_MainCanvas = undefined;
worldViewerWindow.g_CanvasIsPortrait = 0;
// render
/**
 * @remarks
 * C++からアクセスあり
 */ worldViewerWindow.g_UseWebGL10 = false;
worldViewerWindow.g_extPVRTC = null;
worldViewerWindow.g_TextureCompression_PVRTC = false;
worldViewerWindow.g_extETC2 = null;
worldViewerWindow.g_TextureCompression_ETC2 = false;
worldViewerWindow.g_extASTC = null;
worldViewerWindow.g_TextureCompression_ASTC = false;
worldViewerWindow.g_extDXT = null;
worldViewerWindow.g_TextureCompression_DXT = false;
worldViewerWindow.g_Use_EXT_shader_texture_lod = false;
worldViewerWindow.g_Use_EXT_color_buffer_half_float = false;
worldViewerWindow.g_EXT_color_buffer_float = null;
worldViewerWindow.g_EXT_color_buffer_half_float = null;
worldViewerWindow.g_Use_EXT_color_buffer = false;
worldViewerWindow.g_OES_texture_half_float = null;
worldViewerWindow.g_Use_OES_texture_half_float = false;
worldViewerWindow.g_EXT_shader_texture_lod = null;
worldViewerWindow.isIntelWebGLPlatform = isIntelWebGLPlatform;
// audio
worldViewerWindow.g_hel_MixerTabActive = 1.0;
worldViewerWindow.g_hel_MixerFade = INITIAL_MIXER_FADER_SETTING;
worldViewerWindow.g_hel_MixerDistance = INITIAL_MIXER_DISTANCE_SETTING;
worldViewerWindow.g_hel_MixerMute = INITIAL_MIXER_MUTE_SETTING;
worldViewerWindow.g_hel_MixerMasterVolume = 1.0;
worldViewerWindow.g_hel_MixerMasterMute = 0;
worldViewerWindow.g_hel_UseLegacyAudio = false;
/**
 * @remarks
 * C++からアクセスあり
 */ worldViewerWindow.g_hel_Mixer = INITIAL_MIXER_SETTING;
/**
 * @remarks
 * TS内部でのみ使用する。各ミキサーごとの最終的なボリューム値を格納する
 */ worldViewerWindow.g_hel_RealMixer = INITIAL_MIXER_REAL_SETTING;
// video
/**
 * @remarks
 * C++からアクセスあり
 */ worldViewerWindow.g_Video = null;
worldViewerWindow.g_videocanplay = false;
worldViewerWindow.g_videopaused = false;
worldViewerWindow.g_videoended = false;
worldViewerWindow.g_VideoAudioContext = null;
worldViewerWindow.g_VideoSource = null;
worldViewerWindow.g_VideoGainNode = null;
worldViewerWindow.g_VideoHls = null;
worldViewerWindow.g_VideoHlsConfig = null;
worldViewerWindow.g_VideoPlayerType = null;
// TextureCanvas, Gyro
worldViewerWindow.g_TextCanvasList = [], worldViewerWindow.g_GyroActive = false;
worldViewerWindow.g_hel_UploadFileImage = null;
worldViewerWindow.g_InputFile = document.getElementById('input_file'), worldViewerWindow.g_VCUID = '';
worldViewerWindow.g_configurl = document.getElementById('configurl'), worldViewerWindow.g_localText_configurl = document.getElementById('configurl-text'), worldViewerWindow.g_username = document.getElementById('username'), worldViewerWindow.g_localText_username = document.getElementById('username-text');
// Network(named for Skyway as Default) and Others
/**
 * @remarks
 * C++からアクセスあり
 */ worldViewerWindow.g_Skyway_Start = false;
worldViewerWindow.g_Status = null;
worldViewerWindow.g_Log = null;
worldViewerWindow.g_Skyway_MyName = 'Guest';
worldViewerWindow.g_localStream = null;
worldViewerWindow.g_SKYWAY_KEY = '37825849-7771-49bd-890e-61971fb4f431';
worldViewerWindow.g_Skyway_MyAvatar = 0;
worldViewerWindow.g_Skyway_MyAvatar_ForSend = 0;
worldViewerWindow.g_Skyway_Use_CustomAvatarIcon = false;
worldViewerWindow.g_Skyway_CustomAvatarIcon_URL = '';
worldViewerWindow.g_skyway_set_mic_state_timer = null;
worldViewerWindow.g_skyway_msg = document.getElementById('msg'), worldViewerWindow.g_skyway_localText = document.getElementById('js-local-text'), worldViewerWindow.g_SkywayPlayerList = [], worldViewerWindow.g_MainCanvas4XR = null;
worldViewerWindow.g_EnterDialogNetConnectCallFunc = null;
// XRWorldIngame
worldViewerWindow.g_xrwFriendCode = '';
worldViewerWindow.g_hel_UseSkywayAudioGain = true;
worldViewerWindow.g_DataFolderName = 'data';
// procedural call oriented bridges
worldViewerWindow.g_ActionProcedureList = [];
// ProcedureOnclickedAvatarCallFunc
worldViewerWindow.g_OnclickedAvatarProcedureList = [];
// ProcedureOnloadedCallFunc
worldViewerWindow.g_OnloadedProcedureList = [];
// ProcedureOnresizeCallFunc
worldViewerWindow.g_OnresizeProcedureList = [];
// ProcedureSkywayCommandCallFunc
worldViewerWindow.g_SkywayCommandProcedureList = [];
// ProcedureSkywayMyinfoCallFunc
worldViewerWindow.g_SkywayMyinfoProcedureList = [];
// メインキャンバスの設定をこちらで行う
worldViewerWindow.g_ReflectCanvasSizeMode = true;
// JSBinding(Heliport) - Function Table
worldViewerWindow.heliport_funcTable = [];
// JS側からC++に返す戻り値を、一時的に記録しておく。
worldViewerWindow.heliport_retValue = undefined;
const mainCanvas = document.getElementById('canvas');
setMainCanvasDOMElement(mainCanvas);
hel_reflectCanvasSize();
if (mainCanvas !== undefined) {
    mainCanvas.onclick = function() {
        worldViewerWindow.g_MainCanvas.focus();
    };
    mainCanvas.oncontextmenu = (e)=>{
        e.preventDefault();
    };
}
worldViewerWindow.onresize = hel_resizeWindow;
export const setState = ()=>{
    if (worldViewerWindow.Module !== undefined) {
        worldViewerWindow.Module.setstate = (text)=>{
            const status = document.getElementById('status');
            if (!status) return;
            status.innerHTML = text;
        };
    }
};
setState();
// Video Recorder
const g_VideoRecorder = null;
const g_RecordedVideoBlobObj = null;
const g_RecordedVideoBlobURL = null;
const g_VideoRecorderExtension = null;
const g_VideoRecordCanvas = null;
const g_VideoRecordContext = null;
