function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { CreateHeliNetwork, GetIHeliNetwork } from './heliodor_networklib';
import { worldViewerWindow } from '../const';
class CPosChannelFormatter {
    constructor(positionChannel){
        _defineProperty(this, "channel_id", null);
        _defineProperty(this, "spatium_code", null);
        _defineProperty(this, "world_code", null);
        _defineProperty(this, "channel_type", null);
        _defineProperty(this, "create_user_code", null);
        _defineProperty(this, "name", null);
        _defineProperty(this, "description", null);
        _defineProperty(this, "max_player_count", null);
        _defineProperty(this, "max_voice_player_count", null);
        _defineProperty(this, "allow_multi_voice_channel", null);
        _defineProperty(this, "users", null);
        _defineProperty(this, "required_tickets", null);
        if (positionChannel) {
            this.channel_id = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('channel_id');
            this.spatium_code = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('spatium_code');
            this.world_code = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('world_code');
            this.channel_type = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('channel_type');
            this.create_user_code = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('create_user_code');
            this.name = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('name');
            this.description = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('description');
            this.max_player_count = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('max_player_count');
            this.max_voice_player_count = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('max_voice_player_count');
            this.allow_multi_voice_channel = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('allow_multi_voice_channel');
            this.users = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('users');
            this.required_tickets = positionChannel === null || positionChannel === void 0 ? void 0 : positionChannel.getValue('required_tickets');
        }
    }
}
class CVoiceChannelFormatter {
    constructor(voiceChannel){
        _defineProperty(this, "channel_id", null);
        _defineProperty(this, "position_channel_id", null);
        _defineProperty(this, "channel_type", null);
        _defineProperty(this, "create_user_code", null);
        _defineProperty(this, "name", null);
        _defineProperty(this, "description", null);
        _defineProperty(this, "max_player_count", null) // API未実装だが必要
        ;
        _defineProperty(this, "users", null);
        if (voiceChannel) {
            this.channel_id = voiceChannel === null || voiceChannel === void 0 ? void 0 : voiceChannel.getValue('channel_id');
            this.position_channel_id = voiceChannel === null || voiceChannel === void 0 ? void 0 : voiceChannel.getValue('position_channel_id');
            this.channel_type = voiceChannel === null || voiceChannel === void 0 ? void 0 : voiceChannel.getValue('channel_type');
            this.create_user_code = voiceChannel === null || voiceChannel === void 0 ? void 0 : voiceChannel.getValue('create_user_code');
            this.name = voiceChannel === null || voiceChannel === void 0 ? void 0 : voiceChannel.getValue('name');
            this.description = voiceChannel === null || voiceChannel === void 0 ? void 0 : voiceChannel.getValue('description');
            this.max_player_count = voiceChannel === null || voiceChannel === void 0 ? void 0 : voiceChannel.getValue('max_player_count');
            this.users = voiceChannel === null || voiceChannel === void 0 ? void 0 : voiceChannel.getValue('users');
        }
    }
}
class CLiveChannelFormatter {
    constructor(liveChannel){
        _defineProperty(this, "channel_id", null);
        _defineProperty(this, "spatium_code", null);
        _defineProperty(this, "world_code", null);
        _defineProperty(this, "position_channel_code", null);
        _defineProperty(this, "name", null);
        _defineProperty(this, "description", null);
        _defineProperty(this, "max_presenter_count", null) // API未実装だが必要
        ;
        _defineProperty(this, "required_tickets", null);
        _defineProperty(this, "require_login", null);
        _defineProperty(this, "users", null);
        if (liveChannel) {
            this.channel_id = liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.getValue('channel_id');
            this.spatium_code = liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.getValue('spatium_code');
            this.world_code = liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.getValue('world_code');
            this.position_channel_code = liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.getValue('position_channel_code');
            this.name = liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.getValue('name');
            this.description = liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.getValue('description');
            this.max_presenter_count = liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.getValue('max_presenter_count');
            this.required_tickets = liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.getValue('required_tickets');
            this.require_login = liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.getValue('require_login');
            this.users = liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.getValue('users');
        }
    }
}
export class CHeliNetworkMediator {
    getPosChannelOptions(params) {
        const posChOptionParams = {
            channelType: null,
            name: null,
            description: null,
            offset: null,
            limit: null,
            excludeEmptyChannelFlg: null
        };
        if (params !== undefined && params !== null) {
            params.forEach((item)=>{
                posChOptionParams[item.key] = item.value;
            });
        }
        return posChOptionParams;
    }
    getVoiceChannelOptions(params) {
        const voiceChOptionParams = {
            channelType: null,
            name: null,
            description: null,
            offset: null,
            limit: null
        };
        if (params !== undefined && params !== null) {
            params.forEach((item)=>{
                voiceChOptionParams[item.key] = item.value;
            });
        }
        return voiceChOptionParams;
    }
    getCurrentPositionChannel() {
        var _GetIHeliNetwork;
        const posChannel = (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getCurrentPositionChannel();
        if (posChannel === undefined || posChannel === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = new CPosChannelFormatter(posChannel);
        if (result === undefined || result === null) return null;
        return result;
    }
    getCurrentVoiceChannel() {
        var _GetIHeliNetwork;
        const voiceChannel = (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getCurrentVoiceChannel();
        if (voiceChannel === undefined || voiceChannel === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = new CVoiceChannelFormatter(voiceChannel);
        if (result === undefined || result === null) return null;
        return result;
    }
    async getPosChannel(channelid) {
        var _GetIHeliNetwork;
        // ネットワークライブラリが生成されていなければ生成する
        if (GetIHeliNetwork() == null) {
            CreateHeliNetwork('skyway');
        }
        const posChannel = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getPosChannel(channelid));
        if (posChannel === undefined || posChannel === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = new CPosChannelFormatter(posChannel);
        if (result === undefined || result === null) return null;
        return result;
    }
    async getPosChannelList(spatiumcode, worldCode, params) {
        var _GetIHeliNetwork;
        // ネットワークライブラリが生成されていなければ生成する
        if (GetIHeliNetwork() == null) {
            CreateHeliNetwork('skyway');
        }
        const posChOptionParams = this.getPosChannelOptions(params);
        const posChannelList = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getPosChannelList(spatiumcode, worldCode, posChOptionParams));
        if (posChannelList === undefined || posChannelList === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = posChannelList === null || posChannelList === void 0 ? void 0 : posChannelList.getList().map((positionChannel)=>{
            const channel = new CPosChannelFormatter(positionChannel);
            return channel;
        });
        if (result === undefined || result === null) return null;
        return result;
    }
    async joinPosChannel(channelid, authorityJwt, ticketJwt) {
        var _GetIHeliNetwork;
        // ネットワークライブラリが生成されていなければ生成する
        if (GetIHeliNetwork() == null) {
            CreateHeliNetwork('skyway');
        }
        if (channelid === null || channelid == '') return null;
        const posChannel = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getPosChannel(channelid));
        if (posChannel === undefined || posChannel === null) return null;
        // PositionChannelの通知を監視する
        await worldViewerWindow.heliport.api.apiNotification.watchPositionChannel(channelid);
        // 入室
        return await (posChannel === null || posChannel === void 0 ? void 0 : posChannel.join(authorityJwt, ticketJwt));
    }
    async leavePosChannel() {
        var _GetIHeliNetwork_getCurrentPositionChannel, _GetIHeliNetwork;
        // ネットワークライブラリが生成されていなければ生成する
        if (GetIHeliNetwork() == null) {
            CreateHeliNetwork('skyway');
        }
        await ((_GetIHeliNetwork_getCurrentPositionChannel = (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getCurrentPositionChannel()) === null || _GetIHeliNetwork_getCurrentPositionChannel === void 0 ? void 0 : _GetIHeliNetwork_getCurrentPositionChannel.leave());
    }
    async createPosChannel(spatiumCode, worldCode, params) {
        var _GetIHeliNetwork;
        // ネットワークライブラリが生成されていなければ生成する
        if (GetIHeliNetwork() == null) {
            CreateHeliNetwork('skyway');
        }
        const posChOptionParams = this.getPosChannelOptions(params);
        const posChannel = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.createPosChannel(spatiumCode, worldCode, posChOptionParams));
        if (posChannel === undefined || posChannel === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = new CPosChannelFormatter(posChannel);
        if (result === undefined || result === null) return null;
        return result;
    }
    async updatePosChannel(params) {
        var _GetIHeliNetwork_getCurrentPositionChannel, _GetIHeliNetwork;
        // ネットワークライブラリが生成されていなければ生成する
        if (GetIHeliNetwork() == null) {
            CreateHeliNetwork('skyway');
        }
        const posChOptionParams = this.getPosChannelOptions(params);
        await ((_GetIHeliNetwork_getCurrentPositionChannel = (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getCurrentPositionChannel()) === null || _GetIHeliNetwork_getCurrentPositionChannel === void 0 ? void 0 : _GetIHeliNetwork_getCurrentPositionChannel.update(posChOptionParams));
        const result = this.getCurrentPositionChannel();
        if (result === undefined || result === null) return null;
        return result;
    }
    async getVoiceChannel(channelid) {
        var _GetIHeliNetwork;
        const voiceChannel = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getVoiceChannel(channelid));
        if (voiceChannel === undefined || voiceChannel === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = new CVoiceChannelFormatter(voiceChannel);
        if (result === undefined || result === null) return null;
        return result;
    }
    async getVoiceChannelList(poschannelid, params) {
        var _GetIHeliNetwork;
        const voiceChOptionParams = this.getVoiceChannelOptions(params);
        const voiceChannelList = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getVoiceChannelList(poschannelid, voiceChOptionParams));
        if (voiceChannelList === undefined || voiceChannelList === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = voiceChannelList === null || voiceChannelList === void 0 ? void 0 : voiceChannelList.getList().map((voiceChannel)=>{
            const channel = new CVoiceChannelFormatter(voiceChannel);
            return channel;
        });
        if (result === undefined || result === null) return null;
        return result;
    }
    async joinVoiceChannel(voiceChannelId) {
        var _GetIHeliNetwork;
        // ネットワークライブラリが生成されていなければ生成する
        if (GetIHeliNetwork() == null) {
            CreateHeliNetwork('skyway');
        }
        if (voiceChannelId === null || voiceChannelId == '') return null;
        const voiceChannel = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getVoiceChannel(voiceChannelId));
        if (voiceChannel === undefined || voiceChannel === null) return null;
        // pIHeliMicには現状nullを渡す
        // default にするとブラウザ側で認識する最初のデバイスを強制利用することになり、ブラウザの設定から選んだものは利用できないため
        // VketCloud側でマイクデバイスを選択できるようになり変更するタイミングで使用
        /* // deviceIdの取得
        const mediaDeviceList = await navigator.mediaDevices.enumerateDevices();
        const deviceId = mediaDeviceList[0]?.deviceId || null

        const pIHeliMic = {
            "deviceId" : deviceId
        }*/ // 入室
        return await (voiceChannel === null || voiceChannel === void 0 ? void 0 : voiceChannel.join(null));
    }
    async leaveVoiceChannel() {
        var _GetIHeliNetwork_getCurrentVoiceChannel, _GetIHeliNetwork;
        await ((_GetIHeliNetwork_getCurrentVoiceChannel = (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getCurrentVoiceChannel()) === null || _GetIHeliNetwork_getCurrentVoiceChannel === void 0 ? void 0 : _GetIHeliNetwork_getCurrentVoiceChannel.leave());
    }
    async createVoiceChannel(posionChannelCode, params) {
        var _GetIHeliNetwork;
        const voiceChOptionParams = this.getVoiceChannelOptions(params);
        const voiceChannel = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.createVoiceChannel(posionChannelCode, voiceChOptionParams));
        if (voiceChannel === undefined || voiceChannel === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = new CVoiceChannelFormatter(voiceChannel);
        if (result === undefined || result === null) return null;
        return result;
    }
    async updateVoiceChannel(params) {
        var _GetIHeliNetwork_getCurrentVoiceChannel, _GetIHeliNetwork;
        const voiceChOptionParams = this.getVoiceChannelOptions(params);
        await ((_GetIHeliNetwork_getCurrentVoiceChannel = (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getCurrentVoiceChannel()) === null || _GetIHeliNetwork_getCurrentVoiceChannel === void 0 ? void 0 : _GetIHeliNetwork_getCurrentVoiceChannel.update(voiceChOptionParams));
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = this.getCurrentVoiceChannel();
        if (result === undefined || result === null) return null;
        return result;
    }
    // LiveChannel
    async getLiveChannel(channelid) {
        var _GetIHeliNetwork;
        const voiceChannel = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getVoiceChannel(channelid));
        if (voiceChannel === undefined || voiceChannel === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = new CVoiceChannelFormatter(voiceChannel);
        if (result === undefined || result === null) return null;
        return result;
    }
    async getPosChLiveChannelList(poschannelid) {
        var _GetIHeliNetwork;
        const liveChannelList = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getPositionChannelLiveChannelList(poschannelid));
        if (liveChannelList === undefined || liveChannelList === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = liveChannelList === null || liveChannelList === void 0 ? void 0 : liveChannelList.getList().map((liveChannel)=>{
            const channel = new CLiveChannelFormatter(liveChannel);
            return channel;
        });
        if (result === undefined || result === null) return null;
        return result;
    }
    async getWorldLiveChannelList(spatiumCode, worldid) {
        var _GetIHeliNetwork;
        const liveChannelList = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getWorldLiveChannelList(spatiumCode, worldid));
        if (liveChannelList === undefined || liveChannelList === null) return null;
        // HeliScriptで共通のKeyで値を取得できるようにIHelinetworkの値を整形する
        const result = liveChannelList === null || liveChannelList === void 0 ? void 0 : liveChannelList.getList().map((liveChannel)=>{
            const channel = new CLiveChannelFormatter(liveChannel);
            return channel;
        });
        if (result === undefined || result === null) return null;
        return result;
    }
    // ライブチャンネル　演者接続（発話可）
    async joinLiveChannelHost(liveChannelCode) {
        var _GetIHeliNetwork;
        // ネットワークライブラリが生成されていなければ生成する
        if (GetIHeliNetwork() == null) {
            CreateHeliNetwork('skyway');
        }
        if (liveChannelCode === null || liveChannelCode == '') return null;
        const liveChannel = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getLiveChannel(liveChannelCode));
        if (liveChannel === undefined || liveChannel === null) return null;
        // pIHeliMicには現状nullを渡す
        // default にするとブラウザ側で認識する最初のデバイスを強制利用することになり、ブラウザの設定から選んだものは利用できないため
        // VketCloud側でマイクデバイスを選択できるようになり変更するタイミングで使用
        /* // deviceIdの取得
        const mediaDeviceList = await navigator.mediaDevices.enumerateDevices();
        const deviceId = mediaDeviceList[0]?.deviceId || null

        const pIHeliMic = {
            "deviceId" : deviceId
        }*/ // 入室
        return await (liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.joinHost(null));
    }
    // ライブチャンネル　聴衆接続（聞き専）
    async joinLiveChannelAudience(liveChannelCode) {
        var _GetIHeliNetwork;
        // ネットワークライブラリが生成されていなければ生成する
        if (GetIHeliNetwork() == null) {
            CreateHeliNetwork('skyway');
        }
        if (liveChannelCode === null || liveChannelCode == '') return null;
        const liveChannel = await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getLiveChannel(liveChannelCode));
        if (liveChannel === undefined || liveChannel === null) return null;
        // pIHeliMicには現状nullを渡す
        // default にするとブラウザ側で認識する最初のデバイスを強制利用することになり、ブラウザの設定から選んだものは利用できないため
        // VketCloud側でマイクデバイスを選択できるようになり変更するタイミングで使用
        /* // deviceIdの取得
        const mediaDeviceList = await navigator.mediaDevices.enumerateDevices();
        const deviceId = mediaDeviceList[0]?.deviceId || null

        const pIHeliMic = {
            "deviceId" : deviceId
        }*/ // 入室
        return await (liveChannel === null || liveChannel === void 0 ? void 0 : liveChannel.joinAudience());
    }
    async leaveLiveChannel() {
        var _GetIHeliNetwork_getCurrentLiveChannel, _GetIHeliNetwork;
        await ((_GetIHeliNetwork_getCurrentLiveChannel = (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getCurrentLiveChannel()) === null || _GetIHeliNetwork_getCurrentLiveChannel === void 0 ? void 0 : _GetIHeliNetwork_getCurrentLiveChannel.leave());
    }
    releasePlayer(peerId) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.releasePlayer(peerId);
    }
    getPlayer(peerId) {
        const pIHeliNetwork = GetIHeliNetwork();
        if (pIHeliNetwork === undefined || pIHeliNetwork === null) return null;
        return pIHeliNetwork.getPlayer(peerId);
    }
    setPlayerStream(stream) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.setPlayerStream(stream);
    }
    getPeerId() {
        const pIHeliNetwork = GetIHeliNetwork();
        if (pIHeliNetwork == null) return '';
        return pIHeliNetwork.getPeerId();
    }
    setAllPlayerVoiceVolume(volume) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.setAllPlayerVoiceVolume(volume);
    }
    getPlayerList() {
        var _GetIHeliNetwork;
        return (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getPlayerList();
    }
    setPlayerVoiceMute(peerId, mute) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.setPlayerVoiceMute(peerId, mute);
    }
    setPlayerVoiceAttenuate(playerId, volume) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.setPlayerVoiceAttenuate(playerId, volume);
    }
    sendHeartBeat() {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.sendHeartBeat();
    }
    sendData(param) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.sendData(param);
    }
    sendMyPlayerInfoToAllPlayer() {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.sendMyPlayerInfoToAllPlayer();
    }
    async connect(configKey) {
        var _GetIHeliNetwork;
        // ネットワークライブラリが生成されていなければ生成する
        if (GetIHeliNetwork() == null) {
            CreateHeliNetwork('skyway');
        }
        // Firebaseの通知の初期化
        this.initApiNotificationHandler();
        return await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.connect(configKey));
    }
    createHeliNetwork(type) {
        CreateHeliNetwork(type);
    }
    doDebug() {
        return GetIHeliNetwork();
    }
    disconnect() {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.disconnect();
    }
    startSync() {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.sendData('start');
    }
    connectRoomAfterPeerOpen(roomid, authorityJwt = null, ticketJwt = null) {
        var // RTC抽象化前の実装。互換性を保つために残している
        // 抽象化後はhel_net_join_pos_channel・hel_net_leave_pos_channel・hel_net_join_voice_channel・hel_net_leave_voice_channelを使用する
        // // Peer接続完了後にルームにジョインする
        _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.connectRoomAfterPeerOpen(roomid);
    }
    disconnectRoom() {
        var // RTC抽象化前の実装。互換性を保つために残している
        // 抽象化後はhel_net_join_pos_channel・hel_net_leave_pos_channel・hel_net_join_voice_channel・hel_net_leave_voice_channelを使用する
        _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.disconnectRoom();
    }
    connectTalk(talkid) {
        var // RTC抽象化前の実装。互換性を保つために残している
        // 抽象化後はhel_net_join_pos_channel・hel_net_leave_pos_channel・hel_net_join_voice_channel・hel_net_leave_voice_channelを使用する
        _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.connectTalk(talkid);
    }
    disconnectTalk() {
        var // RTC抽象化前の実装。互換性を保つために残している
        // 抽象化後はhel_net_join_pos_channel・hel_net_leave_pos_channel・hel_net_join_voice_channel・hel_net_leave_voice_channelを使用する
        _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.disconnectRoom();
    }
    getMicPermissionState() {
        const pIHeliNetwork = GetIHeliNetwork();
        if (pIHeliNetwork === undefined || pIHeliNetwork === null) return 0;
        return pIHeliNetwork.getMicPermissionState();
    }
    async checkMicPermissionState() {
        var _GetIHeliNetwork;
        await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.checkMicPermissionState());
    }
    initLocalMicInput(deviceId) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.initLocalMicInput(deviceId);
    }
    setMicEnabled(enabled) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.setMicEnabled(enabled);
    }
    async getMicDevices() {
        var _GetIHeliNetwork;
        await ((_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getMicDevices());
    }
    connectWithDisplayMedia(configKey) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.connectWithDisplayMedia(configKey);
    }
    startScreenShare(videoItemName, videoMaterialName, useCamera, useAudio) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.startScreenShare(videoItemName, videoMaterialName, useCamera, useAudio);
    }
    stopScreenShare() {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.stopScreenShare();
    }
    playScreenShareReserved() {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.playScreenShareReserved();
    }
    playScreenShare() {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.playScreenShare();
    }
    initApiNotificationHandler() {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.initApiNotificationHandler();
    }
    apiNotificationReciever(scope, type, params) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.apiNotificationReciever(scope, type, params);
    }
    apiChangeItemPropertySender(refId, key, value) {
        var _GetIHeliNetwork;
        (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.apiChangeItemPropertySender(refId, key, value);
    }
    /**
   * TODO
   * エンジン対応するまでの暫定実装
   */ getSelfPlayerRole() {
        var _GetIHeliNetwork;
        return (_GetIHeliNetwork = GetIHeliNetwork()) === null || _GetIHeliNetwork === void 0 ? void 0 : _GetIHeliNetwork.getSelfPlayerRole();
    }
    constructor(){
        _defineProperty(this, "chsm_parseJwt", (token)=>{
            if (typeof token !== 'string') {
                return null;
            }
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        });
    }
}
// HeliNetworkMediator
const g_HeliNetworkMediator = new CHeliNetworkMediator();
export const GetHeliNetworkMediator = ()=>{
    if (g_HeliNetworkMediator === undefined || g_HeliNetworkMediator === null) {
        return null;
    }
    return g_HeliNetworkMediator;
};
