import { CHELAudio } from './CHELAudio';
import { worldViewerWindow } from './const';
import { getAudioList, getMixerMuteAt, getMixerSettingAt, getMixerMasterVolume, getMixerMasterMute, pushNullToAudioList, setAudioList } from './logics/dataOps';
const defineNextAudioIndexFromAudioList = ()=>{
    const audioList = getAudioList();
    for(let index = 0; index < audioList.length; index++){
        if (audioList[index] === null || audioList[index] === undefined) {
            return index;
        }
    }
    pushNullToAudioList();
    return getAudioList().length - 1;
};
/**
 * @remarks
 * オーディオ
 * C++側, heliodor.jsからも呼出される
 *
 * @param fileName
 * @param type
 * @param play
 * @param loop
 * @param volume
 */ worldViewerWindow.createAudio = (fileName, type, play, loop, volume)=>{
    const index = defineNextAudioIndexFromAudioList();
    const helAudio = new CHELAudio(type, volume);
    setAudioList(helAudio, index);
    helAudio.load(fileName, play, loop);
    return index;
};
/**
 * @remarks
 * C++側, heliodor.jsからも呼出される
 *
 * @param index
 */ worldViewerWindow.releaseAudioIndex = (index)=>{
    var _audioList_index;
    const audioList = getAudioList();
    if (audioList[index] === null) {
        return;
    }
    (_audioList_index = audioList[index]) === null || _audioList_index === void 0 ? void 0 : _audioList_index.release();
    setAudioList(null, index);
};
/**
 * @remarks
 * C++側, heliodor.jsからも呼出される
 *
 * @param index
 * @param loop
 */ worldViewerWindow.playAudio = (index, loop)=>{
    var _audioList_index;
    const audioList = getAudioList();
    if (audioList[index] === null) {
        return;
    }
    (_audioList_index = audioList[index]) === null || _audioList_index === void 0 ? void 0 : _audioList_index.play(loop);
};
/**
 * @remarks
 * C++側, heliodor.jsからも呼出される
 *
 * @param index
 */ worldViewerWindow.stopAudio = (index)=>{
    var _audioList_index;
    const audioList = getAudioList();
    if (audioList[index] === null) {
        return;
    }
    (_audioList_index = audioList[index]) === null || _audioList_index === void 0 ? void 0 : _audioList_index.stop();
};
/**
 * @remarks
 * C++側, heliodor.jsからも呼出される
 *
 * @param index
 */ worldViewerWindow.isPlayAudio = (index)=>{
    var _audioList_index;
    const audioList = getAudioList();
    if (audioList[index] === null) {
        return 0;
    }
    return ((_audioList_index = audioList[index]) === null || _audioList_index === void 0 ? void 0 : _audioList_index.isPlay()) || 0;
};
/**
 * @remarks
 * C++側, heliodor.jsからも呼出される
 *
 * @param index
 * @param realVolume
 */ worldViewerWindow.setAudioVolume = (index, realVolume)=>{
    var _audioList_index;
    const audioList = getAudioList();
    if (audioList[index] === null) {
        return;
    }
    return (_audioList_index = audioList[index]) === null || _audioList_index === void 0 ? void 0 : _audioList_index.setVolume(realVolume);
};
/**
 * @remarks
 * C++側, heliodor.jsからも呼出される
 *
 * @param type
 */ worldViewerWindow.hel_mixer_GetVolume = (type)=>{
    return parseFloat(getMixerSettingAt(type));
};
/**
 * @remarks
 * C++側, heliodor.jsからも呼出される
 *
 * @param type
 */ worldViewerWindow.hel_mixer_IsMute = (type)=>{
    return parseFloat(getMixerMuteAt(type));
};
/**
 * @remarks
 * C++側から呼出される
 */ worldViewerWindow.hel_mixer_GetMasterVolume = ()=>{
    return parseFloat(getMixerMasterVolume());
};
/**
 * @remarks
 * C++側から呼出される
 */ worldViewerWindow.hel_mixer_IsMasterMute = ()=>{
    return parseFloat(getMixerMasterMute());
};
