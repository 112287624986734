function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { CKickBanApiNotification } from "./CKickBanApiNotification";
import { worldViewerWindow } from '../../const';
export class CKickBan {
    addReceivedKickEventListener(fn) {
        var _this_m_KickBanApiNotification;
        (_this_m_KickBanApiNotification = this.m_KickBanApiNotification) === null || _this_m_KickBanApiNotification === void 0 ? void 0 : _this_m_KickBanApiNotification.addPosChannelEvent(fn);
    }
    // paramsはJsonオブジェクト
    send(params) {
        worldViewerWindow.hel_vcc_notify_removed();
    }
    // コンストラクタ
    constructor(){
        _defineProperty(this, "m_KickBanApiNotification", null);
        this.m_KickBanApiNotification = new CKickBanApiNotification();
    }
}
