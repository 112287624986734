// / <reference path="../../node_modules/@types/webxr/index.d.ts" />
import { ETextParamType_CanvasFileName, ETextParamType_CurrentUserName, ETextParamType_Logined, worldViewerWindow } from './const';
import { debugLog } from './debugLog';
import { presetBeforeBoot } from './logics/assign';
import { getCanvasFileName, getCurrentUserName, getXRSession, setXRSession } from './logics/dataOps';
import { setSceneJsonPath } from './logics/dataOps';
import { getLogDOMElement, getMainCanvas4XrDOMElement, getStatusDOMElement, getWebGLRenderingContext } from './logics/domOps';
import { requestIfCameraFpsValueAvailable, requestMemoryAllocate, requestReleaseMemoryFor, requestSetDataToHeap, requestSetVrPointer, requestSetVrPosePointer, requestToBindFrameBufferOf, requestToDrawFrame, requestToGetValueOf, requestToReleaseBoundFrameBuffer, requestToSetVrCameraPointer, requestVRControllerValue, switchToNoneVR, switchToVR } from './logics/moduleCaller';
// 即時実行
// 以下のコードはHTML側にxrStartButtonMultiおよびxrStartButtonSIngle
// という名前のボタンがある時にのみ有効で、そのボタンを押されたら
// WebXRのVR処理のチェックおよび実行が開始される。
// ------------------------------------------------------------------
const xrButtonNameMulti = 'xrStartButtonMulti';
const xrButtonNameSingle = 'xrStartButtonSingle';
const xrButtonMulti = document.getElementById(xrButtonNameMulti);
const xrButtonSingle = document.getElementById(xrButtonNameSingle);
if (xrButtonSingle !== undefined && xrButtonSingle !== null) {
    xrButtonSingle.onclick = async ()=>{
        await checkAndInitVR(worldViewerWindow.xrFileName);
    };
}
if (xrButtonMulti !== undefined && xrButtonMulti !== null) {
    xrButtonMulti.onclick = async ()=>{
        worldViewerWindow.hel_skyway_join();
        worldViewerWindow.hel_skyway_main();
        await worldViewerWindow.hel_skyway_start();
        await checkAndInitVR(worldViewerWindow.xrFileName, 1);
    };
}
worldViewerWindow.hel_checkAndInitVR = async ()=>{
    await checkAndInitVR(worldViewerWindow.xrFileName);
};
worldViewerWindow.hel_exitVR = ()=>{
    const xRSession = getXRSession();
    if (xRSession !== null) {
        xRSession.end();
    }
};
// --------------------------------------------------------------------
const lastPos = [
    0.0,
    0.0,
    0.0
]; // 直前(1フレーム前の)のプレイヤーの座標(x,y,z)
/**
 * heliodor_post.jsから呼出し。global関数化
 * @constructor

  @remarks ※この関数がない場合も動作はするが、その場合はXR機能が有効でない時も
VRボタンが表示されてしまう。
*/ worldViewerWindow.XRButtonCheck = ()=>{
    // navigator.xrはブラウザが提供するxrオブジェクトであり
    // これがnullなら、現在使用中のブラウザがwebxrに対応していない
    // もしくは、現在webxrのデバイスが接続されていないかのどちらかであるため
    // その場合はwebxrのためのボタンを無効化している
    if (xrButtonSingle !== undefined && xrButtonSingle !== null) {
        xrButtonSingle.style.visibility = navigator.xr !== undefined && navigator.xr !== null ? 'visible' : 'hidden';
    }
    if (xrButtonMulti !== undefined && xrButtonMulti !== null) {
        xrButtonMulti.style.visibility = navigator.xr !== undefined && navigator.xr !== null ? 'visible' : 'hidden';
    }
};
const changeWebXRIcons = (flag)=>{
    if (flag) {
        worldViewerWindow.hel_canvas_ToggleChange('webxr_all');
    } else {
        worldViewerWindow.hel_canvas_ResetToggleDefault('webxr_all');
    }
};
export const checkVR = ()=>{
    const XR = navigator.xr;
    if (XR) {
        XR.isSessionSupported('immersive-vr').then((supported)=>{
            if (supported) {
                changeWebXRIcons(true);
            } else {
                debugLog('vr is not supported');
                changeWebXRIcons(false);
            }
        }).catch((err)=>{
            debugLog('support check ' + err.message);
            changeWebXRIcons(false);
        });
    } else {
        debugLog('Is Not Support WebXR On This Browser.');
        changeWebXRIcons(false);
    }
};
/** WebXRのVR機能が使えるかどうかをチェックし、使えるのならアプリのVR版を起動する。
  もし、VR機能使用できない場合はそもそもアプリの起動を行わない。
  @param sceneFile シーンファイル名
  @param IsMulti マルチプレイヤーかどうか(マルチならtrue)
  @remarks
    Promise関数である
*/ export const checkAndInitVR = async (sceneFile, IsMulti = 0)=>{
    const XR = navigator.xr;
    // ブラウザなどの環境がXRに対応していない場合はこれがfalseになり
    // 以降の処理を行わない
    if (XR) {
        const xrSceneFileName = sceneFile === undefined ? 'Scene/webxrtest.json' : sceneFile;
        XR.isSessionSupported('immersive-vr').then((supported)=>{
            // ここで返されるsupportedによりVR機器が接続されているかどうかがわかる
            if (supported) {
                XR.requestSession('immersive-vr', {
                    requiredFeatures: [
                        'local-floor'
                    ]
                }).then(async (xRSession)=>{
                    debugLog('call StartVR');
                    // VRありのHeliodor起動
                    setXRSession(xRSession);
                    // eslint-disable-next-line new-cap
                    await StartVR(0, 0, 0, IsMulti, xrSceneFileName, xRSession);
                }).catch((err)=>{
                    debugLog('immersive-vr check ' + err.message);
                });
            } else {
                // なにも行わずに抜ける
                debugLog('vr is not supported');
            }
        }).catch((err)=>{
            debugLog('support check ' + err.message);
        });
    } else {
        // そもそもこのブラウザではWebXRが有効にならない
        debugLog('Is Not Support WebXR On This Browser.');
    }
};
/**
 * 値が正しいかどうかを返す
 * @param val チェックしたい値
 * @return 正しいならtrue, 正しくないならfalse
 * */ const isValid = (val)=>{
    if (Number.isNaN(val)) {
        return false;
    }
    return val !== null;
};
/**
 * 指定したボタンが正しい(有効)かどうか
 @param button 調べたいボタンオブジェクト
 @return 有効な値を持つボタンならtrue,そうでないならfalse
*/ const isButtonValid = (button)=>{
    if (button === undefined) {
        return false;
    }
    if (button === null) {
        return false;
    }
    return isValid(button.value);
};
/**
 * VRのボタンの値を返す
  @remarks
    gamepad.buttonは場合によってはvalueが取れない事(押してもvalueは0.0)があるため
    pressedフラグを見て0.0or1.0で代用する
  @param button 値を知りたいボタンオブジェクト
  @return 調べたボタンの値
*/ const getVRButtonPressValue = (button)=>{
    if (isButtonValid(button)) {
        if (button.value === 0.0) {
            return button.pressed ? 1.0 : 0.0;
        } else {
            return button.value;
        }
    } else {
        return 0.0;
    }
};
/** ２つのボタンの値の有効な方を返す
 ボタン①に有効な値が入っているならボタン①優先
 * @remarks
 * 両方ともに無効なら0.0を返す
 * @param buttonF ボタン①
 * @param buttonS ボタン②
 */ export const getVROneOfTwoButtonValue = (buttonF, buttonS)=>{
    if (isButtonValid(buttonF)) {
        if (buttonF === 0.0) {
            if (isButtonValid(buttonS)) {
                return buttonS.value;
            }
        } else {
            return buttonF.value;
        }
    } else {
        if (isButtonValid(buttonS)) {
            return buttonS.value;
        }
    }
    return 0.0;
};
/**
  ２つのボタンの値かpressedの有効な方を返す
  ボタン①に有効な値が入っているならボタン①優先
  両方ともに値もpressedも無効なら0.0を返す
  @param buttonF ボタン①
  @param buttonS ボタン②
*/ const getVROneOfTwoButtonPressedValue = (buttonF, buttonS)=>{
    if (isButtonValid(buttonF)) {
        const btnF = getVRButtonPressValue(buttonF);
        if (btnF === 0.0) {
            if (isButtonValid(buttonS)) {
                return getVRButtonPressValue(buttonS);
            }
        } else {
            return btnF;
        }
    } else {
        if (isButtonValid(buttonS)) {
            return getVRButtonPressValue(buttonS);
        }
    }
    return 0.0;
};
/**
  ２つのオブジェクトの値の有効な方を返す(数値)
  値①に有効なものが入っているなら値①優先
  両方ともに有効な値が入っていないなら0.0を返す
  @param first 値①
  @param second 値②
*/ const getVROneOfTwoValue = (first, second)=>{
    if (isValid(first)) {
        if (first === 0.0) {
            // axis[2]有効かつaxis[0]無効の場合は[0]に0.0が入っていることがあるため
            if (isValid(second)) {
                return second;
            }
        } else {
            return first;
        }
    } else {
        if (isValid(second)) {
            return second;
        }
    }
    return 0.0;
};
/**
  あるベクトルにクオータニオンによる回転を適用した結果のベクトルを返す
  @param out 回転結果ベクトル
  @param vec 回転したい元ベクトル
  @param q 回転のためのクオータニオン
  @return 回転結果ベクトル
*/ const applyQuaternion = (out, vec, q)=>{
    // benchmarks: https://jsperf.com/quaternion-transform-vec3-implementations-fixed
    const qx = q[0];
    const qy = q[1];
    const qz = q[2];
    const qw = q[3];
    const x = vec[0];
    const y = vec[1];
    const z = vec[2];
    let uvx = qy * z - qz * y;
    let uvy = qz * x - qx * z;
    let uvz = qx * y - qy * x;
    let uuvx = qy * uvz - qz * uvy;
    let uuvy = qz * uvx - qx * uvz;
    let uuvz = qx * uvy - qy * uvx;
    const w2 = qw * 2;
    uvx *= w2;
    uvy *= w2;
    uvz *= w2;
    uuvx *= 2;
    uuvy *= 2;
    uuvz *= 2;
    out[0] = x + uvx + uuvx;
    out[1] = y + uvy + uuvy;
    out[2] = z + uvz + uuvz;
    return out;
};
/**
  float配列をC++側に渡せる形にする(バイナリfloatにまとめる)
  @param ptr データを書き込むための先頭アドレス
  @param farray float配列
  @return 書き込み終わった直後のアドレス
*/ const nativizeFloatArray = (ptr, farray)=>{
    for(let i = 0; i < farray.length; ++i){
        worldViewerWindow.setValue(ptr + i * 4, farray[i], 'float');
    }
    return ptr + farray.length * 4;
};
/** 配列全てに１つの値を代入
  C言語で言う所のmemsetのようなことを行う
  @param iarray 代入される側の配列オブジェクト
  @param val 代入される値
*/ const valueToArray = (iarray, val)=>{
    for(let i = 0; i < iarray.length; ++i){
        iarray[i] = val;
    }
};
/**
 * 行列情報をC++側に渡せる形にする
 * @param ptr 行列の内容を書き込むためのアドレス
 * @param mat 行列(float配列16個)
 * @return 書き込んだ後ろのアドレス
 */ const NativizeMatrix = (ptr, mat)=>{
    for(let i = 0; i < 16; ++i){
        worldViewerWindow.setValue(ptr + i * 4, mat[i], 'float');
    }
    return ptr + 16 * 4;
};
/**
 * 座標をログ出力
 * @param pos 出力したい座標
 * @param name 出力時の名前文字列
 * @remarks
 * デバッグ用
 * */ export const PrintPosition = (pos, name)=>{
    let str = name + ' : ';
    str += 'x=' + pos.x + ' , ';
    str += 'y=' + pos.y + ' , ';
    str += 'z=' + pos.z;
    debugLog(str);
};
/**
 * クオータニオンをログ出力
 * @remarks
 * デバッグ用
 * @param q クオータニオン
 * @param name 出力するとき用のクオータニオンの名前文字列
 * @constructor
 */ export const PrintQuaternion = (q, name)=>{
    let str = name + ' : ';
    str += 'x=' + q.x + ' , ';
    str += 'y=' + q.y + ' , ';
    str += 'z=' + q.z;
    debugLog(str);
};
/**
 * 行列をログ出力
 * @remarks
 * デバッグ用
 * @param mat 行列(中身はfloat*16配列)
 * @param name 出力用の名前文字列
 * @constructor
 */ export const PrintMatrix = (mat, name)=>{
    let str = name + ' : ';
    for(let i = 0; i < 16; ++i){
        str += mat[i];
        if (i % 4 === 3) {
            str += '\n';
        } else {
            str += ',';
        }
    }
    debugLog(str);
};
// vrのセッションをスタート
const StartVR = async (Type, CharaType, Param1, MultiPlay, StrParam0, session)=>{
    if (xrButtonMulti === null) {
        debugLog('[ StartVR ] no XrButton Assigned');
    // return;
    } else {
        xrButtonMulti.style.visibility = 'hidden';
    }
    const status = getStatusDOMElement();
    if (status !== undefined) {
        status.style.visibility = 'visible';
    }
    const log = getLogDOMElement();
    if (log !== undefined) {
        log.style.visibility = 'visible';
    }
    session.requestReferenceSpace('local-floor').then(async (RefSpace)=>{
        worldViewerWindow.hel_setCanvasFileName('Canvas/CanvasList.json');
        await hel_start_vr(Type, CharaType, Param1, MultiPlay === 1, StrParam0, session, RefSpace);
    });
};
/**
 * コントローラの状態を出力します
 * @remarks
 * デバッグ用に使用する。現在のセッションのVRコントローラの状態を調べ
 * コンソールに出力する。
 * 注意点：毎フレーム出力するとブラウザがフリーズするのでタイミングに注意
 * @param session WebXRのセッション
 * @constructor
 */ export const OutputVRControllerState = (session)=>{
    for (const inputSource of session.inputSources){
        const handedness = inputSource.handedness;
        const gamepad = inputSource.gamepad;
        let str = 'handedness=';
        if (gamepad) {
            str += handedness;
            str += ' , gamepad buttons=';
            let strVal = '';
            let strTouch = '';
            let strPress = '';
            for (const button of gamepad.buttons){
                strVal += button.value + ',';
                strTouch += button.touched + ',';
                strPress += button.pressed + ',';
            }
            debugLog(str);
            debugLog('val=' + strVal);
            debugLog('touch' + strTouch);
            debugLog('press' + strPress);
            let strAxes = 'axes=';
            for (const axis of gamepad.axes){
                strAxes += axis + ',';
            }
            debugLog(strAxes);
        } else {
            debugLog('Gamepad is null');
        }
    }
};
// 終了処理
const onXRSessionEnd = async (session)=>{
    if (session) {
        debugLog('XRSession Is End object=' + session);
        if (session.session !== null) {
            // session.session.end()//この時点でendしてるので必要なしというエラーメッセージが出るが呼んでおいたほうがいい
            requestToReleaseBoundFrameBuffer();
            session.session = null;
        }
        switchToNoneVR();
        worldViewerWindow.hel_requestAnimationFrame();
    }
};
// VR系の初期化処理スタート
const hel_start_vr = async (Type, CharaType, Param1, MultiPlay, StrParam0, session, XRRefSpace)=>{
    // //XRセッション終了時イベント
    session.addEventListener('end', onXRSessionEnd);
    const mainCanvas4Xr = getMainCanvas4XrDOMElement();
    if (mainCanvas4Xr === undefined || mainCanvas4Xr.width <= 0 || mainCanvas4Xr.height <= 0) {
        return;
    }
    const CanTouch = worldViewerWindow.ontouchstart !== undefined;
    setSceneJsonPath(worldViewerWindow.g_DataFolderName + '/' + StrParam0);
    // Set Parameter
    presetBeforeBoot();
    // デバッグのため、ログイン状態にしておく
    // hel_setText(ETextParamType_Logined, (worldViewerWindow.g_Logined ? "1" : "0"));
    worldViewerWindow.hel_setText(ETextParamType_Logined, '1');
    worldViewerWindow.hel_setText(ETextParamType_CanvasFileName, getCanvasFileName());
    worldViewerWindow.hel_setText(ETextParamType_CurrentUserName, getCurrentUserName());
    // requestBootStrapGameEngine(Type, CharaType, Param1, StrParam0, CanTouch, MultiPlay, true);
    // 最後はVRモードかどうか。なので、ここをfalseにするとVRにならない
    //
    mainCanvas4Xr.focus();
    const WebglCtx4XR = getWebGLRenderingContext();
    await WebglCtx4XR.makeXRCompatible(); // ここで待たないとXRモードにできない
    // webxr-polyfill.module.jsによってimport * from 'webxr-polyfill'をされてるためグローバルクラスとして利用
    const webglLayer = new worldViewerWindow.XRWebGLLayer(session, WebglCtx4XR);
    await session.updateRenderState({
        baseLayer: webglLayer,
        depthFar: 10000,
        depthNear: 0.1
    });
    const drawFrameVRClosure = (time, frame)=>{
        drawFrameVR(time, frame, XRRefSpace);
    };
    worldViewerWindow.hel_cancelAnimationFrame();
    switchToVR(0, 0);
    session.requestAnimationFrame(drawFrameVRClosure);
};
// VRコントローラの値を取得し、Heliodor側に通知
const updateVRController = (frame, refSpace)=>{
    const session = frame.session;
    // OutputVRControllerState(XRSession);
    const NUM_OF_CONTROLLERS = 2; // 左手、右手(一応対応すべきが左右手のみとはかぎらないためこうしている)
    const CONTROLLER_INFO_NUM = 3 + 4; // 座標3向き4が2つ分でコントローラの場所、向きを渡す
    const GAMEPAD_INFO_NUM = 2 + 6; // レバー軸2方向＋ボタン6個分
    const controllerParams = new Float32Array(CONTROLLER_INFO_NUM * NUM_OF_CONTROLLERS); // コントローラの場所、向き情報を格納
    const gamepadParams = new Float32Array(GAMEPAD_INFO_NUM * NUM_OF_CONTROLLERS); // ゲームパッド情報を格納
    const activates = new Uint8Array(NUM_OF_CONTROLLERS);
    for(let idx = 0; idx < activates.length; ++idx){
        activates[idx] = 0;
    }
    valueToArray(gamepadParams, 0.0); // gameparams全てに0代入
    // ひとつひとつのinputSourceはXRInputSource
    // https://developer.mozilla.org/en-US/docs/Web/API/XRInputSource
    let controllernum = 0;
    for (const inputSource of session.inputSources){
        // targetRaySpaceの情報を取得することで、VRコントローラの座標・向きを得る
        const targetRayPose = frame.getPose(inputSource.targetRaySpace, refSpace);
        let controllerIdx = 0;
        // オブジェクトがない場合は無視(あっても手元にない場合は認識しない)
        if (!targetRayPose) {
            debugLog('no pose');
            continue;
        } else {
            if (inputSource.handedness === 'none') {
                debugLog('not vrcontroller');
                continue; // 右でも左でもないものは却下
            }
            controllerIdx = inputSource.handedness === 'left' ? 0 : 1; // 左手なら0 , 右手なら1
        }
        ++controllernum;
        // ここまで来た時点でアクティブ
        activates[controllerIdx] = 1;
        // targetRaySpaceの場合tracked-pointerを使用することになります
        // https://developer.mozilla.org/ja/docs/Web/API/XRInputSource/targetRayMode
        if (inputSource.targetRayMode === 'tracked-pointer') {
            // 現在のポーズのtransfromに現在位置と向きの情報が入っている
            // https://developer.mozilla.org/en-US/docs/Web/API/XRRigidTransform
            // position, orientation, matrixを使用可能
            // 今回はpositionとorientationを渡す
            const idx = controllerIdx * CONTROLLER_INFO_NUM;
            let pos = targetRayPose.transform.position;
            const rot = targetRayPose.transform.orientation;
            if (inputSource.gripSpace) {
                const gripPose = frame.getPose(inputSource.gripSpace, refSpace);
                if (gripPose) {
                    pos = gripPose.transform.position;
                }
            }
            controllerParams[idx] = pos.x;
            controllerParams[1 + idx] = pos.y;
            controllerParams[2 + idx] = -pos.z; // Z軸反転
            controllerParams[3 + idx] = -rot.x; // X軸回転反転
            controllerParams[4 + idx] = -rot.y; // Y軸回転反転
            controllerParams[5 + idx] = rot.z; // Z軸回転反転
            controllerParams[6 + idx] = rot.w;
            // gamepad情報を書き込み。詳細は
            // https://hikky.atlassian.net/wiki/spaces/HEL/pages/399474733/WebXR
            // と
            // https://www.w3.org/TR/webxr-gamepads-module/#xr-standard-heading
            // で確認してください
            const gamepad = inputSource.gamepad;
            if (gamepad === null || gamepad === undefined) continue;
            const gpidx = controllerIdx * GAMEPAD_INFO_NUM;
            if (gamepad.axes !== void 0) {
                if (gamepad.axes !== null) {
                    if (gamepad.axes.length > 1) {
                        gamepadParams[gpidx] = getVROneOfTwoValue(gamepad.axes[0], gamepad.axes[2]); // X軸
                        gamepadParams[1 + gpidx] = getVROneOfTwoValue(gamepad.axes[1], gamepad.axes[3]); // Y軸
                    }
                }
            }
            if (gamepad.buttons !== void 0) {
                if (gamepad.buttons !== null) {
                    gamepadParams[2 + gpidx] = gamepad.buttons[0].value; // トリガーボタン
                    gamepadParams[3 + gpidx] = gamepad.buttons[1].value; // グリップボタン
                    // 押し込み。
                    const axisPressValue = getVROneOfTwoButtonPressedValue(gamepad.buttons[2], gamepad.buttons[3]);
                    gamepadParams[4 + gpidx] = axisPressValue;
                    gamepadParams[5 + gpidx] = axisPressValue;
                    if (gamepad.buttons.length < 4) {
                        gamepadParams[6 + gpidx] = 0.0;
                        gamepadParams[7 + gpidx] = 0.0;
                    } else {
                        gamepadParams[6 + gpidx] = gamepad.buttons[4].value === null ? 0.0 : gamepad.buttons[4].value; // X or A
                        gamepadParams[7 + gpidx] = gamepad.buttons[5].value === null ? 0.0 : gamepad.buttons[5].value; // Y or B
                    }
                }
            }
        } else {
            debugLog('not tracked-pointer');
        }
    }
    const controllerdata = requestMemoryAllocate(controllerParams.length * 4); // コントローラデータ
    const gamepaddata = requestMemoryAllocate(gamepadParams.length * 4); // ゲームパッドデータ
    const activedata = requestMemoryAllocate(activates.length); // アクティブかどうかのデータ
    nativizeFloatArray(controllerdata, controllerParams);
    nativizeFloatArray(gamepaddata, gamepadParams);
    requestSetDataToHeap(activates, activedata);
    const isAvailable = requestVRControllerValue(controllernum, activedata, controllerdata, gamepaddata);
    requestReleaseMemoryFor(controllerdata);
    requestReleaseMemoryFor(gamepaddata);
    requestReleaseMemoryFor(activedata);
};
// VR用のdrawFrame関数
const drawFrameVR = (time, frame, XRRefSpace)=>{
    const session = frame.session;
    const glLayer = session.renderState.baseLayer;
    const viewerPose = frame.getViewerPose(XRRefSpace);
    if (viewerPose === null) {
        debugLog('xrsession=' + session);
        debugLog('viewerpose=' + viewerPose);
        debugLog('xrrefspace=' + XRRefSpace);
        debugLog('getViewerPos is Failed');
        debugLog('frame=' + frame);
        // ビューワポーズが確立するまでにはある程度の時間がかかることがあり、その場合はアニメーションフレームを
        // 回しつつ、待つ。
        const drawFrameVRClosure = (t, f)=>{
            drawFrameVR(t, f, XRRefSpace);
        };
        session.requestAnimationFrame(drawFrameVRClosure);
        return;
    }
    // PrintPosition(viewerPose.transform.position,"headpos");
    // PrintQuaternion(viewerPose.transform.orientation,"headrot");
    const NUM_OF_VRCAMERAS = 2;
    const CAMERA_PARAMS = 7;
    // VRパラメータ用メモリ確保
    const SIZE_OF_VR_VIEWPARAM = (16 + 16 + 4) * 4;
    const data = requestMemoryAllocate(SIZE_OF_VR_VIEWPARAM * NUM_OF_VRCAMERAS);
    const cameraParams = new Float32Array(CAMERA_PARAMS * NUM_OF_VRCAMERAS); // カメラ情報を格納
    // C側関数に送るパラメータを作成(左目・右目)
    for (const xrView of viewerPose.views){
        const viewport = glLayer.getViewport(xrView);
        const eyeIdx = xrView.eye === 'left' ? 0 : 1;
        cameraParams[eyeIdx * CAMERA_PARAMS] = xrView.transform.position.x;
        cameraParams[eyeIdx * CAMERA_PARAMS + 1] = xrView.transform.position.y;
        cameraParams[eyeIdx * CAMERA_PARAMS + 2] = xrView.transform.position.z;
        cameraParams[eyeIdx * CAMERA_PARAMS + 3] = xrView.transform.orientation.x;
        cameraParams[eyeIdx * CAMERA_PARAMS + 4] = xrView.transform.orientation.y;
        cameraParams[eyeIdx * CAMERA_PARAMS + 5] = xrView.transform.orientation.z;
        cameraParams[eyeIdx * CAMERA_PARAMS + 6] = xrView.transform.orientation.w;
        let ptr = data + SIZE_OF_VR_VIEWPARAM * eyeIdx;
        worldViewerWindow.setValue(ptr, viewport.x, 'float');
        ptr += 4;
        worldViewerWindow.setValue(ptr, viewport.y, 'float');
        ptr += 4;
        worldViewerWindow.setValue(ptr, viewport.width, 'float');
        ptr += 4;
        worldViewerWindow.setValue(ptr, viewport.height, 'float');
        ptr += 4;
        const viewMat = xrView.transform.inverse.matrix;
        // let viewMat=xrView.transform.inverse.matrix;
        // カメラのZだけ反転
        viewMat[8] *= -1;
        viewMat[9] *= -1;
        viewMat[10] *= -1;
        // カメラ行列セット
        // eslint-disable-next-line new-cap
        ptr = NativizeMatrix(ptr, viewMat);
        // プロジェクション行列セット
        const projMat = xrView.projectionMatrix;
        // eslint-disable-next-line new-cap
        ptr = NativizeMatrix(ptr, projMat);
    }
    const vrcameradata = requestMemoryAllocate(4 * CAMERA_PARAMS * NUM_OF_VRCAMERAS); // カメラデータ
    nativizeFloatArray(vrcameradata, cameraParams);
    requestToSetVrCameraPointer(vrcameradata);
    requestReleaseMemoryFor(vrcameradata);
    // Added from ver 2.9.0
    const posedata = requestMemoryAllocate((3 + 4) * 4); // 座標(3)＋回転(4)がそれぞれ4バイト分のメモリを確保する
    const poseParams = new Float32Array(3 + 4); //
    poseParams[0] = viewerPose.transform.position.x;
    poseParams[1] = viewerPose.transform.position.y;
    poseParams[2] = viewerPose.transform.position.z;
    poseParams[3] = viewerPose.transform.orientation.x;
    poseParams[4] = viewerPose.transform.orientation.y;
    poseParams[5] = viewerPose.transform.orientation.z;
    poseParams[6] = viewerPose.transform.orientation.w;
    nativizeFloatArray(posedata, poseParams); // Floatの配列をメモリの塊化する(C++とやりとりするため)
    requestSetVrPosePointer(posedata); // 上で作った姿勢情報をC++へ送る
    requestReleaseMemoryFor(posedata); // 確保したメモリを解放
    requestSetVrPointer(data); // ビュー情報をC++へ送る
    updateVRController(frame, XRRefSpace); // VRコントローラの情報をC++側へ送る
    // ここでVR側のコンテキストであるglLayerと、Emscriptenの書き込み先であるctx.FRAMEBUFFERを
    // バインドしておかないと、VR機器に正常に表示されないので注意。
    // https://developer.mozilla.org/ja/docs/Web/API/WebXR_Device_API/Rendering
    // より。
    // 「今後のすべての描画コマンドのターゲットとしてそのフレームバッファーをバインドします」を気に留めておくこと
    requestToBindFrameBufferOf(glLayer); // glBufferのframeBufferとModule.ctx.frameBufferをバインドする
    // C++のDrawFrame関数を呼び出す
    requestToDrawFrame();
    // ビュー情報メモリを解放する
    requestReleaseMemoryFor(data);
    // 座標の補正
    // XRRefSpace=adjustCameraPosition(XRRefSpace);
    const drawFrameVRClosure = (t, f)=>{
        drawFrameVR(t, f, XRRefSpace);
    };
    session.requestAnimationFrame(drawFrameVRClosure);
};
// カメラ位置補正(ゲーム側の座標と回転を反映させる)
// ※現在はゲーム側の回転は反映させていません
/**
 * @remarks
 * 未使用
 * @param XRRefSpace
 */ export const adjustCameraPosition = (XRRefSpace)=>{
    const SIZE_OF_FPS_CAMERAPARAM = (3 + 4) * 4;
    const cameradata = requestMemoryAllocate(SIZE_OF_FPS_CAMERAPARAM);
    const isAvailable = requestIfCameraFpsValueAvailable(cameradata);
    if (isAvailable) {
        const camera = [];
        for(let i = 0; i < 7; ++i){
            camera.push(requestToGetValueOf(cameradata + i * 4));
        }
        // let xrRigidTransform=new XRRigidTransform({x: camera[0], y: camera[1], z: camera[2]},
        let offsetPos = [
            lastPos[0] - camera[0],
            lastPos[1] - camera[1],
            lastPos[2] - camera[2]
        ];
        const q = [
            camera[3],
            camera[4],
            camera[5],
            camera[6]
        ];
        offsetPos = applyQuaternion(offsetPos, offsetPos, q);
        for(let i = 0; i < 3; ++i){
            lastPos[i] = camera[i];
        }
        // webxr-polyfill.module.jsによってimport * from 'webxr-polyfill'をされてるためグローバルクラスとして利用
        const xrRigidTransform = new worldViewerWindow.XRRigidTransform({
            x: offsetPos[0],
            y: offsetPos[1],
            z: offsetPos[2]
        }, // let xrRigidTransform=new XRRigidTransform({x: 0.0, y:0.001, z: 0.0},
        {
            x: 0,
            y: 0,
            z: 0,
            w: 1
        });
        // {x: camera[3], y: camera[4], z: camera[5], w: camera[6]});
        XRRefSpace = XRRefSpace.getOffsetReferenceSpace(xrRigidTransform);
    }
    requestReleaseMemoryFor(cameradata);
    return XRRefSpace;
};
