import { ETextParamType_CurrentUserName, ETextParamType_ScriptCallComponentMethod, ETextParamType_SetMicTalkingState, ETextParamType_ScriptCallCanvasComponentMethod, ETextParamType_OnSendTextChat, worldViewerWindow } from './const';
import { getActionProcedureList, getOnclickedAvatarProcedureList, getOnloadedProcedureList, getOnfirsttouchProcedureList, getOnresizeProcedureList, getSkywayCommandProcedureList, getSkywayMyinfoProcedureList, getAudioList, getLocalStream, getMixerTab, getSkywayAvatarNumberForSend, getSkywayUseCustomAvatarIcon, getSkywayMicStateTimer, getSkywayName, getCurrentUserName, getTextureCanvasList, getWorldId, getVCUID, setActionProcedureList, setOnclickedAvatarProcedureList, setOnloadedProcedureList, setOnfirsttouchProcedureList, setOnresizeProcedureList, setSkywayCommandProcedureList, setSkywayMyinfoProcedureList, setCurrentUserName, setMixerDistanceAt, setMixerFaderSettingAt, setMixerMuteAt, setMixerSettingAt, setMixerRealSettingAt, setSkywayAvatarNumber, setSkywayAvatarNumberForSend, setSkywayUseCustomAvatarIcon, setSkywayCustomAvatarIconURL, setSkywayMicStateTimer, setSkywayName, setTextureCanvasList, setUploadedFileBuffer } from './logics/dataOps';
import { hel_video_SetVolume } from './logics/device';
import { defineNextTextCanvasIndex, getConfigUrlDOMElement, getLocalTextConfigUrlDOMElement, getLocalTextUserNameDOMElement, getMainCanvasDOMElement, getWebGLRenderingContext, getInputFileDOMElement, getUserNameDOMElement, getSkywayMsgDOMElement, getSkywayLocalTextDOMElement } from './logics/domOps';
import { requestMemoryAllocate, requestOnDropFiles, requestOnDropFilesBegin, requestOnDropFilesProgress, requestProcedureCallFor, requestProcedureCallBinaryFor, requestProcedurePostFor, requestReleaseMemoryFor, requestSetDataToHeap } from './logics/moduleCaller';
import { hel_isLangJa, hel_isMobile } from './logics/platform';
// Cookie
/**
 * heliodor, skyway, libからもC++からも参照されてる
 * @remarks
 * heliodor.js, heliodor_skyway, C++側とShare関数
 *
 * @param name
 * @param value
 */ worldViewerWindow.hel_set_cookie = (name, value)=>{
    const expires = ';Expires=Thu, 1-Jan-2030 00:00:00 GMT';
    // Cookie needs URL-encode.
    document.cookie = name + '=' + encodeURIComponent(value) + expires;
};
/**
 * heliodor, skyway, xr, libからもC++からも参照されてる
 * @remarks
 * heliodor.js, heliodor_skyway, C++側とShare関数
 *
 * @param name
 */ worldViewerWindow.hel_get_cookie = (name)=>{
    const matches = document.cookie.match(// eslint-disable-next-line no-useless-escape
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};
/**
 * HeliScriptから参照
 * @param name
 * @returns
 */ worldViewerWindow.hel_get_cookie_onEmptyStr = (name)=>{
    const matches = document.cookie.match(// eslint-disable-next-line no-useless-escape
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
    return matches ? decodeURIComponent(matches[1]) : '';
};
/**
 * heliodor_skyway, xrからもC++からも参照されてる
 * @remarks
 * heliodor.js, heliodor_skyway, C++側とShare関数
 *
 * @param Type
 * @param Text
 */ worldViewerWindow.hel_setText = (Type, Text)=>{
    return requestProcedureCallFor(Type, Text);
};
//
worldViewerWindow.hel_postMessage = (Type, Text)=>{
    requestProcedurePostFor(Type, Text);
};
/**
 *
 * @remarks
 *
 * @param Type
 * @param ary_u8
 */ worldViewerWindow.hel_setBinary = (Type, ary_u8)=>{
    return requestProcedureCallBinaryFor(Type, ary_u8);
};
/**
 * @remarks
 * GUIのItemNameに依存した関数
 * itemName/componentName/methodName/param
 * @version 2.9.0
 * @param itemName
 * @param componentName
 * @param methodName
 * @param param
 */ worldViewerWindow.hel_script_CallComponentMethod = (itemName, componentName, methodName, param)=>{
    worldViewerWindow.hel_setText(ETextParamType_ScriptCallComponentMethod, itemName + ',' + componentName + ',' + methodName + ',' + param);
};
worldViewerWindow.hel_script_CallCanvasComponentMethod = (layerName, componentName, methodName, param)=>{
    worldViewerWindow.hel_setText(ETextParamType_ScriptCallCanvasComponentMethod, layerName + ',' + componentName + ',' + methodName + ',' + param);
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param index
 */ worldViewerWindow.getPlayTimeAudio = (index)=>{
    var _audioList_index;
    const audioList = getAudioList();
    if (audioList[index] === null) {
        return 0;
    }
    return ((_audioList_index = audioList[index]) === null || _audioList_index === void 0 ? void 0 : _audioList_index.getPlayTimeAudio()) || 0;
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param type
 * @param volume
 * @param fadeVolume
 * @param distanceVolume
 * @param mute
 */ worldViewerWindow.hel_mixer_SetVolume = (type, volume, mastervolume, fadeVolume, distanceVolume, mute)=>{
    const realVolume = getMixerTab() * volume * mastervolume * fadeVolume * distanceVolume * (mute !== 0 ? 0.0 : 1.0);
    switch(type){
        case worldViewerWindow.HEL_AUDIO_TYPE_WORLD:
            worldViewerWindow.hel_set_cookie('mixervolume_world', volume);
            worldViewerWindow.hel_set_cookie('mixermute_world', mute);
            break;
        case worldViewerWindow.HEL_AUDIO_TYPE_VOICE:
            if (typeof worldViewerWindow.hel_skyway_SetMixerVolume === 'function') {
                worldViewerWindow.hel_skyway_SetMixerVolume(realVolume);
            }
            worldViewerWindow.hel_set_cookie('mixervolume_voice', volume);
            worldViewerWindow.hel_set_cookie('mixermute_voice', mute);
            break;
        case worldViewerWindow.HEL_AUDIO_TYPE_SE:
            worldViewerWindow.hel_set_cookie('mixervolume_se', volume);
            worldViewerWindow.hel_set_cookie('mixermute_se', mute);
            break;
        case worldViewerWindow.HEL_AUDIO_TYPE_SYSTEMSE:
            worldViewerWindow.hel_set_cookie('mixervolume_systemse', volume);
            worldViewerWindow.hel_set_cookie('mixermute_systemse', mute);
            break;
        case worldViewerWindow.HEL_AUDIO_TYPE_VIDEO:
            hel_video_SetVolume(realVolume);
            worldViewerWindow.hel_set_cookie('mixervolume_video', volume);
            worldViewerWindow.hel_set_cookie('mixermute_video', mute);
            break;
        default:
            return;
    }
    setMixerSettingAt(volume, type);
    setMixerRealSettingAt(realVolume, type);
    setMixerFaderSettingAt(fadeVolume, type);
    setMixerDistanceAt(distanceVolume, type);
    setMixerMuteAt(mute, type);
};
// MasterSound
worldViewerWindow.hel_mixer_SetMasterVolume = (volume)=>{
    worldViewerWindow.hel_set_cookie('mixervolume_master', volume);
};
worldViewerWindow.hel_mixer_SetMuteMaster = (mute)=>{
    worldViewerWindow.hel_set_cookie('mixermute_master', mute);
};
// Texture
/**
 * heliodor.jsからもC++からも参照されてる
 * @param size
 */ worldViewerWindow.hel_texture_IsPower2Size = (size)=>{
    return (size & size - 1) === 0;
};
// Texture Canvas
/**
 * heliodor.jsからもC++からも参照されてる
 * @param width
 * @param height
 */ worldViewerWindow.createTextCanvas = (width, height)=>{
    const index = defineNextTextCanvasIndex();
    const textCanvas = document.createElement('canvas');
    setTextureCanvasList(textCanvas, index);
    textCanvas.style.visibility = 'hidden';
    textCanvas.width = width;
    textCanvas.height = height;
    return index;
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param index
 */ worldViewerWindow.getTextCanvas = (index)=>{
    const canvasList = getTextureCanvasList();
    if (index < 0 || index >= canvasList.length) {
        return null;
    }
    return getTextureCanvasList()[index];
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param index
 */ worldViewerWindow.getTextCanvasContext = (index)=>{
    const textCanvas = worldViewerWindow.getTextCanvas(index);
    if (textCanvas === null) return null;
    return textCanvas.getContext('2d');
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param index
 */ worldViewerWindow.clearTextCanvas = (index)=>{
    const textCanvas = worldViewerWindow.getTextCanvas(index);
    if (textCanvas === null) return;
    const context = textCanvas.getContext('2d');
    if (context === null) return;
    context.clearRect(0, 0, textCanvas.width, textCanvas.height);
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param index
 */ worldViewerWindow.releaseTextCanvasIndex = (index)=>{
    const canvasList = getTextureCanvasList();
    if (index < 0 || index >= canvasList.length || canvasList[index] === null) {
        return;
    }
    setTextureCanvasList(null, index);
};
// TAG
/**
 * heliodor.js, skywayからもC++からも参照されてる
 * @param name
 * @param params
 */ worldViewerWindow.hel_gtag = (name, params)=>{
    if (typeof worldViewerWindow.hel_analytics === 'function') {
        worldViewerWindow.hel_analytics('event', name, params);
    } else if (typeof worldViewerWindow.gtag === 'function') {
        worldViewerWindow.gtag('event', name, params);
    }
};
// File Drag & Drop
const setFileReaderEvents = (files)=>{
    if (files.length <= 0) {
        return;
    }
    const file = files[0];
    requestOnDropFilesBegin(file);
    //
    const reader = new FileReader();
    reader.onprogress = (evt)=>{
        if (!evt.lengthComputable || evt.total <= 0) {
            return;
        }
        requestOnDropFilesProgress(evt);
    };
    reader.onload = ()=>{
        setUploadedFileBuffer(reader.result);
        const ary_u8 = new Uint8Array(reader.result);
        // console.log("onload " + file.name + " / " + ary_u8.length);
        const data = requestMemoryAllocate(ary_u8.length);
        requestSetDataToHeap(ary_u8, data);
        requestOnDropFiles(file, ary_u8, data);
        requestReleaseMemoryFor(data);
    };
    reader.readAsArrayBuffer(file);
};
/**
 * C++から参照
 */ worldViewerWindow.hel_get_MainWebGLRenderingContext = ()=>{
    return getWebGLRenderingContext();
};
/**
 * heliodor.jsからもC++からも参照されてる
 */ worldViewerWindow.hel_beginDropFiles = ()=>{
    const mainCanvas = getMainCanvasDOMElement();
    if (mainCanvas === undefined) {
        return;
    }
    mainCanvas.addEventListener('dragover', (e)=>{
        e.stopPropagation();
        e.preventDefault();
    }, false);
    mainCanvas.addEventListener('dragleave', (e)=>{
        e.stopPropagation();
        e.preventDefault();
    }, false);
    mainCanvas.addEventListener('drop', (e)=>{
        e.stopPropagation();
        e.preventDefault();
        setFileReaderEvents(e.dataTransfer.files || []);
    }, false);
    const inputFile = getInputFileDOMElement();
    if (inputFile !== undefined) {
        inputFile.addEventListener('change', (e)=>{
            e.stopPropagation();
            e.preventDefault();
            // @ts-ignore
            // eslint-disable-next-line no-invalid-this
            const files = this.files;
            setFileReaderEvents(files || []);
        }, false);
    }
};
//
const helcore_TransDevicePixelRatio = (n)=>{
    return Math.floor(n / window.devicePixelRatio);
};
/**
 * 汎用的なインプットフィールドのリサイズ関数
 */ worldViewerWindow.hel_resize_InputField = (x, y, width, height, fontSize, DivElementName, InputElementName)=>{
    x = helcore_TransDevicePixelRatio(x);
    y = helcore_TransDevicePixelRatio(y);
    width = helcore_TransDevicePixelRatio(width);
    height = helcore_TransDevicePixelRatio(height);
    fontSize = helcore_TransDevicePixelRatio(fontSize);
    //
    const DivElement = document.getElementById(DivElementName);
    if (DivElement !== undefined && DivElement !== null) {
        DivElement.style.left = x + 'px';
        DivElement.style.top = y + 'px';
        DivElement.style.width = width + 'px';
        DivElement.style.height = height + 'px';
    }
    //
    const InputElement = document.getElementById(InputElementName);
    if (InputElement !== undefined && InputElement !== null) {
        InputElement.style.width = width + 'px';
        InputElement.style.height = height + 'px';
        InputElement.style.fontSize = fontSize + 'px';
    }
};
/**
 *
 * HeliScriptから参照
 */ worldViewerWindow.hel_set_HTMLElement_visibility = (elementName, show)=>{
    const element = document.getElementById(elementName);
    if (element !== undefined && element !== null) {
        element.style.visibility = show ? 'visible' : 'hidden';
    }
};
// configurl
/**
 * heliodor.jsからもC++からも参照されてる
 * @param show
 */ worldViewerWindow.hel_set_show_TextField_configurl = (show)=>{
    const localTextConfigUrl = getLocalTextConfigUrlDOMElement();
    if (localTextConfigUrl !== undefined) {
        localTextConfigUrl.style.visibility = show ? 'visible' : 'hidden';
    }
    const configUrl = getConfigUrlDOMElement();
    if (configUrl !== undefined) {
        configUrl.style.visibility = show ? 'visible' : 'hidden';
    }
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param x
 * @param y
 * @param width
 * @param height
 */ worldViewerWindow.hel_resize_configurl = (x, y, width, height)=>{
    x = helcore_TransDevicePixelRatio(x);
    y = helcore_TransDevicePixelRatio(y);
    width = helcore_TransDevicePixelRatio(width);
    height = helcore_TransDevicePixelRatio(height);
    const configUrl = getConfigUrlDOMElement();
    if (configUrl !== undefined) {
        configUrl.style.left = x + 'px';
        configUrl.style.top = y + 'px';
    }
    const localTextConfigUrl = getLocalTextConfigUrlDOMElement();
    if (localTextConfigUrl !== undefined) {
        localTextConfigUrl.style.width = width + 'px';
        localTextConfigUrl.style.height = height + 'px';
    }
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param text
 */ worldViewerWindow.hel_set_TextField_configurl = (text)=>{
    const localTextConfigUrl = getLocalTextConfigUrlDOMElement();
    if (localTextConfigUrl !== undefined) {
        localTextConfigUrl.value = text;
    }
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param text
 */ worldViewerWindow.hel_set_TextField_configurl_fontSize = (fontSize)=>{
    fontSize = helcore_TransDevicePixelRatio(fontSize);
    const localTextConfigUrl = getLocalTextConfigUrlDOMElement();
    if (localTextConfigUrl !== undefined) {
        localTextConfigUrl.style.fontSize = fontSize + 'px';
    }
};
// username
/**
 * heliodor.jsからもC++からも参照されてる
 * @param show
 */ worldViewerWindow.hel_set_show_TextField_UserName = (show)=>{
    const localTextUserName = getLocalTextUserNameDOMElement();
    if (localTextUserName !== undefined) {
        localTextUserName.style.visibility = show ? 'visible' : 'hidden';
    }
    const userName = getUserNameDOMElement();
    if (userName !== undefined) {
        userName.style.visibility = show ? 'visible' : 'hidden';
    }
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param name
 */ worldViewerWindow.hel_set_TextField_UserName = (name)=>{
    // console.log("*** A-END");
    const localTextUserName = getLocalTextUserNameDOMElement();
    // nameが 'undefined' ＝文字列で入ってる場合がある
    if (localTextUserName === undefined || name === undefined) {
        return;
    }
    localTextUserName.value = name;
// console.log("*** A-worldViewerWindow.g_localText_username(" + worldViewerWindow.g_localText_username + ")");
// console.log("*** A-worldViewerWindow.g_localText_username.value(" + worldViewerWindow.g_localText_username.value + ")");
// console.log("*** A-name(" + name + ")");
// console.log("*** A-END");
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param byCookie
 */ worldViewerWindow.hel_post_TextField_UserName = (byCookie)=>{
    let result_uname;
    if (byCookie) {
        const uname = worldViewerWindow.hel_get_cookie('playername');
        if (uname !== undefined) {
            if (uname.length > 0) {
                result_uname = uname;
            }
        }
    } else {
        const localTextUserName = getLocalTextUserNameDOMElement();
        if (localTextUserName !== undefined) {
            result_uname = localTextUserName.value;
        }
    }
    if (result_uname === undefined) {
        return;
    }
    // 文字列を『全角10文字、半角20文字』の制限に従い、切り取る
    let char_num = 0;
    for(let i = 0; i < result_uname.length; i++){
        const c = result_uname[i];
        let incNum = 1;
        if (c.match(/[ -~]/)) {
            // 半角文字
            incNum = 1;
        } else {
            // 全角文字
            incNum = 2;
        }
        // 20文字を超すならここで処理を抜けて文字列を切り取る
        if (char_num + incNum > 20) {
            result_uname = result_uname.substring(0, i);
            break;
        }
        char_num += incNum;
    }
    // console.log("%s / %d", result_uname, char_num);
    // Send UserName to c++ side via SetText()
    setCurrentUserName(result_uname);
    worldViewerWindow.hel_setText(ETextParamType_CurrentUserName, result_uname);
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param text
 */ worldViewerWindow.hel_set_TextField_UserName_fontSize = (fontSize)=>{
    fontSize = helcore_TransDevicePixelRatio(fontSize);
    const localTextUserName = getLocalTextUserNameDOMElement();
    if (localTextUserName !== undefined) {
        localTextUserName.style.fontSize = fontSize + 'px';
    }
};
/**
 * heliodor.jsからもC++からも参照されてる
 */ worldViewerWindow.hel_update_TextField_UserName = ()=>{
    const localTextUserName = getLocalTextUserNameDOMElement();
    if (localTextUserName === undefined) return;
    const skywayName = getSkywayName();
    worldViewerWindow.hel_remove_emoji(localTextUserName);
    const value = localTextUserName.value;
    if (skywayName !== value) {
        setSkywayName(value);
        worldViewerWindow.hel_set_cookie('playername', value);
    }
    worldViewerWindow.hel_skyway_send_myinfo();
};
/**
 * heliodor.jsからもC++からも参照されてる
 * @param x
 * @param y
 * @param width
 * @param height
 */ worldViewerWindow.hel_resize_UserName = (x, y, width, height)=>{
    x = helcore_TransDevicePixelRatio(x);
    y = helcore_TransDevicePixelRatio(y);
    width = helcore_TransDevicePixelRatio(width);
    height = helcore_TransDevicePixelRatio(height);
    const userName = getUserNameDOMElement();
    if (userName !== undefined) {
        userName.style.left = x + 'px';
        userName.style.top = y + 'px';
    }
    const localTextUserName = getLocalTextUserNameDOMElement();
    if (localTextUserName !== undefined) {
        localTextUserName.style.width = width + 'px';
        localTextUserName.style.height = height + 'px';
    }
};
/**
 * CurrentUserName(GET)
 */ worldViewerWindow.hel_get_current_username = ()=>{
    return getCurrentUserName();
};
/**
 * SkywayName(GET)
 */ worldViewerWindow.hel_get_skyway_username = ()=>{
    return getSkywayName();
};
/**
 * heliodor.js, heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * @param show
 */ worldViewerWindow.hel_skyway_set_show_chatbox = (show)=>{
    const msg = getSkywayMsgDOMElement();
    if (msg !== undefined) {
        msg.style.visibility = show ? 'visible' : 'hidden';
    }
    const localText = getSkywayLocalTextDOMElement();
    if (localText === undefined) {
        return;
    }
    localText.style.visibility = show ? 'visible' : 'hidden';
};
/**
 * heliodor.js, heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * @param text
 */ worldViewerWindow.hel_skyway_set_TextField_localText_fontSize = (fontSize)=>{
    fontSize = helcore_TransDevicePixelRatio(fontSize);
    const skywayLocalText = getSkywayLocalTextDOMElement();
    if (skywayLocalText === undefined) {
        return;
    }
    skywayLocalText.style.fontSize = fontSize + 'px';
};
/**
 * heliodor.js, heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * @param x
 * @param y
 * @param width
 * @param height
 */ worldViewerWindow.hel_skyway_resize_chatbox = (x, y, width, height)=>{
    const msg = getSkywayMsgDOMElement();
    if (msg === undefined) {
        return;
    }
    x = helcore_TransDevicePixelRatio(x);
    y = helcore_TransDevicePixelRatio(y);
    width = helcore_TransDevicePixelRatio(width);
    height = helcore_TransDevicePixelRatio(height);
    msg.style.left = x + 'px';
    msg.style.top = y + 'px';
    const skywayLocalText = getSkywayLocalTextDOMElement();
    if (skywayLocalText === undefined) {
        return;
    }
    skywayLocalText.style.width = width + 'px';
    skywayLocalText.style.height = height + 'px';
};
worldViewerWindow.getNumOfPlayers = (room)=>{
    if (room._options.mode.toLowerCase() === 'mesh') {
        return 1 + Object.values(room.getPeerConnections()).length;
    }
    if (room._options.mode.toLowerCase() === 'sfu') {
        return 1 + room.members.length.length;
    }
};
/**
 * JSから参照あり
 */ worldViewerWindow.hel_skyway_send_JSCustomData = (param)=>{
    worldViewerWindow.hel_skyway_send_data('jsdata:' + param);
};
/**
 * heliodor.js, heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * @param avatar
 */ worldViewerWindow.hel_skyway_set_avatar_forsend = (avatar)=>{
    setSkywayAvatarNumberForSend(avatar);
};
worldViewerWindow.hel_skyway_set_avatar = (avatar)=>setSkywayAvatarNumber(avatar);
// Avatar
/**
 * heliodor.js, heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * @param avatar
 */ worldViewerWindow.hel_skyway_change_avatar = (avatar, uuid, setcookie)=>{
    setSkywayAvatarNumber(avatar);
    setSkywayAvatarNumberForSend(avatar);
    worldViewerWindow.hel_gtag('AvatarChange', {
        param0: getWorldId(),
        param1: avatar
    });
    if (setcookie) {
        worldViewerWindow.hel_set_cookie('Avatar', avatar);
        worldViewerWindow.hel_set_cookie('MyAvatarUUID', uuid);
    }
};
worldViewerWindow.hel_skyway_get_avatar = ()=>{
    return getSkywayAvatarNumberForSend();
};
/**
 * AvatarIcon
 */ worldViewerWindow.hel_set_custom_avataricon = (url)=>{
    setSkywayUseCustomAvatarIcon(url !== null && url !== '');
    setSkywayCustomAvatarIconURL(url);
};
worldViewerWindow.hel_get_use_custom_avataricon = ()=>{
    return getSkywayUseCustomAvatarIcon() ? 1 : 0;
};
const hel_skyway_detect_voice = (input, analyzer, timeDomainArray, frequencyArray)=>{
    analyzer.getFloatTimeDomainData(timeDomainArray);
    analyzer.getFloatFrequencyData(frequencyArray);
    // @ts-ignore
    const maxDomain = Math.max(...timeDomainArray);
    //  console.log('voice!', timeDomainArray, frequencyArray);
    //  console.log('voice!', analyzer.minDecibels, analyzer.maxDecibels);
    //    console.log('voice!', maxDomain);
    const voice_threshold = 0.05;
    if (maxDomain > voice_threshold) {
        const VolumeRate = (maxDomain * 100.0).toFixed();
        worldViewerWindow.hel_canvas_SetLayoutShow('HUD_Voice', true);
        worldViewerWindow.hel_setText(ETextParamType_SetMicTalkingState, VolumeRate);
        worldViewerWindow.hel_canvas_SetGUIShow('hud_voicechat_on', false);
    } else {
        worldViewerWindow.hel_canvas_SetLayoutShow('HUD_Voice', false);
        worldViewerWindow.hel_setText(ETextParamType_SetMicTalkingState, '0');
        worldViewerWindow.hel_canvas_SetGUIShow('hud_voicechat_on', true);
    }
};
/**
 * heliodor.js, heliodor_skyway_rcp.js, heliodor_skyway_xrw.js, C++から参照あり
 * TODO
 * agora/skyway/photon/chalcedonyで今後realtimeEngineSDKに分岐
 * @param state
 * @virtual
 */ worldViewerWindow.hel_skyway_set_mic_state = (state)=>{
    const localStream = getLocalStream();
    if (localStream === undefined) {
        return;
    }
    const tracks = localStream.getAudioTracks();
    if (tracks.length <= 0) {
        return;
    }
    tracks[0].enabled = state;
    if (state) {
        const audioCtx = new AudioContext();
        const input = audioCtx.createMediaStreamSource(localStream);
        const analyzer = audioCtx.createAnalyser();
        input.connect(analyzer);
        const timeDomainArray = new Float32Array(analyzer.fftSize);
        const frequencyArray = new Float32Array(analyzer.frequencyBinCount);
        const timer = setInterval(hel_skyway_detect_voice.bind(null, input, analyzer, timeDomainArray, frequencyArray), 200);
        setSkywayMicStateTimer(timer);
    } else {
        worldViewerWindow.hel_canvas_SetLayoutShow('HUD_Voice', false);
        worldViewerWindow.hel_setText(ETextParamType_SetMicTalkingState, '0');
        const timer = getSkywayMicStateTimer();
        if (timer !== undefined) {
            clearInterval(timer);
        }
    }
};
/**
 * heliodor.js, JS, C++ から参照あり
 */ worldViewerWindow.hel_onclickedavatar_call = (playerID)=>{
    const procedures = getOnclickedAvatarProcedureList();
    for(let i = 0; i < procedures.length; i += 1){
        if (typeof procedures[i] === 'function') {
            procedures[i](playerID);
        }
    }
    if (typeof worldViewerWindow.hel_onclickedavatar === 'function') {
        worldViewerWindow.hel_onclickedavatar(playerID);
    }
};
/**
 * heliodor.js, JS, C++ から参照あり
 */ worldViewerWindow.hel_onclickedavatar_add = (callFunc)=>{
    setOnclickedAvatarProcedureList(callFunc);
};
/**
 * heliodor.js, JS、C++から参照あり
 */ worldViewerWindow.hel_onloaded_call = ()=>{
    const procedures = getOnloadedProcedureList();
    for(let i = 0; i < procedures.length; i += 1){
        if (typeof procedures[i] === 'function') {
            procedures[i]();
        }
    }
    if (typeof worldViewerWindow.hel_onloaded === 'function') {
        worldViewerWindow.hel_onloaded();
    }
};
/**
 * heliodor.js, JS、C++から参照あり
 */ worldViewerWindow.hel_onbeginloading_call = ()=>{
    if (typeof worldViewerWindow.hel_onbeginloading === 'function') {
        worldViewerWindow.hel_onbeginloading();
    }
};
/**
 * heliodor.js, JS、C++から参照あり
 */ worldViewerWindow.hel_onloaded_add = (callFunc)=>{
    setOnloadedProcedureList(callFunc);
};
/**
 * heliodor.js, JS、C++から参照あり
 */ worldViewerWindow.hel_onfirsttouch_call = ()=>{
    const procedures = getOnfirsttouchProcedureList();
    for(let i = 0; i < procedures.length; i += 1){
        if (typeof procedures[i] === 'function') {
            procedures[i]();
        }
    }
};
/**
 * heliodor.js, JS、C++から参照あり
 */ worldViewerWindow.hel_onfirsttouch_add = (callFunc)=>{
    setOnfirsttouchProcedureList(callFunc);
};
/**
 * heliodor.js, JS、C++から参照あり
 */ worldViewerWindow.hel_onresize_generic_call = ()=>{
    const procedures = getOnresizeProcedureList();
    for(let i = 0; i < procedures.length; i += 1){
        if (typeof procedures[i] === 'function') {
            procedures[i]();
        }
    }
};
/**
 * heliodor.js, JS、C++から参照あり
 */ worldViewerWindow.hel_onresize_generic_add = (callFunc)=>{
    setOnresizeProcedureList(callFunc);
};
/**
 * heliodor.js, JS、C++から参照あり
 * @remarks
 * 関数をセット
 * @param actionProcedureCall
 * @version ^3.4
 */ worldViewerWindow.hel_action_bridge_add = (actionProcedureCall)=>setActionProcedureList(actionProcedureCall);
/**
 * @remarks
 * C++から呼び出しのあるプロシージャコール型ブリッジ
 * 現在ご完成を考えhel_action_bridgeも連携する（heliodor_front.jsを利用しても可）
 * @param name
 * @param text
 * @version ^3.4
 */ worldViewerWindow.hel_action_bridge_call = (name, text)=>{
    const procedures = getActionProcedureList();
    for(let i = 0; i < procedures.length; i += 1){
        if (typeof procedures[i] === 'function' && procedures[i](name, text)) {
            return;
        }
    }
    if (typeof worldViewerWindow.hel_action_bridge === 'function') {
        worldViewerWindow.hel_action_bridge(name, text);
    }
};
/**
 * JSから参照あり
 * JSからSkywayCommandをハンドリングする
 */ worldViewerWindow.hel_skyway_command_bridge_add = (callFunc)=>{
    setSkywayCommandProcedureList(callFunc);
};
/**
 * JSから参照あり
 * JSからSkywayCommandをハンドリングする
 */ worldViewerWindow.hel_skyway_command_bridge_call = (ID, Type, Data)=>{
    const procedures = getSkywayCommandProcedureList();
    for(let i = 0; i < procedures.length; i += 1){
        if (typeof procedures[i] === 'function') {
            procedures[i](ID, Type, Data);
        }
    }
};
/**
 * JSから参照あり
 * カスタムユーザーデータを送信する
 */ worldViewerWindow.hel_skyway_myinfo_bridge_add = (callFunc)=>{
    setSkywayMyinfoProcedureList(callFunc);
};
/**
 * JSから参照あり
 */ worldViewerWindow.hel_skyway_myinfo_bridge_call = ()=>{
    const procedures = getSkywayMyinfoProcedureList();
    for(let i = 0; i < procedures.length; i += 1){
        if (typeof procedures[i] === 'function') {
            procedures[i]();
        }
    }
};
/**
 * JS, HeliScriptから参照あり
 */ worldViewerWindow.hel_isLangJa = hel_isLangJa;
worldViewerWindow.hel_isMobile = hel_isMobile;
// 正規表現(大文字小文字の判別)
/**
 * HeliScriptから参照あり
 */ worldViewerWindow.hel_regex_is_halfWidth = (c)=>{
    const cStr = String.fromCharCode(c);
    if (cStr.match(/[ -~]/)) {
        return true;
    } else {
        return false;
    }
};
/**
 * HeliScriptから参照あり
 */ worldViewerWindow.hel_get_vcuid = ()=>{
    const vcuid = getVCUID();
    if (vcuid === undefined || vcuid === null) {
        return '';
    }
    return vcuid;
};
/**
 * JS, HeliScriptから参照あり
 */ worldViewerWindow.hel_toast_notify_message = (message, beginDeltaTime, stayTime, endDeltaTime)=>{
    const JSONData = {
        message: message,
        beginDeltaTime: beginDeltaTime,
        stayTime: stayTime,
        endDeltaTime: endDeltaTime
    };
    worldViewerWindow.hel_script_CallCanvasComponentMethod('toast', 'ToastViewModel', 'NotifyMessageFromJS', JSON.stringify(JSONData));
};
/**
 * JS, HeliScriptから参照あり
 */ worldViewerWindow.hel_textchat_notify_message = (param, isSystem)=>{
    if (isSystem) {
        worldViewerWindow.hel_script_CallCanvasComponentMethod('textchat_common', 'TCViewModel', 'NotifySystemMessageFromJS', param);
    } else {
        worldViewerWindow.hel_script_CallCanvasComponentMethod('textchat_common', 'TCViewModel', 'NotifyChatMessageFromJS', param);
    }
};
/**
 * JS, HeliScriptから参照あり
 */ worldViewerWindow.hel_textchat_send_message = ()=>{
    worldViewerWindow.hel_setText(ETextParamType_OnSendTextChat, '');
};
/**
 * HeliScriptのチャンネルセッションAPIで参照
 */ worldViewerWindow.hel_get_worldid = ()=>{
    return getWorldId();
};
/**
 * JSから参照有り
 */ worldViewerWindow.hel_vcc_notify_removed = ()=>{
    worldViewerWindow.hel_script_CallCanvasComponentMethod('vcc_icon_only_base', 'VCCViewModel', 'NotifyRemoved');
};
