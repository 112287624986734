function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { CItemPropertyApiNotification } from "./CItemPropertyApiNotification";
import { worldViewerWindow } from '../../const';
export class CItemProperty {
    addReceivedPropertyChangeEventListener(fn) {
        var _this_m_ItemPropertyApiNotification;
        (_this_m_ItemPropertyApiNotification = this.m_ItemPropertyApiNotification) === null || _this_m_ItemPropertyApiNotification === void 0 ? void 0 : _this_m_ItemPropertyApiNotification.addPosChannelEvent(fn);
    }
    // paramsはJsonオブジェクト
    send(params) {
        worldViewerWindow.hel_CallSceneComponent(params.ReferenceID, "ActivitySyncInterFace", "SetPropertyViaSync", JSON.stringify(params));
    }
    // コンストラクタ
    constructor(){
        _defineProperty(this, "m_ItemPropertyApiNotification", null);
        this.m_ItemPropertyApiNotification = new CItemPropertyApiNotification();
    }
}
