import { ETextParamType_AvatarFileName, ETextParamType_PlayerMoveEnable, ETextParamType_SceneItem, ETextParamType_WarpPlayer, ETextParamType_StopItem, ETextParamType_PlayEmote, ETextParamType_IsLoading, worldViewerWindow, ETextParamType_SubSceneItem, ETextParamType_GlobalFileName, ETextParamType_SetPlayerPriority } from './const';
import { getVCUID, setVCUID } from './logics/dataOps';
// UID
const hel_makeVCUID = ()=>{
    let Result = '1';
    for(let n = 1; n < 10; n++){
        Result += String(Math.floor(Math.random() * 10));
    }
    return Result;
};
/**
 * @remarks
 *
 * hel_startより呼出し有り
 */ export const hel_prepareVCUID = ()=>{
    const VCUID_CookieName = 'VCUID';
    setVCUID(worldViewerWindow.hel_get_cookie(VCUID_CookieName) || '');
    const vcuid = getVCUID();
    if (vcuid === undefined) {
        const updatedVcuid = hel_makeVCUID();
        setVCUID(updatedVcuid);
        worldViewerWindow.hel_set_cookie(VCUID_CookieName, updatedVcuid);
    }
};
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * heliodor_frontから呼出があるため、global関数としておいておく
 * @param Flag
 */ worldViewerWindow.hel_player_SetMoveEnable = (Flag)=>{
    worldViewerWindow.hel_setText(ETextParamType_PlayerMoveEnable, Flag ? '1' : '0');
};
// For Send chat message by ENTER key when IME is off.
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_front.jsにて参照
 * heliodor_frontから呼出があるため、global関数としておいておく
 * @param event
 */ worldViewerWindow.hel_send_chat_message_enterkey = (event)=>{
    if (event.keyCode === 13 && !event.isComposing) {
        worldViewerWindow.hel_skyway_send_chat_text(); // heliodor_skyway.js
        worldViewerWindow.hel_textchat_send_message();
        event.stopPropagation();
        event.preventDefault();
    }
};
// Item
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * heliodor_front.jsから参照あるためglobal関数としておいておく
 * @param ItemName
 * @param Index
 */ worldViewerWindow.hel_scene_PlayItem = (ItemName, Index)=>{
    worldViewerWindow.hel_setText(ETextParamType_SceneItem, 'Play,' + ItemName + ',' + Index);
};
/**
 * @remarks
 * @param ItemName
 */ worldViewerWindow.hel_scene_StopItem = (ItemName)=>{
    worldViewerWindow.hel_setText(ETextParamType_StopItem, ItemName);
};
/**
 * @param EmoteIndex
 */ worldViewerWindow.hel_player_PlayEmote = (EmoteIndex)=>{
    worldViewerWindow.hel_setText(ETextParamType_PlayEmote, EmoteIndex);
};
/**
 * @remarks
 * @param ItemName
 * @param MaterialName
 * @param FilePath
 */ worldViewerWindow.hel_scene_ReplaceTexture = (ItemName, MaterialName, FilePath)=>{
    worldViewerWindow.hel_setText(ETextParamType_SceneItem, 'ReplaceTexture,' + ItemName + ',' + MaterialName + ',' + FilePath);
};
/**
 * @remarks
 * @param ItemName
 * @param MaterialName
 * @param FilePath
 */ worldViewerWindow.hel_sub_scene_ReplaceTexture = (ItemName, MaterialName, FilePath)=>{
    worldViewerWindow.hel_setText(ETextParamType_SubSceneItem, 'ReplaceTexture,' + ItemName + ',' + MaterialName + ',' + FilePath);
};
/**
 * @remarks
 * @param ItemName
 * @param MaterialName
 * @param CanvasID
 */ worldViewerWindow.hel_scene_ReplaceTextureByHTMLCanvas = (ItemName, MaterialName, CanvasID)=>{
    worldViewerWindow.hel_setText(ETextParamType_SceneItem, 'ReplaceTextureByHTMLCanvas,' + ItemName + ',' + MaterialName + ',' + CanvasID);
};
/**
 * @remarks
 * @param ItemName
 * @param NodeName
 * @param Show
 */ worldViewerWindow.hel_scene_SetEnableCollider = (ItemName, NodeName, Show)=>{
    worldViewerWindow.hel_setText(ETextParamType_SceneItem, 'SetEnableCollider,' + ItemName + ',' + NodeName + ',' + Show);
};
// Player
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * frontから呼出があるため、global関数としておいておく
 * @param X
 * @param Y
 * @param Z
 * @param Angle
 */ worldViewerWindow.hel_player_Warp = (X, Y, Z, Angle)=>{
    worldViewerWindow.hel_setText(ETextParamType_WarpPlayer, '' + X + ',' + Y + ',' + Z + ',' + Angle);
};
/**
 * @remarks
 * プレイヤーの表示優先度を変更する
 * @param PlayerID
 * @param Priority
 */ worldViewerWindow.hel_player_SetPriority = (PlayerID, Priority)=>{
    worldViewerWindow.hel_setText(ETextParamType_SetPlayerPriority, PlayerID + ',' + Priority.toString());
};
/**
 * @remarks
 * XRWorldIngame->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_skyway.jsにて参照
 * XRWorldIngame->heliodor_skyway_xrw.jsにて参照
 * heliodor_front.jsから参照あるためglobal関数としておいておく
 * @param text_context
 */ worldViewerWindow.hel_remove_emoji = (text_context)=>{
    const regex_conditions = RegExp("\\p{Emoji_Modifier_Base}\\p{Emoji_Modifier}?|\\p{Emoji_Presentation}|\\p{Emoji}\\uFE0F", "gu");
    text_context.value = text_context.value.replaceAll(regex_conditions, '*');
};
/**
 * @remarks
 * アバターパッケージJSONをHELIDORに設定するための関数
 *
 * @param avatarFileName
 */ worldViewerWindow.hel_setAvatarFileName = (avatarFileName)=>{
    worldViewerWindow.hel_setText(ETextParamType_AvatarFileName, avatarFileName);
};
/**
 * @remarks
 * アバターパッケージJSONをHELIDORに設定するための関数
 *
 */ worldViewerWindow.hel_isLoading = ()=>{
    return worldViewerWindow.hel_setText(ETextParamType_IsLoading, '') !== 0;
};
worldViewerWindow.hel_setGlobalComponentFileName = (globalFileName)=>{
    worldViewerWindow.hel_setText(ETextParamType_GlobalFileName, globalFileName);
};
