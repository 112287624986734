export const worldViewerWindow = window;
export const HEL_AUDIO_TYPE_WORLD = 0;
export const HEL_AUDIO_TYPE_VOICE = 1;
export const HEL_AUDIO_TYPE_SE = 2;
export const HEL_AUDIO_TYPE_SYSTEMSE = 3;
export const HEL_AUDIO_TYPE_VIDEO = 4;
worldViewerWindow.HEL_AUDIO_TYPE_WORLD = HEL_AUDIO_TYPE_WORLD;
worldViewerWindow.HEL_AUDIO_TYPE_VOICE = HEL_AUDIO_TYPE_VOICE;
worldViewerWindow.HEL_AUDIO_TYPE_SE = HEL_AUDIO_TYPE_SE;
worldViewerWindow.HEL_AUDIO_TYPE_SYSTEMSE = HEL_AUDIO_TYPE_SYSTEMSE;
worldViewerWindow.HEL_AUDIO_TYPE_VIDEO = HEL_AUDIO_TYPE_VIDEO;
export const HEL_VIDEO_PLAYER_DEFAULT = 0;
export const HEL_VIDEO_PLAYER_IVS = 1;
export const HEL_VIDEO_PLAYER_HLS = 2;
worldViewerWindow.HEL_VIDEO_PLAYER_DEFAULT = HEL_VIDEO_PLAYER_DEFAULT;
worldViewerWindow.HEL_VIDEO_PLAYER_IVS = HEL_VIDEO_PLAYER_IVS;
worldViewerWindow.HEL_VIDEO_PLAYER_HLS = HEL_VIDEO_PLAYER_HLS;
export const ETextParamType_WorldID = 0;
export const ETextParamType_RoomID = 1;
export const ETextParamType_SpotName = 2;
export const ETextParamType_LocationHref = 3;
export const ETextParamType_ShowWorldSelect = 4;
export const ETextParamType_Logined = 5;
export const ETextParamType_CanvasFileName = 6;
export const ETextParamType_CurrentUserName = 7;
export const ETextParamType_SceneItem = 8;
export const ETextParamType_OperateCanvas = 9;
export const ETextParamType_ReplaceMyAvatar = 10;
export const ETextParamType_PlayVideo = 11;
export const ETextParamType_NamePlateTextureFileName0 = 12;
export const ETextParamType_NamePlateTextureFileName1 = 13;
export const ETextParamType_NamePlateTextureFileName2 = 14;
export const ETextParamType_NamePlateMicMuteTextureFileName = 15;
export const ETextParamType_NamePlateMicOnTextureFileName = 16;
export const ETextParamType_NamePlateMicTalkingTextureFileName = 17;
export const ETextParamType_SetMicState = 18;
export const ETextParamType_OperateFunctionFlag = 19;
export const ETextParamType_CopyVideoFrameToSystem = 20;
export const ETextParamType_CancelToCopyVideoFrameToSystem = 21;
export const ETextParamType_SetMicTalkingState = 22;
export const ETextParamType_WarpPlayer = 23;
export const ETextParamType_PlayerMoveEnable = 24;
export const ETextParamType_PlayItem = 25;
export const ETextParamType_StopItem = 26;
export const ETextParamType_ShowItem = 27;
export const ETextParamType_HideItem = 28;
export const ETextParamType_ReplaceMyAvatarCustom = 29;
export const ETextParamType_SceneJson = 30;
export const ETextParamType_ScriptCallComponentMethod = 31;
export const ETextParamType_AvatarFileName = 32;
export const ETextParamType_GUIDebugInput = 33;
export const ETextParamType_HeliodorSDKVersion = 34;
export const ETextParamType_ReplaceMyAvatarIcon = 35;
export const ETextParamType_SetItemFileName = 36;
export const ETextParamType_SetFollowCameraDistance = 37;
export const ETextParamType_SetFollowCameraRotate = 38;
export const ETextParamType_SetFollowCameraHeight = 39;
export const ETextParamType_AddFurniture = 40;
export const ETextParamType_SetPlayerRotate = 41;
export const ETextParamType_PlayEmote = 42;
export const ETextParamType_GetAvatarHeight = 43;
export const ETextParamType_StopVideo = 44;
export const ETextParamType_IsPlayVideo = 45;
export const ETextParamType_ReplaceMyAvatarIconCustom = 46;
export const ETextParamType_IsLoading = 47;
export const ETextParamType_SubSceneItem = 48;
export const ETextParamType_SceneOwner = 49;
export const ETextParamType_VideoEnded = 50;
export const ETextParamType_GlobalFileName = 51;
export const ETextParamType_CheckRestriction = 52;
export const ETextParamType_ReplaceFilteredAvatar = 53;
export const ETextParamType_ClearMyAvatarCustomMotion = 54;
export const ETextParamType_CreateMyAvatarCustomMotion = 55;
export const ETextParamType_CreateMyAvatarCustomEmotion = 56;
export const ETextParamType_CreateMyAvatarCustomObject = 57;
export const ETextParamType_SetDateNow = 58;
export const ETextParamType_SetCRPMode = 59;
export const ETextParamType_SetPlayerPriority = 60;
export const ETextParamType_OnStartScreenShare = 61;
export const ETextParamType_OnStopScreenShare = 62;
export const ETextParamType_OnReceivedStartScreenShare = 63;
export const ETextParamType_OnReceivedStopScreenShare = 64;
export const ETextParamType_SetVideoRecording = 65;
export const ETextParamType_SetRoomURL = 66;
export const ETextParamType_IsUseGamePad = 67;
export const ETextParamType_ScriptCallCanvasComponentMethod = 68;
export const ETextParamType_HomeJson = 69;
export const ETextParamType_HomeJsonWithTemplate = 70;
export const ETextParamType_SetGenericWindowState = 71;
export const ETextParamType_OnChangedMicPermissionState = 72;
export const ETextParamType_IsUseMyAvatar = 73;
export const ETextParamType_OnPlayScreenShare = 74;
export const ETextParamType_CompImageListFileName = 75;
export const ETextParamType_SetItemProperty = 76;
export const ETextParamType_OnSendTextChat = 77;
export const ETextParamType_OnNetCreatedPlayerID = 78;
export const ETextParamType_OnNetPosConnected = 79;
export const ETextParamType_OnNetVoiceConnected = 80;
export const ETextParamType_GetFirstLoadingRate = 81;
export const ETextParamType_OnNetPosDisconnected = 82;
export const ETextParamType_OnNetVoiceDisconnected = 83;
worldViewerWindow.ETextParamType_WorldID = ETextParamType_WorldID;
worldViewerWindow.ETextParamType_RoomID = ETextParamType_RoomID;
worldViewerWindow.ETextParamType_SpotName = ETextParamType_SpotName;
worldViewerWindow.ETextParamType_LocationHref = ETextParamType_LocationHref;
worldViewerWindow.ETextParamType_ShowWorldSelect = ETextParamType_ShowWorldSelect;
worldViewerWindow.ETextParamType_Logined = ETextParamType_Logined;
worldViewerWindow.ETextParamType_CanvasFileName = ETextParamType_CanvasFileName;
worldViewerWindow.ETextParamType_CurrentUserName = ETextParamType_CurrentUserName;
worldViewerWindow.ETextParamType_SceneItem = ETextParamType_SceneItem;
worldViewerWindow.ETextParamType_OperateCanvas = ETextParamType_OperateCanvas;
worldViewerWindow.ETextParamType_ReplaceMyAvatar = ETextParamType_ReplaceMyAvatar;
worldViewerWindow.ETextParamType_PlayVideo = ETextParamType_PlayVideo;
worldViewerWindow.ETextParamType_NamePlateTextureFileName0 = ETextParamType_NamePlateTextureFileName0;
worldViewerWindow.ETextParamType_NamePlateTextureFileName1 = ETextParamType_NamePlateTextureFileName1;
worldViewerWindow.ETextParamType_NamePlateTextureFileName2 = ETextParamType_NamePlateTextureFileName2;
worldViewerWindow.ETextParamType_NamePlateMicMuteTextureFileName = ETextParamType_NamePlateMicMuteTextureFileName;
worldViewerWindow.ETextParamType_NamePlateMicOnTextureFileName = ETextParamType_NamePlateMicOnTextureFileName;
worldViewerWindow.ETextParamType_NamePlateMicTalkingTextureFileName = ETextParamType_NamePlateMicTalkingTextureFileName;
worldViewerWindow.ETextParamType_SetMicState = ETextParamType_SetMicState;
worldViewerWindow.ETextParamType_OperateFunctionFlag = ETextParamType_OperateFunctionFlag;
worldViewerWindow.ETextParamType_CopyVideoFrameToSystem = ETextParamType_CopyVideoFrameToSystem;
worldViewerWindow.ETextParamType_CancelToCopyVideoFrameToSystem = ETextParamType_CancelToCopyVideoFrameToSystem;
worldViewerWindow.ETextParamType_SetMicTalkingState = ETextParamType_SetMicTalkingState;
worldViewerWindow.ETextParamType_WarpPlayer = ETextParamType_WarpPlayer;
worldViewerWindow.ETextParamType_PlayerMoveEnable = ETextParamType_PlayerMoveEnable;
worldViewerWindow.ETextParamType_PlayItem = ETextParamType_PlayItem;
worldViewerWindow.ETextParamType_StopItem = ETextParamType_StopItem;
worldViewerWindow.ETextParamType_ShowItem = ETextParamType_ShowItem;
worldViewerWindow.ETextParamType_HideItem = ETextParamType_HideItem;
worldViewerWindow.ETextParamType_ReplaceMyAvatarCustom = ETextParamType_ReplaceMyAvatarCustom;
worldViewerWindow.ETextParamType_SceneJson = ETextParamType_SceneJson;
worldViewerWindow.ETextParamType_ScriptCallComponentMethod = ETextParamType_ScriptCallComponentMethod;
worldViewerWindow.ETextParamType_AvatarFileName = ETextParamType_AvatarFileName;
worldViewerWindow.ETextParamType_GUIDebugInput = ETextParamType_GUIDebugInput;
worldViewerWindow.ETextParamType_HeliodorSDKVersion = ETextParamType_HeliodorSDKVersion;
worldViewerWindow.ETextParamType_ReplaceMyAvatarIcon = ETextParamType_ReplaceMyAvatarIcon;
worldViewerWindow.ETextParamType_SetItemFileName = ETextParamType_SetItemFileName;
worldViewerWindow.ETextParamType_SetFollowCameraDistance = ETextParamType_SetFollowCameraDistance;
worldViewerWindow.ETextParamType_SetFollowCameraRotate = ETextParamType_SetFollowCameraRotate;
worldViewerWindow.ETextParamType_SetFollowCameraHeight = ETextParamType_SetFollowCameraHeight;
worldViewerWindow.ETextParamType_AddFurniture = ETextParamType_AddFurniture;
worldViewerWindow.ETextParamType_SetPlayerRotate = ETextParamType_SetPlayerRotate;
worldViewerWindow.ETextParamType_PlayEmote = ETextParamType_PlayEmote;
worldViewerWindow.ETextParamType_GetAvatarHeight = ETextParamType_GetAvatarHeight;
worldViewerWindow.ETextParamType_StopVideo = ETextParamType_StopVideo;
worldViewerWindow.ETextParamType_IsPlayVideo = ETextParamType_IsPlayVideo;
worldViewerWindow.ETextParamType_ReplaceMyAvatarIconCustom = ETextParamType_ReplaceMyAvatarIconCustom;
worldViewerWindow.ETextParamType_IsLoading = ETextParamType_IsLoading;
worldViewerWindow.ETextParamType_SubSceneItem = ETextParamType_SubSceneItem;
worldViewerWindow.ETextParamType_SceneOwner = ETextParamType_SceneOwner;
worldViewerWindow.ETextParamType_VideoEnded = ETextParamType_VideoEnded;
worldViewerWindow.ETextParamType_GlobalFileName = ETextParamType_GlobalFileName;
worldViewerWindow.ETextParamType_CheckRestriction = ETextParamType_CheckRestriction;
worldViewerWindow.ETextParamType_ReplaceFilteredAvatar = ETextParamType_ReplaceFilteredAvatar;
worldViewerWindow.ETextParamType_ClearMyAvatarCustomMotion = ETextParamType_ClearMyAvatarCustomMotion;
worldViewerWindow.ETextParamType_CreateMyAvatarCustomMotion = ETextParamType_CreateMyAvatarCustomMotion;
worldViewerWindow.ETextParamType_CreateMyAvatarCustomEmotion = ETextParamType_CreateMyAvatarCustomEmotion;
worldViewerWindow.ETextParamType_CreateMyAvatarCustomObject = ETextParamType_CreateMyAvatarCustomObject;
worldViewerWindow.ETextParamType_SetDateNow = ETextParamType_SetDateNow;
worldViewerWindow.ETextParamType_SetCRPMode = ETextParamType_SetCRPMode;
worldViewerWindow.ETextParamType_SetPlayerPriority = ETextParamType_SetPlayerPriority;
worldViewerWindow.ETextParamType_OnStartScreenShare = ETextParamType_OnStartScreenShare;
worldViewerWindow.ETextParamType_OnStopScreenShare = ETextParamType_OnStopScreenShare;
worldViewerWindow.ETextParamType_OnReceivedStartScreenShare = ETextParamType_OnReceivedStartScreenShare;
worldViewerWindow.ETextParamType_OnReceivedStopScreenShare = ETextParamType_OnReceivedStopScreenShare;
worldViewerWindow.ETextParamType_SetVideoRecording = ETextParamType_SetVideoRecording;
worldViewerWindow.ETextParamType_SetRoomURL = ETextParamType_SetRoomURL;
worldViewerWindow.ETextParamType_IsUseGamePad = ETextParamType_IsUseGamePad;
worldViewerWindow.ETextParamType_ScriptCallCanvasComponentMethod = ETextParamType_ScriptCallCanvasComponentMethod;
worldViewerWindow.ETextParamType_HomeJson = ETextParamType_HomeJson;
worldViewerWindow.ETextParamType_HomeJsonWithTemplate = ETextParamType_HomeJsonWithTemplate;
worldViewerWindow.ETextParamType_SetGenericWindowState = ETextParamType_SetGenericWindowState;
worldViewerWindow.ETextParamType_OnChangedMicPermissionState = ETextParamType_OnChangedMicPermissionState;
worldViewerWindow.ETextParamType_IsUseMyAvatar = ETextParamType_IsUseMyAvatar;
worldViewerWindow.ETextParamType_OnPlayScreenShare = ETextParamType_OnPlayScreenShare;
worldViewerWindow.ETextParamType_CompImageListFileName = ETextParamType_CompImageListFileName;
worldViewerWindow.ETextParamType_SetItemProperty = ETextParamType_SetItemProperty;
worldViewerWindow.ETextParamType_OnSendTextChat = ETextParamType_OnSendTextChat;
worldViewerWindow.ETextParamType_OnNetCreatedPlayerID = ETextParamType_OnNetCreatedPlayerID;
worldViewerWindow.ETextParamType_OnNetPosConnected = ETextParamType_OnNetPosConnected;
worldViewerWindow.ETextParamType_OnNetVoiceConnected = ETextParamType_OnNetVoiceConnected;
worldViewerWindow.ETextParamType_GetFirstLoadingRate = ETextParamType_GetFirstLoadingRate;
worldViewerWindow.ETextParamType_OnNetPosDisconnected = ETextParamType_OnNetPosDisconnected;
worldViewerWindow.ETextParamType_OnNetVoiceDisconnected = ETextParamType_OnNetVoiceDisconnected;
//
export const EBinaryParamType_FullBodyTrackingData = 0;
export const EBinaryParamType_Unlink = 1;
export const EBinaryParamType_SetGUIImage = 2;
worldViewerWindow.EBinaryParamType_FullBodyTrackingData = EBinaryParamType_FullBodyTrackingData;
worldViewerWindow.EBinaryParamType_Unlink = EBinaryParamType_Unlink;
worldViewerWindow.EBinaryParamType_SetGUIImage = EBinaryParamType_SetGUIImage;
//
export const MOBILE_USER_AGENT_REGS = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
export const IPHONE_USER_AGENT_REGS = /iPhone|iPod/i;
export const IOS_VER_15 = 1500;
export const INITIAL_MIXER_SETTING = [
    0.2,
    1.0,
    0.2,
    0.2,
    0.2
];
export const INITIAL_MIXER_REAL_SETTING = [
    0.2,
    1.0,
    0.2,
    0.2,
    0.2
];
export const INITIAL_MIXER_FADER_SETTING = [
    1.0,
    1.0,
    1.0,
    1.0,
    1.0
];
export const INITIAL_MIXER_DISTANCE_SETTING = [
    1.0,
    1.0,
    1.0,
    1.0,
    1.0
];
export const INITIAL_MIXER_MUTE_SETTING = [
    0,
    0,
    0,
    0,
    0
];
export const SKYWAY_API_HOST = 'https://v1.api.vketcloud.com/';
