import { worldViewerWindow } from './const';
import { debugLog } from './debugLog';
import { getTextureExtOnAstc, getTextureExtOnETC2, getTextureExtOnPvrtc, getWebGL10Toggle, setTextureExtOnAstc, getTextureAstcToggle, setTextureAstcToggle, setTextureExtOnETC2, getTextureETC2Toggle, setTextureETC2Toggle, setTextureExtOnPvrtc, setWebGL10Toggle, setTextureExtOnDxt, getTextureExtOnDxt, getTextureDxtToggle, setTextureDxtToggle, getTexturePvrtcToggle, setTexturePvrtcToggle, setExtShaderTextureLod, setExtShaderTextureLodToggle, setExtColorBufferFloat, setExtColorBufferHalfFloat, setExtColorBufferToggle, setOesTextureHalfFloat, setOesTextureHalfFloatToggle, getExtShaderTextureLod, getExtColorBufferFloat, getExtColorBufferHalfFloat, getOesTextureHalfFloat, setOesTextureFloatLinear, getOesTextureFloatLinear, setOesTextureFloatLinearToggle, getOesTextureFloat, setOesTextureFloat, setOesTextureFloatToggle, setWebglDepthTexture, getWebglDepthTexture, setWebglDepthTextureToggle, getExtTextureFilterAnisotropic, setExtTextureFilterAnisotropic, setExtTextureFilterAnisotropicToggle, setOesStandardDerivatives, getOesStandardDerivatives, setOesStandardDerivativesToggle, setOesTextureHalfFloatLinear, getOesTextureHalfFloatLinear, setOesTextureHalfFloatLinearToggle, getAngleInstancedArrays, setAngleInstancedArrays, setAngleInstancedArraysToggle, setWebGLDebugRendererInfo, getWebGLDebugRendererInfo, setWebGLDebugRendererInfoToggle, setUnmaskedRendererWebGL, setUnmaskedVendorWebGL } from './logics/dataOps';
import { getMainCanvasDOMElement, setWebGLRenderingContext } from './logics/domOps';
import { defineYourRenderTypes, hel_isiPad, hel_isAndroid, hel_isChrome, hel_getChromeVersion, hel_getiOSVersion, hel_getMacOSVersion } from './logics/platform';
const hel_check_WEBGL_debug_renderer_info = (context)=>{
    setWebGLDebugRendererInfo(context.getExtension('WEBGL_debug_renderer_info'));
    const info = getWebGLDebugRendererInfo();
    if (info !== undefined) {
        setUnmaskedRendererWebGL(context.getParameter(info.UNMASKED_RENDERER_WEBGL));
        setUnmaskedVendorWebGL(context.getParameter(info.UNMASKED_VENDOR_WEBGL));
    }
    return info !== undefined;
};
const hel_checkPVRTC = (context)=>{
    setTextureExtOnPvrtc(context.getExtension('WEBGL_compressed_texture_pvrtc') || context.getExtension('WEBKIT_WEBGL_compressed_texture_pvrtc'));
    const pvrtc = getTextureExtOnPvrtc();
    if (pvrtc === undefined) {
        return false;
    }
    const supportedCompressedTextureFormat = context.getParameter(context.COMPRESSED_TEXTURE_FORMATS);
    if (!supportedCompressedTextureFormat) {
        return false;
    }
    const supportsPVRTC_rgb = supportedCompressedTextureFormat.includes(pvrtc.COMPRESSED_RGB_PVRTC_4BPPV1_IMG);
    if (!supportsPVRTC_rgb) {
        return false;
    }
    return supportedCompressedTextureFormat.includes(pvrtc.COMPRESSED_RGBA_PVRTC_4BPPV1_IMG);
};
const hel_checkETC2 = (context)=>{
    setTextureExtOnETC2(context.getExtension('WEBGL_compressed_texture_etc') || context.getExtension('WEBKIT_WEBGL_compressed_texture_etc'));
    const etc2 = getTextureExtOnETC2();
    if (etc2 === undefined) {
        return false;
    }
    const supportedCompressedTextureFormat = context.getParameter(context.COMPRESSED_TEXTURE_FORMATS);
    if (!supportedCompressedTextureFormat) {
        return false;
    }
    const supportsETC2_rgb = supportedCompressedTextureFormat.includes(etc2.COMPRESSED_RGB8_ETC2);
    if (!supportsETC2_rgb) {
        return false;
    }
    return supportedCompressedTextureFormat.includes(etc2.COMPRESSED_RGBA8_ETC2_EAC);
};
const hel_checkASTC = (context)=>{
    setTextureExtOnAstc(context.getExtension('WEBGL_compressed_texture_astc') || context.getExtension('WEBKIT_WEBGL_compressed_texture_astc'));
    const astc = getTextureExtOnAstc();
    if (astc === undefined) {
        return false;
    }
    const supportedCompressedTextureFormat = context.getParameter(context.COMPRESSED_TEXTURE_FORMATS);
    if (!supportedCompressedTextureFormat) {
        return false;
    }
    return supportedCompressedTextureFormat.includes(astc.COMPRESSED_SRGB8_ALPHA8_ASTC_4x4_KHR);
};
const hel_checkDXT = (context)=>{
    setTextureExtOnDxt(context.getExtension('EXT_texture_compression_bptc'));
    const dxt = getTextureExtOnDxt();
    if (dxt === undefined) {
        return false;
    }
    const supportedCompressedTextureFormat = context.getParameter(context.COMPRESSED_TEXTURE_FORMATS);
    if (!supportedCompressedTextureFormat) {
        return false;
    }
    const supportsDXT_UNORM = supportedCompressedTextureFormat.includes(dxt.COMPRESSED_RGBA_BPTC_UNORM_EXT);
    if (!supportsDXT_UNORM) {
        return false;
    }
    return supportedCompressedTextureFormat.includes(dxt.COMPRESSED_SRGB_ALPHA_BPTC_UNORM_EXT);
};
// Shader Extension
const hel_check_EXT_shader_texture_lod = (context)=>{
    setExtShaderTextureLod(context.getExtension('EXT_shader_texture_lod'));
    const lod = getExtShaderTextureLod();
    return lod !== undefined;
};
const hel_check_OES_standard_derivatives = (context)=>{
    setOesStandardDerivatives(context.getExtension('OES_standard_derivatives'));
    const drv = getOesStandardDerivatives();
    return drv !== undefined;
};
// Texture Extension
const hel_check_EXT_color_buffer = (context)=>{
    // 先にfloatから調べて次にhalf floatを調べる
    // 両方ともだめであればFloat Bufferは使用できないものとする
    setExtColorBufferFloat(context.getExtension('EXT_color_buffer_float'));
    setExtColorBufferHalfFloat(context.getExtension('EXT_color_buffer_half_float'));
    const UseFloatBuffer = getExtColorBufferFloat() !== undefined || getExtColorBufferHalfFloat() !== undefined;
    return UseFloatBuffer;
};
const hel_check_WEBGL_depth_texture = (context)=>{
    setWebglDepthTexture(context.getExtension('WEBGL_depth_texture'));
    const depthTexture = getWebglDepthTexture();
    return depthTexture !== undefined;
};
const hel_check_OES_texture_float = (context)=>{
    setOesTextureFloat(context.getExtension('OES_texture_float'));
    const float = getOesTextureFloat();
    return float !== undefined;
};
const hel_check_OES_texture_half_float = (context)=>{
    setOesTextureHalfFloat(context.getExtension('OES_texture_half_float'));
    const halfFloat = getOesTextureHalfFloat();
    return halfFloat !== undefined;
};
const hel_check_OES_texture_float_linear = (context)=>{
    setOesTextureFloatLinear(context.getExtension('OES_texture_float_linear'));
    const floatLinear = getOesTextureFloatLinear();
    return floatLinear !== undefined;
};
const hel_check_OES_texture_half_float_linear = (context)=>{
    setOesTextureHalfFloatLinear(context.getExtension('OES_texture_half_float_linear'));
    const halfFloatLinear = getOesTextureHalfFloatLinear();
    return halfFloatLinear !== undefined;
};
const hel_check_EXT_texture_filter_anisotropic = (context)=>{
    setExtTextureFilterAnisotropic(context.getExtension('EXT_texture_filter_anisotropic'));
    const filterAnisotropic = getExtTextureFilterAnisotropic();
    return filterAnisotropic !== undefined;
};
const hel_check_ANGLE_instanced_arrays = (context)=>{
    setAngleInstancedArrays(context.getExtension('ANGLE_instanced_arrays'));
    const instancedArrays = getAngleInstancedArrays();
    return instancedArrays !== undefined;
};
/**
 * @remarks
 * GLを描画
 * heliodor.js, C++から呼出あり
 * @param majorVersion
 */ worldViewerWindow.initGL = (majorVersion)=>{
    const mainCanvas = getMainCanvasDOMElement();
    if (mainCanvas === undefined) {
        debugLog('canvas is null');
        return 0;
    }
    if (majorVersion === 1) setWebGL10Toggle(true);
    // iOS/iPadOS/MacOS 16.4以降でZ-Fightingが発生するようになり、WebGL2.0を使用すると直ったので、
    // 16.4以降は常にWebGL2.0を使うことにした。
    if (worldViewerWindow.hel_isiPhone() && !hel_isiPad()) {
        const iOSVer = hel_getiOSVersion();
        console.log('iOS Ver : ' + iOSVer.toString());
        if (iOSVer < 1604) {
            setWebGL10Toggle(true);
        }
    }
    if (hel_isiPad() || worldViewerWindow.hel_isMac()) {
        const MacVer = hel_getMacOSVersion();
        console.log('MacOS Ver : ' + MacVer.toString());
        // MacChromeではMacVer==0で返るので、その場合はWebGL2.0を使用する
        if (MacVer > 0 && MacVer < 1604) {
            setWebGL10Toggle(true);
        }
    }
    if (hel_isChrome()) {
        const ChromeVer = hel_getChromeVersion();
        console.log('Chrome Ver : ' + ChromeVer);
        if ((hel_isAndroid() || worldViewerWindow.hel_isMac()) && ChromeVer >= 120) {
            // Android/MacOS Chrome 120以降でWebGL2.0を使用すると画面が壊れるようになったので、
            // WebGL1.0を使用するようにする
            setWebGL10Toggle(true);
        }
    }
    //
    const glAttribs = {
        alpha: 0,
        premultipliedAlpha: 0,
        depth: true,
        majorVersion,
        minorVersion: 0,
        enableExtensionsByDefault: 1,
        antialias: true
    };
    let webglctx = null;
    if (!getWebGL10Toggle()) {
        glAttribs.antialias = false;
        webglctx = mainCanvas.getContext('webgl2', glAttribs);
    }
    if (!webglctx) {
        setWebGL10Toggle(true);
        glAttribs.majorVersion = 1;
        for (const contextType of defineYourRenderTypes()){
            webglctx = mainCanvas.getContext(contextType, glAttribs);
            if (webglctx) break;
        }
    }
    if (!webglctx) {
        debugLog('webgl is null');
        return 0;
    }
    const glctx = GL.registerContext(webglctx, glAttribs);
    if (!glctx) {
        debugLog('glctx is null');
        return 0;
    }
    GL.makeContextCurrent(glctx);
    // Debug
    setWebGLDebugRendererInfoToggle(hel_check_WEBGL_debug_renderer_info(webglctx));
    // TextureCompression
    setTexturePvrtcToggle(hel_checkPVRTC(webglctx));
    setTextureETC2Toggle(hel_checkETC2(webglctx));
    setTextureAstcToggle(hel_checkASTC(webglctx));
    setTextureDxtToggle(hel_checkDXT(webglctx));
    // Shader Extension
    setExtShaderTextureLodToggle(hel_check_EXT_shader_texture_lod(webglctx));
    setOesStandardDerivativesToggle(hel_check_OES_standard_derivatives(webglctx));
    // Texture Extension
    setExtColorBufferToggle(hel_check_EXT_color_buffer(webglctx));
    setOesTextureHalfFloatToggle(hel_check_OES_texture_half_float(webglctx));
    setOesTextureFloatLinearToggle(hel_check_OES_texture_float_linear(webglctx));
    setOesTextureHalfFloatLinearToggle(hel_check_OES_texture_half_float_linear(webglctx));
    setOesTextureFloatToggle(hel_check_OES_texture_float(webglctx));
    setWebglDepthTextureToggle(hel_check_WEBGL_depth_texture(webglctx));
    setExtTextureFilterAnisotropicToggle(hel_check_EXT_texture_filter_anisotropic(webglctx));
    setAngleInstancedArraysToggle(hel_check_ANGLE_instanced_arrays(webglctx));
    setWebGLRenderingContext(webglctx);
    return 1;
};
/**
 *
 */ worldViewerWindow.hel_isSupported_ASTC = ()=>{
    return getTextureAstcToggle();
};
/**
 *
 */ worldViewerWindow.hel_isSupported_ETC2 = ()=>{
    return getTextureETC2Toggle();
};
/**
 *
 */ worldViewerWindow.hel_isSupported_DXT = ()=>{
    return getTextureDxtToggle();
};
/**
 *
 */ worldViewerWindow.hel_isSupported_PVRTC = ()=>{
    return getTexturePvrtcToggle();
};
