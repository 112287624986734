function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { CTextChatApiNotification } from "./CTextChatApiNotification";
import { worldViewerWindow } from '../../const';
export class CTextChat {
    addReceivedChatEventListener(fn) {
        var _this_m_TextChatApiNotification;
        (_this_m_TextChatApiNotification = this.m_TextChatApiNotification) === null || _this_m_TextChatApiNotification === void 0 ? void 0 : _this_m_TextChatApiNotification.addPosChannelEvent(fn);
    }
    addReceivedSystemChatEventListener(fn) {
        var _this_m_TextChatApiNotification;
        (_this_m_TextChatApiNotification = this.m_TextChatApiNotification) === null || _this_m_TextChatApiNotification === void 0 ? void 0 : _this_m_TextChatApiNotification.addSystemEvent(fn);
    }
    // paramsはJsonオブジェクト
    send(params) {
        worldViewerWindow.hel_script_CallCanvasComponentMethod('textchat_common', 'TCViewModel', 'ReceiveTextChatMessage', JSON.stringify(params));
    }
    // コンストラクタ
    constructor(){
        _defineProperty(this, "m_TextChatApiNotification", null);
        this.m_TextChatApiNotification = new CTextChatApiNotification();
    }
}
