import { CHeliNetworkCallback } from './CHeliNetworkCallback';
import { CHeliCarnelianAgora } from './carnelianagoralib/CHeliCarnelianAgora';
import { CHeliSkyway } from './skywaylib/CHeliSkyway';
let g_pIHeliNetwork = null;
export const CreateHeliNetwork = (type)=>{
    switch(type){
        case 'skyway':
            SetIHeliNetwork(new CHeliSkyway());
            break;
        case 'carnelian_agora':
            SetIHeliNetwork(new CHeliCarnelianAgora());
            break;
        default:
            console.log('CreateHeliNetwork type error : ' + type);
            return;
    }
    console.log('CreateHeliNetwork : ' + type);
};
const SetIHeliNetwork = (pIHeliNetwork)=>{
    g_pIHeliNetwork = pIHeliNetwork;
    g_pIHeliNetwork.setCallback(new CHeliNetworkCallback());
};
export const GetIHeliNetwork = ()=>{
    return g_pIHeliNetwork;
};
