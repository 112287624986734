function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { worldViewerWindow } from './const';
import { debugLog } from './debugLog';
import { getMainCanvasDOMElement } from './logics/domOps';
/**
 * @remarks
 * C++, heliodor.jsから呼出があるためglobal関数
 *
 * @param visible
 */ worldViewerWindow.hel_video_set_movieviewer_ui = (visible)=>{
    // ※スライダーの表示・非表示ははCPPで制御してます。
    // ※スライダーも同時に表示制御したい場合は、 heliodor_front.js の
    // hel_video_set_movieviewer_ui_all() を使う事。
    // console.log("*** hel_video_set_movieviewer_ui(" + visible + ")");
    // 停止中？
    const paused = worldViewerWindow.hel_video_is_paused();
    // --------------------------------------------
    // movieviewer/_po.json の Toggle を設定する。
    // (1)一旦デフォルト値にする（＝movieviewer/_po.jsonのToggle値にする）
    worldViewerWindow.hel_canvas_ResetToggleDefault('IsVisible_VideoPlayerCurrentTime'); // true
    worldViewerWindow.hel_canvas_ResetToggleDefault('IsVisible_VideoPlayerTotalTime'); // true
    worldViewerWindow.hel_canvas_ResetToggleDefault('IsVisible_hud_movie_play'); // false
    worldViewerWindow.hel_canvas_ResetToggleDefault('IsVisible_hud_movie_pause'); // true
    worldViewerWindow.hel_canvas_ResetToggleDefault('IsVisible_hud_movie_forward'); // true
    worldViewerWindow.hel_canvas_ResetToggleDefault('IsVisible_hud_movie_rewind'); // true
    // (2)再設定
    if (visible) {
        // 表示させる
        if (paused) {
            // ポーズ中ならデフォルト値を反転
            worldViewerWindow.hel_canvas_ToggleChange('IsVisible_hud_movie_play'); // false => true
            worldViewerWindow.hel_canvas_ToggleChange('IsVisible_hud_movie_pause'); // true => false
        }
    } else {
        // 表示させない
        worldViewerWindow.hel_canvas_ToggleChange('IsVisible_VideoPlayerCurrentTime'); // true => false
        worldViewerWindow.hel_canvas_ToggleChange('IsVisible_VideoPlayerTotalTime'); // true => false
        worldViewerWindow.hel_canvas_ToggleChange('IsVisible_hud_movie_pause'); // true => false
        worldViewerWindow.hel_canvas_ToggleChange('IsVisible_hud_movie_forward'); // true => false
        worldViewerWindow.hel_canvas_ToggleChange('IsVisible_hud_movie_rewind'); // true => false
    }
    // -----------------------------------------
    // 横画面
    let layout_name = 'movieviewer';
    worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'VideoPlayerCurrentTime', visible);
    worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'VideoPlayerTotalTime', visible);
    if (paused) {
        worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_play', visible);
        worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_pause', false);
    } else {
        worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_play', false);
        worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_pause', visible);
    }
    worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_forward', visible);
    worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_rewind', visible);
    // 縦画面
    layout_name = 'movieviewer_po';
    worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'VideoPlayerCurrentTime', visible);
    worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'VideoPlayerTotalTime', visible);
    if (paused) {
        worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_play', visible);
        worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_pause', false);
    } else {
        worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_play', false);
        worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_pause', visible);
    }
    worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_forward', visible);
    worldViewerWindow.hel_canvas_SetGUIShow(layout_name, 'hud_movie_rewind', visible);
};
/**
 * @remarks
 * C++から呼び出される
 * スクリーンショット
 */ worldViewerWindow.hel_screenshot = ()=>{
    const mainCanvas = getMainCanvasDOMElement();
    if (mainCanvas === undefined) {
        debugLog('[ hel_screenshot ] mainCanvas is not assigned. noop');
        return;
    }
    // iPhoneでのスクショ
    // 通常の方法でスクショを撮影するとSkywayが切断されてしまうため、このようにしている
    if (worldViewerWindow.hel_isiPhone() && mainCanvas !== undefined) {
        window.localStorage['ssBase64'] = mainCanvas.toDataURL('image/jpeg');
        modalSS.show();
        return;
    }
    // その他のデバイスでのスクショ
    mainCanvas.toBlob((blob)=>{
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        const today = new Date();
        a.download = '' + today.getFullYear() + ('00' + (today.getMonth() + 1)).slice(-2) + ('00' + today.getDate()).slice(-2) + '_' + ('00' + today.getHours()).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2) + '.png';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    });
};
// URL
/**
 * C++から参照されてる
 * @param url
 */ worldViewerWindow.hel_openPage = (url)=>{
    // 新規タブを開く
    // iOSでは非同期処理中にopenが使用できないのでその時はページ遷移を行う
    if (!worldViewerWindow.open(url, '_blank')) {
        worldViewerWindow.location.href = url;
    }
};
worldViewerWindow.hel_transitionToPage = (url)=>{
    // ページ遷移
    worldViewerWindow.location.href = url;
};
/**
 * HeliScriptから参照
 */ worldViewerWindow.hel_set_HTMLElement_value = (elementName, value)=>{
    const element = document.getElementById(elementName);
    if (element !== undefined && element != null) {
        element.value = value;
    }
};
/**
 * HeliScriptから参照
 */ worldViewerWindow.hel_get_HTMLElement_value = (elementName)=>{
    const element = document.getElementById(elementName);
    if (element !== undefined && element != null) {
        const value = element.value;
        return value !== undefined && value !== null ? value : '';
    }
    return '';
};
/**
 * UUID
 * HeliScriptから参照
 */ worldViewerWindow.hel_get_randomUUID = ()=>{
    return self.crypto.randomUUID();
};
// ScreenShot Class
class ModalSS {
    show() {
        if (this.container !== null) this.container.style.visibility = 'visible';
    }
    hide() {
        if (this.container !== null) this.container.style.visibility = 'hidden';
    }
    constructor(){
        _defineProperty(this, "container", void 0);
        _defineProperty(this, "btnOpenSS", void 0);
        _defineProperty(this, "btnClose", void 0);
        //
        this.container = document.querySelector('#__ss_modal');
        //
        this.btnOpenSS = document.querySelector('#__ss_modal-dialog-btn_open_ss');
        if (this.btnOpenSS !== null) {
            this.btnOpenSS.addEventListener('click', ()=>{
                this.hide();
            });
        }
        //
        this.btnClose = document.querySelector('#__ss_modal-dialog-btn_close');
        if (this.btnClose !== null) {
            this.btnClose.addEventListener('click', ()=>{
                this.hide();
            });
        }
    }
}
const modalSS = new ModalSS();
