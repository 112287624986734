function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import Peer from 'skyway-js';
import { HEL_AUDIO_TYPE_VOICE, worldViewerWindow } from '../../const';
import { debugLog } from '../../debugLog';
import { helcore_video_play } from '../../heliodor_core_video_callee';
import { g_useVideoStreamFlag, videoStreams } from '../../heliodor_skyway';
import { closeLocalStream, getLocalStream, getMixerRealSettingAt, getSkyRoomId, getSkywayKey, getSkywayName, getSkywayOnTellScreenShareStart, getSkywayOnTellWaitScreenShare, setLocalStream, getSkywayAvatarNumberForSend, getSkywayStartToggle, getSkywayUseCustomAvatarIcon, getSkywayCustomAvatarIconURL } from '../../logics/dataOps';
import { getVideo, setVideo } from '../../logics/domOps';
import { requestOnNetReceiveVoiceJoin, requestOnNetReceiveVoiceLeave } from '../../logics/moduleCaller';
import { EHeliMicPermissionState } from '../IHeliNetwork';
import { CHELSkywayPlayer } from './CHELSkyPlayer';
import { CApiNotification } from "../apiNotificationLib/CApiNotification";
function converChannelSessionToPlayerInfo(channelSession) {
    return _objectSpread({}, channelSession.user, channelSession);
}
export class CHeliChsPlayerInfo {
    getValue(key) {
        if (this.m_playerInfo) {
            return this.m_playerInfo[key];
        }
        return undefined;
    }
    getUserId() {
        var _this_m_playerInfo;
        return (_this_m_playerInfo = this.m_playerInfo) === null || _this_m_playerInfo === void 0 ? void 0 : _this_m_playerInfo.user_code;
    }
    getChannelSessionCode() {
        var _this_m_playerInfo;
        return (_this_m_playerInfo = this.m_playerInfo) === null || _this_m_playerInfo === void 0 ? void 0 : _this_m_playerInfo.channel_session_code;
    }
    getRole() {
        var _this_m_playerInfo, _this_m_playerInfo1;
        if (!((_this_m_playerInfo = this.m_playerInfo) === null || _this_m_playerInfo === void 0 ? void 0 : _this_m_playerInfo.user_role)) {
            return 'normal';
        }
        return (_this_m_playerInfo1 = this.m_playerInfo) === null || _this_m_playerInfo1 === void 0 ? void 0 : _this_m_playerInfo1.user_role;
    }
    constructor(playerInfo){
        _defineProperty(this, "m_playerInfo", void 0);
        this.m_playerInfo = playerInfo;
    }
}
export class CHeliSkywayPosChannelList {
    getList() {
        const iHeliPosChList = [];
        let ArrayNum = 0;
        for(let i = this.m_offset; i < this.m_position_channels.length; i++){
            if (this.m_limit !== undefined && this.m_limit !== null && ArrayNum >= this.m_limit) {
                break;
            }
            iHeliPosChList.push(this.m_position_channels[i]);
            ArrayNum++;
        }
        return iHeliPosChList;
    }
    setError(error) {
        this.m_errorMessage = error;
    }
    getError() {
        return this.m_errorMessage;
    }
    isValid() {
        return this.m_errorMessage === null;
    }
    constructor(postion_channels = [], offset = 0, limit = null){
        _defineProperty(this, "m_position_channels", void 0);
        _defineProperty(this, "m_offset", void 0);
        _defineProperty(this, "m_limit", void 0);
        _defineProperty(this, "m_errorMessage", void 0);
        this.m_position_channels = postion_channels;
        this.m_offset = offset;
        this.m_limit = limit;
        this.m_errorMessage = '';
    }
}
export class CHeliSkywayPosChannnel {
    getError() {
        return this.m_error;
    }
    setError(errorMessage) {
        this.m_error = errorMessage;
    }
    getValue(key) {
        if (this.positionChannel) {
            return this.positionChannel[key];
        }
        return null;
    }
    async join(authorityJwt, ticketJwt) {
        const authorizationJwt = authorityJwt;
        try {
            var // CHeliSkywayPosChannel.joinでconnectRoomAfterPeerOpenを呼び、OnNetPosConnectedをpeer.on('open')で呼びたいところだが
            // 旧UIとの互換性でIHeliNetwork.connectの直後にconnectRoomAfterPeerOpenが呼ばれて通信が始まるようになっているので
            // ChS-APIの接続完了後にOnNetPosConnectedでCRP接続完了を通知する
            _this_m_parent_m_IHeliNetworkCallback;
            const res = (await worldViewerWindow.heliport.api.channelSessionApi.enterPositionChannel(this.m_channelId, authorizationJwt, ticketJwt)).data;
            if (res.code >= 400) {
                // 入室エラー
                this.setError(JSON.stringify(res));
                return false;
            }
            this.m_parent.m_selfPlayerInfo = this.parseJwt((await worldViewerWindow.heliport.api.channelSessionApi.getChannelSessionJwt()).data);
            this.m_parent.setCurrentPositionChannel(this);
            (_this_m_parent_m_IHeliNetworkCallback = this.m_parent.m_IHeliNetworkCallback) === null || _this_m_parent_m_IHeliNetworkCallback === void 0 ? void 0 : _this_m_parent_m_IHeliNetworkCallback.OnNetPosConnected();
            return true;
        } catch (error) {
            console.error('iHeliNet joinPosChannel error:', error);
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    parseJwt(token) {
        if (typeof token !== 'string') {
            return null;
        }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
    async leave() {
        var // Join関数のOnNetPosConnectedとの対比でleaveに記述
        // 座標チャンネルからの退出を通知
        _this_m_parent_m_IHeliNetworkCallback;
        this.m_parent.setCurrentPositionChannel(null);
        // ChS-APIの切断
        await worldViewerWindow.heliport.api.channelSessionApi.leavePositionChannel();
        (_this_m_parent_m_IHeliNetworkCallback = this.m_parent.m_IHeliNetworkCallback) === null || _this_m_parent_m_IHeliNetworkCallback === void 0 ? void 0 : _this_m_parent_m_IHeliNetworkCallback.OnNetPosDisconnected();
    }
    async update(params) {
        try {
            const result = (await worldViewerWindow.heliport.api.channelSessionApi.updatePositionChannel(this.m_channelId, params.channelType, params.name, params.description)).data;
            if (result.code >= 400) {
                this.setError(JSON.stringify(result));
                return false;
            }
            this.positionChannel = result.data.position_channel;
            return true;
        } catch (error) {
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async refresh() {
        try {
            const resPosChDetail = (await worldViewerWindow.heliport.api.channelSessionApi.fetcthPositionChannelDetail(this.m_channelId)).data;
            if (resPosChDetail.code >= 400) {
                this.setError(JSON.stringify(resPosChDetail));
                return false;
            }
            const postionChannelDetail = resPosChDetail.data.position_channel;
            this.positionChannel = postionChannelDetail;
            return true;
        } catch (error) {
            this.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return false;
        }
    }
    async getPlayerList() {
        const updateResult = await this.refresh();
        if (updateResult && this.positionChannel && this.positionChannel.channel_sessions) {
            return this.positionChannel.channel_sessions.map((channelSession)=>{
                const playerInfoDetail = converChannelSessionToPlayerInfo(channelSession);
                return new CHeliChsPlayerInfo(playerInfoDetail);
            });
        } else {
            return [];
        }
    }
    constructor(parent, positionChannel){
        _defineProperty(this, "m_parent", void 0);
        _defineProperty(this, "m_channelId", null);
        _defineProperty(this, "m_channelType", null);
        _defineProperty(this, "positionChannel", null);
        _defineProperty(this, "m_error", '');
        this.m_parent = parent;
        if (positionChannel) {
            this.m_channelId = positionChannel.channel_id;
            this.m_channelType = positionChannel.channel_type;
            this.positionChannel = positionChannel;
            this.m_error = '';
        }
    }
}
export class CHeliSkyway {
    // 接続
    async connect(configKey) {
        if (!navigator.mediaDevices) {
            debugLog('navigator.mediaDevices not found');
            return null;
        }
        // LocalStreamの取得
        const localStream = g_useVideoStreamFlag ? await this.setLocalAudioStreamWithDummyVideoBy() : await this.setLocalAudioStreamBy();
        // マイク許可状態の確認
        await this.checkMicPermissionState();
        // マイクが許可されていなければ以下の接続をおこなわない
        if (localStream == null) {
            closeLocalStream();
            return null;
        }
        // default mic mute
        worldViewerWindow.hel_skyway_set_mic_state(false);
        // Render local stream
        await CHeliSkyway.playLocalStream(localStream);
        //
        const peer = new Peer({
            key: configKey === undefined ? getSkywayKey() : configKey,
            debug: 0
        });
        this.setPeer(peer);
        peer.on('error', console.error);
        return this.getPeer();
    }
    disconnect() {
        this.disconnectRoom();
        this.destroyPeer();
        closeLocalStream();
        CHeliSkyway.resetLocalStream();
    }
    isConnected() {
        const peer = this.getPeer();
        if (peer == null) return false;
        return true;
    }
    getPeerId() {
        const peer = this.getPeer();
        if (peer == null) return '';
        const id = peer.id !== undefined ? peer.id : '';
        return id;
    }
    setPeer(peer) {
        this.m_Peer = peer;
    }
    getPeer() {
        return this.m_Peer;
    }
    destroyPeer() {
        if (this.m_Peer == null) return;
        this.m_Peer.destroy();
        this.m_Peer = null;
    }
    setMicEnabled(enabled) {}
    // マイク許可状態
    getMicPermissionState() {
        return this.m_MicPermissionState;
    }
    async checkMicPermissionState() {
        const prevPermissionState = this.m_MicPermissionState;
        try {
            const permissionStatus = await navigator.permissions.query({
                name: 'microphone'
            });
            if (permissionStatus.state === 'denied') {
                this.m_MicPermissionState = EHeliMicPermissionState.Denied;
            } else if (permissionStatus.state === 'granted') {
                this.m_MicPermissionState = EHeliMicPermissionState.Granted;
            } else if (permissionStatus.state === 'prompt') {
                this.m_MicPermissionState = EHeliMicPermissionState.Prompt;
            } else {
                this.m_MicPermissionState = EHeliMicPermissionState.Prompt;
            }
        } catch (e) {
            // Firefoxでは'microphone'に対応していないので、LocalEmptyStreamを使用しているかで判別する(マイク不許可時でもマルチプレイを行うために必ず何かしらのLocalStreamが生成されるようになっている)
            if (this.m_UseLocalEmptyStream) {
                // LocalEmptyStreamを使用している時はマイク不許可状態である
                this.m_MicPermissionState = EHeliMicPermissionState.Denied;
            } else {
                this.m_MicPermissionState = EHeliMicPermissionState.Granted;
            }
        }
        // iOSではマイク不許可時にDeniedではなくPromptしか返ってこず、マイク許可を促すCanvasが出なくなってしまうのでDeniedにする
        if (worldViewerWindow.hel_isSafari() && this.m_MicPermissionState == EHeliMicPermissionState.Prompt) {
            this.m_MicPermissionState = EHeliMicPermissionState.Denied;
        }
        // 状態変更によるコールバック呼び出し
        if (this.m_IHeliNetworkCallback != null && prevPermissionState != this.m_MicPermissionState) {
            this.m_IHeliNetworkCallback.OnChangedMicPermissionState(this.m_MicPermissionState);
        }
    }
    setCurrentPositionChannel(posionChannel) {
        this.m_current_position_channel = posionChannel;
    }
    /**
   *
   * 座標チャネル
   */ getCurrentPositionChannel() {
        return this.m_current_position_channel;
    }
    async getPosChannel(channelid) {
        const res = (await worldViewerWindow.heliport.api.channelSessionApi.fetcthPositionChannelDetail(channelid)).data;
        if (res.code >= 400) {
            return null;
        }
        const postionChannelDetail = res.data.position_channel;
        const iHeliPositionChannel = new CHeliSkywayPosChannnel(this, postionChannelDetail);
        return iHeliPositionChannel;
    }
    async createPosChannel(spatiumCode, worldCode, params) {
        try {
            const resCreatePosChannel = (await worldViewerWindow.heliport.api.channelSessionApi.createPositionChannel(spatiumCode, worldCode, params.channelType, params.name, params.description)).data;
            if (resCreatePosChannel.code >= 400) {
                const iHeliPositionChannel = new CHeliSkywayPosChannnel(this, null);
                iHeliPositionChannel.setError(JSON.stringify(resCreatePosChannel));
                return iHeliPositionChannel;
            }
            const postionChannelDetail = resCreatePosChannel.data.position_channel;
            const iHeliPositionChannel = new CHeliSkywayPosChannnel(this, postionChannelDetail);
            return iHeliPositionChannel;
        } catch (error) {
            const iHeliPositionChannel = new CHeliSkywayPosChannnel(this, null);
            iHeliPositionChannel.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return iHeliPositionChannel;
        }
    }
    async getPosChannelList(spatiumcode, worldCode, params) {
        try {
            // 座標チャンネルリストを表示
            const excludeEmptyChannelFlg = params.excludeEmptyChannelFlg !== undefined && params.excludeEmptyChannelFlg !== null ? params.excludeEmptyChannelFlg : true;
            const response = (await worldViewerWindow.heliport.api.channelSessionApi.fetchWolrdPositionChannelList(spatiumcode, worldCode, excludeEmptyChannelFlg)).data;
            if (response.code >= 400) {
                const iHeliPosChList = new CHeliSkywayPosChannelList();
                iHeliPosChList.setError(`${response.code}:${response.message}`);
                return iHeliPosChList;
            }
            const posChList = response.data.position_channels.map((positionChannel)=>{
                const postionChannelDetail = positionChannel;
                const iHeliPositionChannel = new CHeliSkywayPosChannnel(this, postionChannelDetail);
                return iHeliPositionChannel;
            });
            const offset = params.offset ? params.offset : 0;
            const limit = params.limit ? params.limit : null;
            const iHeliPosChList = new CHeliSkywayPosChannelList(posChList, offset, limit);
            return iHeliPosChList;
        } catch (error) {
            const iHeliPosChList = new CHeliSkywayPosChannelList();
            iHeliPosChList.setError(JSON.stringify({
                code: 400,
                message: 'internal error',
                type: 'heliodor',
                status: 'ng',
                data: error
            }));
            return iHeliPosChList;
        }
    }
    /**
   *
   * 音声チャネル
   */ async createVoiceChannel(posionChannelCode, params) {
        return null;
    }
    async getVoiceChannel(channelid) {
        return null;
    }
    async getVoiceChannelList(poschannelid, params) {
        return null;
    }
    getCurrentVoiceChannel() {
        return null;
    }
    // トーク
    connectTalk(roomid) {
        // const leaveTrigger = document.getElementById('js-leave-trigger');
        const remoteVideos = document.getElementById('js-remote-streams');
        // Note that you need to ensure the peer has connected to signaling server
        // before using methods of peer instance.
        const peer = this.getPeer();
        if (peer == null || !peer.open) {
            debugLog('peer not open');
            return;
        }
        //
        const localStream = getLocalStream();
        if (localStream === undefined) {
            debugLog('localStream is not exist');
            return;
        }
        // roomidにサブドメインを付加した名前を実際には利用する
        // これは異なるドメインで同一ワールド名だと同じroomidが取得できてしまうのを回避するための対策
        const roomIdWithSubDomain = CHeliSkyway.addSubDomainRoomId(roomid);
        //
        const room = peer.joinRoom(roomid, {
            mode: 'sfu',
            stream: localStream,
            videoReceiveEnabled: true
        });
        this.setSkywayRoom(room);
        room.once('open', ()=>{
            var // Carnelianとの互換性でOpenで音声チャンネルのCRPにつながったことにする
            _this_m_IHeliNetworkCallback, // PlayerID(PeerID)が使用可能になったことを通知する
            _this_m_IHeliNetworkCallback1;
            worldViewerWindow.hel_skyway_receive_data('open', peer.id, '');
            // to set my name
            worldViewerWindow.hel_skyway_receive_data('data', peer.id, 'name:' + getSkywayName());
            // send my name to old player
            this.sendMyPlayerInfoToAllPlayer();
            (_this_m_IHeliNetworkCallback = this.m_IHeliNetworkCallback) === null || _this_m_IHeliNetworkCallback === void 0 ? void 0 : _this_m_IHeliNetworkCallback.OnNetVoiceConnected("");
            (_this_m_IHeliNetworkCallback1 = this.m_IHeliNetworkCallback) === null || _this_m_IHeliNetworkCallback1 === void 0 ? void 0 : _this_m_IHeliNetworkCallback1.OnNetCreatedPlayerID(peer.id);
        });
        room.on('peerJoin', (peerId)=>{
            this.createPlayer(peerId);
            // send my name to new player
            this.sendMyPlayerInfoToAllPlayer();
            // 新たにジョインしたプレイヤーに対して、既に画面共有していた場合はstreamを送る
            this.sendScreenShareStart();
            // 音声チャンネルへのJoinを通知
            requestOnNetReceiveVoiceJoin(peerId, '');
        });
        // Render remote stream for new peer join in the room
        room.on('stream', async (stream)=>{
            this.setPlayerStream(stream);
            // 映像ストリームを含んでいた場合、再生可能にするため格納する
            const remoteVideoStreamTracks = stream.getVideoTracks();
            if (remoteVideoStreamTracks.length > 0) {
                const remoteVideoStream = new MediaStream(remoteVideoStreamTracks);
                // @ts-ignore
                const peerId = stream.peerId || stream.id;
                videoStreams.set(peerId, remoteVideoStream);
            }
            const newAudio = document.createElement('audio');
            newAudio.srcObject = stream;
            newAudio.playsInline = true;
            // mark peerId to find it later at peerLeave event
            newAudio.setAttribute('data-peer-id', stream.peerId);
            remoteVideos.append(newAudio);
            await newAudio.play().catch(console.error);
        });
        // 画面共有用にstreamを受けるのに使用する
        peer.on('call', (mediaConnection)=>{
            console.log('Skyway called');
            // このanswerで答えることでこの下のstreamを受けることが出来る
            mediaConnection.answer(getLocalStream());
            mediaConnection.on('stream', async (stream)=>{
                console.log('Skyway call stream');
                // Render remote stream for callee
                const updatedVideo = document.getElementById('video0');
                setVideo(updatedVideo);
                const video = getVideo();
                if (video) {
                    console.log('video connect');
                    video.srcObject = stream;
                    video.playsInline = true;
                    await video.play().catch(console.error);
                } else {
                    console.log('video null');
                }
            });
        });
        //
        room.on('data', ({ data , src  })=>{
            // Show a message sent to the room and who sent
            //      messages.textContent += `${src}: ${data}\n`;
            // debugLog(`${src}: ${data}`);
            if (data === 'screenshare:tell') {
                const fn = getSkywayOnTellScreenShareStart();
                if (fn !== undefined) {
                    fn();
                    return;
                }
            } else if (data === 'screenshare:waitstart') {
                const fn = getSkywayOnTellWaitScreenShare();
                if (fn !== undefined) {
                    fn();
                    return;
                }
            }
            if (data.startsWith('screenshare:')) {
                const paramsScreenShare = data.split(':');
                if (paramsScreenShare[1] === 'start') {
                    const remoteStream = videoStreams.get(src);
                    if (worldViewerWindow.hel_video_is_playing_same_stream(remoteStream)) {
                        return;
                    }
                    // グローバルにhel_onReceiveNoticeScreenShareStartが定義されていた場合に実行する
                    if (typeof worldViewerWindow.hel_onReceiveNoticeScreenShareStart === 'function') {
                        worldViewerWindow.hel_onReceiveNoticeScreenShareStart(src, remoteStream, paramsScreenShare[2], paramsScreenShare[3]);
                    }
                    // 予約
                    this.reserveRemotoVideoStream(remoteStream, paramsScreenShare[2], paramsScreenShare[3]);
                    // プレイヤー情報に画面共有中のフラグを設定する
                    const player = this.getPlayer(src);
                    if (player != null) {
                        player.SetScreenShare(true);
                    }
                    // HeliScriptのコールバックを呼び出す
                    worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_OnReceivedStartScreenShare, '');
                }
                if (paramsScreenShare[1] === 'stop') {
                    this.stopCallScreenShare(src);
                    // プレイヤー情報の画面共有中フラグを解除する
                    const player = this.getPlayer(src);
                    if (player != null) {
                        player.SetScreenShare(false);
                    }
                }
            }
            worldViewerWindow.hel_skyway_receive_data('data', src, data);
            // dataが 'msg:' の場合は、チャットメッセージ。なのでチャットのバッジ更新を行う。
            if (data.startsWith('msg:')) {
                worldViewerWindow.hel_chatbadge_ui_update(); // チャットログバッジのUI更新
            }
        });
        // for closing room members
        room.on('peerLeave', (peerId)=>{
            // そのプレイヤーが画面共有中であれば解除する
            const player = this.getPlayer(peerId);
            if (player != null && player.IsScreenShare()) {
                this.stopCallScreenShare(peerId);
            }
            //
            worldViewerWindow.hel_skyway_release_player(peerId);
            const remoteVideo = remoteVideos.querySelector(`[data-peer-id="${peerId}"]`);
            if (remoteVideo && remoteVideo.srcObject) {
                remoteVideo.srcObject.getTracks().forEach((track)=>track.stop());
                remoteVideo.srcObject = null;
                remoteVideo.remove();
            }
            if (videoStreams.has(peerId)) {
                // ビデオストリームの除去
                videoStreams.delete(peerId);
                if (typeof worldViewerWindow.hel_onReceiveNoticeScreenShareStop === 'function') {
                    worldViewerWindow.hel_onReceiveNoticeScreenShareStop(peerId);
                }
            }
            // messages.textContent += `=== ${peerId} left ===\n`;
            // worldViewerWindow.hel_skyway_add_text_chat("", `${peerId} leaved`);
            worldViewerWindow.hel_skyway_receive_data('leave', peerId, '');
            // 音声チャンネルからのLeaveを通知
            requestOnNetReceiveVoiceLeave(peerId, '');
        });
        // for closing myself
        room.once('close', ()=>{
            var // Carnelianとの互換性でcloseで音声チャンネルのCRPから切断したことにする
            _this_m_IHeliNetworkCallback;
            Array.from(remoteVideos.children).forEach((remoteVideo)=>{
                remoteVideo.srcObject.getTracks().forEach((track)=>track.stop());
                remoteVideo.srcObject = null;
                remoteVideo.remove();
            });
            this.releaseAllPlayer();
            (_this_m_IHeliNetworkCallback = this.m_IHeliNetworkCallback) === null || _this_m_IHeliNetworkCallback === void 0 ? void 0 : _this_m_IHeliNetworkCallback.OnNetVoiceDisconnected();
        });
        // roomidをURLに反映する
        worldViewerWindow.hel_net_replace_room_url_with_roomid(roomid);
    }
    connectRoomAfterPeerOpen(roomid) {
        const peer = this.getPeer();
        if (peer == null) {
            throw new Error('peer has not been set yet');
        }
        peer.on('open', ()=>{
            this.connectTalk(roomid);
        });
    }
    disconnectRoom() {
        if (this.m_Room == null) return;
        this.m_Room.close();
        this.m_Room = null;
    }
    setSkywayRoom(meshRoom) {
        this.m_Room = meshRoom;
    }
    static addSubDomainRoomId(roomid) {
        const parts = location.hostname.split('.');
        const subdomain = parts.shift();
        const skywayRoomId = subdomain + '_' + roomid;
        return skywayRoomId;
    }
    // ハートビート
    sendHeartBeat() {
        if (!this.isInRoom()) return;
        const numOfPlayers = 1 + worldViewerWindow.getNumOfPlayers(this.getSkywayRoom());
        // console.log("hel_skyway_send_heartbeat " + NumofPlayer);
        worldViewerWindow.sendHeartbeat(getSkyRoomId(), numOfPlayers);
    }
    // プレイヤー
    createPlayer(peerId) {
        const skywayPlayer = new CHELSkywayPlayer(peerId, getMixerRealSettingAt(HEL_AUDIO_TYPE_VOICE));
        const skywayPlayerList = this.m_PlayerList;
        for(let n = 0; n < skywayPlayerList.length; n++){
            if (skywayPlayerList[n] === null) {
                this.setPlayerList(skywayPlayer, n);
                return;
            }
        }
        skywayPlayerList.push(skywayPlayer);
    }
    releasePlayer(peerId) {
        const skywayPlayerList = this.m_PlayerList;
        for(let n = 0; n < skywayPlayerList.length; n++){
            const skywayPlayer = skywayPlayerList[n];
            if (skywayPlayer === null) {
                continue;
            }
            if (skywayPlayer.getPeerId() === peerId) {
                skywayPlayer.release();
                this.setPlayerList(null, n);
                return;
            }
        }
    }
    setPlayerStream(stream) {
        if (!stream.peerId) {
            debugLog('stream.peerId not');
            return;
        }
        let player = this.getPlayer(stream.peerId);
        if (player === null) {
            this.createPlayer(stream.peerId);
            player = this.getPlayer(stream.peerId);
            if (player === null) {
                debugLog('CreatePlayer error : ' + stream.peerId);
                return;
            }
        }
        player.setStream(stream);
    }
    getPlayer(peerId) {
        const skywayPlayerList = this.m_PlayerList;
        for(let n = 0; n < skywayPlayerList.length; n++){
            const skywayPlayer = skywayPlayerList[n];
            if (skywayPlayer === null) {
                continue;
            }
            if (skywayPlayer.getPeerId() === peerId) {
                return skywayPlayer;
            }
        }
        return null;
    }
    sendMyPlayerInfoToAllPlayer() {
        if (!this.isConnected()) return;
        worldViewerWindow.hel_skyway_send_data('join:' + getSkywayName() + ':' + getSkywayAvatarNumberForSend() + ':' + (getSkywayStartToggle() ? 1 : 0) + ':' + (getSkywayUseCustomAvatarIcon() ? 1 : 0));
        // アバター情報を送信する
        if (getSkywayAvatarNumberForSend() !== -1) {
            worldViewerWindow.hel_skyway_send_data('avatar:' + getSkywayAvatarNumberForSend());
        }
        // カスタムアバターアイコンのURLを送信する
        if (getSkywayUseCustomAvatarIcon() && getSkywayCustomAvatarIconURL() !== '') {
            worldViewerWindow.hel_skyway_send_data('customavataricon:' + getSkywayCustomAvatarIconURL());
        }
        // カスタムユーザーデータを送信する
        worldViewerWindow.hel_skyway_myinfo_bridge_call();
    }
    // プレイヤー：音量
    setPlayerVoiceMute(peerId, mute) {
        const Player = this.getPlayer(peerId);
        if (Player === null) return;
        // eslint-disable-next-line new-cap
        Player.SetMute(mute);
    }
    setPlayerVoiceAttenuate(peerId, volume) {
        const Player = this.getPlayer(peerId);
        if (Player === null) return;
        // eslint-disable-next-line new-cap
        Player.SetVoiceAttenuate(volume);
    }
    setAllPlayerVoiceVolume(volume) {
        const skywayPlayerList = this.m_PlayerList;
        for(let n = 0; n < skywayPlayerList.length; n++){
            const skywayPlayer = skywayPlayerList[n];
            if (skywayPlayer === null) {
                continue;
            }
            skywayPlayer.setVolume(volume);
        }
    }
    // 低レベルデータ送信
    sendData(param) {
        const room = this.getSkywayRoom();
        if (room == null) {
            return;
        }
        room.send(param);
    }
    // 画面共有
    async connectWithDisplayMedia() {
        const localVideo = document.getElementById('js-local-stream');
        // const remoteVideos = document.getElementById('js-remote-streams') as HTMLVideoElement;
        //
        // const sdkSrc = document.querySelector('script[src*=skyway]');
        if (!navigator.mediaDevices) {
            debugLog('navigator.mediaDevices not found');
            return;
        }
        const localAudioStream = await CHeliSkyway.setLocalAudioStreamBy();
        const localVideoStream = await navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: {
                width: {
                    ideal: 1280
                },
                height: {
                    ideal: 720
                },
                frameRate: {
                    ideal: 30
                }
            }
        }).catch(console.error);
        localAudioStream.addTrack(localVideoStream.getVideoTracks()[0]);
        // default mic mute
        worldViewerWindow.hel_skyway_set_mic_state(false);
        // Render local stream
        localVideo.muted = true;
        localVideo.srcObject = localAudioStream;
        localVideo.playsInline = true;
        await localVideo.play().catch(console.error);
        // eslint-disable-next-line require-atomic-updates
        this.m_Peer = new Peer({
            key: worldViewerWindow.g_SKYWAY_KEY,
            debug: 0
        });
        this.m_Peer.on('error', console.error);
        this.m_Peer.on('open', ()=>{
            worldViewerWindow.hel_skyway_join();
        });
    }
    async startScreenShare(videoItemName, videoMaterialName, useCamera, useAudio) {
        if (!navigator.mediaDevices) {
            debugLog('navigator.mediaDevices not found');
            return;
        }
        let localVideoStream;
        if (useCamera) {
            localVideoStream = await navigator.mediaDevices.getUserMedia({
                audio: useAudio,
                video: true
            }).catch(console.error);
        } else {
            localVideoStream = await CHeliSkyway.setLocalScreenShareStreamBy(useAudio);
        }
        if (!localVideoStream) {
            return;
        }
        // Webブラウザから画面共有停止したときのイベントを設定する
        localVideoStream.getVideoTracks()[0].addEventListener('ended', ()=>{
            worldViewerWindow.hel_skyway_stop_screen_sharing_via_room();
        });
        //
        this.replaceLocalStream(localVideoStream);
        // Render local stream
        // 動画の再生
        worldViewerWindow.hel_video_stop();
        helcore_video_play(videoItemName, videoMaterialName, './Video/blankVideo.mp4', true, false, false);
        const video = getVideo();
        if (video !== undefined) {
            const previewVideoStream = new MediaStream();
            previewVideoStream.addTrack(localVideoStream.getVideoTracks()[0]);
            video.srcObject = previewVideoStream;
            await video.play();
        }
        this.setScreenShareCurrentInfo(videoItemName, videoMaterialName);
        setTimeout(()=>{
            // 全ユーザーに画面共有の開始を通知する
            this.sendScreenShareStart();
        }, 1000);
        // グローバルに hel_onScreenShareStart が定義されていた場合に実行する
        if (typeof worldViewerWindow.hel_onScreenShareStart === 'function') {
            worldViewerWindow.hel_onScreenShareStart();
        }
        // HeliScriptのコールバックを呼び出す
        worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_OnStartScreenShare, '');
    }
    async stopScreenShare() {
        worldViewerWindow.hel_video_stop();
        const localStream = getLocalStream();
        if (localStream === undefined) {
            return;
        }
        localStream.getVideoTracks().forEach((track)=>track.stop());
        localStream.removeTrack(localStream.getVideoTracks()[0]);
        const canvas = document.createElement('canvas');
        canvas.getContext('2d');
        const dummyTrack = canvas.captureStream().getVideoTracks()[0];
        localStream.addTrack(dummyTrack);
        setLocalStream(localStream);
        this.replaceLocalStream(localStream);
        this.setScreenShareCurrentInfo('', '');
        worldViewerWindow.hel_skyway_send_data(`screenshare:stop`);
        // HeliScriptのコールバックを呼び出す
        worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_OnStopScreenShare, '');
    }
    setScreenShareCurrentInfo(videoItemName, videoMaterialName) {
        this.m_ScreenShare_CurrentVideoItemName = videoItemName;
        this.m_ScreenShare_CurrentVideoMaterialName = videoMaterialName;
    }
    sendScreenShareStart() {
        if (this.m_ScreenShare_CurrentVideoItemName === '') return;
        if (this.m_ScreenShare_CurrentVideoMaterialName === '') return;
        worldViewerWindow.hel_skyway_send_data(`screenshare:start:${this.m_ScreenShare_CurrentVideoItemName}:${this.m_ScreenShare_CurrentVideoMaterialName}`);
    }
    reserveRemotoVideoStream(remoteVideoStream, videoItemName, videoMaterialName) {
        this.m_skyway_reseved_remoteVideoStream = remoteVideoStream;
        this.m_skyway_reseved_videoItemName = videoItemName;
        this.m_skyway_reseved_videoMaterialName = videoMaterialName;
    }
    stopCallScreenShare(peerId) {
        // グローバルにhel_onReceiveNoticeScreenShareStopが定義されていた場合に実行する
        if (typeof worldViewerWindow.hel_onReceiveNoticeScreenShareStop === 'function') {
            worldViewerWindow.hel_onReceiveNoticeScreenShareStop(peerId);
        } else {
            worldViewerWindow.hel_video_stop();
            // HeliScriptのコールバックを呼び出す
            worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_OnReceivedStopScreenShare, '');
        }
    }
    playScreenShare() {
        worldViewerWindow.hel_skyway_send_data('screenshare:waitstart');
        if (this.m_Room == null) return;
        this.m_Room.on('stream', (stream)=>{
            const video = getVideo();
            if (video !== undefined) {
                if (video.srcObject !== stream) {
                    const videoTracks = stream.getVideoTracks();
                    if (videoTracks.length > 0) {
                        video.srcObject = stream;
                        video.playsInline = true;
                    }
                }
            }
        });
    }
    playScreenShareReserved() {
        worldViewerWindow.hel_skyway_play_remote_video_stream(this.m_skyway_reseved_remoteVideoStream, this.m_skyway_reseved_videoItemName, this.m_skyway_reseved_videoMaterialName);
    }
    // コールバック
    setCallback(callback) {
        this.m_IHeliNetworkCallback = callback;
    }
    //
    async setLocalAudioStreamWithDummyVideoBy() {
        const getLocalAudioStream = async ()=>{
            const localStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false
            }).then((val)=>{
                this.m_UseLocalEmptyStream = false;
                return val;
            }).catch((error)=>{
                // マイク無許可時
                console.error('getLocalAudioStream', error);
                this.m_UseLocalEmptyStream = true;
                // 空のStreamを作成する
                const localEmptyStream = new MediaStream();
                return localEmptyStream;
            });
            return localStream;
        };
        const localStream = await getLocalAudioStream();
        if (localStream === undefined || localStream === null) {
            return null;
        }
        const canvas = document.createElement('canvas');
        canvas.getContext('2d');
        localStream.addTrack(canvas.captureStream().getVideoTracks()[0]);
        setLocalStream(localStream);
        return localStream;
    }
    async setLocalAudioStreamBy() {
        try {
            const localStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false
            });
            setLocalStream(localStream);
            this.m_UseLocalEmptyStream = false;
            return localStream;
        } catch (error) {
            // 空のStreamを生成
            const localStream = new MediaStream();
            setLocalStream(localStream);
            this.m_UseLocalEmptyStream = true;
            return localStream;
        }
    }
    static async setLocalScreenShareStreamBy(useAudio) {
        const localStream = getLocalStream();
        const localScreenStream = await navigator.mediaDevices.getDisplayMedia({
            audio: useAudio,
            video: {
                width: {
                    ideal: 1280
                },
                height: {
                    ideal: 720
                },
                frameRate: {
                    ideal: 30
                }
            }
        }).catch(console.error);
        if (!localScreenStream || localStream === undefined) {
            return null;
        }
        localStream.removeTrack(localStream.getVideoTracks()[0]);
        localStream.addTrack(localScreenStream.getVideoTracks()[0]);
        if (useAudio) {
            localStream.addTrack(localScreenStream.getAudioTracks()[0]);
        }
        setLocalStream(localStream);
        return localStream;
    }
    replaceLocalStream(stream) {
        if (this.m_Room == null) {
            console.log('replaceLocalStream : m_room is null');
            return;
        }
        this.m_Room.replaceStream(stream);
        setLocalStream(stream);
    }
    static async playLocalStream(localStream) {
        const localVideo = CHeliSkyway.getLocalVideoElement();
        localVideo.muted = true;
        localVideo.srcObject = localStream;
        localVideo.playsInline = true;
        // HTMLVideoElement.playがブラウザレベルの潜在的なバグか何かで失敗時にRejectを返さずに処理がここでストップするような動作をするので暫定的処置としてawaitを削除する
        // https://github.com/videojs/video.js/issues/5441
        localVideo.play().catch(console.error);
    }
    static resetLocalStream() {
        const localVideo = CHeliSkyway.getLocalVideoElement();
        localVideo.srcObject = null;
        localVideo.load();
    }
    static getLocalVideoElement() {
        return document.getElementById('js-local-stream');
    }
    // プレイヤーリスト
    setPlayerList(skywayPlayer, index) {
        this.m_PlayerList[index] = skywayPlayer;
    }
    releaseAllPlayer() {
        const skywayPlayerList = this.m_PlayerList;
        for(let n = 0; n < skywayPlayerList.length; n++){
            const skywayPlayer = skywayPlayerList[n];
            if (skywayPlayer === null) {
                continue;
            }
            worldViewerWindow.hel_skyway_receive_data('leave', skywayPlayer.getPeerId(), '');
            skywayPlayer.release();
            this.setPlayerList(null, n);
        }
    }
    // ルーム
    getSkywayRoom() {
        return this.m_Room;
    }
    isInRoom() {
        return this.m_Room != null;
    }
    getApiNotificationHandler() {
        return this.m_ApiNotification;
    }
    apiNotificationReciever(scope, type, params) {
        if (this.m_ApiNotification == null) {
            return;
        }
        this.m_ApiNotification.dispatchEvent(scope, type, params);
    }
    apiChangeItemPropertySender(refId, key, value) {
        var _this_m_current_position_channel_positionChannel, _this_m_current_position_channel_positionChannel1, _this_m_current_position_channel_positionChannel2;
        if (this.m_current_position_channel == null) {
            return;
        }
        // APIにメッセージを送信
        worldViewerWindow.heliport.api.ingameStateManagerApi.changeItemProperty((_this_m_current_position_channel_positionChannel = this.m_current_position_channel.positionChannel) === null || _this_m_current_position_channel_positionChannel === void 0 ? void 0 : _this_m_current_position_channel_positionChannel.spatium_code, (_this_m_current_position_channel_positionChannel1 = this.m_current_position_channel.positionChannel) === null || _this_m_current_position_channel_positionChannel1 === void 0 ? void 0 : _this_m_current_position_channel_positionChannel1.world_code, (_this_m_current_position_channel_positionChannel2 = this.m_current_position_channel.positionChannel) === null || _this_m_current_position_channel_positionChannel2 === void 0 ? void 0 : _this_m_current_position_channel_positionChannel2.channel_id, refId, "{\"" + key + "\": \"" + value + "\"}");
    }
    initApiNotificationHandler() {
        var _this_m_ApiNotification;
        (_this_m_ApiNotification = this.m_ApiNotification) === null || _this_m_ApiNotification === void 0 ? void 0 : _this_m_ApiNotification.init();
    }
    /**
   * TODO
   * エンジン対応するまでの暫定実装
   */ getSelfPlayerRole() {
        var _this_m_selfPlayerInfo, _this_m_selfPlayerInfo1;
        // user_roleがnullの場合がある
        if (((_this_m_selfPlayerInfo = this.m_selfPlayerInfo) === null || _this_m_selfPlayerInfo === void 0 ? void 0 : _this_m_selfPlayerInfo.user_role) == null) {
            return "normal";
        }
        return (_this_m_selfPlayerInfo1 = this.m_selfPlayerInfo) === null || _this_m_selfPlayerInfo1 === void 0 ? void 0 : _this_m_selfPlayerInfo1.user_role;
    }
    // コンストラクタ
    constructor(){
        _defineProperty(this, "m_Peer", null);
        // マイク許可状態
        _defineProperty(this, "m_MicPermissionState", EHeliMicPermissionState.Prompt);
        // ルーム
        _defineProperty(this, "m_Room", null);
        // プレイヤーリスト
        _defineProperty(this, "m_PlayerList", []);
        // 画面共有
        _defineProperty(this, "m_ScreenShare_CurrentVideoItemName", '');
        _defineProperty(this, "m_ScreenShare_CurrentVideoMaterialName", '');
        _defineProperty(this, "m_skyway_reseved_remoteVideoStream", null);
        _defineProperty(this, "m_skyway_reseved_videoItemName", '');
        _defineProperty(this, "m_skyway_reseved_videoMaterialName", '');
        // コールバック
        _defineProperty(this, "m_IHeliNetworkCallback", null);
        // 入室中の座標/音声チャンネル
        _defineProperty(this, "m_current_position_channel", null);
        // 空のLocalStreamを使用しているかどうか
        _defineProperty(this, "m_UseLocalEmptyStream", true);
        _defineProperty(this, "m_ApiNotification", null);
        _defineProperty(this, "m_selfPlayerInfo", null);
        this.m_ApiNotification = new CApiNotification();
        this.m_selfPlayerInfo = null;
    }
}
