// HeliodorSDK
import { ETextParamType_SetMicState, ETextParamType_Logined, ETextParamType_CanvasFileName, ETextParamType_CurrentUserName, ETextParamType_CompImageListFileName, worldViewerWindow } from './const';
import { debugLog } from './debugLog';
import { setState } from './heliodor_core_globals';
import { hel_prepareVCUID } from './heliodor_core_misc';
import { checkAndInitVR, checkVR } from './heliodor_xr';
import { presetBeforeBoot } from './logics/assign';
import { hel_resizeWindow } from './logics/callCGui';
import { hel_mixer_LoadCookie_Mute, hel_mixer_LoadCookie_Volume } from './logics/cookieOps';
import { getAnimationFrameRequest, getCanvasFileName, getCompImageListFileName, getCurrentUserName, getLoggedInStatusToggle, getWorldId, setAnimationFrameRequest, setCanvasFileName, setCompImageListFileName, setDataFolderName, setMixerTab, setSceneJsonPath } from './logics/dataOps';
import { initializeGamePad, updateGamepadsInfo } from './logics/device';
import { getMainCanvasDOMElement } from './logics/domOps';
import { hel_mixer_UpdateAll } from './logics/mixerOps';
import { requestBootStrapGameEngine, requestToDrawFrame, requestOnWindowActivate, requestOnBeforeUnload, requestOnUnload, callComponentMethod } from './logics/moduleCaller';
import { requestResizeWindow } from './logics/moduleCaller';
import { hel_isMobile } from './logics/platform';
/**
 * @remarks
 * JRWorld_web->heliodor_front.jsにて参照
 * JRWorld_web->heliodor_xr.jsにて参照
 * XRWorldIngame->heliodor_front.jsにて参照
 * XRWorldIngame->heliodor_xr.jsにて参照
 * XRWorldIngame->heliodor_xrw_main.jsにて参照
 * front, heliodor_xrw_mainから呼び出しがあるためグローバルにとどめてる
 */ worldViewerWindow.hel_setCanvasFileName = (canvasFileName)=>{
    setCanvasFileName(canvasFileName);
};
/**
 * @remarks
 * 圧縮画像リストを使用するときにファイル名を設定する
 */ worldViewerWindow.hel_setCompImageListFileName = (compImageListFileName)=>{
    setCompImageListFileName(compImageListFileName);
};
//
const drawFrame = ()=>{
    updateGamepadsInfo();
    requestToDrawFrame();
    const myReq = worldViewerWindow.requestAnimationFrame(drawFrame);
    setAnimationFrameRequest(myReq);
};
/**
 * @remarks
 */ worldViewerWindow.hel_SetDataFolderName = (DataFolderName)=>{
    setDataFolderName(DataFolderName);
};
/**
 * AnimationFrameのループを開始する
 */ worldViewerWindow.hel_requestAnimationFrame = ()=>{
    drawFrame();
};
/**
 * AnimationFrameのループを停止する
 */ worldViewerWindow.hel_cancelAnimationFrame = ()=>{
    const request = getAnimationFrameRequest();
    worldViewerWindow.cancelAnimationFrame(request);
};
/**
 * frontから呼び出しがあるためグローバルにとどめてる
 * @virtual
 */ worldViewerWindow.hel_start = (Type, CharaType, Param1, MultiPlay, StrParam0)=>{
    setState();
    const mainCanvas = getMainCanvasDOMElement();
    if (mainCanvas === undefined || mainCanvas.width <= 0 || mainCanvas.height <= 0) {
        debugLog('[ hel_start ] mainCanvas is not assigned. noop');
        return;
    }
    const CanTouch = worldViewerWindow.ontouchstart !== undefined;
    setSceneJsonPath(worldViewerWindow.g_DataFolderName + '/' + StrParam0);
    // Initialize
    hel_mixer_LoadCookie_Volume();
    hel_mixer_LoadCookie_Mute();
    hel_prepareVCUID();
    worldViewerWindow.hel_set_show_TextField_configurl(false);
    worldViewerWindow.hel_set_show_TextField_UserName(false);
    // gtag呼び出し
    worldViewerWindow.hel_gtag('Start', {
        param0: getWorldId(),
        param1: MultiPlay
    });
    // Set Parameter
    presetBeforeBoot();
    worldViewerWindow.hel_setText(ETextParamType_Logined, getLoggedInStatusToggle() ? '1' : '0');
    worldViewerWindow.hel_setText(ETextParamType_CanvasFileName, getCanvasFileName());
    worldViewerWindow.hel_setText(ETextParamType_CompImageListFileName, getCompImageListFileName());
    worldViewerWindow.hel_setText(ETextParamType_CurrentUserName, getCurrentUserName());
    //
    requestBootStrapGameEngine(Type, CharaType, Param1, StrParam0, CanTouch, MultiPlay, false);
    initializeGamePad();
    //
    mainCanvas.focus();
    if (mainCanvas !== undefined) {
        requestResizeWindow(mainCanvas);
    }
    drawFrame();
};
/**
 * frontから呼び出しがあるためグローバルにとどめてる
 * @virtual
 */ worldViewerWindow.hel_startScene = (CharaType, MultiPlay, SceneFileName, IsVR = false)=>{
    if (IsVR) {
        checkAndInitVR(SceneFileName, MultiPlay ? 1 : 0);
    } else {
        worldViewerWindow.hel_start(0, CharaType, 0, MultiPlay, SceneFileName);
    }
};
/**
 * Heliodorのシーンロード完了時に呼ばれるコールバック関数
 */ worldViewerWindow.hel_onloadedForSystem = ()=>{
    checkVR(); // WebXRリリース延期のためコメントアウト
};
// 即時実行
document.addEventListener('visibilitychange', ()=>{
    // ウィンドウアクティブ・非アクティブイベント
    requestOnWindowActivate(document.visibilityState !== 'hidden');
    // PCではタブの非アクティブでミュートにしない
    if (!hel_isMobile()) return;
    // 以降はミュート関連の処理
    if (document.visibilityState === 'hidden') {
        setMixerTab(0.0);
        hel_mixer_UpdateAll();
        // Force MicMute
        worldViewerWindow.hel_skyway_set_mic_state(false);
        worldViewerWindow.hel_setText(ETextParamType_SetMicState, '0');
        worldViewerWindow.hel_canvas_ResetToggleDefault('voicechat');
    } else if (document.visibilityState === 'visible') {
        setMixerTab(1.0);
        hel_mixer_UpdateAll();
    }
}, false);
document.addEventListener('fullscreenchange', ()=>hel_resizeWindow());
window.addEventListener('beforeunload', (event)=>requestOnBeforeUnload());
window.addEventListener('pagehide', (event)=>requestOnUnload());
// 以下の関数はダブってるので削除した(sdk3.8: 2023/3/6 nishida）
//  hel_setCanvasLayerShow
// C++側から検索を依頼されたJS関数を登録しておくためのテーブル
worldViewerWindow.heliport_funcTable = [];
// オブジェクト名と関数名で関数を探し、見つかった関数をテーブルに登録する
worldViewerWindow.hel_bindjs_find_func = (objNameList, funcName)=>{
    let targetObj = worldViewerWindow;
    for (const objName of objNameList){
        const newObj = targetObj[objName];
        if (newObj === undefined) {
            return -1;
        }
        targetObj = newObj;
    }
    const func = targetObj[funcName];
    if (func === undefined || typeof func !== 'function') {
        return -1;
    }
    const index = worldViewerWindow.heliport_funcTable.length;
    worldViewerWindow.heliport_funcTable.push(func);
    return index;
};
// IDで指定した登録済みの関数を呼び出す
worldViewerWindow.hel_bindjs_call_func = (funcID, asyncReceiver, params)=>{
    if (funcID < 0 || funcID >= worldViewerWindow.heliport_funcTable.length) {
        return null;
    }
    const result = worldViewerWindow.heliport_funcTable[funcID](...params);
    if (asyncReceiver) {
        // asyncだった場合はここでPromiseの結果を受ける
        result.then(asyncReceiver);
    } else {
        return result;
    }
};
// C++から呼び出される
worldViewerWindow.hel_debug_showstatus = (flag)=>{
    const status = document.getElementById('status');
    if (status) {
        status.style.display = flag ? 'block' : 'none';
    }
    const log = document.getElementById('log');
    if (log) {
        log.style.display = flag ? 'block' : 'none';
    }
};
// ////////////////////////////
// JSからHSのコンポーネントメソッド呼び出し
// ////////////////////////////
// シーン側コンポーネント呼び出し
worldViewerWindow.hel_CallSceneComponent = (itemName, componentName, methodName, ...params)=>{
    return callComponentMethod(true, itemName, componentName, methodName, params);
};
// Canvas側コンポーネント呼び出し
worldViewerWindow.hel_CallCanvasComponent = (layerName, componentName, methodName, ...params)=>{
    return callComponentMethod(false, layerName, componentName, methodName, params);
};
// 初回ローディング率をint値の0～100の値を返す
// ローディングが完了していたら100を返す
// 未完了であれば100未満を返す
worldViewerWindow.hel_GetLoadingRate = ()=>{
    return worldViewerWindow.hel_setText(worldViewerWindow.ETextParamType_GetFirstLoadingRate, "");
};
