import { ETextParamType_OperateCanvas, worldViewerWindow } from '../const';
import { getMainCanvasDOMElement } from './domOps';
import { requestResizeWindow } from './moduleCaller';
/**
 * @remarks
 * ウィンドウクライアントサイズをcanvasに反映させる
 */ export const hel_reflectCanvasSize = ()=>{
    if (!worldViewerWindow.g_ReflectCanvasSizeMode) return;
    const mainCanvas = getMainCanvasDOMElement();
    if (mainCanvas === undefined) return;
    const width = Math.floor(mainCanvas.clientWidth * window.devicePixelRatio);
    const height = Math.floor(mainCanvas.clientHeight * window.devicePixelRatio);
    if (width <= 0 || height <= 0) {
        return;
    }
    mainCanvas.width = width;
    mainCanvas.height = height;
};
/**
 * @remarks
 * JSからC++に画面サイズの変更をリクエスト
 */ export const hel_resizeWindow = ()=>{
    hel_reflectCanvasSize();
    const mainCanvas = getMainCanvasDOMElement();
    if (mainCanvas !== undefined) {
        requestResizeWindow(mainCanvas);
    }
};
/**
 * @remarks
 * JSからC++を呼出し
 * 未使用
 * @param layoutName
 * @param guiName
 * @param show
 */ export const hel_canvas_SetCloneGUIShow = (layoutName, guiName, show)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetCloneGUIShow,' + layoutName + ',' + guiName + ',' + (show ? 'true' : 'false'));
};
/**
 * @remarks
 * JSからC++を呼出し
 * 未使用
 * @param layoutName
 * @param guiName
 * @param text
 */ export const hel_canvas_SetCloneGUIText = (layoutName, guiName, text)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetCloneGUIText,' + layoutName + ',' + guiName + ',' + text);
};
/**
 * @remarks
 * JSからC++を呼出し
 * 未使用
 * @param layoutName
 * @param guiName
 * @param fileName
 */ export const hel_canvas_SetCloneGUIImage = (layoutName, guiName, fileName)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetCloneGUIImage,' + layoutName + ',' + guiName + ',' + fileName);
};
/**
 * @remarks
 * JSからC++を呼出し
 * 未使用
 * @param layoutName
 * @param show
 */ export const hel_canvas_SetCloneLayoutShow = (layoutName, show)=>{
    worldViewerWindow.hel_setText(ETextParamType_OperateCanvas, 'SetCloneLayoutShow,' + layoutName + ',' + (show ? 'true' : 'false'));
};
