import { worldViewerWindow } from './const';
import { getVideoRecorder, setVideoRecorder, getRecordedVideoBlobObj, setRecordedVideoBlobObj, getRecordedVideoBlobURL, setRecordedVideoBlobURL, setVideoRecorderExtension, getAudioList, getVideoRecordCanvas, setVideoRecordCanvas, getVideoRecordContext, setVideoRecordContext } from './logics/dataOps';
import { getMainCanvasDOMElement } from './logics/domOps';
/**
 * HeliScriptから参照あり
 */ worldViewerWindow.hel_release_RecordedVideo = ()=>{
    // 以前のBlobがまだ存在する場合はまず解放する
    const prevBlob = getRecordedVideoBlobURL();
    if (prevBlob !== undefined && prevBlob !== null) {
        URL.revokeObjectURL(prevBlob);
        setRecordedVideoBlobURL(null);
    }
    setRecordedVideoBlobObj(null);
};
/**
 * HeliScriptから参照あり
 */ worldViewerWindow.hel_start_VideoRecording = ()=>{
    //
    let recorder = getVideoRecorder();
    if (recorder === undefined || recorder === null) {
        const MainCanvas = getMainCanvasDOMElement();
        if (MainCanvas === undefined || MainCanvas === null) return;
        // レコード用のキャンバスを作成
        const RecordCanvas = document.createElement('canvas');
        RecordCanvas.style.visibility = 'hidden';
        RecordCanvas.width = MainCanvas.width;
        RecordCanvas.height = MainCanvas.height;
        setVideoRecordCanvas(RecordCanvas);
        const RecordCtx = RecordCanvas.getContext('2d');
        if (RecordCtx !== null) {
        // RecordCtx.imageSmoothingEnabled = false;
        }
        setVideoRecordContext(RecordCtx);
        // ビデオレコーダーを作成
        recorder = createRecoreder();
        // レコード開始
        recorder.start();
        setVideoRecorder(recorder);
    }
};
const createRecoreder = ()=>{
    //
    let recorder = null;
    let supportedType = null;
    let extension = null;
    const recordedStream = new MediaStream();
    // レコードタイプと拡張子を選定
    if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9,opus')) {
        supportedType = 'video/webm;codecs=vp9,opus';
        extension = 'webm';
    } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8,opus')) {
        supportedType = 'video/webm;codecs=vp8,opus';
        extension = 'webm';
    } else if (MediaRecorder.isTypeSupported('video/mp4;codecs=avc1')) {
        supportedType = 'video/mp4;codecs=avc1';
        extension = 'mp4';
    }
    // キャンバストラックをストリームに追加
    const RecordCanvas = getVideoRecordCanvas();
    if (RecordCanvas !== undefined && RecordCanvas !== null) {
        const CanvasStream = RecordCanvas.captureStream();
        const videoTrack = CanvasStream.getVideoTracks();
        recordedStream.addTrack(videoTrack[0]);
    }
    // オーディオトラックをストリームに追加
    const audioList = getAudioList();
    if (audioList !== undefined && audioList !== null) {
        audioList.forEach((element)=>{
            if (element !== null) {
                if (element.isPlaying() && element.getType() == 0) {
                    // BGM
                    const context = element.getContext();
                    if (context !== undefined && (context === null || context === void 0 ? void 0 : context.state) == 'running') {
                        const gainNode = element.getGainNode();
                        if (gainNode !== undefined) {
                            const dest = context.createMediaStreamDestination();
                            gainNode.connect(dest);
                            const audioStream = dest.stream;
                            const audioTracks = audioStream.getTracks();
                            const audioTrack = audioTracks[0];
                            if (audioStream.active && audioTrack !== undefined && audioTrack !== null && audioTrack.enabled && !audioTrack.muted) {
                                recordedStream.addTrack(audioTrack);
                            }
                        }
                    }
                }
            }
        });
    }
    // レコーダーを作成
    if (supportedType !== null && supportedType != '') {
        recorder = new MediaRecorder(recordedStream, {
            mimeType: supportedType
        });
    }
    // レコード完了後のイベントを追加
    if (recorder) {
        recorder.ondataavailable = (e)=>{
            const videoBlob = new Blob([
                e.data
            ], {
                type: e.data.type
            });
            setRecordedVideoBlobObj(videoBlob);
            const BlobURL = window.URL.createObjectURL(videoBlob);
            setRecordedVideoBlobURL(BlobURL);
        };
    }
    //
    setVideoRecorderExtension(extension);
    return recorder;
};
/**
 * C++から参照あり
 */ worldViewerWindow.hel_request_frame_for_VideoRecording = ()=>{
    const recorder = getVideoRecorder();
    if (recorder !== undefined && recorder !== null) {
        const videoTrack = recorder.stream.getVideoTracks()[0];
        const RecordCanvas = getVideoRecordCanvas();
        const MainCanvas = getMainCanvasDOMElement();
        if (videoTrack !== undefined && videoTrack !== null && RecordCanvas !== undefined && RecordCanvas !== null && MainCanvas !== undefined && MainCanvas !== null) {
            const ctx = getVideoRecordContext();
            ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(MainCanvas, 0, 0);
        // videoTrack.requestFrame();
        }
    }
};
/**
 * HeliScriptから参照あり
 */ worldViewerWindow.hel_stop_VideoRecording = ()=>{
    const recorder = getVideoRecorder();
    if (recorder !== undefined && recorder !== null) {
        recorder.stop();
        // レコード関連のリソースを解放する
        const tracks = recorder.stream.getTracks();
        tracks.forEach((track)=>{
            track.stop();
            recorder.stream.removeTrack(track);
        });
        setVideoRecorder(null);
        const RecordCanvas = getVideoRecordCanvas();
        if (RecordCanvas !== undefined && RecordCanvas !== null) {
            RecordCanvas.remove();
            setVideoRecordCanvas(null);
            setVideoRecordContext(null);
        }
    }
};
/**
 * HeliScriptから参照あり
 */ worldViewerWindow.hel_open_recorded_video_preview = ()=>{
    const BlobURL = getRecordedVideoBlobURL();
    if (BlobURL !== undefined && BlobURL !== null) {
        if (worldViewerWindow.hel_isiPhone()) {
            window.location.href = BlobURL;
        } else {
            window.open(BlobURL, '_blank');
        }
    }
};
/**
 * HeliScriptから参照有り
 */ worldViewerWindow.hel_convert_webm_to_mp4 = async ()=>{
    const videoBlob = getRecordedVideoBlobObj();
    let responseData = '';
    if (videoBlob !== undefined && videoBlob !== null && !worldViewerWindow.hel_isiPhone()) {
        const formData = new FormData();
        formData.append('file', videoBlob);
        await fetch('https://vket-helper-chan.api.vket.com/webm_convert', {
            method: 'POST',
            body: formData
        }).then((res)=>{
            if (!res.ok) {
                throw new Error(`${res.status} ${res.statusText}`);
            }
            return res.json();
        }).then((data)=>{
            responseData = data.images.file;
        });
    } else {
        responseData = getRecordedVideoBlobURL();
    }
    return responseData;
};
/**
 * HeliScriptから参照あり
 */ worldViewerWindow.hel_save_recorded_video = (downloadURL)=>{
    if (downloadURL !== undefined && downloadURL !== null && downloadURL !== '') {
        const extension = 'mp4';
        // 保存ダイアログを開く
        const a = document.createElement('a');
        a.href = downloadURL;
        const today = new Date();
        a.download = '' + today.getFullYear() + ('00' + (today.getMonth() + 1)).slice(-2) + ('00' + today.getDate()).slice(-2) + '_' + ('00' + today.getHours()).slice(-2) + ('00' + today.getMinutes()).slice(-2) + ('00' + today.getSeconds()).slice(-2) + '.' + extension;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
};
/**
 *
 */ worldViewerWindow.hel_get_VideoRecordCanvas = ()=>{
    return getVideoRecordCanvas();
};
/**
 *
 */ worldViewerWindow.hel_get_VideoRecordContext = ()=>{
    return getVideoRecordContext();
};
