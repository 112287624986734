function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { CAchievement } from "./CAchievement";
import { CTextChat } from "./CTextChat";
import { CItemProperty } from "./CItemProperty";
import { CKickBan } from "./CKickBan";
export class CApiNotification {
    init() {
        if (this.m_Achievement == null) {
            this.m_Achievement = new CAchievement();
        }
        this.m_Achievement.addReceivedToastEventListener(this.m_Achievement.send);
        if (this.m_TextChat == null) {
            this.m_TextChat = new CTextChat();
        }
        this.m_TextChat.addReceivedChatEventListener(this.m_TextChat.send);
        this.m_TextChat.addReceivedSystemChatEventListener(this.m_TextChat.send);
        if (this.m_ItemProperty == null) {
            this.m_ItemProperty = new CItemProperty();
        }
        this.m_ItemProperty.addReceivedPropertyChangeEventListener(this.m_ItemProperty.send);
        if (this.m_KickBan == null) {
            this.m_KickBan = new CKickBan();
        }
        this.m_KickBan.addReceivedKickEventListener(this.m_KickBan.send);
    }
    addEventListener(scope, type, fn) {
        const scopes = this.m_callbackMap.get(scope);
        if (!scopes) {
            // 初回の場合
            const typeMap = new Map();
            this.m_callbackMap.set(scope, typeMap.set(type, [
                fn
            ]));
        } else {
            // 既にある場合、追加
            const callbacks = scopes.get(type);
            callbacks === null || callbacks === void 0 ? void 0 : callbacks.push(fn);
            if (!callbacks) {
                // scopeがない場合
                scopes.set(type, [
                    fn
                ]);
            }
        }
    }
    dispatchEvent(scope, type, params) {
        const scopes = this.m_callbackMap.get(scope);
        const callbacks = scopes === null || scopes === void 0 ? void 0 : scopes.get(type);
        callbacks === null || callbacks === void 0 ? void 0 : callbacks.forEach((fn)=>{
            fn(params);
        });
    }
    constructor(){
        _defineProperty(this, "m_Achievement", null);
        _defineProperty(this, "m_TextChat", null);
        _defineProperty(this, "m_ItemProperty", null);
        _defineProperty(this, "m_KickBan", null);
        // Scope
        _defineProperty(this, "m_notifyCollectionSystem", "System");
        _defineProperty(this, "m_notifyCollectionPositionChannel", "PositionChannel");
        _defineProperty(this, "m_notifyCollectionPersonal", "Personal");
        _defineProperty(this, "m_callbackMap", new Map());
    }
}
